import Tag from 'components/Tag/index';


/**
 * Criteria not found tag that is used in the context of label annotations.
 */
export default function CriteriaNotMetTag() {
  return (
    <Tag>Criteria Not Met</Tag>
  );
}
