import {size} from 'lodash';
import PropTypes from 'prop-types';

import ClinicalAnnotationEvidenceList from 'components/ClinicalAnnotation/EvidenceList';
import GuidelineAnnotationDetail from 'components/GuidelineAnnotation/Detail';
import HistoryTab from 'components/History/Tab';
import LinksTab from 'components/LinksTab';
import PublicationsTab from 'components/Literature/PublicationsTab';
import Loading from 'components/Loading';
import SideNav from 'components/SideNav';
import KyError from 'components/errors/KyError';
import NotFound from 'components/shared/error/not_found';
import FactSection from 'components/shared/fact_section';
import FullWidthResourceTemplate from 'components/templates/FullWidthResource';
import useAppContext from 'conf/AppContext';
import Types from 'conf/types';
import {useGet} from 'helpers/KyHooks';
import GuidelineAnnotationRelatedChemicalsTab from 'pages/GuidelineAnnotation/RelatedChemicalsTab';

const propTypes = {
  id: PropTypes.string.isRequired,
  section: PropTypes.string,
  loadId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
/**
 * Page component to fetch data for a guideline annotation detail
 *
 * @param {object} props - props container
 * @param {string} props.id - the guideline annotation ID
 * @param {string} props.section - the tab/section to display
 * @param {string|number} props.loadId - used to force re-fetch of the data
 */
export default function GuidelineAnnotationPage({id, section, loadId = 0}) {
  const appContext = useAppContext();
  const {response, error} = useGet(`site/guidelineAnnotation/${id}`, {
    cacheResponse: !appContext.isPreview,
  }, {
    loadId,
  });

  const baseUrl = Types.guidelineAnnotation.url(id);
  const menuItems = [
    {url: `${baseUrl}/annotation`, label: 'Annotation', otherUrls: [baseUrl]},
  ];

  let name = `Guideline Annotation ${id}`;
  let content = <Loading />;
  if (error) {
    if (error?.response?.status === 404) {
      return <NotFound />;
    }
    content = <KyError kyError={error} />;
  } else if (response) {
    const {guideline, relatedChemicals: otherChemicals, clinicalAnnotationCount, approved, gsiAlleles = {}} = response.data;
    const hasGenotypes = (guideline.source === 'CPIC' || guideline.source === 'DPWG') && guideline.recommendation;

    // set up tabs and ordering
    menuItems.push({url: `${baseUrl}/relatedChemicals`, label: 'Related Chemicals', count: {total: size(guideline.relatedChemicals) + size(otherChemicals), pediatric: 0}});
    menuItems.push({url: `${baseUrl}/publications`, label: 'Publications', count: {total: size(guideline.literature), pediatric: 0}});
    menuItems.push({url: `${baseUrl}/clinicalAnnotation`, label: 'Clinical Annotations', count: {total: clinicalAnnotationCount, pediatric: 0}});
    menuItems.push({url: `${baseUrl}/link`, label: 'Links'});
    menuItems.push({url: `${baseUrl}/history`, label: 'History'});

    // determine what to display in-page
    if (section === 'annotation') {
      content = <GuidelineAnnotationDetail {...guideline} gsiAlleles={gsiAlleles} otherChemicals={otherChemicals} hasGenotypes={hasGenotypes} approved={approved} />;
    } else if (section === 'publications') {
      content = (
        <PublicationsTab
          literature={guideline.literature}
          blurb={(
            <p>
              <i>
                A guideline may have more than one publication if it has been updated by the originating group since its
                original publication.
              </i>
            </p>
          )}
        />
      );
    } else if (section === 'history') {
      content = <HistoryTab {...guideline} />;
    } else if (section === 'link') {
      content = <LinksTab id={id} objCls={guideline.objCls} />;
    } else if (section === 'clinicalAnnotation') {
      content = <ClinicalAnnotationEvidenceList id={guideline.id} objCls={guideline.objCls} />;
    } else if (section === 'relatedChemicals') {
      content = <GuidelineAnnotationRelatedChemicalsTab id={guideline.id} relatedChemicals={guideline.relatedChemicals} otherChemicals={otherChemicals} />;
    } else {
      content = <FactSection title="Not Found"><p>Section not found for this guideline</p></FactSection>;
    }
    name = guideline.name;
  }

  return (
    <FullWidthResourceTemplate id={id} type="guidelineAnnotation" name={name}>
      <SideNav items={menuItems}>
        {content}
      </SideNav>
    </FullWidthResourceTemplate>
  );
}
GuidelineAnnotationPage.propTypes = propTypes;
