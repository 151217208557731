import PropTypes from 'prop-types';

import FeedbackLink from 'components/links/Feedback';
import ReadableWidth from 'components/templates/ReadableWidth';
import useAppContext from 'conf/AppContext';


const propTypes = {
  location: PropTypes.string,
  msg: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
};
export default function ServerError({location, msg}) {
  const appContext = useAppContext();
  const body = `Server error @ /${location}.`;
  return (
    <ReadableWidth title="Server Error">
      <p>We encountered an error while processing your request.</p>

      <p>
        If you arrived at this page via a link from another site
        please <FeedbackLink subject="Server error (external source)" body={body} srcUrl={location}>let us know</FeedbackLink> so
        that we can work with them to fix the issue.
      </p>

      <p>
        If you arrived at this page via a link from within PharmGKB,
        please <FeedbackLink subject="Server error (internal source)" body={body} srcUrl={location}>let us know</FeedbackLink> so
        that we may correct the error.
      </p>

      <p>Our apologies for the inconvenience,<br />The PharmGKB Team</p>
      {
        msg && appContext.user && (<div className="alert alert-danger">{msg}</div>)
      }
    </ReadableWidth>
  );
}
ServerError.propTypes = propTypes;
