import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import {extractIconProps, extractProps} from 'utils/propUtils';


const propTypes = {
  // iconProps
  className: PropTypes.string,
  size: PropTypes.string,
  transform: PropTypes.string,
};

export default function EditIcon(props) {
  return <FontAwesomeIcon icon={['far', 'pen-to-square']} {...extractIconProps(props)} {...extractProps(props)} />;
}
EditIcon.propTypes = propTypes;
