import logger from 'conf/Logger';

/**
 * This is a click handler to copy text to the clipboard.
 * This should only be used on modern browsers, and as such should be limited to curators-only.
 *
 * The element with the onClick handler must have the `data-clipboard-text` attribute filled in.
 */
const copyToClipboard = (event) => {
  event.preventDefault();
  event.stopPropagation();
  if (!navigator?.clipboard) {
    logger.error('No clipboard');
    return;
  }
  let dataNode = event.target;
  while (dataNode && !dataNode.getAttribute('data-clipboard-text')) {
    dataNode = dataNode.parentElement;
  }
  if (dataNode) {
    const text = dataNode.getAttribute('data-clipboard-text');
    if (text) {
      navigator.clipboard.writeText(text)
        .catch((err) => {
          logger.error('Error copying to clipboard:', err);
        });
    } else {
      logger.warn('Nothing to copy!');
    }
    dataNode.blur();
  } else {
    logger.warn('Missing "data-clipboard-text" attribute');
  }
  event.target.blur();
};

export default copyToClipboard;
