import {filter, groupBy, keys, map, merge, size, some, sortBy, uniq} from 'lodash';
import {useState} from 'react';
import {toast} from 'react-toastify';

import DeleteButton from 'components/Button/Delete';
import EditButton from 'components/Button/Edit';
import ApproveControl from 'components/edit/ApproveControl';
import EditControls from 'components/edit/EditControls';
import KyError from 'components/errors/KyError';
import DownloadIcon from 'components/icons/Download';
import Link from 'components/links/Link';
import ResourceLink from 'components/links/Resource';
import CuratorOnly from 'components/shared/curator_only';
import Fact from 'components/shared/fact';
import FactSection from 'components/shared/fact_section';
import HtmlContainer from 'components/shared/html_container';
import {DataSource} from 'conf/enums';
import {FileTypeEnum} from 'pages/Guideline/FileTypeEnum';

export default function GuidelineOverviewTab({
  guideline, annotations, fileArtifacts, flowcharts, publications = [], supplements = [],
}) {
  const [exists, setExists] = useState(true);
  const {id, link, summary} = guideline;

  const deleteSuccessHandler = () => {
    setExists(false);
    toast.success(<span>Deleted <Link href="/edit/guidelines">Back to List</Link></span>);
  };

  const deleteFailureHandler = (deleteId, err) => {
    toast.error(<KyError kyError={err} />);
  };

  const files = merge(groupBy(fileArtifacts, (f) => f.type), groupBy(flowcharts, (f) => f.type));
  const years = uniq(map([...publications, ...supplements], (f) => f.year));
  const isCpic = some(annotations, (a) => a.source === DataSource.displayName['Clinical Pharmacogenetics Implementation Consortium']);
  
  return (
    <>
      <EditControls>
        <EditButton href={`/edit/guideline/${id}`} disabled={!exists} />
        <ApproveControl id={id} type="Guideline" disabled={!exists} />
        <DeleteButton
          url={`curation/guideline/${id}`}
          id={id}
          onSuccess={deleteSuccessHandler}
          onFailure={deleteFailureHandler}
          disabled={!exists}
        />
      </EditControls>

      {summary && (
        <FactSection title="Summary">
          <HtmlContainer html={summary.html} />
        </FactSection>
      )}

      <FactSection title={size(annotations) > 1 ? 'Annotations' : 'Annotation'}>
        <p className="font-italic">
          This section contains annotations that PharmGKB curation staff have written on this guideline.
        </p>
        {size(annotations) > 1 && (
          <p className="font-italic">
            There are multiple annotations because this guideline covers more than one gene-drug pair and has distinct
            information for each.
          </p>
        )}
        {map(annotations, (ann) => (
          <Fact key={ann.id} label={<ResourceLink resource={ann} />}>
            <HtmlContainer html={ann.summaryMarkdown.html} />
          </Fact>
        ))}
      </FactSection>

      {isCpic && (
        <CuratorOnly>
          <FactSection title="CPIC Files">
            {map(sortBy(years).reverse(), (y) => (
              <Fact key={y} label={`${y} Guideline Publication`}>
                {map(filter(publications, (p) => p.year === y), (p) => <div key={p.url}><FileLink label="Publication" {...p} /></div>)}
                {map(filter(supplements, (p) => p.year === y && p.url.endsWith('.pdf')), (p) => <div key={p.url}><FileLink label="Supplement" {...p} /></div>)}
              </Fact>
            ))}
            {map(sortBy(keys(files)), (k) => (
              <Fact key={k} label={FileTypeEnum[k]} inline={true}>{map(files[k], (f) => <div key={f.url}><FileLink {...f} /></div>)}</Fact>
            ))}
          </FactSection>
        </CuratorOnly>
      )}

      <FactSection title="Source">
        <p className="font-italic">This is the original source of the guideline that PharmGKB has annotated.</p>
        <Link href={link._url}>{link.name || link.resourceId}</Link>
      </FactSection>
    </>
  );
}

function FileLink({url, label}) {
  const filename = decodeURIComponent(url?.substring(url.lastIndexOf('/') + 1));
  const prefix = label ? label + ' ' : '';
  return (
    <>
      <Link href={url}><DownloadIcon /> {filename}</Link>
      {' '}
      {label && <span>{prefix}</span>}
    </>
  );
}
