import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {filter, map} from 'lodash';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';

import Button from 'components/Button';
import ApproveButton from 'components/Button/Approve';
import EditButton from 'components/Button/Edit';
import HistoryTable from 'components/History/Table';
import LiteratureCitation, {getDefaultCitation, renderAuthors} from 'components/Literature/Citation';
import PharmgkbTagButton from 'components/PharmgkbTag/Button';
import PediatricTag from 'components/Tag/Pediatric';
import EditControls from 'components/edit/EditControls';
import InfoIcon from 'components/icons/Info';
import LicenseIcon from 'components/icons/License';
import ResourceCounts, {convertTypedCounts, countProps} from 'components/resource/resourceCounts';
import FactSection from 'components/shared/fact_section';
import HtmlContainer, {addHtmlContainerIfDefined} from 'components/shared/html_container';
import {useTour} from 'components/tour/hook';
import useAppContext from 'conf/AppContext';
import usePediatricContext from 'context/Pediatric';


const propTypes = {
  pathway: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    imageLink: PropTypes.string,
    imageMap: PropTypes.string,
    summary: PropTypes.shape({
      html: PropTypes.string,
    }),
    description: PropTypes.shape({
      html: PropTypes.string,
    }),
    citation: PropTypes.object,
    authors: PropTypes.string,
    terms: PropTypes.arrayOf(PropTypes.shape({
      resource: PropTypes.string.isRequired,
      term: PropTypes.string.isRequired,
    })),
    pediatric: PropTypes.bool,
    history: PropTypes.arrayOf(PropTypes.object),
    _submissionId: PropTypes.string,
  }),
  counts: PropTypes.shape({
    pathway: countProps,
    literature: countProps,
  }),
  needsApproval: PropTypes.bool,
  userId: PropTypes.string,
};
/**
 * Renders pathway overview tab.
 */
export default function PathwayOverviewTab({pathway, counts, needsApproval, userId}) {
  const appContext = useAppContext();
  const pediatricContext = usePediatricContext();
  const [isApproved, setApproved] = useState(!needsApproval);

  const readyForTour = useTour('pathway');
  useEffect(() => {
    readyForTour();
  }, [readyForTour]);


  const resourceCounts = convertTypedCounts('pathway', pathway.id, counts);
  const {citation} = pathway;
  let authors;
  let citationSection;
  if (citation && citation.title) {
    authors = renderAuthors(citation.authors);
    citationSection = (<LiteratureCitation {...citation} />);
  } else {
    authors = pathway?.authors;
    citationSection = getDefaultCitation();
  }

  let editControls;
  if (appContext.isPreview) {
    const onApproval = () => {
      toast.success('Pathway approved');
      setApproved(true);
    };
    editControls = (
      <EditControls>
        <EditButton href={`/edit/pathway/${pathway.id}`} label="Edit Pathway" />
        <Button href="/edit/pathway/upload/gpml" className="btn-primary">
          <FontAwesomeIcon icon="upload" /> Upload GPML
        </Button>
        <Button href={`/edit/pathway/${pathway.id}/upload/files`} className="btn-warning">
          <FontAwesomeIcon icon="upload" /> Upload Files
        </Button>
        <ApproveButton objCls="Pathway" objId={pathway.id} disabled={isApproved} onSuccess={onApproval} />
        <Button key="submission" href={`/submission/${pathway._submissionId}`} className="btn-secondary">
          <FontAwesomeIcon icon={['far', 'file']} /> Submission
        </Button>
        <PharmgkbTagButton objId={pathway.id} objCls="pathway" />
        <span className="ml-2 fixed-width-font">owner: {userId || <em>unassigned</em>}</span>
      </EditControls>
    );
  }

  const renderPediatricTag = () => {
    if (!pediatricContext.isPediatricMode || !pathway.pediatric) {
      return '';
    }
    return (
      <div className="mt-3">
        <PediatricTag />
      </div>
    );
  };

  return (
    <>
      {editControls}
      <FactSection>
        <ResourceCounts counts={resourceCounts} />
        {renderPediatricTag()}
      </FactSection>
      <FactSection title="Summary" id="pathway-summary">
        {renderMarkdown(pathway?.summary?.html)}
      </FactSection>

      <FactSection>
        {renderDiagram(pathway?.imageLink, pathway?.imageMap, pathway?.id)}
      </FactSection>

      <FactSection title="Description" id="pathway-description">
        {renderMarkdown(pathway?.description?.html)}
      </FactSection>

      <FactSection title="Authors">
        <span className="fact-content">{authors}</span>
      </FactSection>

      <FactSection title="Citation" id="pathway-citation">
        <div className="mb-3">
          {citationSection}
        </div>
        <p>Please consult the <a href="/page/citingPharmgkb">citation policy</a> on how to cite this pathway.</p>
      </FactSection>

      <FactSection title="Therapeutic Categories">
        {renderCategories(pathway?.terms)}
      </FactSection>

      {pathway?.history && (
        <FactSection title="History">
          <HistoryTable history={pathway?.history} parentCls="Pathway" parentId={pathway.id} />
        </FactSection>
      )}
    </>
  );
}
PathwayOverviewTab.propTypes = propTypes;


function renderDiagram(imageLink, imageMap, id) {
  if (!imageLink) {
    return <p className="empty">None</p>;
  }
  return (
    <div className="pathwayDiagram">
      <img src={imageLink} alt="pathway diagram" useMap="#pathwayMap" />
      <div className="pathwayDiagram--footer">
        <div className="legend"><Button href="/page/pathwayLegend"><InfoIcon /> Diagram Legend</Button></div>
        <div className="info-box">
          <LicenseIcon compact={true} /> Pathway <a href={`/pathway/${id}/downloads`}>images and data</a> are available under a Creative
          Commons BY-SA 4.0 license.
        </div>
      </div>
      {addHtmlContainerIfDefined(imageMap, {inline: true, style: {display: 'none'}})}
    </div>
  );
}

function renderMarkdown(mdHtml) {
  if (mdHtml) {
    return <HtmlContainer html={mdHtml} />;
  } else {
    return <div className="empty">None</div>;
  }
}

function renderCategories(terms) {
  const categories = filter(terms, (t) => t.resource === 'Pathway Categories');
  if (categories.length === 0) {
    return (<div className="empty">None</div>);
  }
  return (
    <ul>
      {map(categories, (c) => <li key={c.id}>{c.term}</li>)}
    </ul>
  );
}
