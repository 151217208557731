import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import PropTypes from 'prop-types';


const propTypes = {
  headerGroup: PropTypes.object,
  isGroup: PropTypes.bool,
  memoizedMaxDepth: PropTypes.number.isRequired,
  memoizedColumns: PropTypes.arrayOf(PropTypes.object),
  column: PropTypes.object.isRequired,
  colNum: PropTypes.number.isRequired,
};
/**
 * Renders a column header (<th />) for a react-table.
 */
export default function ColumnHeader({headerGroup, isGroup = false, memoizedMaxDepth, memoizedColumns,
  column, colNum}) {
  const groupClassName = (colNum > 0 && isGroup) ? 'reactTable__group--border' : '';
  const headerProps = {
    className: clsx(column.headerClassName || column.className, groupClassName),
  };
  if (column.style) {
    headerProps.style = column.style;
  } else if (memoizedMaxDepth > 0 && memoizedColumns?.[0]?.id === 'expander' &&
    column.id.indexOf('expander_placeholder_') === 0) {
    headerProps.style = memoizedColumns[0].style;
  }
  if (column.isSorted) {
    headerProps['aria-sort'] = column.isSortedDesc ? 'descending' : 'ascending';
  }


  const renderContainer = (children) => {
    if (column.sortable) {
      return <button {...column.getSortByToggleProps()} className="headerContainer">{children}</button>;
    }
    return <div className="headerContainer">{children}</div>;
  };

  const addResizer = column?.getResizerProps && !column.disableResizing && headerGroup.headers.length !== colNum + 1;
  const allHeaderProps = column.getHeaderProps({...headerProps});
  return (
    <th {...allHeaderProps} key={allHeaderProps?.key} scope={isGroup ? 'colgroup' : 'col'}>
      {renderContainer((
        <span>
          {column.render('Header')}
          {_renderSortIcon(column)}
        </span>
      ))}
      {addResizer && <div {...column.getResizerProps()} className="reactTable__resizer" />}
    </th>
  );
}
ColumnHeader.propTypes = propTypes;


function _renderSortIcon(col) {
  if (col.sortable) {
    if (col.isSorted) {
      if (col.isSortedDesc) {
        return <span className="sortIcon">&nbsp;<FontAwesomeIcon icon={['fad', 'sort-down']} aria-label="sort descending" /></span>;
      } else {
        return <span className="sortIcon">&nbsp;<FontAwesomeIcon icon={['fad', 'sort-up']} aria-label="sort ascending" /></span>;
      }
    }
    return <span className="sortIcon">&nbsp;<FontAwesomeIcon icon={['fad', 'sort']} aria-label="not sorted" /></span>;
  }
  return '';
}
