import {startsWith} from 'lodash';
import PropTypes from 'prop-types';

import FeedbackLink from 'components/links/Feedback';
import ReadableWidthTemplate from 'components/templates/ReadableWidth';


const propTypes = {
  location: PropTypes.string,
};

export default function GoneErrorPage({location}) {
  let link = 'link.';
  if (location) {
    const url = startsWith(location, '/') ? location : `/${location}`;
    link = `link to "${url}".`;
  }

  return (
    <ReadableWidthTemplate title="No Longer Available">
      <p className="error-code">Error Code: 410</p>

      <p>The page you requested is no longer available.</p>

      <p>
        If you arrived at this page via a link from another site
        please <FeedbackLink body={`Bad external ${link}`}>let us know</FeedbackLink> so
        that we can work with them to fix the issue.
      </p>

      <p>
        If you arrived at this page via a link from within PharmGKB,
        please <FeedbackLink body={`Bad internal ${link}`}>let us know</FeedbackLink> so
        that we may correct the error.
      </p>

      <p>Our apologies for the inconvenience,<br />The PharmGKB Team</p>
    </ReadableWidthTemplate>
  );
}

GoneErrorPage.propTypes = propTypes;
