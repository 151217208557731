import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import Button from 'components/Button';
import EditControls from 'components/edit/EditControls';
import Link from 'components/links/Link';
import CuratorOnly from 'components/shared/curator_only';
import {CrossReferenceResource} from 'conf/enums';
import {crossReferenceProps} from 'conf/types';

import './LitXref.scss';


export function resourceLabel(xref) {
  switch (xref.resource) {
    case 'PubMed':
      return 'PMID:';
    case 'DOI':
      return 'DOI:';
    case 'PubMed Central':
      return 'PMCID:';
    case CrossReferenceResource.shortName.fda:
    case CrossReferenceResource.shortName.DailyMed:
    case CrossReferenceResource.shortName.url:
      return `${xref.resource}: `;
    default:
      return `${xref.resource}:`;
  }
}


const propTypes = {
  xref: crossReferenceProps.isRequired,
};
/**
 * Renders a link for a literature's cross-reference.
 * This is a special case because we use special labels.
 *
 * @return {JSX.Element}
 */
export default function LitXrefLink({xref}) {
  if (!xref) {
    return null;
  }
  return renderLitXrefLink(resourceLabel(xref), xref.resourceId, xref._url, null, xref.resource);
}
LitXrefLink.propTypes = propTypes;


/**
 * Helper function to render literature link.
 *
 * @param {string} label
 * @param {string} id
 * @param {string} url
 * @param {string} [title]
 * @param {string} resource
 * @return {JSX.Element}
 */
export function renderLitXrefLink(label, id, url, title, resource) {
  const props = {};
  if (title) {
    props.title = title;
  }
  let diffLink = null;
  if (resource === CrossReferenceResource.shortName.fda) {
    diffLink = (
      <CuratorOnly styled={false}>
        <EditControls>
          <Button className="btn-secondary btn-xs" href={`/labelApplication/${id}/diff`}><FontAwesomeIcon icon={['far', 'code']} /> Diff</Button>
        </EditControls>
      </CuratorOnly>
    );
  }
  return (
    <span className="litXrefLink" {...props}>
      <span className="litXrefLink__label">{label}</span><Link href={url}>{id}</Link> {diffLink}
    </span>
  );
}
