import './MedicalDisclaimer.scss';

export default function MedicalDisclaimer({}) {
  return (
    <div className="medicalDisclaimer">
      <div className="container">
        The information on this website is not intended for direct diagnostic use or medical
        decision-making without review by a health care professional. Individuals should not
        change their health behavior solely on the basis of information contained on this
        website. If you have questions about the information contained on this website, please
        see a health care professional.
      </div>
    </div>
  );
}
