import PropTypes from 'prop-types';
import React from 'react';

import MailtoLink from 'components/links/Mailto';


const propTypes = {
  subject: PropTypes.string,
  body: PropTypes.string,
  srcUrl: PropTypes.string,
  className: PropTypes.string,
  role: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

/**
 * Renders a feedback link.  If `children` is not provided, the link text will be "contact us".
 * The anchor element's 'aria-label' attribute will be set to "Send email".
 *
 * @param {object} [props]
 * @param {string} [props.subject]
 * @param {string} [props.body]
 * @param {string} [props.srcUrl] - URL of page feedback is sent from
 * @param {string} [props.className]
 * @param {string} [props.role]
 * @param {node|string} [props.children] - the link text
 */
export default function FeedbackLink({subject = 'PharmGKB Feedback', body, srcUrl,
  className, role, children = 'contact us'}) {
  let text = '';
  if (srcUrl) {
    const url = srcUrl.startsWith('/') ? srcUrl : `/${srcUrl}`;
    text += `Feedback from page "${url}"\n\n`;
  }
  if (body) {
    text += body + '\n\n\n';
  }

  return (
    <MailtoLink
      email="feedback@pharmgkb.org"
      subject={subject}
      body={text}
      className={className}
      role={role}
    >
      {children || 'contact us'}
    </MailtoLink>
  );
}

FeedbackLink.propTypes = propTypes;
