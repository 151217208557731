import PropTypes from 'prop-types';
import {isString, map} from 'lodash';


export const hitPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  objCls: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
  description: PropTypes.string,
  thumbnailLink: PropTypes.string,
  numClinicalAnnotations: PropTypes.number,
  numGuidelineAnnotations: PropTypes.number,
  numLabelAnnotations: PropTypes.number,
  numVariantAnnotations: PropTypes.number,
});

const connectionsRegex = /\[[A-Za-z]+:[Pp]?[AaSs]?[0-9]+:".*?"]/g;
const connectionRegex = /([A-Za-z]+):([Pp]?[AaSs]?[0-9]+):"(.*?)"/;

/**
 * Converts a resources string ('[gene:PA124:"name"][chemical:PA451906:"name"]') into an array of hits.
 *
 * @param {string} pinnedResources - a resources string
 * @return {Array} array of simple hits
 */
export function getHitsFromPinnedResources(pinnedResources) {
  if (!pinnedResources || !isString(pinnedResources)) {
    return [];
  }

  const rez = pinnedResources.match(connectionsRegex);
  if (!rez) {
    return [];
  }
  return map(rez, (c) => {
    const entity = c.match(connectionRegex);
    return {objCls: entity[1], id: entity[2], name: entity[3]};
  });
}

export function getConnectionsFromPinnedResources(pinnedResources) {
  if (!pinnedResources || !isString(pinnedResources)) {
    return [];
  }

  const rez = pinnedResources.match(connectionsRegex);
  if (!rez) {
    return [];
  }
  return map(rez, (c) => {
    const entity = c.match(connectionRegex);
    return `${entity[1]}/${entity[2]}`;
  });
}


export const combinationUrl = (pinnedHits) => `/combination/${map(pinnedHits, (h) => h.id).join(',')}`;
