import {filter} from 'lodash';

import Link from 'components/links/Link';
 import {DataSource} from 'conf/enums';

export default function DrugTargetItem({id, object1, object2, sources, subjectId}) {
  const obj = findObject(object1, object2, subjectId);
  const sourceText = filter(sources, (s) => s !== DataSource.shortName.PharmGKB).join(', ');

  return (
    <li>
      <Link href={`/linkAnnotation/${id}`}>{obj.symbol || obj.name}</Link> <span className="text-muted small">(source: {sourceText})</span>
    </li>
  );
}

export function findObject(obj1, obj2, subjectId) {
  if (!obj2 || obj2.id === subjectId) {
    return obj1;
  } else {
    return obj2;
  }
}
