import clsx from 'clsx';
import {useSelect} from 'downshift';
import {filter, find, isUndefined, map, orderBy, sortBy, uniqueId} from 'lodash';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';

import 'components/Table/Filter/MultiSelect.scss';
import './Tab.scss';
import {renderItemWithTags} from 'components/List/Item';
import SearchableList, {COUNT_MODE} from 'components/List/Searchable';
import Loading from 'components/Loading';
import KyError from 'components/errors/KyError';
import useAppContext from 'conf/AppContext';
import {useGet} from 'helpers/KyHooks';
import useUniqueId from 'hooks/uniqId';
import {makeOntologyTermOptions} from 'utils/formUtils';


const propTypes = {
  /**
   * Type of entity these literature are for.
   */
  objCls: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  /**
   * Title for tab.
   */
  title: PropTypes.string,
  blurb: PropTypes.node,
  /**
   * Literature to display.
   * If not specified, literature will be loaded dynamically.
   */
  literature: PropTypes.arrayOf(PropTypes.object),
};
/**
 * Renders literature tab.
 */
export default function LiteratureTab({objCls, id, title = 'Literature', blurb, literature}) {
  const appContext = useAppContext();
  const sortId = useUniqueId({prefix: 'sort-'});
  const [data, setData] = useState();
  const [filters, setFilters] = useState({paperTypes: [], hasInitialFiltered: false, hasInitialSorted: true});
  const [sort, setSort] = useState('1');
  const [error, setError] = useState(false);

  const {response: pgxPaperTypesResponse, error: pgxPaperTypesError} = useGet('data/ontologyTerm', {
    searchParams: {resource: 'pgxPaperTypes', view: 'max'},
  }, {
    transform: (r) => makeOntologyTermOptions(r.data),
  });

  useEffect(() => {
    const abortController = new AbortController();
    const loadData = async () => {

      try {
        let rez;
        if (isUndefined(literature)) {
          const target = objCls === 'combination' ? id : `${objCls}/${id}`;
          rez = await appContext.api.get(`site/tab/literature/${target}`, {
            parseJson: true,
            cacheResponse: true,
          });
        }

        let matches = literature || rez?.data;
        const {paperTypes} = filters;
        if (paperTypes.length > 0) {
          matches = filter(matches, (r) => {
            for (let x = 0; x < paperTypes.length; x += 1) {
              const {label} = find(pgxPaperTypesResponse, ({value}) => value === paperTypes[x]);
              const {pgxPaperTypes} = r.properties;
              if (pgxPaperTypes.includes(label)) {
                return true;
              }
            }
            return false;
          });
        }

        if (sort === '1') { // publication date sort by descending
          matches = orderBy(matches,
            [(d) => d.properties?.year ?? -1, (d) => d.properties?.month ?? -1, 'name'],
            ['desc', 'desc', 'asc']);
        } else if (sort === '2') { // publication date sort by ascending
          matches = sortBy(matches, ['properties.year', 'properties.month', 'name']);
        } else if (sort === '3') { // connectivity sort by descending
          matches = orderBy(matches,
            [(d) => d.connectivity ?? -1, 'name'],
            ['desc', 'asc']);
        }
        setData(matches);
      } catch (err) {
        setError(err);
      }
    };

    // noinspection JSIgnoredPromiseFromCall
    loadData();
    return () => {
      abortController.abort();
    };
  }, [filters, sort]);

  const updatePaperTypesFilters = (selectedPaperTypes) => {
    setFilters({...filters, paperTypes: selectedPaperTypes, hasInitialFiltered: selectedPaperTypes.length > 0});
  };

  const changeHandler = (e) => {
    setSort(e.target.value);
  };

  let body = <Loading />;
  if (error || pgxPaperTypesError) {
    body = (
      <>
        <KyError kyError={error} />;
        <KyError kyError={pgxPaperTypesError} />;
      </>
    );
  } else if (data && pgxPaperTypesResponse) {
    const extraControls = (
      <>
        <DropdownSelect
          items={pgxPaperTypesResponse}
          updateFilters={updatePaperTypesFilters}
          label="Filter by PGx Paper Types"
        />
        <div>
          <label htmlFor={sortId}>Sort by</label>
          <select
            id={sortId}
            name="sort"
            className="custom-select form-control"
            onChange={changeHandler}
            defaultValue={sort}
          >
            <option value="1">Publication Date - Descending</option>
            <option value="2">Publication Date - Ascending</option>
            <option value="3">Connectivity</option>
          </select>
        </div>
      </>
    );

    let countMode;
    if (filters.hasInitialFiltered) {
      countMode = COUNT_MODE.FILTERED;
    } else if (filters.hasInitialSorted) {
      countMode = COUNT_MODE.SORTED;
    } else {
      countMode = COUNT_MODE.LIST;
    }
    body = (
      <SearchableList
        className="mt-4"
        collection={data}
        renderItemFn={renderItemWithTags}
        singularNoun="item"
        searchKeys={['name', 'properties.journal', 'properties.year', 'properties.authors']}
        countMode={countMode}
        extraControls={extraControls}
      />
    );
  }

  return (
    <div className="literatureTab">
      <h3>{title}</h3>
      {
        blurb || (
          <p>
            The publications shown below are associated with this {objCls.toLowerCase()} in PharmGKB based on (1)
            literature annotations, (2) variant annotations, (3) VIP summaries, or (4) pathways.
          </p>
        )
      }
      {body}
    </div>
  );
}
LiteratureTab.propTypes = propTypes;

function stateReducer(state, actionAndChanges) {
  const {changes, type} = actionAndChanges;
  switch (type) {
    case useSelect.stateChangeTypes.MenuKeyDownEnter:
    case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
    case useSelect.stateChangeTypes.ItemClick:
      return {
        ...changes,
        isOpen: true, // keep menu open after selection.
        highlightedIndex: state.highlightedIndex,
      };
    default:
      return changes;
  }
}
function DropdownSelect({items, updateFilters, label}) {
  const [selectedItems, setSelectedItems] = useState([]);
  const [filterId] = useState(uniqueId('list-'));
  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getItemProps,
  } = useSelect({
    items,
    stateReducer,
    selectedItem: null,
    onSelectedItemChange: ({selectedItem}) => {
      if (!selectedItem) {
        return;
      }
      const index = selectedItems.indexOf(selectedItem.value);
      if (index > 0) {
        setSelectedItems([
          ...selectedItems.slice(0, index),
          ...selectedItems.slice(index + 1),
        ]);
        updateFilters([
          ...selectedItems.slice(0, index),
          ...selectedItems.slice(index + 1),
        ]);
      } else if (index === 0) {
        setSelectedItems([...selectedItems.slice(1)]);
        updateFilters([...selectedItems.slice(1)]);
      } else {
        setSelectedItems([...selectedItems, selectedItem.value]);
        updateFilters([...selectedItems, selectedItem.value]);
      }
    },
  });

  let selectedItemLabel;
  if (selectedItems.length === 1) {
    const selectedItem = find(items, (item) => item.value === selectedItems[0]);
    selectedItemLabel = selectedItem.label;
  } else if (selectedItems.length === 0) {
    selectedItemLabel = 'All';
  } else {
    selectedItemLabel = 'Multiple';
  }

  // use base style from Table/Filter/MultiSelect.scss
  return (
    <div className="literatureTabFilter multiSelectFilter">
      <label {...getLabelProps()}>{label}</label>
      <button
        {...getToggleButtonProps({
          type: 'button',
          className: 'custom-select multiSelectFilter__button form-control',
        })}
      >
        {selectedItemLabel}
      </button>

      <ul {...getMenuProps({className: clsx({'list-group': isOpen, 'd-none': !isOpen})})}>
        {isOpen && renderDropdown(getItemProps, getLabelProps, items, selectedItems, filterId)}
      </ul>
    </div>
  );
}

function renderDropdown(getItemProps, getLabelProps, items, selectedItems, filterId) {
  return map(items, (item, index) => (
    <div
      {...getItemProps({
        key: item.value,
        item,
        index,
        className: clsx('list-group-item'),
      })}
    >
      <label
        {...getLabelProps({
          id: `${filterId}-${item.value}`,
          className: 'multiSelectFilter__label',
        })}
      >
        <input
          id={`${filterId}-${item.value}`}
          type="checkbox"
          checked={selectedItems.includes(item.value)}
          value={item.value}
          onChange={() => null}
          className="form-check-inline"
        />
        {item.label}
      </label>
    </div>
  ));
}
