import numeral from 'numeraljs';
import PropTypes from 'prop-types';

import './AnnotationsList.scss';

import clinicalAnnotationIcon from 'images/icons/clinicalAnnotation.svg';
import fdaLabelIcon from 'images/icons/fdaLabel.svg';
import guidelineIcon from 'images/icons/guideline.svg';
import labelIcon from 'images/icons/label.svg';
import pathwayIcon from 'images/icons/pathway.svg';
import variantAnnotationIcon from 'images/icons/variantAnnotation.svg';
import vipIcon from 'images/icons/vip.svg';

import Svg from 'components/shared/Svg';


const propTypes = {
  basicVarAnns: PropTypes.number,
  clinAnns: PropTypes.number,
  dosingGuidelines: PropTypes.number,
  drugLabels: PropTypes.number,
  fdaDrugLabels: PropTypes.number,
  pgkbPathways: PropTypes.number,
  vipGenes: PropTypes.number,
  drugsWithVarAnns: PropTypes.number,
};
/**
 * Renders list of statistics for annotations.
 */
export default function AnnotationLists({
  basicVarAnns = 0,
  clinAnns = 0,
  dosingGuidelines = 0,
  drugLabels = 0,
  fdaDrugLabels = 0,
  pgkbPathways = 0,
  vipGenes = 0,
  drugsWithVarAnns = 0,
}) {
  return (
    <div className="container home-section">
      <h3 className="section-heading annotations">Annotations</h3>
      <div className="row">
        <div className="col-md-6">
          <div className="annotation-left">
            <h4 className="annotation-heading">Clinical</h4>
            <a href="/guidelineAnnotations" className="annotation-item">
              <Svg className="annotation-icon" src={guidelineIcon} />
              <span className="annotation-name">Clinical Guideline Annotations</span>
              <span className="annotation-count">
                {numeral(dosingGuidelines).format('0,0')}
              </span>
            </a>

            <a href="/labelAnnotations" className="annotation-item">
              <Svg className="annotation-icon" src={labelIcon} />
              <span className="annotation-name">Drug Label Annotations</span>
              <span className="annotation-count">
                {numeral(drugLabels).format('0,0')}
              </span>
            </a>

            <a href="/fdaLabelAnnotations" className="annotation-item">
              <Svg className="annotation-icon" src={fdaLabelIcon} />
              <span className="annotation-name">FDA Drug Label Annotations</span>
              <span className="annotation-count">
                {numeral(fdaDrugLabels).format('0,0')}
              </span>
            </a>

            <a href="/clinicalAnnotations" id="clinann" className="annotation-item">
              <Svg className="annotation-icon" src={clinicalAnnotationIcon} />
              <span className="annotation-name">Clinical Annotations</span>
              <span className="annotation-count">
                {numeral(clinAnns).format('0,0')}
              </span>
            </a>
          </div>
        </div>

        <div className="col-md-6">
          <div className="annotation-right">
            <h4 className="annotation-heading">Research</h4>
            <a href="/pathways" className="annotation-item">
              <Svg className="annotation-icon" src={pathwayIcon} />
              <span className="annotation-name">Pathways</span>
              <span className="annotation-count">
                {numeral(pgkbPathways).format('0,0')}
              </span>
            </a>

            <a href="/vips" id="vips" className="annotation-item">
              <Svg className="annotation-icon" src={vipIcon} />
              <span className="annotation-name mixed-case">VIPs (Very Important Pharmacogenes)</span>
              <span className="annotation-count">
                {numeral(vipGenes).format('0,0')}
              </span>
            </a>

            <a href="/variantAnnotations" id="varann" className="annotation-item">
              <Svg className="annotation-icon" src={variantAnnotationIcon} />
              <span className="annotation-name">Variant Annotations</span>
              <span className="annotation-count">
                {numeral(basicVarAnns).format('0,0')}
              </span>
            </a>

            <a href="/annotatedDrugs" id="drugs" className="annotation-item">
              <Svg className="annotation-icon" src={variantAnnotationIcon} />
              <span className="annotation-name">Annotated Drugs</span>
              <span className="annotation-count">
                {numeral(drugsWithVarAnns).format('0,0')}
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
AnnotationLists.propTypes = propTypes;
