import PropTypes from 'prop-types';

import Link from 'components/links/Link';
import {stringifySearchParams} from 'components/links/utils';


const propTypes = {
  email: PropTypes.string.isRequired,
  subject: PropTypes.string,
  body: PropTypes.string,
  className: PropTypes.string,
  role: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

/**
 * Renders a mailto link.
 * The anchor element's 'aria-label' attribute will be set to "Send email".
 *
 * @param {object} props
 * @param {string} props.email - email address of recipient
 * @param {string} [props.subject] - subject
 * @param {string} [props.body] - bode of email
 * @param {string} [props.className]
 * @param {string} [props.role]
 * @param {node|string} props.children - the link text
 */
export default function MailtoLink({email, subject, body, className, role, children}) {
  return (
    <Link
      href={generateMailto({email, subject, body})}
      newTab={true}
      className={className}
      role={role}
      aria-label="Send email"
    >
      {children}
    </Link>
  );
}

MailtoLink.propTypes = propTypes;


/**
 * Generates a mailto URI.
 *
 * @param {object} [args]
 * @param {string} args.email
 * @param {string} [args.subject]
 * @param {string} [args.body]
 * @return {string}
 */
export function generateMailto({email, subject, body}) {
  // don't want to stringify empty values (makes it simpler to test)
  const params = {
    subject: subject || undefined,
    body: body || undefined,
  };
  let qs = stringifySearchParams(params);
  if (qs) {
    qs = `?${qs}`;
  }
  return `mailto:${email}${qs}`;
}
