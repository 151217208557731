import {map} from 'lodash';
import {useState} from 'react';

import DiffWords from 'components/Diff/Words';
import Loading from 'components/Loading';
import KyError from 'components/errors/KyError';
import ReadableWidthTemplate from 'components/templates/ReadableWidth';
import {useGet} from 'helpers/KyHooks';
import LabelApplicationForm from 'pages/LabelApplicationDiff/form';

/**
 * Component to let the user pick two versions of a label application and see the difference between them
 *
 * @param {object} props - props container
 * @param {string} props.applicationId - FDA application ID
 */
export default function LabelApplicationDiff({applicationId}) {
  const {response, error} = useGet('data/LabelApplicationVersion', {searchParams: {applicationId}});
  const [diffs, setDiffs] = useState([]);
  const [initialized, setInitialized] = useState(false);

  const handleSuccess = (updatedDiffs) => {
    setDiffs(updatedDiffs);
    setInitialized(true);
  };

  let body;
  if (error) {
    body = <KyError kyError={error} />;
  } else if (!response) {
    body = <Loading />;
  } else {
    body = (
      <div className="mb-4">
        <LabelApplicationForm applicationId={applicationId} versions={response.data} onSuccess={handleSuccess} />
      </div>
    );
  }
  return (
    <ReadableWidthTemplate title={`Diff for FDA Application ${applicationId}`}>
      {body}
      {diffs.length === 0 && initialized && (
        <div className="text-muted font-italic ml-3">No diffs found</div>
      )}
      <div>
        {map(Object.keys(diffs), (k) => {
          return (
            <div key={k} className="mb-3">
              <h5>{k}</h5>
              <DiffWords diffs={diffs[k]} />
            </div>
          );
        })}
      </div>
    </ReadableWidthTemplate>
  );
}
