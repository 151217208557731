import PropTypes from 'prop-types';
import {useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {toast} from 'react-toastify';

import ButtonPanel from 'components/Button/Panel';
import KyError from 'components/errors/KyError';
import FormCancel from 'components/form/Cancel';
import FormCheckboxGroup from 'components/form/CheckboxGroup';
import FormSubmit from 'components/form/Submit';
import useAppContext from 'conf/AppContext';


const propTypes = {
  selected: PropTypes.arrayOf(PropTypes.object),
  tags: PropTypes.arrayOf(PropTypes.object),
  objId: PropTypes.string,
  objCls: PropTypes.string,
  closeHandler: PropTypes.func.isRequired,
};
/**
 * Renders modal form for selecting PharmGKB Tags to apply to an entity.
 */
export default function PharmgkbTagForm({selected = [], tags = [], objCls, objId, closeHandler}) {
  const appContext = useAppContext();
  const [submitError, setSubmitError] = useState(null);
  const formMethods = useForm({defaultValues: {tags: selected}});
  const {handleSubmit} = formMethods;

  const closeForm = () => {
    closeHandler();
  };


  const onSubmit = async (values) => {
    if (submitError) {
      setSubmitError(undefined);
    }
    try {
      await appContext.api.post(`curation/tag/${objCls}/${objId}`, {
        json: values,
        parseJson: true,
      });
      toast.success('Saved!');
      closeForm();
      appContext.reloadData();

    } catch (submitErr) {
      setSubmitError(<KyError kyError={submitErr} />);
    }
  };

  const official = [];
  const notOfficial = [];
  for (let x = 0; x < tags.length; x += 1) {
    const t = tags[x];
    if (t.forType.length > 0 && !t.forType.includes(objCls.toLowerCase())) {
      continue;
    }
    if (t.official) {
      official.push(t);
    } else {
      notOfficial.push(t);
    }
  }
  const officialTags = official.length > 0
    ? <FormCheckboxGroup name="tags" label={(<h4>Official Tags</h4>)} options={official} className="mb-4" />
    : null;

  return (
    <FormProvider {...formMethods}>
      {submitError}
      <form key="pgkbTagForm" onSubmit={handleSubmit(onSubmit)}>
        {officialTags}
        <FormCheckboxGroup
          name="tags"
          label={(<h4>PharmGKB Tags</h4>)}
          options={notOfficial}
        />

        <ButtonPanel
          buttons={[
            <FormSubmit>Update</FormSubmit>,
            <FormCancel actionHandler={closeForm}>Cancel</FormCancel>,
          ]}
        />
      </form>
    </FormProvider>
  );
}
PharmgkbTagForm.propTypes = propTypes;
