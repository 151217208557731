import {map, size} from 'lodash';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {FormProvider, useForm, useWatch} from 'react-hook-form';

import ButtonPanel from 'components/Button/Panel';
import Loading from 'components/Loading';
import KyError from 'components/errors/KyError';
import FormCancel from 'components/form/Cancel';
import FormCombobox from 'components/form/Combobox';
import FormError from 'components/form/Error';
import FormSubmit from 'components/form/Submit';
import FormText from 'components/form/Text';
import {handleSubmitErrors} from 'components/form/utils';
import Link from 'components/links/Link';
import useAppContext from 'conf/AppContext';
import {normalizeType} from 'conf/types';
import {useGet} from 'helpers/KyHooks';


const propTypes = {
  objCls: PropTypes.string.isRequired,
  objId: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
/**
 * Modal form for adding a new link (either a cross-reference or ontology term).
 *
 * @param {object} props - component props
 * @param {string} props.objCls
 * @param {string} props.objId
 * @param {Function} props.onSave - function to call on save
 * @param {Function} props.onClose - function to call on cancel
 * @return {JSX.Element}
 */
export default function LinksTabNewForm({objCls, objId, onSave, onClose}) {
  const appContext = useAppContext();
  const [allowReuse, setAllowReuse] = useState(false);
  const showLinkOuts = normalizeType(objCls) === 'chemical';
  const formMethods = useForm({
    defaultValues: {
      refId: '',
      refTerm: '',
      resource: undefined,
    },
  });
  const {
    handleSubmit,
    getValues,
    control,
    clearErrors,
    formState: {errors},
    setError,
  } = formMethods;
  
  const watchAllValues = useWatch({control});
  useEffect(() => {
    if (errors?.root?.dependentName) {
      clearErrors();
    }
    if (allowReuse === true) {
      setAllowReuse(false);
    }
  }, [watchAllValues]);

  useEffect(() => {
    if (watchAllValues?.resource?.value && size(errors) > 0) {
      clearErrors();
    }
  }, [watchAllValues?.resource?.value]);

  const {response, error} = useGet(`site/linksTab/${objCls}/_resourceOptions`, {cacheResponse: true});

  const onSubmit = async (values) => {
    try {
      const {type} = values.resource;
      let endPointType = 'linkOuts';
      const data = {
        resource: values.resource.value,
      };
      if (showLinkOuts) {
        if (type === 'ontologyTerms') {
          data.name = values.refTerm;
        }
        data.resourceId = values.refId;
      } else {
        endPointType = type;
        if (type === 'ontologyTerms') {
          data.termId = values.refId;
          data.term = values.refTerm;
        } else {
          data.resourceId = values.refId;
        }
      }
      const rez = await appContext.api.post(`curation/${objCls}/${objId}/${endPointType}`, {
        searchParams: {
          allowReuse,
        },
        json: [data],
        parseJson: true,
      });
      setAllowReuse(false);
      onSave(values.resource.type, rez.data?.[0]);
    } catch (err) {
      if (err?.response?.status === 409) {
        const jsend = await appContext.api.readResponse(err.response);
        // when set a server or global error with root as the key,
        // this type of error will not persist with each submission.
        setError('root.dependentName', {
          type: 'custom',
          message:  (
            <div className="alert alert-danger mb-0">
              <p>This cross-reference is currently associated with:</p>
              <ul>
                {map(jsend.data.dependencies, (dep) => (
                  <li key={dep.id}>
                    <Link href={dep.url} newTab={true}>{dep.name ? dep.name : dep.id}</Link>
                  </li>
                ))}
              </ul>
              <p>Are you sure you want to add this?</p>
            </div>
          ),
        });
        setAllowReuse(true);
      } else {
        await handleSubmitErrors(appContext, err, setError);
        setAllowReuse(false);
      }
    }
  };

  let body = <Loading />;
  if (error) {
    body = <KyError kyError={error} />;
  } else if (response) {
    const resourceName = getValues('resource.label') ? `${getValues('resource.label')} ID` : 'ID';
    const resourceType = getValues('resource.type') ?? 'crossReferences';

    body = (
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h3>Add New Link</h3>
          <FormCombobox
            label="Resource"
            name="resource"
            options={response?.data}
            required={true}
          />
          <FormText
            name="refId"
            label={resourceName}
            required={true}
            validation={{
              valid: async (refId) => {
                const resource = getValues('resource');
                if (resource?.value && refId) {
                  try {
                    await appContext.api.get(`curation/linkOut/${resource.value}/${encodeURIComponent(refId)}`);
                  } catch (err) {
                    const json = await err.response.json();
                    if (err.response.status === 400) {
                      return json?.data?.errors?.[0]?.message || 'Invalid ID';
                    }
                    return json?.data?.errors?.[0]?.message || 'Something unexpected happened.';
                  }
                }
              },
            }}
          />
          <FormText
            name="refTerm"
            label="Term"
            disabled={resourceType === 'crossReferences'}
            validation={{
              required: (term) => {
                const resource = getValues('resource');
                if (term?.length === 0 && resource?.type === 'ontologyTerms') {
                  return 'Need a term from the other system';
                }
                return true;
              },
            }}
          />
          <FormError />
          <ButtonPanel
            buttons={[
              <FormSubmit key="save" ignoreErrorKeys="dependentName">Save</FormSubmit>,
              <FormCancel key="cancel" actionHandler={onClose}>Cancel</FormCancel>,
            ]}
          />
        </form>
      </FormProvider>
    );
  }

  return (
    <div className="linksTab">
      {body}
    </div>
  );
}
LinksTabNewForm.propTypes = propTypes;
