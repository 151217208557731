import PropTypes from 'prop-types';
import {useState} from 'react';

import Loading from 'components/Loading';
import VariantAnnotationsBarChart from 'components/VariantAnnotation/BarChart';
import VariantAnnotationsTabIntro from 'components/VariantAnnotation/TabIntro';
import VariantAnnotationTable from 'components/VariantAnnotation/Table';
import KyError from 'components/errors/KyError';
import useAppContext from 'conf/AppContext';
import {useGet} from 'helpers/KyHooks';


const propTypes = {
  objCls: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default function VariantAnnotationsPediatricTab({objCls, id}) {
  const appContext = useAppContext();
  const [filter, setFilter] = useState(null);
  const target = objCls === 'combination' ? id : `${objCls}/${id}`;
  const url = `site/tab/variantAnnotations/${target}`;
  const {response: pedResponse, error: pedError} = useGet(url, {
    searchParams: {
      pediatric: true,
    },
    cacheResponse: !appContext.isPreview,
  });
  const {response, error} = useGet(url, {
    searchParams: {
      pediatric: false,
    },
    cacheResponse: !appContext.isPreview,
  });

  let pedTable = <Loading />;
  let barChart = <div className="vaFigure vaFigure--loading figure figure--floatRight"><Loading /></div>;
  if (pedError) {
    pedTable = <KyError kyError={pedError} />;
  } else if (pedResponse) {
    if (pedResponse?.data?.length > 0) {
      pedTable = (
        <VariantAnnotationTable
          id="pediatric-variantAnnotations"
          data={pedResponse?.data}
          objCls={objCls}
          showPediatricColumn={false}
          updatedFilters={filter}
        />
      );
      barChart = (
        <VariantAnnotationsBarChart
          variantAnnotations={pedResponse?.data}
          objCls={objCls}
          filterChangeFn={setFilter}
          captionTitle="Pediatric annotations by "
          className="figure--floatRight"
        />
      );
    } else {
      pedTable = <p className="noData">No pediatric variant annotations.</p>;
      barChart = null;
    }
  }
  let nonPedTable = <Loading />;
  if (error) {
    nonPedTable = <KyError kyError={error} />;
  } else if (response) {
    if (response?.data?.length > 0) {
      nonPedTable = (
        <VariantAnnotationTable
          id="nonPediatric-variantAnnotations"
          data={response?.data}
          objCls={objCls}
          showPediatricColumn={false}
        />
      );
    } else {
      nonPedTable = <p className="noData">No non-pediatric variant annotations.</p>;
    }
  }

  return (
    <div className="variantAnnotationsTab">
      <VariantAnnotationsTabIntro objCls={objCls} id={id} chart={barChart} />

      <h4>Pediatric Variant Annotations</h4>
      {pedTable}

      <h4 className="mt-5">Non-Pediatric Variant Annotations</h4>
      {nonPedTable}
    </div>
  );
}
VariantAnnotationsPediatricTab.propTypes = propTypes;
