import PropTypes from 'prop-types';
import {useFormContext} from 'react-hook-form';

import Button from 'components/Button';

const propTypes = {
  actionHandler: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
};
/**
 * Cancel button for use with react-hook-form.
 */
export default function FormCancel({actionHandler, className = 'btn-secondary', children}) {
  const {formState: {isSubmitting}} = useFormContext();
  
  return (
    <Button
      actionHandler={actionHandler}
      className={className}
      disabled={isSubmitting}
    >
      {children}
    </Button>
  );
}
FormCancel.propTypes = propTypes;
