import {flatMap, isArray, isNil, isString, reduce, size} from 'lodash';

// JSON.parse chokes on HTML inside JSON if it is not properly encoded.
// http://www.thorntech.com/2012/07/4-things-you-must-do-when-putting-html-in-json/
export function toJSONSafeHTML(html) {
  if (!isString(html)) {
    return '';
  }
  return html.replace(/<\//g, '<\\/').replace(/"/g, '\\"');
}

// Input: interpose([1, 2, 3], "a")
// Output: [1, "a", 2, "a", 3]
export function interpose(array, separator) {
  return flatMap(array, (a, i) => (
    (i === (array.length - 1)) ? [a] : [a, separator]
  ));
}

/**
 * Converts a value to an array.
 *
 * @param {*} value - value to convert to an array.
 * @return {Array} if the value is an array, returns it; if it is a single value,
 * returns an array of length 1 with that value else returns any empty array.
 */
export function asArray(value) {
  if (value) {
    return isArray(value) ? value : [value];
  } else {
    return [];
  }
}

/**
 * Converts an array of words and a joining word into a long single string including the joining
 * word.
 * For example words=['one','two','three'} and joining='or' would give:
 * 'one, two or three'
 *
 * @param {Array} words - an array of word strings
 * @param {string} joining - a string to join the final term (e.g. and, or, but)
 * @return {string} a single joined string
 */
export function englishJoin(words, joining) {
  if (!words || words.length === 0) {
    return '';
  } else if (words.length === 1) {
    return words[0];
  } else if (words.length === 2) {
    return words[0] + ' ' + joining + ' ' + words[1];
  } else {
    return words
      .slice(0, words.length - 2)
      .map((i) => i + ', ')
      .join('') +
      words[words.length - 2] + ' ' + joining + ' ' + words[words.length - 1];
  }
}

/**
 * Counts the occurrences of distinct values in the given array and makes an object with keys of the distinct array
 * values with the count of those values in the original array.
 * For example: inArray = ['a', 'b', 'a'] would give {'a': 2, 'b': 1}
 *
 * If a string is passed then it will count as a single value.
 * For example: inArray = 'foo' would give {'foo': 1}
 *
 * @param {Array} inArray - an array of values
 * @return {object} an object of counts by array value
 */
export function countValues(inArray) {
  if (!inArray || inArray.length === 0) {
    return {};
  }
  if (isString(inArray)) {
    const payload = {};
    payload[inArray] = 1;
    return payload;
  }
  return reduce(inArray, (acc, curr) => {
    if (!isNil(curr) && (!isString(curr) || curr.length !== 0)) {
      acc[curr] = 1 + (acc[curr] || 0);
      return acc;
    } else {
      return acc;
    }
  }, {});
}

export function countsToArray(inObject) {
  if (!inObject || size(inObject) === 0) {
    return [];
  }
  const payload = [];
  for (const allele in inObject) {
    const count = inObject[allele];
    for (let i = 0; i < count; i += 1) {
      payload.push(allele);
    }
  }
  return payload;
}
