import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useEffect, useMemo} from 'react';
import {useFormContext} from 'react-hook-form';

import './Checkbox.scss';
import CheckboxLabeledInput from 'components/form/CheckboxLabeledInput';
import logger from 'conf/Logger';
import {buildCustomValidation, checkErrors, renderLabel} from 'utils/formUtils';


const propTypes = {
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.bool,
  help: PropTypes.string,
  helpLink: PropTypes.string,
  validation: PropTypes.object,
  /** Marks input field as read-only.  Will submit. */
  readOnly: PropTypes.bool,
  /** Disables input field.  Will not submit. */
  disabled: PropTypes.bool,
  className: PropTypes.string,
};
/**
 * Renders a true/false checkbox input.
 */
export default function FormCheckbox({label, name, value, help, helpLink, readOnly = false, disabled = false,
  validation, className, ...inputAttribs}) {
  const {formState: {errors}, unregister} = useFormContext();
  const customValidation = useMemo(() => buildCustomValidation({validation}), [validation]);

  useEffect(() => {
    if (readOnly && disabled) {
      unregister(name, {keepDefaultValue: true});
    }
  }, [readOnly, disabled]);

  if (readOnly && disabled) {
    logger.error('FormCheckbox cannot be both readOnly and disabled');
    return <div className="text-danger">error - check console</div>;
  }

  const fieldsetProps = {};
  if (readOnly) {
    fieldsetProps.disabled = true;
  }

  const {invalid, errorMsg} = checkErrors({disabled, errors, name});
  return (
    <fieldset
      className={clsx('form-group', className, {
        'is-invalid': invalid,
        'text-muted': disabled || readOnly,
      })}
      {...fieldsetProps}
    >
      <CheckboxLabeledInput
        label={renderLabel(label, helpLink)}
        name={name}
        defaultChecked={value}
        validation={customValidation}
        disabled={disabled}
        className="formCheckbox"
        {...inputAttribs}
      />
      {help && <small className="form-text text-muted">{help}</small>}
      {errorMsg}
    </fieldset>
  );
}
FormCheckbox.propTypes = propTypes;
