import React from 'react';
import PropTypes from 'prop-types';
import {map, size} from 'lodash';
import clsx from 'clsx';

import Fact from 'components/shared/fact';
import InfoLink from 'components/links/Info';
import {studyParametersProps} from 'conf/types';
import StudySizeFact from 'components/VariantAnnotation/StudySizeFact';
import FrequencyFact from 'components/VariantAnnotation/FrequencyFact';
import FactSection from 'components/shared/fact_section';
import Tag from 'components/Tag';
import CountryLabel from 'components/CountryLabel';
import CuratorOnly from 'components/shared/curator_only';

const propTypes = {
  studyParameter: studyParametersProps.isRequired,
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  nested: PropTypes.bool,
};
/**
 * A component to display study parameter information
 *
 * @param {object} props - props container
 * @param {Array<object>} props.studyParameter - a StudyParameters object
 * @param {number} props.index - the numerical index of hte study parameter, optional
 * @param {boolean} [props.nested=false] - true if this will appear nested inside a Clinical Annotation
 * @return {JSX.Element} a collection of Facts about study parameters
 */
export default function StudyParameterTable({studyParameter, index, nested = false}) {
  const {
    characteristics, characteristicsType, confidenceIntervalStart: start, confidenceIntervalStop: stop,
    populationEthnicity, populationTypes, pvalueString, pvalueOp, ratioStat, ratioStatType, studyTypes,
    alleleOfFreqInCases, alleleOfFreqInControls, frequencyInCases, frequencyInControls, usedForScoring,
    country,
  } = studyParameter;

  let populationText;
  if (populationEthnicity?.mixed) {
    if (populationEthnicity?.notes) {
      populationText = `Multiple groups, ${populationEthnicity.notes}`;
    } else {
      populationText = 'Multiple groups';
    }
  } else if (size(populationEthnicity?.ethnicities) > 0) {
    populationText = populationEthnicity.ethnicities.join(', ');
  } else if (populationEthnicity?.unknown && populationEthnicity?.notes) {
    populationText = `Unknown. ${populationEthnicity.notes}`;
  } else {
    populationText = populationEthnicity?.customEthnicity;
  }

  return (
    <div className={clsx('study-parameter', {'tour-second-study-parameter': index === 2})}>
      {!!index && <div className="study-parameter-index"><h4>{index}.</h4></div>}
      <FactSection contentClasses="study-parameter-content" sectionClasses="mb-0" inline={true} compact={true}>
        {usedForScoring && <CuratorOnly styled={false}><div className="mb-2"><Tag>Used For Scoring</Tag></div></CuratorOnly>}

        <Fact label="Study type" literal={studyTypes.join(', ')} compact={true} />

        <StudySizeFact
          cases={studyParameter.studyCases}
          controls={studyParameter.studyControls}
        />

        <Fact label="Association p-value" pageTourClass="tour-p-value" compact={true} trim={true}>
          {(pvalueString !== undefined && pvalueOp.term)
            ? `${pvalueOp.term} ${pvalueString}`
            : null}
        </Fact>

        <Fact label="Statistical analysis" compact={true}>
          {(ratioStat !== -1 && ratioStatType?.term)
            ? `${ratioStatType.term}: ${ratioStat}`
            : null}
        </Fact>

        <Fact label="Confidence interval" compact={true}>
          {(start !== -1 && stop !== -1)
            ? `${start} - ${stop}`
            : null}
        </Fact>

        <FrequencyFact
          alleleCase={alleleOfFreqInCases}
          alleleControl={alleleOfFreqInControls}
          freqCase={frequencyInCases}
          freqControl={frequencyInControls}
        />

        <Fact
          label="Biogeographical group"
          pageTourClass="bio-group"
          labelBtns={<InfoLink href="/page/biogeographicalGroups" tooltip="More info on groups" />}
          compact={true}
        >
          {populationText}
        </Fact>

        {!!country && <Fact label="Country" compact={true}><CountryLabel country={country} /></Fact>}

        <Fact label="Population description" pageTourClass="tour-pop-char" compact={true}>
          {size(populationTypes) > 0 && <div>{map(populationTypes, (t) => t.term).join('; ')}</div>}
          {(characteristics && characteristicsType) && <p className="mb-0">{`${characteristicsType}: ${characteristics}`}</p>}
        </Fact>
      </FactSection>
    </div>
  );
}
StudyParameterTable.propTypes = propTypes;
