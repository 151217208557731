import {map} from 'lodash';
import PropTypes from 'prop-types';

import KyError from 'components/errors/KyError';
import FactSection from 'components/shared/fact_section';
import useAppContext from 'conf/AppContext';
import {useGet} from 'helpers/KyHooks';

const propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  objCls: PropTypes.string.isRequired,
};
/**
 * Clinical annotations that use the given annotation as evidence. This can be used on variant, guideline, or label
 * annotation pages.
 *
 * @param {object} props
 * @param {string} [props.id] - ID of the object you want related clinical annotations for
 * @param {string} [props.objCls] - class of the object you want related clinical annotations for
 * @return {React.ReactNode} a FactSection of a ordered list of clinical annotation links, returns null if no related
 * annotations exist
 */
export default function ClinicalAnnotationEvidenceList({id, objCls}) {
  const appContext = useAppContext();

  const searchParams = {view: 'min'};
  if (/Variant.* Annotation/i.exec(objCls) !== null) {
    searchParams['variantAnnotations.id'] = id;
  } else if (objCls === 'Guideline Annotation') {
    searchParams['relatedGuidelines.accessionId'] = id;
  } else if (objCls === 'Label Annotation') {
    searchParams['relatedLabels.accessionId'] = id;
  }

  const {response, error} = useGet('data/clinicalAnnotation', {
    searchParams,
    cacheResponse: !appContext.isPreview,
  });

  if (error) {
    if (error.response?.status !== 404 && appContext.isPreview) {
      appContext.toastError(<KyError kyError={error} />);
      return null;
    }
  }

  let body;
  if (!id || error?.response?.status === 404) {
    body = <p>This annotation has not been used as evidence for any clinical annotations.</p>;
  } else {
    body = (
      <>
        <p>This annotation has been used as evidence for the following clinical annotations.</p>
        <ol>{map(response?.data, (ann) => <li key={ann.id}><a href={`/clinicalAnnotation/${ann.id}`}>{ann.name}</a></li>)}</ol>
      </>
    );
  }

  return (
    <FactSection id="evidence" title="Evidence for Clinical Annotations">
      {body}
    </FactSection>
  );
}

ClinicalAnnotationEvidenceList.propTypes = propTypes;
