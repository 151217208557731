import {isEmpty} from 'lodash';

import LinksTabForm from 'components/LinksTab/Form';
import Loading from 'components/Loading';
import KyError from 'components/errors/KyError';
import {useGet} from 'helpers/KyHooks';

export default function LinksTabUpdateSection({objCls, objId, onSave, onClose}) {
  const {response, error} = useGet(`curation/${objCls}/${objId}/linkOuts/_update`);

  let body = <Loading />;
  if (error) {
    body = <KyError kyError={error} />;
  } else if (response) {
    if (isEmpty(response?.data?.links)) {
      return (
        <p className="empty mb-4">No new links found.</p>
      );
    }
    return (
      <LinksTabForm
        links={response.data.links}
        objCls={objCls}
        objId={objId}
        onSave={onSave}
        onClose={onClose}
      />
    );
  }

  return (
    <div className="linksTab">
      {body}
    </div>
  );
}
