import {filter, find, map, reduce} from 'lodash';
import {useEffect, useMemo, useState} from 'react';

import './index.scss';
import Loading from 'components/Loading';
import InlinePage from 'components/Page/Inline';
import AccIdCell, {accIdFilter, baseAccIdColumn, sortAccIds} from 'components/Table/Cell/AccId';
import MultiSelectFilter, {
  MULTISELECT_AND_LABEL, multiselectAndFilter, multiselectOrFilter,
} from 'components/Table/Filter/MultiSelect';
import SelectFilter from 'components/Table/Filter/Select';
import TextFilter from 'components/Table/Filter/Text';
import VirtualizedTable from 'components/Table/Virtualized';
import {defaultViewLinkColumn, fixedColumnStyle} from 'components/Table/columnHelpers';
import {renderTotalDataFn} from 'components/Table/utils';
import AlternateDrugTag from 'components/Tag/AlternateDrug';
import DosingInfoTag from 'components/Tag/DosingInfo';
import {CancerTag, IndicationTag, PrescribingTag} from 'components/Tag/LabelAnnotation';
import LabelAnnotationLevelTag from 'components/Tag/LabelAnnotationLevel';
import OtherGuidanceTag from 'components/Tag/OtherGuidance';
import PediatricTag from 'components/Tag/Pediatric';
import KyError from 'components/errors/KyError';
import Link from 'components/links/Link';
import FullFluidWidthTemplate from 'components/templates/FullFluidWidth';
import {getBaseUrl} from 'conf/types';
import usePediatricContext from 'context/Pediatric';
import {useGet} from 'helpers/KyHooks';
import FdaLabelAnnotationsBarChart from 'pages/FdaLabelAnnotations/BarChart';
import {listAllLabelAnnotations} from 'pages/LabelAnnotations';


const PgxLevelCell = ({value}) => <LabelAnnotationLevelTag level={value} className="tag--xs" />;


/**
 * Options for the Tags filter, including custom labels.
 */
const tagOptions = [
  {
    id: 'dosingInformation',
    label: <DosingInfoTag context="labelAnnotation" className="tag--xs" />,
  },
  {
    id: 'alternateDrugAvailable',
    label: <AlternateDrugTag context="labelAnnotation" className="tag--xs" />,
  },
  {
    id: 'prescribingInformation',
    label: <PrescribingTag className="tag--xs" />,
  },
  {
    id: 'otherPrescribingGuidance',
    label: <OtherGuidanceTag context="labelAnnotation" className="tag--xs" />,
  },
  {
    id: 'cancerGenome',
    label: <CancerTag className="tag--xs" />,
  },
  {
    id: 'pediatric',
    label: <PediatricTag className="tag--xs" />,
  },
  {
    id: 'indication',
    label: <IndicationTag className="tag--xs" />,
  },
];

/**
 * Returns a string of all included tags, separated by semicolons.
 * Used to format field for table TSV file download.
 */
const tagsAccessor = (row) => {
  const tags = [];
  for (let x = 0; x < tagOptions.length; x += 1) {
    if (row[tagOptions[x].id]) {
      tags.push(tagOptions[x].id);
    }
  }
  return tags;
};

const TagsCell = ({value}) => (
  <div className="tagContainer">
    {value.includes('dosingInformation') ? <DosingInfoTag context="labelAnnotation" className="tag--xs" /> : null}
    {value.includes('alternateDrugAvailable') ? <AlternateDrugTag context="labelAnnotation" className="tag--xs" /> : null}
    {value.includes('prescribingInformation') ? <PrescribingTag className="tag--xs" /> : null}
    {value.includes('otherPrescribingGuidance') ? <OtherGuidanceTag context="labelAnnotation" className="tag--xs" /> : null}
    {value.includes('cancerGenome') ? <CancerTag className="tag--xs" /> : null}
    {value.includes('pediatric') ? <PediatricTag className="tag--xs" /> : null}
    {value.includes('indication') ? <IndicationTag className="tag--xs" /> : null}
  </div>
);

const TagsFilter = (props) => <MultiSelectFilter {...props} label={MULTISELECT_AND_LABEL} options={tagOptions} />;
const FdaPgxAssociationCell = ({value}) => (
  <ul className="list-unstyled pl-0">
    {map(value, (val) => (
      <li key={val}>{val}</li>
    ))}
  </ul>
);
const FdaPgxAssociationFilter = (props) => <MultiSelectFilter {...props} options={['Recommendations', 'Safety or Response', 'PK']} />;


const columns = [
  {
    ...defaultViewLinkColumn,
    Cell: ViewLinkCell,
  },
  {
    Header: 'Drug',
    id: 'drug',
    accessor: 'drug',
    ...baseAccIdColumn,
  },
  {
    Header: 'Genes',
    id: 'relatedGenes',
    accessor: 'relatedGenes',
    ...baseAccIdColumn,
  },
  {
    Header: 'Alleles',
    id: 'relatedAlleles',
    accessor: 'relatedAlleles',
    Cell: AccIdCell,
    sortable: true,
    sortType: sortAccIds,
    Filter: TextFilter,
    filter: accIdFilter,
  },
  {
    Header: 'FDA Biomarker List',
    id: 'biomarkerStatus',
    accessor: 'biomarkerStatus',
    Filter: MultiSelectFilter,
    filter: multiselectAndFilter,
    sortable: true,
    ...fixedColumnStyle('220'),
  },
  {
    Header: 'FDA PGx Association',
    id: 'fdaPgxAssociationCategory',
    accessor: 'fdaPgxAssociationCategory',
    Cell: FdaPgxAssociationCell,
    Filter: FdaPgxAssociationFilter,
    filter: multiselectOrFilter,
    ...fixedColumnStyle('220'),
  },
  {
    Header: 'PGx Level',
    id: 'pgxLevel',
    accessor: 'pgxLevel',
    Cell: PgxLevelCell,
    Filter: SelectFilter,
    sortable: true,
    ...fixedColumnStyle('170'),
  },
  {
    Header: 'Tags',
    id: 'tags',
    accessor: tagsAccessor,
    Cell: TagsCell,
    Filter: TagsFilter,
    filter: multiselectAndFilter,
    ...fixedColumnStyle('170'),
  },
];


/**
 * Renders FDA label annotations page.
 */
export default function FdaLabelAnnotationsPage() {
  const pediatricContext = usePediatricContext();
  const [filters, setFilters] = useState(null);

  const initialFilters = useMemo(() => [
    {
      id: 'tags',
      value: reduce(tagOptions, (acc, curr) => { acc[curr.id] = false; return acc; }, {}),
    },
  ], []);
  useEffect(() => {
    let tagFilter = find(filters, (f) => f.id === 'tags');
    if (pediatricContext.isPediatricMode) {
      if (!tagFilter) {
        tagFilter = {id: 'tags', value: {}};
      }
      tagFilter.value.pediatric = true;
    } else {
      if (tagFilter) {
        tagFilter.value.pediatric = false;
      }
    }

    const newFilters = filter(filters, (f) => f.id !== 'tags');
    if (tagFilter) {
      newFilters.push(tagFilter);
    }

    setFilters(newFilters);
  }, [pediatricContext.isPediatricMode]);
  
  const {response, error} = useGet('site/labelAnnotation/fda');

  let content = <Loading />;
  let barChart = <div className="fdaFigure fdaFigure--loading figure figure--floatRight"><Loading /></div>;
  if (response) {
    const renderTotalFn = (numVisible) => renderTotalDataFn(response.data, numVisible, 'drug gene pair');
    const initialState = {
      filters: initialFilters,
      sortBy: [
        {
          id: 'drug',
          desc: false,
        },
      ],
    };
    content = (
      <VirtualizedTable
        id="fdaLabelAnnotations"
        columns={columns}
        data={response?.data}
        initialState={initialState}
        updatedFilters={filters}
        canShowFullscreen={true}
        renderTotalFn={renderTotalFn}
      />
    );

    barChart = (
      <FdaLabelAnnotationsBarChart
        fdaLabelAnnotations={response?.data}
        filterChangeFn={setFilters}
        className="figure--floatRight"
      />
    );

  } else if (error) {
    content = <KyError kyError={error} />;
  }

  return (
    <FullFluidWidthTemplate className="fdaLabelAnnotationsPage" title="FDA Drug Label Annotations and PGx Associations">
      <div className="container clearfix">
        {barChart}
        <InlinePage id="fdaLabelListingHeader" />
      </div>

      <div className="container mt-3">
        {listAllLabelAnnotations()}
        {content}
      </div>
    </FullFluidWidthTemplate>
  );
}

function ViewLinkCell({value}) {
  if (!value) {
    return '';
  }
  const url = getBaseUrl(value.id, value.objCls);
  const title = `View ${value.objCls}`;

  return (
    <Link href={url} className="btn btn-xs btn-primary" iconOnly={true} title={title}>
      Details
    </Link>
  );
}
