import {includes} from 'lodash';

import {baseAccIdColumn} from 'components/Table/Cell/AccId';
import BooleanCell from 'components/Table/Cell/Boolean';
import ViewLinkCell, {viewLinkExportHeader, viewLinkExportValue} from 'components/Table/Cell/ViewLink';
import BooleanSelectFilter, {BooleanCheckboxFilter, booleanFilter} from 'components/Table/Filter/Boolean';
import MultiSelectFilter, {multiselectOrFilter} from 'components/Table/Filter/MultiSelect';
import {sortBooleans} from 'components/Table/comparators';
import {DataSource} from 'conf/enums';


/**
 * Gets fixed column props with the specified width (in px).
 *
 * @param {number} width
 * @param {object} [style]
 */
export function fixedColumnStyle(width, style) {
  return {
    width,
    disableResizing: true,
    style: {
      ...style,
      flex: 'none',
    },
  };
}

/**
 * Gets non-fixed flex column style.
 *
 * @param {object} [p]
 * @param {number} [p.width]
 * @param {number} [p.grow]
 * @param {number} [p.shrink]
 * @param {object} [style]
 */
export function flexColumnStyle({width = 150, grow = 1, shrink = 0}, style) {
  return {
    width,
    style: {
      ...style,
      flexGrow: grow,
      flexShrink: shrink,
    },
  };
}

export function getBooleanCol(width) {
  return {
    Cell: BooleanCell,
    sortable: true,
    sortType: sortBooleans,
    Filter: BooleanSelectFilter,
    filter: booleanFilter,
    className: 'text-center',
    ...fixedColumnStyle(width),
  };
}

export function getCheckboxCol(width, filter = booleanFilter) {
  return {
    Cell: BooleanCell,
    sortable: true,
    sortType: sortBooleans,
    Filter: BooleanCheckboxFilter,
    filter,
    className: 'text-center',
    ...getCheckboxColStyle(width),
  };
}

function getCheckboxColStyle(width) {
  return {
    ...fixedColumnStyle(width, {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
    }),
  };
}

export const defaultPediatricColumn = {
  id: 'pediatric',
  Header: 'Pediatric',
  accessor: (originalRow) => {
    if (originalRow?.tags) {
      return includes(originalRow.tags, 'pediatric');
    }
    return originalRow.pediatric;
  },
  ...getCheckboxCol(130),
};


export const defaultSourceColumn = {
  id: 'source',
  Header: 'Source',
  accessor: (originalRow) => {
    if (originalRow.source) {
      if (originalRow.source === DataSource.shortName.PRO) {
        return 'Other';
      }
      return DataSource.displayName[originalRow.source] ?? originalRow.source;
    }
    return '';
  },
  Filter: MultiSelectFilter,
  filter: multiselectOrFilter,
  sortable: true,
  ...flexColumnStyle({width: 130}),
};


export const defaultRelatedGenesColumn = {
  id: 'gene',
  Header: 'Genes',
  accessor: ((originalRow) => {
    if (originalRow?.properties?.genes) {
      return originalRow.properties.genes;
    } else if (originalRow?.relatedGenes) {
      return originalRow.relatedGenes;
    }
  }),
  ...baseAccIdColumn,
  ...flexColumnStyle({width: 120}),
};


export const defaultRelatedChemicalsColumn = {
  id: 'molecule',
  Header: 'Drugs',
  accessor: (originalRow) => {
    if (originalRow?.properties?.chemicals) {
      return originalRow.properties.chemicals;
    } else if (originalRow?.relatedChemicals) {
      return originalRow.relatedChemicals;
    }
  },
  ...baseAccIdColumn,
  ...flexColumnStyle({width: 150, grow: 2}),
};


export const defaultRelatedPhenotypesColumn = {
  id: 'phenotype',
  Header: 'Phenotypes',
  accessor: (originalRow) => {
    if (originalRow?.properties?.phenotypes) {
      return originalRow.properties.phenotypes;
    } else if (originalRow?.relatedPhenotypes) {
      return originalRow.relatedPhenotypes;
    }
  },
  ...baseAccIdColumn,
  ...flexColumnStyle({width: 150, grow: 2}),
};


/**
 * Typical defaults for a View Link column.
 */
export const defaultViewLinkColumn = {
  id: 'view',
  Header: '',
  className: 'viewLinkCell',
  // width is required for sticky column to work properly
  ...fixedColumnStyle(90),
  accessor: (originalRow) => originalRow,
  Cell: ViewLinkCell,
  getColumnExportValue: viewLinkExportHeader,
  getCellExportValue: viewLinkExportValue,
};
