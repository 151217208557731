import {filter, intersection, sortBy, split} from 'lodash';
import {useEffect, useState} from 'react';

import './Pathways.scss';
import ChartConstants from 'components/Chart/Constants';
import {renderItemWithTags} from 'components/List/Item';
import {COUNT_MODE} from 'components/List/Searchable';
import InlinePage from 'components/Page/Inline';
import PathwaysBarChart from 'components/Pathway/BarChart';
import KyError from 'components/errors/KyError';
import CategoryListTemplate from 'components/templates/CategoryList';
import useAppContext from 'conf/AppContext';
import Types from 'conf/types';
import {useGet} from 'helpers/KyHooks';


export default function PathwaysPage() {
  const appContext = useAppContext();
  const [categories, setCategories] = useState({chart: [], filters: []});
  const [categoriesError, setCategoriesError] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [barChartFilter, setBarChartFilter] = useState(null);

  const {response: pathwaysResponse, error: pathwaysError} = useGet('site/pathways', {}, {cacheOnLive: true});

  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      try {
        const catResponse = await appContext.api.get('site/pathwayCategories', {parseJson: true});
        if (mounted) {
          const data = sortBy(catResponse.data, (d) => d.ontologyTerm.term);
          const chart = [];
          const filters = [];
          for (let x = 0; x < data.length; x += 1) {
            const {ontologyTerm, count} = data[x];
            chart.push({label: ontologyTerm.term, value: count});
            filters.push({name: ontologyTerm.term, count});
          }
          setCategories({chart, filters});
        }
      } catch (error) {
        setCategoriesError(error);
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    loadData();
    return () => {
      mounted = false;
    };
  }, []);

  const pathways = pathwaysResponse ? sortBy(pathwaysResponse?.data.hits, (f) => f.name) : [];


  const collection = selectedFilters.length === 0
    ? pathways
    : filter(
      pathways,
      (pw) => intersection(selectedFilters, split(pw.categories, '; ')).length > 0);

  const description = (
    <div className="clearfix">
      <PathwaysBarChart
        categories={categories.chart}
        filterChangeFn={setBarChartFilter}
        className="figure--floatRight"
        yAxisLabel={{truncate: ChartConstants.truncateOptions.END}}
      />
      <InlinePage id="pathwayHeader" />
    </div>
  );

  let countMode;
  if (selectedFilters.length === 0) {
    countMode = COUNT_MODE.LIST;
  } else {
    countMode = COUNT_MODE.FILTERED;
  }

  return (
    <CategoryListTemplate
      title="PharmGKB Pathways"
      className="pathwaysPage"
      description={description}
      filters={categories.filters}
      onFilterUpdate={(newFilters) => setSelectedFilters(newFilters)}
      collection={collection}
      renderItem={renderItemWithTags}
      searchKeys={Types.pathway.searchKeys}
      singularNoun="pathway"
      countMode={countMode}
      loaded={Boolean(pathwaysResponse || pathwaysError)}
      error={(categoriesError && <KyError kyError={categoriesError} />) || (pathwaysError && <KyError kyError={pathwaysError} />)}
      selectedFilters={barChartFilter}
    />
  );
}
