import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import {extractIconProps, extractProps} from 'utils/propUtils';

export const FONTAWESOME_FOCUS_ICON = 'binoculars';

const propTypes = {
  // iconProps
  className: PropTypes.string,
  size: PropTypes.string,
  transform: PropTypes.string,
};

export default function FocusIcon(props) {
  return (
    <FontAwesomeIcon
      icon={['far', 'binoculars']}
      {...extractIconProps(props)}
      {...extractProps(props)}
    />
  );
}
FocusIcon.propTypes = propTypes;
