import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import './Edit.scss';
import Button from 'components/Button/index';


const propTypes = {
  objCls: PropTypes.string.isRequired,
  objId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /**
   * Forces link to be opened in new tab if true, in same tab if false.
   */
  newTab: PropTypes.bool,
  /**
   * Determines if button should be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * Determines if button is icon-only.
   */
  iconOnly: PropTypes.bool,
  /**
   * The button's label.
   */
  label: PropTypes.string,
};
/**
 * Renders a diff button.  Just like a regular button, but with code icon.
 * Defaults to icon only.
 */
export default function DiffButton({objCls, objId, newTab = true, disabled = false, iconOnly = true, label = 'Diff'}) {
  return (
    <Button href={`/edit/diff/${objCls}/${objId}`} newTab={newTab} disabled={disabled} className="btn-secondary" iconOnly={iconOnly} title={label}>
      <FontAwesomeIcon icon={['far', 'code']} />
      {iconOnly ? '' : label}
    </Button>
  );
}
DiffButton.propTypes = propTypes;
