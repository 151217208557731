/* eslint-env browser, node */
// Provides helper methods to support both server-side and client-side rendering.
import AmpersandRouter from 'ampersand-router';
import {capitalize, clone, endsWith, forEach, get, includes, map, values, without} from 'lodash';
import {createRoot} from 'react-dom/client';
import {renderToString} from 'react-dom/server';

import Root from 'components/root';
import NotFound from 'components/shared/error/not_found';
import ServerError from 'components/shared/error/server_error';
import App from 'conf/app';
import {isEntity} from 'helpers/entities';
import LoginPage from 'pages/user/Login';

export default AmpersandRouter.extend({
  root: null,

  // The server-side renderer provides a serialized representation of the first
  // routes data on window.INITIAL_DATA. Load it, if present.
  loadInitialData(props = {}) {
    if (!App.inBrowser || !window.INITIAL_DATA) { return props; }

    const data = clone(props);
    const initial = JSON.parse(window.INITIAL_DATA || '{}');

    // The initial data is only valid for the first route, so we clear it after load.
    window.INITIAL_DATA = null;

    forEach(data, (val, key) => {
      if (initial[key]) {
        if (isEntity(data[key])) {
          data[key].set(initial[key]);
          data[key].isSynced = true;
        } else {
          data[key] = initial[key];
        }
      }
    });

    return data;
  },

  hasInitialError() {
    if (!App.inBrowser) { return false; }

    const statusCode = window.STATUS_CODE;

    // Errors also only apply on first route.
    window.STATUS_CODE = null;

    return (statusCode && statusCode !== '200');
  },

  /*
   * Given a React component class and props, fetch all model/collection props,
   * load initial data provided by the server (if any), and renders result.
   *
   * Return a rendered result immediately -- before the fetches have completed.
   *
   * Triggers a "route-fetched" event, with completed render, once fetches complete.
   * The server-side renderer has all necessary data once this event has fired.
   */
  fetchAndRender(Comp, props = {}, pageProps = {}) {
    if (this.hasInitialError()) {
      // The server has already tried and failed to fetch its data, and therefore
      // has already rendered an error page. There's no need to render the component
      // again in the browser, and it will just cause flashing.
      return;
    }

    const data = this.loadInitialData(props);
    const promises = values(data).filter(isEntity).map((m) => m.fetch());

    Promise.all(promises)
      .then(() => this.trigger('route-fetched', {data, html: this.render(Comp, data, pageProps)}))
      .catch((reason) => {
        const codeToComp = {
          401: LoginPage,
          404: NotFound,
        };
        let errorCode = 500;
        let errorComp = ServerError;
        let errorMsg;
        if (reason.resp) {
          errorCode = reason.resp.statusCode;
          errorComp = codeToComp[reason.resp.statusCode];
          if (!errorComp) {
            if (errorCode < 100) {
              errorCode = 500;
            }
            errorComp = ServerError;
            if (reason.resp.body && reason.resp.headers &&
              reason.resp.headers['content-type'] === 'application/json') {
              errorMsg = parseJsendErrorToJsx(reason.resp.body);
            }
            App.log.error(reason);
          }
        } else {
          if (reason.message) {
            errorMsg = `Check console: ${reason.message}`;
          }
          App.log.error(reason);
        }
        // TODO(markwoon): need to figure out how to pass location to errorComp
        this.trigger('route-fetched', {
          statusCode: errorCode,
          html: this.render(errorComp, {msg: errorMsg}, pageProps),
        });
      });

    return this.render(Comp, data, pageProps);
  },

  // Render Comp to string or to DOM (depending on execution environment).
  // Return the result.
  render(Comp, props, pageProps) {
    const page = <Root {...pageProps} key={Comp?.name}><Comp {...props} /></Root>;

    if (App.inBrowser) {
      if (!this.root) {
        this.root = createRoot(document.getElementById('app'));
      }
      return this.root.render(page);
    } else {
      return renderToString(page);
    }
  },

  // Render a blank stub page for other stuff to get rendered later.
  renderStub() {
    const page = <Root />;

    let result;
    if (App.inBrowser) {
      if (!this.root) {
        this.root = createRoot(document.getElementById('app'));
      }
      result = this.root.render(page);
    } else {
      result = renderToString(page);
    }

    this.trigger('route-fetched', {data: {}, html: result});
    return result;
  },

  /*
   * Redirect to another page, handling both server and browser environments.
   * For redirects to other domains, the destination must start with a protocol
   * (http:// or https://) to help us distinguish them from relative paths.
   */
  redirect(destination, permanent = false, browserOnly = false) {
    if (App.inBrowser) {
      if (destination.match(/^https?:/)) {
        window.location.replace(destination);
      } else {
        this.navigate(destination, {replace: permanent});
      }
    } else {
      if (browserOnly) {
        return this.renderStub();
      }

      this.trigger('route-fetched', {redirect: {destination, permanent}});
    }
  },

  // Redirects to the login page.
  showLogin(code = '', nextUrl) {
    const url = nextUrl || App.currentPath();
    return this.login(`?nextUrl=${url}&code=${code}`);
  },
});


/**
 * Parse JSEND error message and convert error messages to JSX.
 *
 * @param {object} jsend
 * @param {boolean} [styleAsAlertOnError]
 * @return {React.ReactNode}
 */
function parseJsendErrorToJsx(jsend, styleAsAlertOnError = false) {
  const errorClassName = styleAsAlertOnError ? 'alert alert-danger' : '';
  if (jsend) {
    if (jsend.data) {
      const response = [];
      let fieldKeys = Object.keys(jsend.data);
      if (includes(fieldKeys, 'errors')) {
        fieldKeys = without(fieldKeys, 'errors');
        response.push(_actionErrors(jsend.data.errors));
      }
      if (includes(fieldKeys, 'dependencies')) {
        fieldKeys = without(fieldKeys, 'dependencies');
        response.push(_dependencyErrors(jsend.data.dependencies));
      }
      if (fieldKeys.length > 0) {
        response.push(<p key="fep">Please fix the following error(s):</p>);
        response.push(_formatFieldErrors(fieldKeys, jsend.data));
      }
      if (response.length === 1) {
        if (styleAsAlertOnError) {
          return <div className={errorClassName}>{response[0]}</div>;
        } else {
          return response[0];
        }
      } else if (response.length > 1) {
        if (styleAsAlertOnError) {
          return <div className={errorClassName}>{response}</div>;
        } else {
          return response;
        }
      }
    } else if (jsend.message) {
      // message
      return <p className={errorClassName}>{jsend.message}</p>;
    }
  }
  App.log.error('Unexpected error message format', jsend);
  return <p className={errorClassName}>Something went wrong while loading data.</p>;
}

const _addPeriodIfNeccessary = (text) => (endsWith(text, '.') ? text : `${text}.`);

function _actionErrors(errors) {
  const {length} = errors;
  if (length === 0) {
    return;
  }
  if (length === 1) {
    return (<p key="ae1">{_addPeriodIfNeccessary(errors[0].message)}</p>);
  }
  return (
    <ul key="aeul">
      {map(errors, (e) => (<li>{_addPeriodIfNeccessary(e.message)}</li>))}
    </ul>
  );
}

const _formatDependency = (dep) => {
  const {dependencyType, name, url} = dep;
  return (
    <span>
      {capitalize(dependencyType)}: {' '} <a href={url} target="_blank" rel="noopener noreferrer">{name}</a>
    </span>
  );
};

function _dependencyErrors(dependencies) {
  const {length} = dependencies;
  if (length === 0) {
    return;
  }
  if (length === 1) {
    return (
      <div>
        <p>Failed due to:</p>
        <p key="de1">{_formatDependency(dependencies[0])}</p>
      </div>
    );
  }
  return (
    <div>
      <p>Failed due to:</p>
      <ul key="deul" className="list-unstyled">
        {map(dependencies, (d) => (<li>{_formatDependency(d)}</li>))}
      </ul>
    </div>
  );
}

function _formatFieldErrors(fieldKeys, fieldErrors) {
  const numKeys = fieldKeys.length;
  if (numKeys === 0) {
    return;
  }
  const errors = [];
  for (let x = 0; x < numKeys; x += 1) {
    const messages = get(fieldErrors, fieldKeys[x]);
    const numMessages = messages.length;
    for (let y = 0; y < numMessages; y += 1) {
      const k = `${fieldKeys[x]}${y}`;
      const m = `${fieldKeys[x]}: ${messages[y].message}`;
      errors.push(<li key={k}>{m}</li>);
    }
  }
  return (<ul key="feul">{errors}</ul>);
}
