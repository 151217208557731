import PropTypes from 'prop-types';
import {ReactElement, useEffect, useState} from 'react';

import NotFound from 'components/shared/error/not_found';
import useAppContext from 'conf/AppContext';

const propTypes = {
  id: PropTypes.string,
};
/**
 * Redirects generic accession identifies to the related page.
 *
 * @param {object} props - props container
 * @param {string} props.id
 * @return {ReactElement}
 */
export default function RedirectAccessionId({id}) {
  const appContext = useAppContext();
  const [kyError, setKyError] = useState(null);

  useEffect(() => {
    let mounted = true;
    const load = async () => {
      try {
        const json = {query: id, field: 'id'};
        const rez = await appContext.api.post('search', {
          json,
          parseJson: true,
        });
        if (mounted) {
          if (rez.data.total === 1) {
            appContext.redirect(rez.data.hits[0].url);
          } else {
            setKyError(<NotFound />);
          }
        }
      } catch (err) {
        if (mounted) {
          setKyError(<NotFound />);
        }
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    load();
    return () => {
      mounted = false;
    };
  }, [id]);

  return kyError;
}
RedirectAccessionId.propTypes = propTypes;
