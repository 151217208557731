import clsx from 'clsx';
import PropTypes from 'prop-types';


const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
/**
 * This is meant to be a standard component for displaying a message when no content exists.
 */
export default function Empty({className, children}) {
  const defaultMessage = <p>Not annotated by PharmGKB.</p>;

  return (
    <div className={clsx('empty', className)}>{children ?? defaultMessage}</div>
  );
}
Empty.propTypes = propTypes;
