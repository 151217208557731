import PropTypes from 'prop-types';
import {toast} from 'react-toastify';

import KyError from 'components/errors/KyError';
import ReadableWidthTemplate from 'components/templates/ReadableWidth';
import useAppContext from 'conf/AppContext';
import PasswordForm from 'pages/user/PasswordForm';

const propTypes = {
  userId: PropTypes.string.isRequired,
};
export default function UpdatePassword({userId}) {
  const appContext = useAppContext();

  const handleSubmit = async (data) => {
    try {
      const formData = new URLSearchParams();
      formData.set('newPassword', data.password);
      formData.set('currentPassword', data.currentPassword);
      await appContext.api.put(`user/${userId}/updatePassword`, {body: formData});
      toast.success('Password updated successfully');
    } catch (err) {
      appContext.toastError(<KyError kyError={err} />);
    }
  };

  return (
    <ReadableWidthTemplate title="Update Password">
      <div className="col-md-8">
        <PasswordForm
          requiresCurrentPassword={true}
          onSubmit={handleSubmit}
        />
      </div>
    </ReadableWidthTemplate>
  );
}
UpdatePassword.propTypes = propTypes;
