import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {format as dateFormat, parseISO} from 'date-fns';
import {filter, map, size, toString} from 'lodash';
import numeral from 'numeraljs';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {toast} from 'react-toastify';

import './OverviewTab.scss';
import Button from 'components/Button';
import ApproveAndDiffButtons from 'components/Button/ApproveAndDiff';
import DeleteButton from 'components/Button/Delete';
import EditButton from 'components/Button/Edit';
import WaitingButton from 'components/Button/Waiting';
import DrugInteractionList from 'components/Literature/DrugInteractionList';
import FullTextButton from 'components/Literature/FullTextButton';
import MeshTerm from 'components/Literature/MeshTerm';
import LiteratureXrefs from 'components/Literature/Xrefs';
import PharmgkbTagButton from 'components/PharmgkbTag/Button';
import Section from 'components/Section';
import Tag from 'components/Tag';
import PediatricTag from 'components/Tag/Pediatric';
import EditControls from 'components/edit/EditControls';
import KyError from 'components/errors/KyError';
import InfoLink from 'components/links/Info';
import ResourceLink, {renderResourceLinks} from 'components/links/Resource';
import CommaSeparatedList from 'components/shared/CommaSeparatedList';
import CuratorOnly from 'components/shared/curator_only';
import Empty from 'components/shared/empty';
import HtmlContainer from 'components/shared/html_container';
import useAppContext from 'conf/AppContext';
import {DataAnnotationType, DataSource, OntologyResource} from 'conf/enums';
import {
  accessionIdProps,
  crossReferenceProps,
  dataAnnotationProps,
  multilinkAnnProps,
  ontologyTermProps,
} from 'conf/types';
import usePediatricContext from 'context/Pediatric';
import RelatedInPaper from 'pages/edit/Literature/RelatedInPaper';

export const LiteratureTypes = {
  LITERATURE: 'Literature',
  DRUG_LABEL: 'Drug Label',
};

const propTypes = {
  literature: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    authors: PropTypes.arrayOf(PropTypes.string),
    journal: PropTypes.string,
    year: PropTypes.number,
    summary: PropTypes.string,
    crossReferences: PropTypes.arrayOf(crossReferenceProps),
    pediatric: PropTypes.bool,
    meshTerms: PropTypes.arrayOf(PropTypes.string),
    terms: PropTypes.arrayOf(ontologyTermProps),
    curationState: PropTypes.string,
    viaExternal: PropTypes.bool,
    viaCpicReview: PropTypes.bool,
    viaJournalScan: PropTypes.bool,
    viaOther: PropTypes.bool,
    viaLitSuggest: PropTypes.bool,
    viaPgxMine: PropTypes.bool,
    litSuggestScore: PropTypes.number,
    pgxMineScore: PropTypes.number,
    notes: PropTypes.string,
    curatorNotes: PropTypes.string,
    fullTextUrl: PropTypes.string,
    type: PropTypes.string,
  }),
  related: PropTypes.shape({
    chemical: PropTypes.arrayOf(accessionIdProps),
    disease: PropTypes.arrayOf(accessionIdProps),
    gene: PropTypes.arrayOf(accessionIdProps),
    haplotype: PropTypes.arrayOf(accessionIdProps),
    pathway: PropTypes.arrayOf(accessionIdProps),
  }),
  relationships: PropTypes.arrayOf(multilinkAnnProps),
  vipRelationships: PropTypes.object,
  lastUpdated: PropTypes.string,
  newRel: PropTypes.string,
  rxAnn: dataAnnotationProps,
  ampTesting: ontologyTermProps,
  labels: PropTypes.arrayOf(accessionIdProps),
};
/**
 * Renders literature overview tab.
 */
export default function LiteratureOverviewTab({literature, related, relationships,
  vipRelationships, lastUpdated, newRel, rxAnn, ampTesting, labels}) {
  const appContext = useAppContext();
  const pediatricContext = usePediatricContext();
  const [updating, setUpdating] = useState(false);
  const updateFromPubMed = async () => {
    try {
      setUpdating(true);
      await appContext.api.get(`curation/literature/${literature.id}/_updateFromPubMed`);
      toast.success('Update complete.');
      window.location.reload();
    } catch (err) {
      setUpdating(false);
      appContext.toastError(<KyError kyError={err} />);
    }
  };

  const deleteSuccessHandler = (daId) => {
    toast.success('Annotation deleted');
    document.getElementById(`rxAnnotation-${daId}`).remove();
  };
  const deleteFailureHandler = (daId, error) => {
    appContext.toastError(<KyError kyError={error} />);
  };

  const paperTypes = map(
    filter(literature.terms, (t) => t.resource === OntologyResource.shortName.pgxPaperTypes), (t) => t.term);

  let addRxAnnBtn;
  if (!rxAnn) {
    const addRxAnnUrl = new URL('/edit/dataAnnotation', appContext.baseUrl);
    addRxAnnUrl.searchParams.append('type', DataAnnotationType.shortName.rx);
    addRxAnnUrl.searchParams.append('litId', toString(literature.id));
    addRxAnnBtn = (
      <Button href={addRxAnnUrl.href}>
        <FontAwesomeIcon icon="plus" /> Add Rx Annotation
      </Button>
    );
  }

  let testingSection;
  let testingBtn;
  if (ampTesting) {
    const {sources, tierTargets} = ampTesting;
    const title = `${DataSource.displayName[sources[0]]} Testing`;
    testingSection = (
      <Section
        id={`${ampTesting.id}`}
        title={title}
        editControls={[
          <EditButton key="edit" href={`/edit/dataAnnotation/${ampTesting.id}`} label="Edit Testing" />,
          <ApproveAndDiffButtons key="approve" objCls="DataAnnotation" objId={ampTesting.id} approved={ampTesting.approved} published={ampTesting.published} />,
        ]}
      >
        <HtmlContainer html={ampTesting.markdown.html} reducedHeaderSize={true} className="mt-4" />
        <table className="table table--nonFluid">
          <thead>
            <tr>
              <th>Minimum Set - Tier 1</th>
              <th>Optional - Tier 2</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{map(filter(tierTargets, (t) => t.ampTier === 'Tier 1'), (t) => <div key={t.id}><ResourceLink resource={t} /></div>)}</td>
              <td>{map(filter(tierTargets, (t) => t.ampTier === 'Tier 2'), (t) => <div key={t.id}><ResourceLink resource={t} /></div>)}</td>
            </tr>
          </tbody>
        </table>
      </Section>
    );
  } else {
    testingBtn = (
      <Button href={`/edit/dataAnnotation?type=${DataAnnotationType.displayName['PGx Testing']}&source=AMP&litId=${literature.id}`}>
        <FontAwesomeIcon icon="plus" /> Add Testing Info
      </Button>
    );
  }

  return (
    <div className="literatureOverviewTab">
      <EditControls>
        <Tag className="tag--sm">
          Status: {literature.curationState}
        </Tag>
        <EditButton href={`/edit/literature/${literature.id}`} />
        <PharmgkbTagButton objId={literature.id} objCls="literature" />
        <WaitingButton
          actionHandler={updateFromPubMed}
          waiting={updating}
          waitingLabel="Updating from PubMed"
          title={lastUpdated ? `Last updated on ${dateFormat(parseISO(lastUpdated), 'MM/dd/yyyy')}` : ''}
        >
          <FontAwesomeIcon icon="sync" /> Update from PubMed
        </WaitingButton>
        {addRxAnnBtn}
        {testingBtn}
      </EditControls>
      {
        literature.pediatric &&
        <div className="mb-4"><PediatricTag /></div>
      }

      {
        literature?.authors?.length > 0 && (
          <>
            <h4>Authors</h4>
            <p>{`${literature.authors.join(', ')}`}</p>
          </>
        )
      }

      <FullTextButton {...literature} />

      <Section title="Cross-References">
        <LiteratureXrefs
          xrefs={literature.crossReferences}
          className={clsx({'mb-3': literature?.fullTextUrl, 'mb-5': !literature.fullTextUrl})}
        />
      </Section>

      {renderSummary(literature, labels)}

      {paperTypes.length > 0 && (
        <CuratorOnly className="mt-3">
          <h5>PGx Paper Type{' '}
            <InfoLink
              href="/help/pgxPaperTypes"
              iconClassName="icon--superscript"
              tooltip="Learn more about PGx paper types"
            />
          </h5>
          <CommaSeparatedList items={paperTypes} />
        </CuratorOnly>
      )}

      {testingSection}

      {!!rxAnn && (
        <Section
          id={`rxAnnotation-${rxAnn.id}`}
          title="Rx Annotation"
          helpLink="/page/rxAnnotation"
          editControls={(
            <>
              <EditButton href={`/edit/dataAnnotation/${rxAnn.id}`} iconOnly={true} />
              <DeleteButton
                url={`curation/dataAnnotation/${rxAnn.id}`}
                id={rxAnn.id}
                iconOnly={true}
                onSuccess={deleteSuccessHandler}
                onFailure={deleteFailureHandler}
              />
            </>
          )}
        >
          <HtmlContainer html={rxAnn.markdown.html} reducedHeaderSize={true} className="mt-4" />
        </Section>
      )}

      {pediatricContext.isPediatric && (
        <Section title="Specialty Population">
          <span>Pediatric</span>
        </Section>
      )}

      <Section title="Discussed In Paper" inline={true} compact={true} helpLink="/page/discussedInPaper">
        {renderDiscussedInPaper(related)}
      </Section>

      <RelatedInPaper
        literatureId={literature?.id}
        relationships={relationships}
        vipRelationships={vipRelationships}
        newRel={parseInt(newRel)}
      />

      <DrugInteractionList interactionType="drugDrug" literatureId={literature.id} />
      <DrugInteractionList interactionType="drugGene" literatureId={literature.id} />

      <CuratorOnly>
        {(literature?.meshTerms.length || 0) > 0 && (
          <Section title="MeSH Terms from PubMed" helpLink="https://www.nlm.nih.gov/mesh/meshhome.html" className="mb-0">
            {literature?.meshTerms.length > 0 && (
              <ul className="list-inline">
                {map(literature.meshTerms, (t) => <li key={t} className="list-inline-item mr-4"><MeshTerm term={t} /></li>)}
              </ul>
            )}
          </Section>
        )}

        <h3>Referred By</h3>
        {
          (literature.viaCpicReview || literature.viaExternal || literature.viaJournalScan ||
            literature.viaLitSuggest || literature.viaPgxMine || literature.viaOther)
            ? (
              <ul>
                {literature.viaCpicReview && <li>CPIC Review</li>}
                {literature.viaExternal && <li>External Source</li>}
                {literature.viaJournalScan && <li>Journal Scan</li>}
                {literature.viaLitSuggest && <li>LitSuggest (score: {numeral(literature.litSuggestScore).format('.000')})</li>}
                {literature.viaPgxMine && <li>PGxMine (score: {literature.pgxMineScore})</li>}
                {literature.viaOther && <li>Other</li>}
              </ul>
            )
            : <p>Unknown.</p>
        }

        {literature.curatorNotes && (
          <>
            <h3>Curator Notes</h3>
            {literature.curatorNotes}
          </>
        )}
      </CuratorOnly>
    </div>
  );
}
LiteratureOverviewTab.propTypes = propTypes;

function renderDiscussedInPaper(related) {
  if (!related || !(related.chemical || related.gene || related.haplotype || related.pathway || related.disease)) {
    return <Empty />;
  }
  return (
    <table className="facts">
      <tbody>
        {related?.chemical && <tr><th>Molecules</th><td>{renderResourceLinks(related.chemical)}</td></tr>}
        {related?.gene && <tr><th>Genes</th><td>{renderResourceLinks(related.gene)}</td></tr>}
        {related?.haplotype && <tr><th>Haplotypes</th><td>{renderResourceLinks(related.haplotype)}</td></tr>}
        {related?.pathway && <tr><th>Pathways</th><td>{renderResourceLinks(related.pathway)}</td></tr>}
        {related?.disease && <tr><th>Diseases</th><td>{renderResourceLinks(related.disease)}</td></tr>}
      </tbody>
    </table>
  );
}

function renderSummary(literature, labels) {
  let summary;
  if (literature.type === LiteratureTypes.DRUG_LABEL) {
    if (size(labels) === 0) {
      return null;
    }
    summary = (
      <div className="litLabelSummaryList">
        {map(labels, (l) => (
          <div>
            <HtmlContainer html={l.summaryMarkdown.html} />
            <div className="font-italic">More information at <ResourceLink resource={l} /></div>
          </div>
        ))}
      </div>
    );
  } else {
    if (!literature.summary) {
      return null;
    }
    summary = <span className="summary">{literature.summary}</span>;
  }
  return (
    <Section title="Summary">
      {summary}
    </Section>
  );
}
