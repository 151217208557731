// These variables are set by Webpack's DefinePlugin:
/* global BASE_URL, BASE_API_URL, CPIC_API_URL, ENV, MODE, SENTRY_DSN, UMAMI_ID */
// MODE - production or preview
// ENV  - production, preview, beta, development or local, or test (when run by Jest)
import localforage from 'localforage';
import * as localforageMemoryDriver from 'localforage-driver-memory';
import {startsWith} from 'lodash';
import PropTypes from 'prop-types';
import {createContext, useContext, useEffect, useMemo, useState} from 'react';
import Modal from 'react-modal';
import {toast} from 'react-toastify';

import {replaceSearchParam} from 'components/links/utils';
import logger from 'conf/Logger';
import KyHelper from 'helpers/KyHelper';
import {checkCookiesEnabled} from 'helpers/cookies';
import Router from 'routes/router';


const USER_CACHE_KEY = 'pgkb.user';

// use the memory driver in case cookies are disabled
localforage.defineDriver(localforageMemoryDriver);
const cache = localforage.createInstance({
  name: 'PharmGKB-' + ENV,
  driver: [localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE, localforageMemoryDriver._driver],
});

let apiCacheTtl = 30;
if (MODE !== 'production') {
  if (ENV === 'local') {
    apiCacheTtl = 5;
  } else {
    apiCacheTtl = 15;
  }
}
const api = new KyHelper({
  prefixUrl: BASE_API_URL,
  cache,
  cacheTtl: apiCacheTtl,
});
const cpicApi = new KyHelper({
  prefixUrl: CPIC_API_URL,
  cache,
  cacheTtl: apiCacheTtl,
});


const inBrowser = (typeof window !== 'undefined');
const cookiesEnabled = checkCookiesEnabled();
const currentUser = {
  userId: '',
  email: '',
  name: '',
  isLoggedIn: false,
  login: (userData) => {
    currentUser.userId = userData.userId;
    currentUser.email = userData.email;
    currentUser.name = userData.name;
    currentUser.isLoggedIn = true;
  },
  logout: () => {
    currentUser.userId = '';
    currentUser.email = '';
    currentUser.name = '';
    currentUser.isLoggedIn = false;
  },
};
const router = new Router();

const initializeApp = () => {
  cache.getItem(USER_CACHE_KEY)
    .then((userData) => {
      if (userData) {
        currentUser.login(userData);
      }
      if (inBrowser) {
        if (!router.history.started()) {
          router.history.start();
        }
      }
    });
  Modal.setAppElement('#app');
  return {router, currentUser};
};


/**
 * Global application context.
 *
 * Default value is undefined intentionally (for reasons, see
 * https://kentcdodds.com/blog/how-to-use-react-context-effectively).
 *
 * @property {boolean} isPreview - true if app is in preview mode; false if in production mode
 * @property {boolean} isDevEnv - true if app is in a non-production environment
 * @property {string} umamiId
 * @property {string} sentryDsn
 * @property {boolean} inBrowser - true if is app running in browser
 * @property {boolean} actionOnLeft - if the action button should be on the left
 *
 * @property {object} user - the currently signed-in user
 * @property {Function} login - method to log a user in
 * @property {Function} logout - method to log a user out
 *
 * @property {KyHelper} api - KyHelper instance
 * @property {LocalForage} cache - localforage instance
 *
 * @property {getApiUrl} apiUrl - gets the API URL for the specified endpoint
 * @property {string} baseUrl - base URL for the app
 * @property {getCurrentUrl} getCurrentUrl - gets the current URL (i.e. schema://hostname/path)
 * @property {getCurrentPath} getCurrentPath - gets the current path (i.e. URL without
 * schema://hostname)
 */
const AppContext = createContext(undefined);


const propTypes = {
  children: PropTypes.node,
  /**
   * Sets production mode (for testing).
   */
  forceProductionMode: PropTypes.bool,
  /**
   * Sets user (for testing).
   */
  forceUser: PropTypes.object,
  /**
   * Sets whether action button is on the left (for testing).
   * Mutually exclusive with `forceActionOnRight`.
   */
  forceActionOnLeft: PropTypes.bool,
  /**
   * Sets whether action button is on the right (for testing).
   * Mutually exclusive with `forceActionOnLeft`.
   */
  forceActionOnRight: PropTypes.bool,
  /**
   * Sets whether app is running in test mode (for testing).
   */
  testMode: PropTypes.bool,
};

/**
 * This is a React.Context.Provider for the entire app.
 *
 * @param {object} props
 * @param {React.node} props.children
 * @param {boolean} [props.forceProductionMode] - forces production mode (for testing)
 * @param {object} [props.forceUser] - forces setting of user (for testing)
 * @param {boolean} [props.forceActionOnLeft] - forces action button on left (for testing)
 * @param {boolean} [props.forceActionOnRight] - forces action button on right (for testing)
 * @param {boolean} [props.testMode] - sets whether running in testMode
 * @return {React.Context.Provider}
 */
const AppContextProvider = ({children, forceProductionMode = false, forceUser,
  forceActionOnLeft = false, forceActionOnRight = false, testMode = false}) => {
  const [user, setUser] = useState(forceUser || null);

  const baseUrl = BASE_URL;
  const isDevEnv = !['production', 'preview'].includes(ENV);
  let isPreview = MODE !== 'production';
  // forceProductionMode only applies if defined and true; otherwise, MODE trumps it
  if (forceProductionMode) {
    isPreview = false;
  }

  const sentryDsn = SENTRY_DSN;
  const umamiId = UMAMI_ID;
  const isWindows = inBrowser && navigator?.platform.indexOf('Win') > -1;
  // browser detection: https://stackoverflow.com/a/31732310/11976351
  const isSafari = inBrowser && (
    navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') === -1 &&
    navigator.userAgent.indexOf('FxiOS') === -1
  );


  // Windows default is for action button to be on the left; everything else defaults to right
  let actionOnLeft = isWindows;
  if (forceActionOnLeft) {
    actionOnLeft = true;
  }
  if (forceActionOnRight) {
    actionOnLeft = false;
  }

  /**
   * Gets the API URL for the specified endpoint.
   *
   * @function getApiUrl
   * @param {string} endpoint
   * @return {string}
   */
  const apiUrl = (endpoint) =>
    BASE_API_URL + (startsWith(endpoint, '/') ? endpoint : ('/' + endpoint));

  const cpicUrl = (endpoint) =>
    CPIC_API_URL + (startsWith(endpoint, '/') ? endpoint : ('/' + endpoint));

  /**
   * Gets the API URL for downloading attachments.
   *
   * @param {string} fileName
   * @return {string}
   */
  const downloadAttachmentUrl = (fileName) => apiUrl(`/download/file/attachment/${fileName}`);

  /**
   * Gets the current URL (i.e. schema://hostname/path).
   *
   * @function getCurrentUrl
   * @return {string}
   */
  const getCurrentUrl = () => baseUrl + '/' + router.history.fragment;

  /**
   * Gets the current path (i.e. URL without scheme://hostname).
   *
   * @function getCurrentPath
   * @return {string}
   */
  const getCurrentPath = () => '/' + router.history.fragment;

  /**
   * Routes to the given path (i.e. URL without scheme://hostname).
   *
   * @param {string} path
   * @param {boolean} replaceHistory - determines if redirect will add to history or replace current location in history
   * @return {void}
   */
  const redirect = (path, replaceHistory = false) => {
    router.redirect(path, replaceHistory);
  };

  /**
   * Reloads the current page.
   */
  const reload = () => {
    router.reload();
  };


  const reloadData = (loadId) => {
    const path = replaceSearchParam(getCurrentPath(), 'loadId', `${loadId || Date.now()}`);
    redirect(path, true);
  };


  const login = async (userData, redirectUrl, isLogin = true) => {
    logger.info('Logging in as', userData.userId);
    setUser(userData);
    currentUser.login(userData);
    try {
      await cache.setItem(USER_CACHE_KEY, userData);
    } catch (cacheError) {
      logger.error('Error adding user cache entry', cacheError);
    }
    if (inBrowser && !testMode) {
      const target = redirectUrl || '/';
      if (getCurrentPath() === target) {
        router.reload();
      } else {
        router.redirect(target);
      }
    }
  };

  const logout = async () => {
    if (user || currentUser.isLoggedIn) {
      logger.info('Logging out', user?.userId || currentUser?.userId);
      try {
        await api.get('auth/signOut');
      } catch (error) {
        logger.error('Problem signing out', error);
        return;
      }
      setUser(null);
      currentUser.logout();
      try {
        await cache.removeItem(USER_CACHE_KEY);
      } catch (cacheError) {
        logger.error('Error removing user cache entry', cacheError);
      }
      router.redirect('/');
    }
  };

  // cannot use useGet hook because it's dependent AppContext, which isn't initialized yet
  useEffect(() => {
    async function loadUser() {
      try {
        await login(await api.get('user', {parseJson: true}), getCurrentPath(), false);
      } catch (kyError) {
        setUser(null);
        currentUser.logout();
        logger.debug('Not logged in');
      }
    }
    if (forceUser) {
      setUser(forceUser);
    } else {
      // noinspection JSIgnoredPromiseFromCall
      loadUser();
    }
  }, [forceUser]);


  /**
   * Checks if given error from ky is due to a network error.
   */
  const isApiUp = async () => {
    try {
      await fetch(BASE_API_URL.substring(0, BASE_API_URL.indexOf('/', 10)) + '/v1/');
      return false;
    } catch (error) {
      logger.error('Error checking API', error);
      return true;
    }
  };

  /**
   * Renders a persistent error toast.
   *
   * @param {string|React.ReactNode|JSX.Element} error
   * @return {string} toast ID (can be used to dismiss toast)
   */
  const toastError = (error) => toast.error(error, {autoClose: false, closeOnClick: false});

  /**
   * Renders a persistent warning toast.
   *
   * @param {string|React.ReactNode|JSX.Element} warning
   * @return {string} toast ID (can be used to dismiss toast)
   */
  const toastWarning = (warning) => toast.warn(warning, {autoClose: false, closeOnClick: false});


  const providerProps = useMemo(() => ({
    isDevEnv,
    isPreview,
    umamiId,
    sentryDsn,
    inBrowser,
    cookiesEnabled,
    isSafari,
    actionOnLeft,

    user,
    login,
    logout,

    api,
    isApiUp,
    cache,
    toastError,
    toastWarning,

    apiUrl,
    downloadAttachmentUrl,
    baseUrl,
    getCurrentUrl,
    getCurrentPath,
    redirect,
    reload,
    reloadData,

    cpicApi,
    cpicUrl,
  }), [
    isPreview,
    actionOnLeft,
    user,
  ]);

  return (
    <AppContext.Provider value={providerProps}>
      {children}
    </AppContext.Provider>
  );
};
AppContextProvider.propTypes = propTypes;

const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('AppContext must be used within AppProvider');
  }
  return context;
};

export default useAppContext;
export {AppContextProvider, initializeApp, cache as _cache};
