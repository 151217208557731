import PropTypes from 'prop-types';

import './Inline.scss';
import Loading from 'components/Loading';
import PageContent from 'components/Page/Content';
import useEditContext from 'components/edit/EditContext';
import KyError from 'components/errors/KyError';
import CuratorOnly from 'components/shared/curator_only';
import useAppContext from 'conf/AppContext';
import {useGet} from 'helpers/KyHooks';


const propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showTitle: PropTypes.bool,
  reducedHeaderSize: PropTypes.bool,
  className: PropTypes.string,
  editHint: PropTypes.string,
  extraControls: PropTypes.node,
};

export default function InlinePage({id, showTitle = false, reducedHeaderSize = false, className = '', editHint,
  extraControls}) {
  const appContext = useAppContext();
  const editContext = useEditContext();

  const {response, error} = useGet(`data/page/${id}`, {
    searchParams: {
      view: 'min',
    },
    cacheResponse: !appContext.isPreview,
  });

  let content = <Loading />;
  if (response) {
    content = (
      <PageContent
        id={id}
        title={response.data?.title}
        html={response.data?.markdown?.html}
        showTitle={showTitle}
        reducedHeaderSize={reducedHeaderSize}
        className={className}
        editHint={editHint}
        extraControls={extraControls}
      />
    );
  } else if (error) {
    if (error?.response?.status === 404 && editContext.isEditMode) {
      content = (
        <CuratorOnly>
          <p>
            Cannot find page with key &quot;{id}&quot;.   Would you like to create it?
          </p>
          <p>
            <a href={`/edit/page?pageKey=${encodeURIComponent(id)}`} className="btn btn-primary">Create</a>
          </p>
        </CuratorOnly>
      );
    } else {
      content = <KyError kyError={error} />;
    }
  }

  return content;
}
InlinePage.propTypes = propTypes;
