import PropTypes from 'prop-types';
import {lazy, Suspense} from 'react';

import Loading from 'components/Loading';
import FeedbackLink from 'components/links/Feedback';
import CuratorOnly from 'components/shared/curator_only';
import useAppContext from 'conf/AppContext';


const pmidRegex = /^\/?pmid\/(.*)$/;
const pageRegex = /^\/?page\/(.*)$/;

const propTypes = {
  location: PropTypes.string,
};
export default function NotFound({location}) {
  const appContext = useAppContext();
  const path = location ?? appContext.getCurrentPath();
  
  const body = `Bad link from external source to /${location}.`;

  return (
    <div className="container">
      <div className="error-box island-md">
        <span className="error-code">Error Code: 404</span>
        <h1>Resource Not Found</h1>

        <p>The page you requested cannot be found.</p>

        { appContext.user && renderHint(path) }

        <p>
          If you arrived at this page via a link from another site
          please <FeedbackLink subject="Bad link (external source)" body={body} srcUrl={location}>let us know</FeedbackLink> so
          that we can work with them to fix the issue.
        </p>

        <p>
          If you arrived at this page via a link from within PharmGKB,
          please <FeedbackLink subject="Bad link (internal source)" body={body} srcUrl={location}>let us know</FeedbackLink> so
          that we may correct the error.
        </p>

        <p>Our apologies for the inconvenience,<br />The PharmGKB Team</p>
      </div>
    </div>
  );
}
NotFound.propTypes = propTypes;



function renderHint(path) {
  const pmidMatch = pmidRegex.exec(path);
  if (pmidMatch) {
    return <MissingPmid subPath={pmidMatch[1]} />;
  }
  const pageMatch = pageRegex.exec(path);
  if (pageMatch) {
    return <MissingPage subPath={pageMatch[1]} />;
  }
}


function MissingPmid({subPath}) {
  const hashCheck = subPath.match(/^(\d+?)#.*$/);
  const pmid = hashCheck ? hashCheck[1] : subPath;

  if (!pmid.match(/^\d+$/)) {
    return (
      <CuratorOnly>
        &quot;{pmid}&quot; does not appear to be a valid PMID.
      </CuratorOnly>
    );
  }

  const PmidImportForm = lazy(() => import(/* webpackChunkName: "curation" */'edit/literature/PmidImportForm'));
  return (
    <CuratorOnly>
      <p>
        This PMID is not in our system. Do you want to add it?
      </p>
      <Suspense fallback={<Loading />}>
        <PmidImportForm pmid={pmid} smallForm={true} />
      </Suspense>
    </CuratorOnly>
  );
}

function MissingPage({subPath}) {
  const hashCheck = subPath.match(/^(.+?)#.*$/);
  let pageKey = hashCheck ? hashCheck[1] : subPath;
  const rez = pageKey.match(/([\w-.]+)/);
  if (!rez || rez[1] !== pageKey) {
    return null;
  }
  pageKey = rez[1];
  return (
    <CuratorOnly>
      <p>
        This page is not in our system.  Would you like to create it?
      </p>
      <p>
        <a href={`/edit/page?pageKey=${encodeURIComponent(pageKey)}`} className="btn btn-primary">Create</a>
      </p>
    </CuratorOnly>
  );
}
