import {toString} from 'lodash';
import PropTypes from 'prop-types';

import ApproveButton from 'components/Button/Approve';
import useEditContext from 'components/edit/EditContext';
import EditControls from 'components/edit/EditControls';
import Link from 'components/links/Link';
import {getBaseUrl, normalizeType} from 'conf/types';

import './ViewLink.scss';


const propTypes = {
  value: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    objCls: PropTypes.string.isRequired,
    needsApproval: PropTypes.bool,
  }),
};
/**
 * Renders an button to go to the page indicated by the AccessionIdentifier (value).
 *
 * Must `{className: 'viewLinkCell'}`.
 *
 * To export, use `{getCellExportValue: viewLinkExportValue}`.
 */
export default function ViewLinkCell({value}) {
  const editContext = useEditContext();
  if (!value) {
    return '';
  }
  const url = getBaseUrl(value.id, value.objCls);

  const renderApproveBtn = () => {
    if (!editContext.isEditMode) {
      return '';
    }
    return (
      <EditControls className="mt-1 editControls--compact">
        <ApproveButton
          objCls={normalizeType(value.objCls)}
          objId={toString(value.id)}
          disabled={!value.needsApproval}
          className="btn-sm"
          iconOnly={true}
        />
      </EditControls>
    );
  };

  return (
    <>
      <Link href={url} className="btn btn-xs btn-primary" iconOnly={true} title="Details">
        Details
      </Link>
      {renderApproveBtn()}
    </>
  );
}
ViewLinkCell.propTypes = propTypes;


/**
 * React-table `getColumnExportValue` for view link column.
 *
 * @return {string}
 */
export function viewLinkExportHeader() {
  return 'PharmGKB ID';
}


/**
 * React-table `getCellExportValue` for view link column.
 *
 * @param {object} row
 * @param {object} column
 * @return {string}
 */
export function viewLinkExportValue(row, column) {
  return row.values[column.id].id;
}
