import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useMemo} from 'react';
import {useFormContext} from 'react-hook-form';

import FormGroup from 'components/form/Group';
import {buildCustomValidation, isInvalid} from 'utils/formUtils';

const propTypes = {
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  help: PropTypes.string,
  helpLink: PropTypes.string,
  validation: PropTypes.object,
  required: PropTypes.bool,
  /** Marks input field as read-only. Will submit */
  readOnly: PropTypes.bool,
  /** Disables input field. Will not submit. */
  disabled: PropTypes.bool,
  className: PropTypes.string,
};
/**
 * Textarea element to use with react-hook-form.
 */

export default function FormTextarea({label, name, value, help, helpLink, readOnly = false, disabled, validation,
  required = false, className, ...textareaAttribs}) {

  return (
    <FormGroup
      label={label}
      name={name}
      help={help}
      helpLink={helpLink}
      readOnly={readOnly}
      disabled={disabled}
      className={className}
      render={(id) => (
        <TextareaInput
          id={id}
          name={name}
          defaultValue={value}
          validation={validation}
          required={required}
          readOnly={readOnly}
          disabled={disabled}
          {...textareaAttribs}
        />
      )}
    />
  );
}
FormTextarea.propTypes = propTypes;


function TextareaInput({name, defaultValue, validation, required, readOnly, disabled,
  className, ...textareaAttribs}) {
  const {register, formState: {errors}} = useFormContext();
  const customValidation = useMemo(() => buildCustomValidation({validation, required}),
    [validation, required]);
  const invalid = isInvalid({disabled, errors, name});
  return (
    <textarea
      className={clsx('form-control', className, {'is-invalid': invalid})}
      {...register(name, {disabled, ...customValidation})}
      defaultValue={defaultValue}
      readOnly={readOnly}
      {...textareaAttribs}
    />
  );
}
