import {isUndefined} from 'lodash';
import PropTypes from 'prop-types';
import {createContext, useContext, useEffect, useMemo, useState} from 'react';

import useAppContext from 'conf/AppContext';
import logger from 'conf/Logger';


const EDIT_MODE_CACHE_KEY = 'pgkb.editMode';

/**
 * Edit context (determines whether edit controls should be visible).
 */
const EditContext = createContext(undefined);

function _canEdit(appContext) {
  return appContext.isPreview && !!appContext.user;
}

const propTypes = {
  children: PropTypes.node,
  /** Forces edit mode (for testing).  */
  forceEditMode: PropTypes.bool,
};
/**
 * This is a React.Context.Provider for the edit context.
 */
export function EditContextProvider({children, forceEditMode}) {
  const appContext = useAppContext();
  const [isEditMode, setEditMode] = useState(isUndefined(forceEditMode) ? _canEdit(appContext) : forceEditMode);

  useEffect(() => {
    if (!isUndefined(forceEditMode) || !_canEdit(appContext)) {
      return;
    }
    appContext.cache.getItem(EDIT_MODE_CACHE_KEY)
      .then((value) => {
        if (value !== null) {
          const isEnabled = value === 'true';
          if (isEnabled !== isEditMode) {
            setEditMode(isEnabled);
          }
        }
      })
      .catch((err) => {
        logger.warn('Error loading edit mode from cache', err);
      });
  }, [forceEditMode, appContext.user, appContext.isPreview]);

  /**
   * Toggles edit mode.
   */
  const toggleEditMode = () => {
    setEditMode((prevState) => {
      appContext.cache.setItem(EDIT_MODE_CACHE_KEY, !prevState ? 'true' : 'false')
        .catch((err) => logger.warn('Error caching edit mode', err));
      return !prevState;
    });
  };

  /**
   * Updates edit mode to specified value.
   */
  const updateEditMode = (isEnabled) => {
    appContext.cache.setItem(EDIT_MODE_CACHE_KEY, isEnabled ? 'true' : 'false')
      .catch((err) => logger.warn('Error caching edit mode', err));
    setEditMode(isEnabled);
  };

  const providerProps = useMemo(() => ({
    isEditMode,
    setEditMode: updateEditMode,
    toggleEditMode,
  }), [isEditMode]);

  return (
    <EditContext.Provider value={providerProps}>
      {children}
    </EditContext.Provider>
  );
}
EditContextProvider.propTypes = propTypes;


const useEditContext = () => {
  const context = useContext(EditContext);
  if (!context) {
    throw new Error('EditContext must be used within EditContextProvider');
  }
  return context;
};
export default useEditContext;
