import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import Button from 'components/Button';
import ApproveAndDiffButtons from 'components/Button/ApproveAndDiff';
import EditButton from 'components/Button/Edit';
import ApprovalStatusFilter, {approvalStatusFilter} from 'components/Table/Filter/ApprovalStatus';
import {fixedColumnStyle} from 'components/Table/columnHelpers';
import EditControls from 'components/edit/EditControls';
import FocusIcon from 'components/icons/Focus';


/**
 * Typical defaults for an Controls column.
 */
export const baseControlsColumn = {
  id: 'controls',
  Header: 'Controls',
  accessor: controlAccessor,
  Cell: ControlCell,
  Filter: ApprovalStatusFilter,
  filter: approvalStatusFilter,
  getCellExportValue: controlsExportValue,
  getColumnExportValue: controlsExportHeader,
};

export default function ControlCell({row, objCls}) {
  const {id, approved, published} = row.original;
  return (
    <EditControls className="editControls--inline">
      <Button href={`/${objCls}/${id}`} newTab={true} iconOnly={true} className="btn-primary" title="View details">
        <FocusIcon />
      </Button>
      <EditButton href={`/edit/${objCls}/${id}`} newTab={true} iconOnly={true}>edit</EditButton>
      <ApproveAndDiffButtons iconOnly={true} objCls={objCls} objId={id} approved={approved} published={published} />
      <span className="indicator">{!published && <FontAwesomeIcon icon="eye-slash" />}</span>
    </EditControls>
  );
}

/**
 * This function will add a "Controls" column to the table if we're in edit mode. This is safe to call even if you're
 * not in editMode since it will just return the given columns if not in edit mode.
 *
 * @param {Array} columns - the columns (without a Controls column) for the table
 * @param {string} objCls - the class this table lists
 * @param {boolean} editMode - true if the site is in edit mode, false (default) otherwise
 * @param {number} width - width of controls column
 * @return {Array} a new set of columns, possibly with a Controls column at the beginning
 */
export function addControlsColumn(columns, objCls, editMode = false, width = 170) {
  if (!editMode) {
    return columns;
  } else {
    return [
      {
        ...baseControlsColumn,
        Cell: ({row}) => <ControlCell row={row} objCls={objCls} />,
        ...fixedColumnStyle(width),
      },
      ...columns,
    ];
  }
}

function controlAccessor(originalRow) {
  const {approved, published} = originalRow;
  return ({
    approved,
    published,
  });
}

/**
 * React-table `getCellExportValue` for Controls.
 *
 * @param {object} row
 * @param {object} column
 * @return {string}
 */
function controlsExportValue(row, column) {
  const {approved, published} = row.values[column.id];
  let output = '';
  if (!approved) {
    output += 'unapproved';
  }
  if (!published) {
    if (output?.length > 0) {
      output += ', ';
    }
    output += 'unpublished';
  }
  return output;
}

function controlsExportHeader() {
  return 'Action needed';
}
