import {groupBy, map, sortBy} from 'lodash';

import {findObject} from 'components/DrugTarget/Item';
import LabelOccurrenceItem from 'components/LabelOccurrence/Item';

/**
 * Show a list of objects that are associated with the given subjectId grouped by label section.
 *
 * @param {object} props - props container
 * @param {Array} props.occurrences - an array of LinkAnnotation objects
 * @param {string} props.subjectId - the ID of the page this is being shown on
 */
export default function LabelOccurrenceSection({occurrences = [], subjectId}) {
  const groupedOccurrences = groupBy(occurrences, (o) => o.type);
  if (occurrences.length === 0) {
    return null;
  }
  return (
    <ul>
      {map(Object.keys(groupedOccurrences), (g) => {
        const anns = sortBy(groupedOccurrences[g], (a) => findObject(a.object1, a.object2, subjectId).name);
        return (
          <li key={g}>
            {g}
            <ul>{map(anns, (o) => <LabelOccurrenceItem key={o.id} {...o} subjectId={subjectId} />)}</ul>
          </li>
        );
      })}
    </ul>
  );
}
