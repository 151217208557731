import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {capitalize, find, flatten, forEach, includes, map, size, startCase, values} from 'lodash';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';

import './index.scss';
import Button from 'components/Button';
import ApproveButton from 'components/Button/Approve';
import DataAnnotationUpsertButton from 'components/DataAnnotation/UpsertButton';
import HistoryTable from 'components/History/Table';
import MultiColumnList from 'components/List/MultiColumn';
import Loading from 'components/Loading';
import PharmgkbTagButton from 'components/PharmgkbTag/Button';
import AmpTierTag from 'components/Tag/AmpTier';
import RareVariantTag from 'components/Tag/RareVariant';
import EditControls from 'components/edit/EditControls';
import Link from 'components/links/Link';
import ResourceCounts, {convertTypedCounts, resourceCountsProps} from 'components/resource/resourceCounts';
import InlineList from 'components/shared/InlineList';
import CuratorOnly from 'components/shared/curator_only';
import Fact from 'components/shared/fact';
import FactSection from 'components/shared/fact_section';
import VipRelatedSummary from 'components/vip_gene/VipRelatedSummary';
import useAppContext from 'conf/AppContext';
import logger from 'conf/Logger';
import {accessionIdProps, historyProps, sequenceLocationProps} from 'conf/types';
import DbsnpUpdateButton from 'pages/Variant/OverviewTab/DbsnpUpdateButton';
import VariantFrequencyTable from 'pages/Variant/OverviewTab/FrequencyTable';

const frequencyTabs = [
  {source: 'gnomadGenome', name: 'gnomAD Genomes', label: 'gnomAD Genome'},
  {source: 'gnomadExome', name: 'gnomAD Exomes', label: 'gnomAD Exome'},
  {source: '1000_Genomes', name: '1000 Genomes', label: '1000 Genomes'},
  {source: 'ALFA', name: 'Allele Frequency Aggregator', label: 'ALFA'},
];

const propTypes = {
  variant: PropTypes.shape({
    id: PropTypes.string,
    symbol: PropTypes.string,
    changeClassification: PropTypes.string,
    type: PropTypes.string,
    relatedGenes: PropTypes.arrayOf(accessionIdProps),
    locations: PropTypes.arrayOf(sequenceLocationProps),
    lastUpdatedFromDbsnp: PropTypes.string,
    obsolete: PropTypes.bool,
    rare: PropTypes.bool,
    raritySource: PropTypes.string,
    ampTier: PropTypes.string,
    history: PropTypes.arrayOf(historyProps),
  }),
  counts: resourceCountsProps,
  needsApproval: PropTypes.bool,
  description: PropTypes.shape({
    id: PropTypes.number,
    html: PropTypes.string,
  }),
  vips: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    tier: PropTypes.string,
    sections: PropTypes.arrayOf(PropTypes.string),
  })),
  frequencySources: PropTypes.arrayOf(PropTypes.string),
};
/**
 * Renders the variant overview tab.
 */
export default function VariantOverviewTab({variant, counts = {}, needsApproval, description, vips = [],
  frequencySources = []}) {
  const appContext = useAppContext();
  const [frequencySource, setFrequencySource] = useState(frequencyTabs[0].source);

  useEffect(() => {
    // Open the first tab that includes data
    const initialTabId = find(frequencyTabs, (tab) => includes(frequencySources, tab.name))?.source;
    setFrequencySource(initialTabId || frequencyTabs[0].source);
  }, [frequencySources]);

  if (!variant) {
    return <Loading />;
  }

  const resourceCounts = convertTypedCounts('variant', variant?.id, counts);
  const getType = () => {
    if (variant.type === 'snp') {
      return 'SNP';
    }
    if (variant.type === 'unknown') {
      return <span className="empty">Unknown</span>;
    }
    return capitalize(variant.type);
  };
  const getClass = () => {
    if (variant.changeClassification === 'Not Available') {
      return <span className="empty">Not Available</span>;
    }
    return startCase(variant.changeClassification);
  };


  const frequencySourceChangeHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const source = event.target.getAttribute('data-id');
    if (source) {
      setFrequencySource(source);
    } else {
      logger.warn('No data-id', event.target);
    }
  };

  const renderTab = (source, name, label) => {
    const isSelected = frequencySource === source;
    const hasData = includes(frequencySources, name);

    return (
      <a
        key={source}
        href="#"
        role="tab"
        aria-selected={isSelected}
        className={clsx('nav-item', 'nav-link', {active: isSelected, empty: !hasData})}
        onClick={frequencySourceChangeHandler}
        data-id={source}
      >
        {label}
        {hasData && <FontAwesomeIcon className="dataPresentDot" icon="circle" size="2xs" />}
      </a>
    );
  };

  const aliases = _collateAliases(variant);
  const aliasRenderFn = (elem) => {
    const xrefMarker = appContext.user ? <sup>&dagger;</sup> : '';
    if (elem.retired) {
      return <>{elem.name}{xrefMarker} (retired)</>;
    }
    return elem.name;
  };
  const onApproveSuccess = () => {
    toast.success('Approved.');
    appContext.reloadData();
  };

  
  let aliasNote = '';
  if (appContext.isPreview && aliases?.length) {
    aliasNote = (
      <p>
        A <sup>&dagger;</sup> indicates that the alias comes from cross-references.
      </p>
    );
  }
  return (
    <div className="overviewTab">
      <EditControls>
        {variant.obsolete && <><span className="badge badge-danger">Retired RSID</span>&nbsp;</>}
        <Button href={`/edit/variant/${variant.id}`}>
          <FontAwesomeIcon icon="edit" /> Edit
        </Button>
        {variant.symbol ? <DbsnpUpdateButton id={variant.id} rsid={variant.symbol} lastUpdated={variant.lastUpdatedFromDbsnp} /> : ''}
        <Button href={`/edit/alleles/${variant.id}`}>
          <FontAwesomeIcon icon="edit" /> Change Alleles
        </Button>
        <ApproveButton objCls="variant" objId={variant.id} disabled={!needsApproval} onSuccess={onApproveSuccess} />
        <PharmgkbTagButton objId={variant.id} objCls="variant" />
      </EditControls>
      <div>
        <FactSection>
          <ResourceCounts counts={resourceCounts} />
          {(variant.rare || variant.ampTier) && (
            <div className="mt-3">
              {variant.rare && <RareVariantTag source={variant.raritySource} />}
              {variant.ampTier && <AmpTierTag className="tag--sm" tier={variant.ampTier} />}
            </div>
          )}
        </FactSection>

        <FactSection>
          <Fact
            label="Description"
            html={description?.html}
            labelBtns={<DataAnnotationUpsertButton targetId={variant.id} type="Description" annotationId={description?.id} />}
            hideWhenEmpty={!appContext.isPreview}
          />

          {size(vips) > 0 && (
            <CuratorOnly>
              {map(vips, (vip) => <VipRelatedSummary {...vip} key={vip.url} />)}
            </CuratorOnly>
          )}

          <Fact label="Associated Genes">
            <InlineList collection={variant.relatedGenes} />
          </Fact>
          <Fact label="Type">{getType()}</Fact>
          <Fact label="Classification">{getClass()}</Fact>
        </FactSection>

        <FactSection title="Locations">
          <SequenceLocations locations={variant.locations} />
        </FactSection>

        <FactSection title="Variant Frequencies">
          {variant.rare && (
            <p>
              This variant is considered <strong>rare</strong> based on global allele frequencies{variant.raritySource === 'Unknown' ? null : ` based on data from ${variant.raritySource}`}. <a href="/page/rareVariants">more info</a>
            </p>
          )}
          <nav className="nav nav-tabs" role="tablist">
            {map(frequencyTabs, (tab) => renderTab(tab.source, tab.name, tab.label))}
          </nav>
          <div className="nav-tab-content" role="tabpanel">
            <VariantFrequencyTable variantId={variant.id} source={frequencySource} />
          </div>
        </FactSection>


        <FactSection title="Identifiers" compact={true}>
          <Fact label="PharmGKB ID" literal={variant.id} />
          <Fact
            label="Aliases"
            labelBtns={(
              <EditControls className="editControls--inline">
                <Button
                  href={`/edit/alternateNames/variant/${variant.id}`}
                  title="Edit alternate names"
                >
                  <FontAwesomeIcon icon="edit" /> Edit
                </Button>
              </EditControls>
            )}
          >
            <MultiColumnList data={aliases} renderFn={aliasRenderFn} sizeFn={aliasSizeFn} />
            {aliasNote}
          </Fact>
        </FactSection>

        <FactSection title="History">
          <HistoryTable history={variant?.history} parentCls="Variant" parentId={variant?.id} />
        </FactSection>
      </div>
    </div>
  );
}
VariantOverviewTab.propTypes = propTypes;


const seqLocPropTypes = {
  locations: PropTypes.arrayOf(sequenceLocationProps),
};
/**
 * Renders a Variant's SequenceLocations.
 *
 * @param {object} props - component props
 * @param {Array} [props.locations] - sequence locations
 * @return {React.ReactNode}
 */
export function SequenceLocations({locations = []}) {
  const getRange = (begin, end) => {
    if (!begin || !end) {
      return '';
    } else if (begin === end) {
      return begin;
    } else {
      return `${begin} - ${end}`;
    }
  };

  const renderChange = (locationId, referenceAllele, variantAllele, variantHgvs) => {
    const hgvs = variantHgvs ? `HGVS: ${variantHgvs}` : '';
    return (
      <li key={`${locationId}-${referenceAllele}-${variantAllele}`}>
        <span title={hgvs}>{referenceAllele}&nbsp;&gt;&nbsp;{variantAllele}</span>
      </li>
    );
  };

  const renderChanges = (locationId, referenceAllele, variantAlleles, variantHgvs) => {
    if (variantAlleles.length === 0) {
      return null;
    }
    return (
      <ul className="list-unstyled mb-0">
        {variantAlleles.map((v, index) =>
          renderChange(locationId, referenceAllele, v, variantHgvs?.[index]))}
      </ul>
    );
  };

  const renderLocation = (loc) => {
    const {id, assembly, sequence, begin, end, referenceAllele, variantAlleles, variantHgvs,
      source} = loc;
    return (
      <tr key={id}>
        <td>{assembly}</td>
        <td>{!sequence._url ? sequence.resourceId : <Link href={sequence._url} showNewTabIcon={true}>{sequence.resourceId}</Link>}</td>
        <td>{getRange(begin, end)}</td>
        <td>{renderChanges(id, referenceAllele, variantAlleles, variantHgvs)}</td>
        <td>{source}</td>
      </tr>
    );
  };

  if (!locations?.length) {
    return <p className="empty">None</p>;
  }
  return (
    <table className="table table-sm">
      <thead>
        <tr>
          <th>Assembly</th>
          <th>Sequence</th>
          <th>Position</th>
          <th>Alleles</th>
          <th>Source</th>
        </tr>
      </thead>
      <tbody>
        {map(locations, renderLocation)}
      </tbody>
    </table>
  );
}
SequenceLocations.propTypes = seqLocPropTypes;

function _collateAliases(variant) {
  const aliases = [];
  if (variant) {
    forEach(variant?.crossReferences, (xref) => {
      if (xref.resource === 'dbSNP' && !xref.current && xref.resourceId !== variant.symbol) {
        aliases.push({name: xref.resourceId, retired: true});
      }
    });
    forEach(flatten(values(variant?.altNames)), (altName) => {
      aliases.push({name: altName});
    });
  }
  return aliases;
}

function aliasSizeFn(elem) {
  if (elem.retired) {
    return elem.name.length + 1;
  }
  return elem.name.length;
}
