import clsx from 'clsx';
import {every, forEach, map} from 'lodash';
import PropTypes from 'prop-types';
import {useMemo} from 'react';

import BarChart from 'components/Chart/LazyBar';
import Loading from 'components/Loading';
import {calculateAccIdKey} from 'utils/dependencyUtils';


const propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  })),
  pathways: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf(PropTypes.string),
  })),
  filterChangeFn: PropTypes.func,
  className: PropTypes.string,
  yAxisLabel: PropTypes.object,
};

/**
 * Renders pathways bar chart.
 *
 * @param {object} props - props container
 * @param {Array} [props.categories]
 * @param {Array} [props.pathways]
 * @param {Function} [props.filterChangeFn] - function to call whenever y-axis is changed
 * @param {string} [props.className]
 * @param {object} [props.yAxisLabel]
 * @return {JSX.Element | null}
 */
export default function PathwaysBarChart({categories, pathways, filterChangeFn, className, yAxisLabel}) {

  const dependencyKey = categories
    ? map(categories, (category) => category.label).join(',')
    : calculateAccIdKey(pathways);

  const chartData = useMemo(() => {
    if (categories) {
      return categories;
    } else if (pathways) {
      return createPathwaysChartData(pathways);
    } else {
      return null;
    }
  }, [dependencyKey]);

  if (chartData.length === 0 || pathways?.length <= 5) {
    return null;
  }

  if (!chartData) {
    return (
      <div className="pathwaysFigure pathwaysFigure--loading figure figure--floatRight">
        <Loading />
      </div>
    );
  }

  const isStepSize = every(chartData, (element) => element.value <= 5);
  let stepSize;
  if (isStepSize) {
    stepSize = 1;
  }

  const handleClick = (label) => {
    if (filterChangeFn) {
      filterChangeFn([label]);
    }
  };

  return (
    <figure key={dependencyKey} className={clsx('pathwaysFigure', className)}>
      <BarChart
        data={chartData}
        horizontal={true}
        yAxisLabel={yAxisLabel}
        clickHandler={handleClick}
        xAxisLabel={{stepSize}}
      />
      <figcaption className="text-center text-muted">Count of pathways by category</figcaption>
    </figure>
  );
}
PathwaysBarChart.propTypes = propTypes;


function createPathwaysChartData(pathways) {
  const entityCounts = {};
  forEach(pathways, ({categories}) => {

    if (!categories) {
      return;
    }

    for (let x = 0; x < categories.length; x += 1) {
      const category = categories[x];

      if (!entityCounts[category]) {
        entityCounts[category] = 1;
      } else {
        entityCounts[category] += 1;
      }
    }
  });

  const keys = Object.keys(entityCounts);
  return map(keys, (label) => ({label, value: entityCounts[label]}));
}
