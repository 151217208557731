import {compact, difference, filter, get, indexOf, intersection, isArray, map, size} from 'lodash';
import {orderBy} from 'natural-orderby';
import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {toast} from 'react-toastify';

import DeleteButton from 'components/Button/Delete';
import EditButton from 'components/Button/Edit';
import AllelePhenotypeEvaluation from 'components/ClinicalAnnotation/AllelePhenotypeEvaluation';
import AllelePhenotypeRow from 'components/ClinicalAnnotation/AllelePhenotypeRow';
import GuidelineEvidence from 'components/ClinicalAnnotation/GuidelineEvidence';
import LabelEvidence from 'components/ClinicalAnnotation/LabelEvidence';
import {suggest, validate} from 'components/ClinicalAnnotation/Validation';
import HistoryTable from 'components/History/Table';
import Section from 'components/Section';
import ClinicalAnnotationLevelTag from 'components/Tag/ClinicalAnnotationLevel';
import LimitedEvidenceTag from 'components/Tag/LimitedEvidence';
import PediatricTag from 'components/Tag/Pediatric';
import RareVariantTag from 'components/Tag/RareVariant';
import VipTierTag from 'components/Tag/VipTier';
import VariantAnnotationDetail from 'components/VariantAnnotation/Detail';
import ApproveControl from 'components/edit/ApproveControl';
import EditControls from 'components/edit/EditControls';
import KyError from 'components/errors/KyError';
import InfoIcon from 'components/icons/Info';
import Link from 'components/links/Link';
import ResourceLink, {renderResourceLinks} from 'components/links/Resource';
import AlleleNote from 'components/shared/AlleleNote';
import CuratorOnly from 'components/shared/curator_only';
import {useTour} from 'components/tour/hook';
import useAppContext from 'conf/AppContext';
import * as Types from 'conf/types';
import {accessionIdProps} from 'conf/types';

const propTypes = {
  clinicalAnnotation: PropTypes.shape({
    id: PropTypes.number,
    objCls: PropTypes.string,
    name: PropTypes.string,
    relatedChemicalsLogic: PropTypes.string,
    types: PropTypes.arrayOf(PropTypes.string),
    levelOfEvidence: Types.ontologyTermProps,
    location: Types.variantLocationProps,
    allelePhenotypes: PropTypes.arrayOf(PropTypes.shape({
      limitedEvidence: PropTypes.bool,
      allele: PropTypes.string,
      function: PropTypes.string,
      phenotype: PropTypes.string,
    })),
    relatedDiseases: PropTypes.arrayOf(Types.accessionIdProps),
    relatedChemicals: PropTypes.arrayOf(Types.accessionIdProps),
    variantAnnotations: PropTypes.arrayOf(Types.variantAnnotationProps),
    history: PropTypes.arrayOf(Types.historyProps),
    clinVarSubmissions: PropTypes.arrayOf(PropTypes.string),
    guidelines: PropTypes.arrayOf(accessionIdProps),
    labels: PropTypes.arrayOf(accessionIdProps),
    rareVariant: PropTypes.bool,
    raritySource: PropTypes.string,
    topTierVip: PropTypes.bool,
    minusStrand: PropTypes.bool,
    pediatric: PropTypes.bool,
    overrideLevel: PropTypes.bool,
    overrideLevelDescription: PropTypes.string,
    internalNotes: PropTypes.string,
    score: PropTypes.number,
    scoreVariantAnnotation: PropTypes.number,
    scoreGuideline: PropTypes.number,
    scoreLabel: PropTypes.number,
    scoreTotal: PropTypes.number,
    conflictingVariantAnnotationIds: PropTypes.arrayOf(PropTypes.number),
    userId: PropTypes.string,
  }).isRequired,
  nested: PropTypes.bool,
};
export default function ClinicalAnnotationDetail({clinicalAnnotation, nested = false}) {
  const appContext = useAppContext();

  const readyForTour = useTour('clinicalAnnotation');
  useEffect(() => {
    readyForTour();
  }, [readyForTour]);

  const renderClinVarLink = (x, i) => (
    <li key={i}>
      <Link href={`https://www.ncbi.nlm.nih.gov/clinvar/?term=${x}`}>{x}</Link>
    </li>
  );

  const renderLinks = (subIds) => {
    if (!subIds || subIds.length === 0) {
      return null;
    }
    // eslint-disable-next-line lodash/prefer-lodash-method
    const subLinks = subIds.map(renderClinVarLink);
    if (subLinks.length === 0) {
      return null;
    }

    return (
      <Section title="ClinVar Annotation" id="clinvar">
        <p>
          This annotation is included in
          the <Link href="https://www.ncbi.nlm.nih.gov/clinvar/">ClinVar archive</Link>. The following are
          the identifiers ClinVar has assigned to this annotation.
        </p>
        <ul>
          {subLinks}
        </ul>
      </Section>
    );
  };

  const {
    id, clinVarSubmissions, name, relatedChemicalsLogic, types, levelOfEvidence, location, allelePhenotypes,
    relatedDiseases, relatedChemicals, variantAnnotations, history, objCls, minusStrand,
    pediatric, rareVariant, raritySource, topTierVip, conflictingVariantAnnotationIds, guidelines, labels,
    scoreVariantAnnotation, scoreGuideline, scoreLabel, scoreTotal,
  } = clinicalAnnotation;

  const errors = validate(topTierVip)({allelePhenotypes});
  const suggestions = suggest(allelePhenotypes);


  const type = size(types) > 0 ? types.join(', ') : null;
  const links = renderLinks(clinVarSubmissions);

  const lit = new Set(map(variantAnnotations, (v) => get(v, 'literature.id')));
  const litPos = new Set(filter(variantAnnotations, (v) => get(v, 'isAssociated')).map((v) => get(v, 'literature.id')));
  const litNeg = new Set(
    filter(variantAnnotations, (v) => !get(v, 'isAssociated'))
      .map((v) => get(v, 'literature.id')),
  );
  const hasLimitedEvidence = size(filter(allelePhenotypes, (a) => a.limitedEvidence)) > 0;

  const evidenceComps = [
    ...map(
      guidelines,
      (g) => ({comp: GuidelineEvidence, compProps: g}),
    ),
    ...map(
      labels,
      (l) => ({comp: LabelEvidence, compProps: l}),
    ),
    ...map(
      orderBy(variantAnnotations, ['score'], ['desc']),
      (v) => ({
        comp: VariantAnnotationDetail,
        compProps: {
          variantAnnotation: v,
          nested: true,
          hideHistory: true,
          conflicting: (indexOf(conflictingVariantAnnotationIds, v.id) !== -1),
        },
      }),
    ),
  ];

  return (
    <div>
      <EditControls>
        <EditButton href={`/edit/clinicalAnnotation/${id}`} />
        {!nested && <ApproveControl type="clinicalAnnotation" id={id} />}
        {!nested && (
          <DeleteButton
            url={`curation/clinicalAnnotation/${id}`}
            onSuccess={() => toast.success('Clinical Annotation Deleted', {autoClose: 5000})}
            onFailure={(caId, error) => appContext.toastError(<KyError kyError={error} />)}
            confirmMessage="Delete this Clinical Annotation?"
          />
        )}
      </EditControls>

      <div className="clinical-annotation-detail">
        {nested && <h3 className="fact-section-header">{name}</h3>}

        <div className="row">
          <div className="col-md-3 compact-facts trim-facts">
            <LocalFact title="Level of Evidence" titleClassName="tour-clinical-ann-evidence-level">
              <ClinicalAnnotationLevelTag level={levelOfEvidence?.term} />
            </LocalFact>
            <LocalFact title="Phenotype Category" titleClassName="tour-clinical-ann-type">
              {type}
            </LocalFact>
            <LocalFact title="Genes">
              {location?.genes?.length > 0 && renderResourceLinks(location.genes)}
            </LocalFact>
            <LocalFact title="Variant">
              {location?.variant && <ResourceLink resource={location.variant} />}
              {rareVariant && <div><RareVariantTag source={raritySource} /></div>}
            </LocalFact>
            <LocalFact title="Haplotypes">
              {location?.haplotypes?.length > 0 && renderResourceLinks(location.haplotypes)}
            </LocalFact>
            <LocalFact title="Drugs">
              {relatedChemicals?.length > 0 &&
                renderResourceLinks(relatedChemicals, relatedChemicalsLogic?.toLowerCase())}
            </LocalFact>
            <LocalFact title="Phenotypes">
              {relatedDiseases?.length > 0 && renderResourceLinks(relatedDiseases)}
            </LocalFact>
            <LocalFact title="Specialty Population" titleClassName="tour-specialty-population">
              {pediatric && <PediatricTag />}
            </LocalFact>
            <LocalFact title="PharmGKB ID">
              {id}
            </LocalFact>
          </div>

          <div className="col-md-9">
            <AllelePhenotypeEvaluation suggestions={suggestions} errors={errors} />
            <table className="allele-phenotypes-table tour-allele-phenotypes-table mb-3">
              <thead>
                <tr>
                  <th>Allele</th>
                  <th>Phenotype</th>
                </tr>
              </thead>
              <tbody>
                {map(allelePhenotypes, (ap) => <AllelePhenotypeRow {...ap} key={ap.allele} />)}
              </tbody>
            </table>

            {!nested && location?.type === 'snp' && minusStrand && <AlleleNote /> }
            {location?.type === 'haplotype' && (
              <section>
                <InfoIcon /> The level of evidence on this clinical annotation reflects the strength of the evidence base
                at the level of the gene and not at the level of individual alleles.
                {hasLimitedEvidence && (
                  <>
                    {' '}Users should be aware that some alleles may only be supported by limited evidence, as shown by
                    the <LimitedEvidenceTag /> tag.
                  </>
                )}
              </section>
            )}
          </div>
        </div>

        <Section
          title="Level of Evidence Calculation"
          helpLink="/page/clinAnnScoring"
          id="ca-level-section"
          className="mt-3"
        >
          <dl className="facts facts--small">
            <dt className="tour-clinical-ann-score">Total Score</dt>
            <dd>{scoreTotal}</dd>

            <dt>Score Breakdown</dt>
            <dd>
              <ul>
                <li>Variant Annotations = <span className="text-monospace">{scoreVariantAnnotation}</span></li>
                <li>Dosing Guideline Annotations = <span className="text-monospace">{scoreGuideline}</span></li>
                <li>Drug Label Annotations = <span className="text-monospace">{scoreLabel}</span></li>
              </ul>
            </dd>

            {(rareVariant || topTierVip) && (
              <>
                <dt>Level Modifiers</dt>
                <dd>
                  <div className="tagContainer">
                    {rareVariant && <RareVariantTag className="tag--sm" source={raritySource} />}
                    {topTierVip && <VipTierTag tier="Tier 1" className="tag--sm" />}
                  </div>
                </dd>
              </>
            )}

            {!clinicalAnnotation.overrideLevel && (
              <>
                <dt>Calculated Level from Score</dt>
                <dd><ClinicalAnnotationLevelTag level={levelOfEvidence?.term} className="tag--sm" /></dd>
              </>
            )}
            {clinicalAnnotation.overrideLevel && (
              <>
                <dt>Level Override</dt>
                <dd>The level of evidence was set by a curator instead of by the scoring system.</dd>
                <dt>Reason for Override</dt>
                <dd>{clinicalAnnotation.overrideLevelDescription}</dd>
              </>
            )}
          </dl>
        </Section>

        <CuratorOnly>
          <table id="va-evidence-summary" style={{width: 'auto'}}>
            <thead>
              <tr>
                <th>Type</th><th>n</th><th>Positive</th><th>Mixed</th><th>Negative</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Var Anns</th>
                <td>{size(variantAnnotations)}</td>
                <td>{filter(variantAnnotations, (v) => get(v, 'isAssociated')).length}</td>
                <td>N/A</td>
                <td>{filter(variantAnnotations, (v) => !get(v, 'isAssociated')).length}</td>
              </tr>
              <tr>
                <th>Literature</th>
                <td>{lit.size}</td>
                <td>{difference([...litPos], [...litNeg]).length}</td>
                <td>{intersection([...litPos], [...litNeg]).length || 0}</td>
                <td>{difference([...litNeg], [...litPos]).length}</td>
              </tr>
            </tbody>
          </table>
          <dl>
            <dt>Override Level?</dt>
            <dd>{clinicalAnnotation.overrideLevel ? 'Yes' : 'No'}</dd>

            {clinicalAnnotation.overrideLevel && (
              <>
                <dt>Override Reason</dt>
                <dd>{clinicalAnnotation.overrideLevelDescription || 'N/A'}</dd>
              </>
            )}

            <dt>Curated By</dt>
            <dd>{clinicalAnnotation?.userId || 'unknown'}</dd>
            {clinicalAnnotation.internalNotes && (
              <>
                <dt>Internal Notes</dt>
                <dd className="condensedFont internalNotes">
                  {clinicalAnnotation.internalNotes}
                </dd>
              </>
            )}
          </dl>
        </CuratorOnly>

        <Section title="Evidence" titleClassName="tour-clinical-ann-evidence">
          <ul>
            <li>{size(guidelines)} Dosing Guideline Annotation{size(guidelines) !== 1 && 's'}</li>
            <li>{size(labels)} Drug Label Annotation{size(labels) !== 1 && 's'}</li>
            <li>
              {size(variantAnnotations)} Variant Annotation{size(variantAnnotations) !== 1 && 's'} from {size(lit)} Publication{size(lit) !== 1 && 's'}
              <ul>
                <li>{size(filter(variantAnnotations, (va) => va.isAssociated))} Positive association Variant Annotation{size(filter(variantAnnotations, (va) => va.isAssociated)) !== 1 && 's'}</li>
                <li>{size(filter(variantAnnotations, (va) => !va.isAssociated))} Negative association Variant Annotation{size(filter(variantAnnotations, (va) => !va.isAssociated)) !== 1 && 's'}</li>
              </ul>
            </li>
          </ul>
          {map(evidenceComps, (c, i) => <c.comp key={`evidence${i}`} index={i + 1} {...c.compProps} />)}
        </Section>

        {links}

        <Section title="History">
          <HistoryTable history={history} parentCls={objCls} parentId={id} />
        </Section>
      </div>
    </div>
  );
}
ClinicalAnnotationDetail.propTypes = propTypes;

function LocalFact({title, titleClassName, children}) {
  if (!children || (isArray(children) && compact(children).length === 0)) {
    return null;
  }
  return (
    <>
      <h4 className={titleClassName}>{title}</h4>
      <div className="mb-4">
        {children}
      </div>
    </>
  );
}
