import PropTypes from 'prop-types';

import LicenseIcon from 'components/icons/License';
import Link from 'components/links/Link';


export default function License({className}) {
  return (
    <div className={`alert alert-info ${className}`} role="alert">
      <div className="row">
        <div className="col-sm-auto">
          <LicenseIcon />
        </div>
        <div className="col">
          PharmGKB data is licensed under a <br /><Link rel="license" href="/page/dataUsagePolicy">Creative Commons Attribution-ShareAlike 4.0 International License</Link>.
        </div>
      </div>
    </div>
  );
}
License.propTypes = {
  className: PropTypes.string,
};
