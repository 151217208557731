import PropTypes from 'prop-types';

import LabelAnnotationTable from 'components/LabelAnnotation/Table';
import Loading from 'components/Loading';
import InlinePage from 'components/Page/Inline';
import KyError from 'components/errors/KyError';
import useAppContext from 'conf/AppContext';
import {useGet} from 'helpers/KyHooks';


const propTypes = {
  objCls: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default function LabelAnnotationTab({objCls, id}) {
  const appContext = useAppContext();

  const target = objCls === 'combination' ? id : `${objCls}/${id}`;
  const {response, error} = useGet(`site/tab/labelAnnotations/${target}`, {
    cacheResponse: !appContext.isPreview,
  });

  let body = <Loading />;
  if (error) {
    body = <KyError kyError={error} />;
  } else if (response) {
    body = <LabelAnnotationTable data={response.data} />;
  }

  return (
    <div className="labelAnnotationTab">
      <h3>Drug Label Annotations</h3>

      <div className="island-bottom">
        <InlinePage id="drugLabelListingHeader" />
      </div>
      {body}
    </div>
  );
}
LabelAnnotationTab.propTypes = propTypes;
