import React from 'react';
import PropTypes from 'prop-types';
import Fact from 'components/shared/fact';

const propTypes = {
  alleleCase: PropTypes.string,
  alleleControl: PropTypes.string,
  freqCase: PropTypes.number,
  freqControl: PropTypes.number,
};
/**
 * Fact display for study parameter alleles and their frequencies. Frequency information was not consistently entered,
 * some curators assumed one meaning and other assumed a different meaning. This component will detect the most common
 * assumptions of field values and display them in appropriate manners.
 *
 * IMPORTANT NOTE: This component MAY HIDE information even if it exists. If the information is not in a known pattern
 * it will be hidden.
 *
 * @param {object} props - props container
 * @param {string} props.alleleCase - case or first allele
 * @param {string} props.alleleControl - control or second allele
 * @param {number} props.freqCase - frequency of case allele
 * @param {number} props.freqControl - frequency of control allele
 * @return {JSX.Element|null} Fact layout of allele frequency information
 */
export default function FrequencyFact({alleleCase, alleleControl, freqCase, freqControl}) {
  const label = 'Allele frequency';
  
  const aCase = alleleCase === 'none' ? null : alleleCase;
  const aControl = alleleControl === 'none' ? null : alleleControl;
  const fCase = freqCase === -1 ? null : freqCase;
  const fControl = freqControl === -1 ? null : freqControl;

  if (aCase != null && aControl != null && aCase === aControl && fCase > 0 && fControl > 0) {
    return (
      <Fact pageTourClass="tour-study-frequency" compact={true} label={label} literal={`${aCase}: case=${fCase}, control=${fControl}`} />
    );
  } else if (aCase != null && aControl != null && aCase !== aControl && fCase > 0 && fControl > 0) {
    return (
      <Fact pageTourClass="tour-study-frequency" compact={true} label={label} literal={`${aCase}=${fCase}; ${aControl}=${fControl}`} />
    );
  } else if (aCase != null && aControl == null && fCase > 0) {
    return (
      <Fact pageTourClass="tour-study-frequency" compact={true} label={label} literal={`${aCase}=${fCase}`} />
    );
  } else {
    return null;
  }
}
FrequencyFact.propTypes = propTypes;
