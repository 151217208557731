import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet-async';

import {renderInfoLink} from 'components/templates/utils';

const propTypes = {
  title: PropTypes.string.isRequired,
  helpLink: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
/**
 * This a template that will have a true full-width layout that goes from the left edge of the
 * viewport to the right edge of the viewport and not be contained to any "readable" width. There
 * are no breakpoints for layout when the viewport is resized.
 *
 * The title of the page is kept within the static container width so it lines up with the site
 * header. If you want to do this for other parts of the page wrap them in a div with class
 * "container". This is recommended for paragraph text.
 *
 * @param {object} args - args container
 * @param {string} args.title - the title of the page to show in the window chrome
 * @param {string} [args.helpLink]
 * @param {string} [args.className] - the classes to add to the main element
 * @param {*} args.children - elements to layout in the template
 */
export default function FullFluidWidthTemplate({title, helpLink, className, children}) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className={clsx('page-wrap', className)}>
        <header className="container">
          <h1 className="pageTitle">
            {title}
            {renderInfoLink(helpLink)}
          </h1>
        </header>
        <div className="container-fluid">
          {children}
        </div>
      </main>
    </>
  );
}
FullFluidWidthTemplate.propTypes = propTypes;
