import clsx from 'clsx';
import {format as dateFormat, parseISO} from 'date-fns';
import {compact, filter, forEach, map, omit, size} from 'lodash';
import PropTypes from 'prop-types';
import {useEffect, useMemo, useState} from 'react';

import './Table.scss';
import {baseAccIdColumn} from 'components/Table/Cell/AccId';
import VirtualizedTable from 'components/Table/Virtualized';
import AlternateDrugTag from 'components/Tag/AlternateDrug';
import DosingInfoTag from 'components/Tag/DosingInfo';
import OtherGuidanceTag from 'components/Tag/OtherGuidance';
import PediatricTag from 'components/Tag/Pediatric';
import TestingInfoTag from 'components/Tag/TestingInfo';
import ResourceIcon from 'components/icons/Resource';
import usePediatricContext from 'context/Pediatric';


const sources = {
  cpic: 'CPIC',
  dpwg: 'DPWG',
  other: 'Other',
};


const renderHeader = (name, src, publisher, origTable) => {
  if (!origTable) {
    return null;
  }

  const {rows} = origTable;
  let rez = 0;
  if (src === 'drugs') {
    rez = rows.length;
  } else if (publisher && publisher !== src) {
    rez = 0;
  } else {
    forEach(rows, ({values}) => {
      rez += size(values[src]);
    });
  }
  return <>{name} <span className="text-muted">({rez})</span></>;
};


const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  sourceFilter: PropTypes.string,
  pediatricOnlyData: PropTypes.bool,
  height: PropTypes.string,
  minHeight: PropTypes.string,
};
/**
 * Renders a table of guideline annotations based on drugs.
 */
export default function GuidelineAnnotationTable({data, sourceFilter = '', pediatricOnlyData = false, height,
  minHeight}) {
  const pediatricContext = usePediatricContext();
  const [publisher, setPublisher] = useState('');
  const [filters, setFilters] =  useState({});
  const [tableData, setTableData] = useState({columns: [], rows: data});

  useEffect(() => {
    if (sourceFilter) {
      setPublisher(sourceFilter);
    }
  }, [sourceFilter]);

  useEffect(() => {
    if (pediatricOnlyData || pediatricContext.isPediatricMode) {
      if (!filters.pediatric) {
        setFilters({...filters, pediatric: true});
      }
    } else {
      if (filters.pediatric) {
        setFilters(omit(filters, 'pediatric'));
      }
    }
  }, [pediatricOnlyData, pediatricContext.isPediatricMode]);

  const renderSourceCounts = (rows) => {
    const rez = {cpic: 0, dpwg: 0, other: 0, drugs: 0};
    forEach(rows, (c) => {
      rez.cpic += size(c.cpic);
      rez.dpwg += size(c.dpwg);
      rez.other += size(c.other);
      rez.drugs += 1;
    });
    return rez;
  };

  let counts = useMemo(() => renderSourceCounts(data), [data]);

  useEffect(() => {
    const renderBoxes = ({value}) => (
      <div className="publisher-guidelines">
        {map(compact(value), (g) => (
          <GuidelineItem
            key={g.url}
            publisher={publisher}
            filters={filters}
            guideline={g}
          />
        ))}
      </div>
    );

    const rows = filter(data, (row) => rowFilter(publisher, filters, row));
    counts = renderSourceCounts(rows);

    const columns = [
      {
        id: 'drug',
        Header: (origTable) => renderHeader('Drugs', 'drugs', publisher, origTable),
        accessor: 'drug',
        ...baseAccIdColumn,
      },
    ];
    // add columns based on sources
    forEach(Object.keys(sources), (src) => {
      if (counts[src] > 0) {
        columns.push({
          id: src,
          Header: (origTable) => renderHeader(sources[src], src, publisher, origTable),
          accessor: src,
          Cell: renderBoxes,
          getCellExportValue: sourceExportValue,
        });
      }
    });

    setTableData({
      columns,
      rows,
    });

  }, [data, publisher, filters]);


  const onPublisherChange = (ev) => setPublisher(ev.target.value);

  const onChange = (e) => {
    if (e.target.checked) {
      setFilters({...filters, [e.target.name]: true});
    } else {
      setFilters({...filters, [e.target.name]: false});
    }
  };

  const customFilters = (
    <div className="guidelineAnnotationTable__controls form-inline">
      <div className="form-group">
        <label htmlFor="guidePubSelect">Filter&nbsp;</label>
        <select className="custom-select-sm form-control-sm" id="guidePubSelect" value={publisher} onChange={onPublisherChange}>
          <option value="">All</option>
          {counts.cpic > 0 && <option value="cpic">CPIC</option>}
          {counts.dpwg > 0 && <option value="dpwg">DPWG</option>}
          {counts.other > 0 && <option value="other">Other</option>}
        </select>
        &nbsp;guidelines for those with
      </div>
      {createFilter('hasTestingInfo', TestingInfoTag, filters, onChange)}
      {createFilter('alternateDrugAvailable', AlternateDrugTag, filters, onChange, {context: 'guidelineAnnotation'})}
      {createFilter('dosingInformation', DosingInfoTag, filters, onChange, {context: 'guidelineAnnotation'})}
      {createFilter('otherPrescribingGuidance', OtherGuidanceTag, filters, onChange, {context: 'guidelineAnnotation'})}
      {!pediatricOnlyData && createFilter('pediatric', PediatricTag, filters, onChange)}
    </div>
  );


  if (tableData.columns.length === 0) {
    // skip initial render until tableConfig is defined
    return null;
  }
  return (
    <div className="guidelineAnnotationTable">
      <VirtualizedTable
        id="guidelineAnnotationTable"
        columns={tableData.columns}
        data={tableData.rows}
        disableExport={false}
        customFilters={customFilters}
        height={height}
        minHeight={minHeight}
      />
    </div>
  );
}
GuidelineAnnotationTable.propTypes = propTypes;


function GuidelineItem({publisher, filters, guideline}) {
  const {url, genes, updatedDate, source, recommendation, hasTestingInfo, alternateDrugAvailable, dosingInformation,
    otherPrescribingGuidance, pediatric} = guideline;
  if (publisher) {
    const lcSrc = source.toLowerCase();
    if (publisher === 'other') {
      if (sources[lcSrc]) {
        return null;
      }
    } else if (publisher !== lcSrc) {
      return null;
    }
  }
  if (!tagFilter(filters, guideline)) {
    return null;
  }
  const recText = !recommendation ? (<div className="noRec"><em>No recommendation</em></div>) : null;
  const hasTestingInfoTag = hasTestingInfo ? <TestingInfoTag className="mt-1 tag--sm" /> : null;
  const dateDisplay = updatedDate ? dateFormat(parseISO(updatedDate), 'MM/dd/yyyy') : null;
  const altDrugTag = alternateDrugAvailable ? <AlternateDrugTag context="guidelineAnnotation" className="mt-1 tag--sm" /> : null;
  const dosingInfoTag = dosingInformation ? <DosingInfoTag context="guidelineAnnotation" className="mt-1 tag--sm" /> : null;
  const otherRxTag = otherPrescribingGuidance ? <OtherGuidanceTag context="guidelineAnnotation" className="mt-1 tag--sm" /> : null;
  const pediatricTag = pediatric ? <PediatricTag className="mt-1 tag--sm" /> : null;

  let formattedSource = source;
  if (source === 'SEFF/SEOM') {
    formattedSource = 'SEFF_SEOM';
  }

  return (
    <div className={clsx('guidelineItem', formattedSource)}>
      <div className={clsx('guidelineItem__icon', {'guidelineItem__icon--noRec': !recommendation})}>
        <a href={url}>
          <ResourceIcon type="guidelineAnnotation" />
        </a>
      </div>
      <div className="guidelineItem__text">
        <div>
          <a href={url}>{genes}</a>
        </div>
        <div className="dateline">
          <div>{dateDisplay}</div>
          <div>{source}</div>
        </div>
        {recText}
        {hasTestingInfoTag}
        {altDrugTag}
        {dosingInfoTag}
        {otherRxTag}
        {pediatricTag}
      </div>
    </div>
  );
}
GuidelineItem.propTypes = {
  publisher: PropTypes.string,
  filters: PropTypes.shape({
    hasTestingInfo: PropTypes.bool,
    alternateDrugAvailable: PropTypes.bool,
    dosingInformation: PropTypes.bool,
    otherPrescribingGuidance: PropTypes.bool,
    pediatric: PropTypes.bool,
  }),
  guideline: PropTypes.shape({
    url: PropTypes.string,
    genes: PropTypes.string,
    updatedDate: PropTypes.string,
    source: PropTypes.string,
    recommendation: PropTypes.bool,
    hasTestingInfo: PropTypes.bool,
    alternateDrugAvailable: PropTypes.bool,
    dosingInformation: PropTypes.bool,
    otherPrescribingGuidance: PropTypes.bool,
    pediatric: PropTypes.bool,
  }),
};


/**
 * React-table `getCellExportValue` for source.
 *
 * @param {object} row
 * @param {object} column
 * @return {string}
 */
function sourceExportValue(row, column) {

  const source = row.values[column.id];
  let output = '';

  forEach(source, (val) => {

    if (val.genes) {
      output += val.genes;
    }

    if (val.updatedDate) {
      output += ' ' + dateFormat(parseISO(val.updatedDate), 'MM/dd/yyyy');
    }

    if (!val.recommendation) {
      output += ' (no recommendation)';
    }

    if (val.hasTestingInfo) {
      output += ' (testing info)';
    }
  });

  return output;
}


function createFilter(type, Tag, filters, onChange, tagProps) {
  const id = `gaTagFilter-${type}`;
  return (
    <div className="form-check">
      <input
        id={id}
        className="mr-2"
        type="checkbox"
        name={type}
        checked={!!filters[type]}
        onChange={onChange}
      />
      <label htmlFor={id}><Tag className="tag--sm" {...tagProps} /></label>
    </div>
  );
}


function rowFilter(publisher, filters, row) {
  let guidelines;
  if (publisher) {
    guidelines = row[publisher];
  } else {
    guidelines = [...row.cpic, ...row.dpwg, ...row.other];
  }
  return filter(guidelines, (g) => tagFilter(filters, g)).length > 0;
}

function tagFilter(filters, guideline) {
  return !(
    (filters?.hasTestingInfo && !guideline.hasTestingInfo) ||
    (filters?.alternateDrugAvailable && !guideline.alternateDrugAvailable) ||
    (filters?.dosingInformation && !guideline.dosingInformation) ||
    (filters?.otherPrescribingGuidance && !guideline.otherPrescribingGuidance) ||
    (filters?.pediatric && !guideline.pediatric)
  );
}
