import clsx from 'clsx';
import PropTypes from 'prop-types';

import Tag from 'components/Tag';
import {BiomarkerStatus} from 'conf/enums';

const propTypes = {
  current: PropTypes.bool,
  showHelp: PropTypes.bool,
  className: PropTypes.string,
};

export default function BiomarkerTag({current = true, showHelp = true, className}) {
  const props = {className: clsx('fdaBiomarkerTag', className)};
  if (showHelp) {
    props.tooltipText = 'Learn more about FDA Biomarkers';
    props.tooltipUrl = '/page/drugLabelLegend#drug-label-annotation-tags';
  }
  return <Tag {...props}>{!current && 'Ex-'}FDA Biomarker</Tag>;
}
BiomarkerTag.propTypes = propTypes;


export function renderBiomarkerTag({obj, showHelp, className}) {
  let status;
  if (obj.tags) {
    if (obj.tags.includes('biomarker')) {
      status = BiomarkerStatus.shortName.biomarker;
    } else if (obj.tags.includes('ex-biomarker')) {
      status = BiomarkerStatus.shortName.exBiomarker;
    }
  } else {
    status = obj.biomarkerStatus;
  }
  return biomarkerTagFor({status, showHelp, className});
}



export function biomarkerTagFor({status, showHelp = true, className}) {
  if (isBiomarker(status)) {
    return <BiomarkerTag showHelp={showHelp} className={className} />;
  }
  if (isExBiomarker(status)) {
    return <BiomarkerTag current={false} showHelp={showHelp} className={className} />;
  }
  return null;
}

export function isBiomarker(status) {
  return status === BiomarkerStatus.shortName.biomarker;
}

export function isExBiomarker(status) {
  return status === BiomarkerStatus.shortName.exBiomarker;
}
