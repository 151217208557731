import {useEffect} from 'react';

import {baseAccIdColumn} from 'components/Table/Cell/AccId';
import TextFilter from 'components/Table/Filter/Text';
import SimpleTable from 'components/Table/Simple';
import {fixedColumnStyle, flexColumnStyle} from 'components/Table/columnHelpers';
import Tag from 'components/Tag';
import HtmlContainer from 'components/shared/html_container';
import App from 'conf/app';

export default function FdaPgxAssociationTable({associations = [], onlyCurrent = true, scrollTo}) {
  useEffect(() => {
    try {
      if (scrollTo) {
        // eslint-disable-next-line no-undef
        const targetElement = document.querySelector(scrollTo);
        if (targetElement) {
          targetElement.scrollIntoView();
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-undef
      App.log.info(`Using a bad query selector ${window.location.hash}`);
    }
  }, [scrollTo]);

  const columns = [
    {
      id: 'relatedChemcials',
      Header: 'Chemical',
      accessor: 'relatedChemicals',
      ...baseAccIdColumn,
      ...fixedColumnStyle(220),
    },
    {
      id: 'relatedGenes',
      Header: 'Gene',
      accessor: 'relatedGenes',
      ...baseAccIdColumn,
      ...fixedColumnStyle(180),
    },
    {
      id: 'affectedSubgroup',
      Header: 'Affected Subgroups',
      accessor: 'affectedSubgroup',
      sortable: true,
      Filter: TextFilter,
      ...fixedColumnStyle(250),
    },
    {
      id: 'summary',
      Header: 'Description of Gene-Drug Interaction',
      accessor: 'summaryMarkdown',
      Cell: ({value}) => <HtmlContainer html={value.html} inline={true} />,
      Filter: TextFilter,
      ...flexColumnStyle({width: 600, grow: 6}),
    },
    {
      id: 'versionDate',
      Header: 'FDA Content Date',
      accessor: 'versionDate',
      Cell: ContentDateCell,
      ...fixedColumnStyle(180),
    },
  ];

  return (
    <SimpleTable
      columns={columns}
      data={associations.filter((a) => !onlyCurrent || !a.removed)}
      initialState={({
        sortBy: [{id: 'relatedChemicals'}, {id: 'relatedGenes'}],
      })}
    />
  );
}

function ContentDateCell({row, value}) {
  let tag;
  if (row.original.removed) {
    tag = <Tag className="tag--sm tag--retired">Removed</Tag>;
  }
  return (
    <span id={row.original.id}>
      {value}
      {tag}
    </span>
  );
}
