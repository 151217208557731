import clsx from 'clsx';
import {isEmpty, isNil, some} from 'lodash';
import PropTypes from 'prop-types';

import {addHtmlContainerIfDefined} from 'components/shared/html_container';

const propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  labelBtns: PropTypes.node,
  literal: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  html: PropTypes.string,
  source: PropTypes.string,
  children: PropTypes.node,

  factClasses: PropTypes.string,
  labelClasses: PropTypes.string,
  contentClasses: PropTypes.string,
  id: PropTypes.string,
  pageTourClass: PropTypes.string,

  trim: PropTypes.bool,
  compact: PropTypes.bool,
  inline: PropTypes.bool,
  halfWidth: PropTypes.bool,

  /** Whether to render `null` when empty. */
  hideWhenEmpty: PropTypes.bool,
};
/**
 * Displays a "fact" -- a label and content.
 *
 * Handles:
 * - multiple input formats
 * - multiple style options
 * - missing data
 *
 * Content can be provided in 3 formats with these props:
 * `literal`  -- a literal number, boolean, or string
 * `html`     -- an HTML string, to insert as-is
 * `source`   -- a String for where the data comes from, wrapped in a small tag
 * `children` -- arbitrary React node(s), for the most control
 *
 * If more than one format is provided, they are included in the order above.
 *
 * Style variations:
 * `trim`    ` -- remove leading and trailing margins
 * `compact`   -- reduce vertical spacing of fact, label, and content
 * `inline`    -- position content beside, not below, label
 * `halfWidth` -- set width to 50% of container, allowing 2 column layout
 *
 * More options:
 * `labelBtns`     -- nodes, usually curator edit controls, to put to the right of the label
 * `hideWhenEmpty` -- whether to render `null` when empty
 */
export default function Fact({
  label,
  labelBtns,
  literal,
  html,
  source,
  children,
  factClasses,
  labelClasses,
  contentClasses,
  id,
  pageTourClass,
  trim = false,
  compact = false,
  inline = false,
  halfWidth = false,
  hideWhenEmpty = true,
}) {
  const empty = isNil(literal) && isEmpty(html) && !some(children);
  const hidden = hideWhenEmpty && empty;
  if (hidden) {
    return null;
  }

  const markup = addHtmlContainerIfDefined(html, {inline: true});
  const footer = !isEmpty(source)
    ? <footer><small>{source}</small></footer>
    : null;

  return (
    <div
      className={clsx('fact', factClasses, pageTourClass, {
        trim,
        compact,
        inline,
        'half-width': halfWidth,
      })}
      id={id}
    >
      <header>
        <h4 className={clsx('factLabel', labelClasses)}><div className="factLabel__content">{label}</div> {labelBtns || null}</h4>
      </header>
      <span className={clsx('fact-content', contentClasses)}>
        {literal}
        {markup}
        {children}
      </span>
      {footer}
    </div>
  );
}
Fact.propTypes = propTypes;
