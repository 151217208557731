import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {format as dateFormat, parseISO} from 'date-fns';
import {orderBy} from 'natural-orderby';
import PropTypes from 'prop-types';
import {useState} from 'react';

import './index.scss';

import Button from 'components/Button';
import ApproveButton from 'components/Button/Approve';
import Loading from 'components/Loading';
import useEditContext from 'components/edit/EditContext';
import EditControls from 'components/edit/EditControls';
import KyError from 'components/errors/KyError';
import FileDownloadLink from 'components/links/Download/File';
import Fact from 'components/shared/fact';
import FactSection from 'components/shared/fact_section';
import ReadableWidthResource from 'components/templates/ReadableWidthResource';
import useAppContext from 'conf/AppContext';
import {useGet} from 'helpers/KyHooks';
import {stringifySearchParams} from 'components/links/utils';


const propTypes = {
  id: PropTypes.string.isRequired,
};

export default function SubmissionPage({id}) {
  const appContext = useAppContext();
  const editContext = useEditContext();
  const [loadId, setLoadId] = useState(1);

  const {response, error} = useGet(`site/submission/${id}`, {}, {loadId, cacheOnLive: true});


  const renderHistory = (history) => (
    <>
      <h3>History</h3>
      {renderHistoryData(history)}
    </>
  );

  const approveCallback = () => {
    setLoadId(loadId + 1);
  };

  const title = `Submission ${id}`;
  let subtitle = '';
  let body;
  let loaded = false;
  if (error) {
    body = <KyError kyError={error} />;
  } else if (response) {
    const {data} = response;
    subtitle = data.submission.title;
    let {type, status} = data.submission;
    if (type === 'Haplotype') {
      const params = {
        submissionId: id,
      };
      if (data.gene) {
        type = <>{type} (for <a href={`/gene/${data.gene.id}/haplotype`}>{data.gene.symbol}</a>)</>;
        params.geneSymbol = data.gene.symbol;
      }
      if (editContext.isEditMode) {
        status = (
          <>
            {status}
            <EditControls>
              <Button key="upload" href={`/edit/haplotype-submission?${stringifySearchParams(params)}`}>
                <FontAwesomeIcon icon="upload" /> Upload Haplotypes
              </Button>
              <ApproveButton
                key="approve"
                objCls="Submission"
                objId={id}
                disabled={!data.needsApproval}
                label="Approve Haplotypes"
                onSuccess={approveCallback}
              />
            </EditControls>
          </>
        );
      }
    } else if (type === 'Pathway') {
      if (data.pathway) {
        type = <>{type} (<a href={`/pathway/${data.pathway.id}/haplotype`}>{data.pathway.name}</a>)</>;
      }
      if (editContext.isEditMode) {
        status = (
          <>
            {status}
            <EditControls>
              <Button href="/edit/pathway/upload/gpml" className="btn-primary">
                <FontAwesomeIcon icon="upload" /> Upload GPML
              </Button>
              <ApproveButton
                key="approve"
                objCls="Submission"
                objId={id}
                disabled={!data.needsApproval}
                label="Approve Haplotype"
                onSuccess={approveCallback}
              />
            </EditControls>
          </>
        );
      }
    }

    body = (
      <div className="submissionPage">
        <FactSection>
          <Fact label="Submitter" literal={data.submission.projects} />
          <Fact label="Type">{type}</Fact>
          <Fact label="Status">{status}</Fact>
        </FactSection>
        {appContext.isPreview && renderHistory(data.submission.data)}
      </div>
    );
    loaded = true;
  } else {
    body = <Loading />;
  }

  return (
    <ReadableWidthResource
      id={id}
      type="Submission"
      title={title}
      subtitle={subtitle}
      name={title}
      loaded={loaded}
    >
      {body}
    </ReadableWidthResource>
  );
}
SubmissionPage.propTypes = propTypes;


function renderSubmissionEvent(event) {
  let {type} = event;
  if (event.s3Path) {
    type = <>{event.type} <FileDownloadLink file={{path: event.s3Path}} iconOnly={true} /></>;
  } else if (type === 'Data approved') {
    type = <><FontAwesomeIcon icon="check" /> {type}</>;
  }
  let note = null;
  if (event.note) {
    note = <blockquote>{event.note}</blockquote>;
  }

  return (
    <tr key={event.id}>
      <td>{dateFormat(parseISO(event.timestamp), 'MM/dd/yy h:mma')}</td>
      <td>{type}
        {note && <span className="fixed-width-font">{note}</span>}
      </td>
      <td>{event.submitterId}</td>
    </tr>
  );
}

function renderHistoryData(data) {
  if (!data) {
    return <p>None.</p>;
  }
  return (
    <table className="table table-sm submissionUploads">
      <thead>
        <tr>
          <th>Timestamp</th>
          <th>Event</th>
          <th>Submitter</th>
        </tr>
      </thead>
      <tbody>
        {orderBy(data, ['timestamp'], ['desc']).map(renderSubmissionEvent)}
      </tbody>
    </table>
  );
}
