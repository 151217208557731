import Loading from 'components/Loading';
import KyError from 'components/errors/KyError';
import Link from 'components/links/Link';
import ReadableWidthTemplate from 'components/templates/ReadableWidth';
import {useGet} from 'helpers/KyHooks';
import FdaBiomarkerDiffForm from 'pages/edit/FdaBiomarker/diff/diffForm';

export default function FdaBiomarkerDiffPage({}) {
  const {response, error} = useGet('curation/fdaBiomarker/diff/options');
  
  let body = <Loading />;
  if (error) {
    body = <KyError kyError={error} />;
  } else if (response) {
    body = <FdaBiomarkerDiffForm options={response.data} />;
  }
  
  return (
    <ReadableWidthTemplate title="FDA Biomarker History Diff">
      <p>
        <Link href="https://www.fda.gov/drugs/science-and-research-drugs/table-pharmacogenomic-biomarkers-drug-labeling">
          Original FDA Biomarkers Page
        </Link>
      </p>
      {body}
    </ReadableWidthTemplate>
  );
}
