import {camelCase, concat, map} from 'lodash';

import './Hit.scss';
import {createActionHandler} from 'components/Button';
import ListItem, {parseDescription} from 'components/List/Item';
import useSearchBar from 'components/Search/Bar/Provider';
import {combinationUrl, hitPropType} from 'components/Search/utils';
import useAppContext from 'conf/AppContext';
import Types from 'conf/types';


const combinationQuickLinks = {
  'Prescribing Info': '/prescribingInfo',
  'Label Annotation': '/labelAnnotation',
  'Clinical Annotation': '/clinicalAnnotation',
  'Variant Annotation': '/variantAnnotation',
  Pathway: '/pathway',
  Literature: '/literature',
};


const propTypes = {
  hit: hitPropType,
};
/**
 * Renders an individual search hit.
 */
export default function SearchHit({hit}) {
  const appContext = useAppContext();
  const searchBar = useSearchBar();

  let isCombination = false;
  let baseUrl = hit.url;
  if (searchBar.hasPinned()) {
    baseUrl = combinationUrl(concat(searchBar.state.pinnedHits, hit));
    isCombination = true;
  }

  const actionHandler = createActionHandler((event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.target.tagName !== 'A') {
      appContext.redirect(baseUrl);
    } else {
      appContext.redirect(event.target.href);
    }
  });


  const renderResource = (h) => {
    let url;
    let description;
    if (!isCombination) {
      url = h.url;
      description = parseDescription({item: hit});
    }

    return (
      <ListItem
        key={h.id}
        objCls={h.objCls}
        name={h.symbol || h.name}
        url={url}
        description={description}
      />
    );
  };

  const renderCombination = () => {
    const resources = [];
    const {pinnedHits} = searchBar.state;
    for (let x = 0; x < pinnedHits.length; x += 1) {
      resources.push(renderResource(pinnedHits[x]));
      resources.push(<span key={`and${x}`} className="listItem__and">and</span>);
    }
    resources.push(renderResource(hit));
    return (
      <div className="resultHit__combination">
        {resources}
      </div>
    );
  };


  const renderResourceQuickLink = (type, subLink, count) => {
    const label = `${Types[type].displayType}s`;
    if (!count) {
      return (
        <span key={subLink} className="resultHit__quickLink resultHit__quickLink--empty">
          {label}&nbsp;({count})
        </span>
      );
    }
    return (
      <a key={subLink} href={`${baseUrl}${subLink}`} className="resultHit__quickLink">
        {label}&nbsp;({count})
      </a>
    );
  };

  const renderResourceQuickLinks = () => {
    const curCcType = camelCase(hit.objCls);
    if (isCombination || Types[curCcType].searchShowAnnCounts) {
      return (
        <div className="resultHit__extras">
          {renderResourceQuickLink('dosingGuidelineAnnotation', '/prescribingInfo#guideline-annotations',
            hit.numGuidelineAnnotations)}
          {renderResourceQuickLink('labelAnnotation', '/labelAnnotation', hit.numLabelAnnotations)}
          {renderResourceQuickLink('clinicalAnnotation', '/clinicalAnnotation', hit.numClinicalAnnotations)}
          {renderResourceQuickLink('variantAnnotation', '/variantAnnotation', hit.numVariantAnnotations)}
        </div>
      );
    }
    return '';
  };

  const renderCombinationQuickLinks = () => (
    <div className="resultHit__extras">
      {map(Object.keys(combinationQuickLinks), (label) =>
        <a key={label} href={`${baseUrl}${combinationQuickLinks[label]}`} className="resultHit__quickLink resultHit__quickLink--combination">{label}</a>,
      )}
    </div>
  );

  return (
    <div
      className="listItemContainer resultHit"
      onClick={actionHandler}
      onKeyDown={actionHandler}
      role="button"
      tabIndex={0}
    >
      {isCombination ? renderCombination() : renderResource(hit)}
      {isCombination ? renderCombinationQuickLinks() : renderResourceQuickLinks()}
    </div>
  );
}
SearchHit.propTypes = propTypes;
