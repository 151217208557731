import clsx from 'clsx';
import {map} from 'lodash';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import './index.scss';
import Loading from 'components/Loading';


const propTypes = {
  collection: PropTypes.arrayOf(PropTypes.object),
  renderItemFn: PropTypes.func.isRequired,
  /**
   * Name of item being rendered.  Used if there are no items to display.
   */
  singularNoun: PropTypes.string.isRequired,
  className: PropTypes.string,
};
/**
 * Renders a list of Accession objects.
 */
export default function List({collection, renderItemFn, singularNoun, className}) {
  let body = <Loading />;
  if (collection) {
    if (collection.length === 0) {
      body = <div className="list__empty">No {pluralize(singularNoun)}.</div>;
    } else {
      body = map(collection, renderItemFn);
    }
  }

  return (
    <div className={clsx('list', className)}>
      {body}
    </div>
  );
}
List.propTypes = propTypes;
