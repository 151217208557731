import clsx from 'clsx';
import PropTypes from 'prop-types';

import Tag, {renderTagIfPresent} from 'components/Tag/index';

import './LabelAnnotation.scss';


function renderLabelTag(name, showHelp, className) {
  const props = {};
  if (className) {
    props.className = className;
  }
  if (showHelp) {
    props.tooltipText = `Learn more about the ${name} tag`;
    props.tooltipUrl = '/page/drugLabelLegend#drug-label-annotation-tags';
  }
  return (
    <Tag {...props}>{name}</Tag>
  );
}


const propTypes = {
  showHelp: PropTypes.bool,
  className: PropTypes.string,
};


export function CancerTag({showHelp = true, className}) {
  return renderLabelTag('Cancer Genome', showHelp, clsx('cancerGenomeTag', className));
}
CancerTag.propTypes = propTypes;

export function renderCancerTag({obj, showHelp, className}) {
  return renderTagIfPresent({
    tag: CancerTag,
    obj,
    dbProp: 'cancerGenome',
    indexProp: 'cancer-genome',
    showHelp,
    className,
  });
}


export function PrescribingTag({showHelp = true, className}) {
  return renderLabelTag('Prescribing Info', showHelp, clsx('prescribingInfoTag', className));
}
PrescribingTag.propTypes = propTypes;

export function renderPrescribingTag({obj, showHelp, className}) {
  return renderTagIfPresent({
    tag: PrescribingTag,
    obj,
    dbProp: 'prescribingMarkdown',
    indexProp: 'prescribing',
    showHelp,
    className,
  });
}

export function IndicationTag({className}) {
  return renderLabelTag('Indication', true, clsx('indicationTag', className));
}

export function renderIndicationTag({obj, showHelp, className}) {
  return renderTagIfPresent({
    tag: IndicationTag,
    obj,
    dbProp: 'indication',
    indexProp: 'indication',
    showHelp,
    className,
  });
}
