import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';

import NotFound from 'components/shared/error/not_found';
import useAppContext from 'conf/AppContext';

const propTypes = {
  id: PropTypes.string.isRequired,
};
/**
 * Redirect Hgnc to the related page.
 *
 * @param {object} props - props container
 * @param {string} props.id
 * @return {JSX.Element}
 */
export default function RedirectHgnc({id}) {
  const appContext = useAppContext();
  const [error, setError] = useState(null);

  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      try {
        const rez = await appContext.api.post('search', {
          json: {
            objCls: ['Gene'],
            query: id,
            field: 'name',
          },
          parseJson: true,
        });
        if (mounted) {
          if (rez.data.total === 1) {
            appContext.redirect(rez.data.hits[0].url, true);
          } else {
            setError(<NotFound />);
          }
        }
      } catch (err) {
        if (mounted) {
          setError(<NotFound />);
        }
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    loadData();
    return () => {
      mounted = false;
    };
  }, [id]);
  
  return error;
}
RedirectHgnc.propTypes = propTypes;
