import './Frame.scss';
import PropTypes from 'prop-types';

import useEditContext from 'components/edit/EditContext';
import EditControls from 'components/edit/EditControls';

const propTypes = {
  controls: PropTypes.node.isRequired,
  children: PropTypes.node,
};
/**
 * Renders a frame around an EditControl and what it controls.
 */
export default function EditFrame({controls, children}) {
  const editContext = useEditContext();
  if (!editContext.isEditMode) {
    return children;
  }
  
  return (
    <div className="editFrame">
      <EditControls>
        {controls}
      </EditControls>
      {children}
    </div>
  );
}
EditFrame.propTypes = propTypes;
