import clsx from 'clsx';
import PropTypes from 'prop-types';

import './CuratorOnly.scss';
import useAppContext from 'conf/AppContext';


const propTypes = {
  /**
   * The node to show or hide.
   */
  children: PropTypes.node.isRequired,
  /**
   * Will the children be wrapped in a styled div with a label (true, default)
   * or rendered with no additional styling (false).
   */
  styled: PropTypes.bool,
  className: PropTypes.string,
};
/**
 * Only show the children of this element on preview.
 *
 * @param {object} props - props container
 * @param {string} props.className - class names to add to the containing div
 * @param {boolean} props.styled - Will the children be wrapped in a styled div with a label (true, default) or rendered with no additional styling (false)
 * @param {*} props.children - The node to show or hide
 * @return {React.ReactNode}
 */
export default function CuratorOnly({styled = true, className, children}) {
  const appContext = useAppContext();

  if (!appContext.isPreview) {
    return null;
  }
  if (styled) {
    return (
      <div className={clsx('curatorOnly alert', className)}>
        <h4 className="curatorOnly__title">(curators only)</h4>
        {children}
      </div>
    );
  } else {
    return children;
  }
}
CuratorOnly.propTypes = propTypes;
