import clsx from 'clsx';
import {filter, isFunction, isString} from 'lodash';
import PropTypes from 'prop-types';

import './Boolean.scss';


const propTypes = {
  /**
   * Column props passed by react-table.
   */
  column: PropTypes.shape({
    filterValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    setFilter: PropTypes.func,
    /**
     * Unique id of column
     */
    id: PropTypes.string,
    /**
     * Column header.
     */
    Header: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node]),
    /**
     * CSS classes to be added to the filter cell.
     */
    filterClassName: PropTypes.string,
  }).isRequired,
  /**
   * Label - used for screen reader-only label.
   * If unspecified, screen reader-only label defaults to `Header` value.
   */
  label: PropTypes.string,
};

function filterLabel(label, Header) {
  if (label) {
    return `Filter ${label}`;
  } else if (isFunction(Header)) {
    return `Filter ${Header()}`;
  } else {
    return `Filter ${Header}`;
  }
}

/**
 * Renders a select input field.
 */
export default function BooleanSelectFilter({
  column: {filterValue = false, setFilter, id, Header, filterClassName},
  label,
}) {
  const changeHandler = (event) => {
    setFilter(event.target.value);
  };

  return (
    <label className={clsx('booleanFilter', filterClassName)}>
      <select name={id} className={clsx('custom-select', {filterSet: filterValue})} onChange={changeHandler}>
        <option value="">--</option>
        <option value="true">Yes</option>
        <option value="false">No</option>
      </select>
      <span className="sr-only">{filterLabel(label, Header)}</span>
    </label>
  );
}
BooleanSelectFilter.propTypes = propTypes;

/**
 * Renders a checkbox.
 */
export function BooleanCheckboxFilter({
  column: {filterValue = false, setFilter, id, Header, filterClassName},
  label,
}) {
  const changeHandler = (event) => {
    setFilter(event.target.checked);
  };

  return (
    <label className={clsx('booleanFilter', filterClassName)}>
      <input
        name={id}
        type="checkbox"
        checked={filterValue}
        onChange={changeHandler}
        className={clsx({filterSet: filterValue})}
      />
      <span className="sr-only">{filterLabel(label, Header)}</span>
    </label>
  );
}
BooleanCheckboxFilter.propTypes = propTypes;


/**
 * React-table `filter` for booleans.
 *
 * MultiSelectFilter will pass in a string filterValue.
 * SelectFilter will pass in a boolean filterValue.
 *
 * @param {Array} rows
 * @param {Array} columnIds
 * @param {boolean|string} filterValue
 * @return {Array}
 */
export function booleanFilter(rows, columnIds, filterValue) {
  if (!filterValue) {
    return rows;
  }
  let filterBoolValue = filterValue ?? false;
  if (isString(filterValue)) {
    filterBoolValue = filterValue === 'true';
  }
  const id = columnIds[0];
  return filter(rows, (r) => r.values[id] === filterBoolValue);
}
