import PropTypes from 'prop-types';
import {useState} from 'react';

import Template from 'components/templates/ReadableWidth';
import useAppContext from 'conf/AppContext';
import PasswordForm from 'pages/user/PasswordForm';


const propTypes = {
  userId: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};
export default function ResetPassword({userId, token}) {
  const [message, setMessage] = useState(null);
  const appContext = useAppContext();


  const handleSubmit = async (data) => {
    try {
      const body = new URLSearchParams();
      body.set('token', token);
      body.set('password', data.password);
      await appContext.api.post(`user/${encodeURIComponent(userId)}/resetPassword`, {body});
      setMessage(
        <div className="alert alert-success">Password updated successfully! You can now <a href="/user/login">sign in to your account</a>.</div>);
    } catch (err) {
      if (err.name === 'HTTPError' && err.response.status === 403) {
        setMessage(<div className="alert alert-warning">Your token has expired.  Please request another <a href="/user/resetPassword">password reset</a>.</div>);
      } else {
        throw err;
      }
    }
  };

  return (
    <Template title="Reset Password">
      <div className="row justify-content-md-center">
        <div className="col-md-8">
          <PasswordForm onSubmit={handleSubmit} />
        </div>
        <div className="mt-3">{message}</div>
      </div>
    </Template>
  );
}
ResetPassword.propTypes = propTypes;
