import {map, sortBy} from 'lodash';
import PropTypes from 'prop-types';

import './PublicationsTab.scss';

import LiteratureCitation from 'components/Literature/Citation';
import Fact from 'components/shared/fact';
import {literatureProps} from 'conf/types';


const propTypes = {
  literature: PropTypes.arrayOf(literatureProps).isRequired,
  blurb: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};
/**
 * Renders publications tab - a list of literature, organized by year.
 */
export default function PublicationsTab({literature, blurb}) {
  const publications = {};
  for (let x = 0; x < literature.length; x += 1) {
    const lit = literature[x];
    // TODO(markwoon): remove this when we've cleaned up Guideline/GuidelineAnnotation literature
    //  to not include URL xrefs
    if (lit.crossReferences?.length === 1 && lit.crossReferences[0].resource === 'URL' && /https:\/\/cpicpgx\.org/y.test(lit.crossReferences[0]._url)) {
      // eslint-disable-next-line no-continue
      continue;
    }
    if (!publications[lit.year]) {
      publications[lit.year] = [lit];
    } else {
      publications[lit.year] = [...publications[lit.year], lit];
    }
  }

  const renderYear = (year) => {
    const label = year === '-1' ? 'Unknown Year' : year;
    return (
      <Fact label={label} key={year}>
        <ul>
          {
            map(sortBy(publications[year], ['pubDate', 'title']), (lit) => (
              <li className="mb-3">
                <LiteratureCitation key={lit.id} {...lit} />
              </li>
            ))
          }
        </ul>
      </Fact>
    );
  };

  return (
    <div className="publicationsTab">
      <h3>Publications</h3>

      {blurb}
      {map(Object.keys(publications).sort().reverse(), (year) => renderYear(year))}
    </div>
  );
}
PublicationsTab.propTypes = propTypes;
