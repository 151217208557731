import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import PharmgkbTagEditor from 'components/PharmgkbTag/Edit';
import useModalService from 'components/shared/ModalService';


const propTypes = {
  objId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  objCls: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
};
/**
 * Renders button to edit PharmGKB Tags.
 */
export default function PharmgkbTagButton({objId, objCls, label = 'Edit Tags', className}) {
  const modalService = useModalService();

  const closeHandler = () => {
    modalService.close();
  };

  const openHandler = () => {
    modalService.open({
      closeHandler,
      style: {
        width: '30em',
      },
      content: <PharmgkbTagEditor objId={objId} objCls={objCls} closeHandler={closeHandler} />,
    });
  };

  return (
    <Button actionHandler={openHandler} className={clsx('button--edit', className)}>
      <FontAwesomeIcon icon={['far', 'tag']} /> {label}
    </Button>
  );
}
PharmgkbTagButton.propTypes = propTypes;
