import PropTypes from 'prop-types';
import {useState} from 'react';
import {FormProvider, useForm, useFormContext} from 'react-hook-form';
import Modal from 'react-modal';
import {toast} from 'react-toastify';

import ButtonPanel from 'components/Button/Panel';
import FormCheckboxGroup from 'components/form/CheckboxGroup';
import FormText from 'components/form/Text';
import InfoLink from 'components/links/Info';
import useAppContext from 'conf/AppContext';
import logger from 'conf/Logger';
import {createCookie} from 'helpers/cookies';


export const SURVEY_COOKIE_NAME = 'dlSurvey';

function setSurveyCookie() {
  createCookie(SURVEY_COOKIE_NAME, 1, 180);
}


const userCategories = [
  {value: 'researcher', label: 'Researcher'},
  {value: 'clinician', label: 'Clinician'},
];
const instCategories = [
  {value: 'academic', label: 'Academic'},
  {value: 'government', label: 'Government'},
  {value: 'industry', label: 'Industry'},
  {value: 'hospital', label: 'Hospital'},
];


const propTypes = {
  url: PropTypes.string.isRequired,
  onSurveyClose: PropTypes.func.isRequired,
};
/**
 * Renders form for download survey.
 *
 * @param {object} props - component properties
 * @param {string} props.url
 * @param {Function} props.onSurveyClose
 * @return {React.ReactNode}
 */
export default function DownloadLinkForm({url, onSurveyClose}) {
  const appContext = useAppContext();
  const [isOpen, setOpen] = useState(true);
  const defaultValues = {
    artifactType: 'download',
    artifactId: url,
    userType: [],
    userOther: '',
    instType: [],
    instOther: '',
    institution: '',
    reason: '',
  };
  const formMethods = useForm({defaultValues});
  const {handleSubmit} = formMethods;

  const closeModal = () => {
    setOpen(false);
    onSurveyClose();
  };

  const skipSubmit = async (event) => {
    // need to prevent default so that click doesn't trigger immediate file download (which happens in FF and Safari)
    // need to download _after_ hitting endpoint
    event.preventDefault();
    setSurveyCookie();
    try {
      await appContext.api.post('site/survey', {json: defaultValues});
    } catch (error) {
      logger.warn(error);
    } finally {
      closeModal();
      window.location = url;
    }
  };

  const onSubmit = async (values) => {
    setSurveyCookie();
    try {
      await appContext.api.post('site/survey', {json: values});
      toast.success('Survey submitted.  Thank you.');
    } catch (error) {
      logger.warn(error);
    } finally {
      closeModal();
      window.location = url;
    }
  };


  return (
    <Modal
      contentLabel="Download Survey"
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="modalWindow"
      overlayClassName="modalOverlay"
      style={{
        content: {
          width: '30rem',
          height: '42rem',
        },
      }}
    >
      <p>
        Help make PharmGKB more useful to you!
      </p>
      <p>
        The following questions help us ensure we are meeting your needs. <InfoLink href="/page/userSurveyDetails" tooltip="more info about this survey" />
      </p>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormCheckboxGroup
            name="userType"
            options={userCategories}
            label="What best describes your role(s)"
            otherFieldName="userOther"
          />

          <FormText
            name="institution"
            label="What Institution are you affiliated with?"
            type="text"
            placeholder="Company name, School name, etc…"
          />

          <FormCheckboxGroup
            name="instType"
            options={instCategories}
            label="What role(s) best describe your institution"
            otherFieldName="instOther"
          />

          <FormText
            name="reason"
            label="What do you want to do with this data?"
            type="text"
          />

          <ButtonPanel
            buttons={[
              <DownloadButton key="download" url={url} onSubmit={onSubmit} />,
              <a href={url} key="skip" onClick={skipSubmit} className="btn btn-secondary" download={true}>Skip Survey & Download</a>,
            ]}
          />

        </form>
      </FormProvider>
    </Modal>
  );
}
DownloadLinkForm.propTypes = propTypes;


/**
 * Renders download button.  Need this wrapper so that can get a handle react-hook-form context.
 *
 */

function DownloadButton({url, onSubmit}) {
  const {handleSubmit} = useFormContext();

  const clickHandler = (event) => {
    // need to prevent default so that click doesn't trigger immediate file download (which happens in FF and Safari)
    // need to download _after_ hitting endpoint
    event.preventDefault();
    handleSubmit(onSubmit)();
  };

  return (<a href={url} onClick={clickHandler} className="btn btn-primary" download={true}>Download</a>);
}

DownloadButton.propTypes = {
  url: PropTypes.string,
  onSubmit: PropTypes.func,
};
