/* eslint-env browser */
import 'styles/main.scss';

import 'conf/Sentry';
import App from 'conf/app';

catchLinkClicks();

// polyfill functionality that doesn't get patched by polyfill.io
const polyfills = [];
if (!window.AbortController) {
  polyfills.push(import(/* webpackChunkName: "polyfill-abortcontroller" */
    'abortcontroller-polyfill/dist/polyfill-patch-fetch'));
}
if (!Array.from) {
  polyfills.push(import(/* webpackChunkName: "polyfill-array-from" */'core-js/es/array/from'));
}
if (!Object.assign) {
  polyfills.push(import(/* webpackChunkName: "polyfill-object-assign" */
    'core-js/es/object/assign'));
}
if (polyfills.length === 0) {
  App.initializeApp();
} else {
  Promise.all(polyfills)
    .then(() => {
      App.initializeApp();
    });
}

/**
 * Catch all link clicks and navigate using router to prevent page reloads.
 */
function catchLinkClicks() {
  document.addEventListener('click', (event) => {
    const closestAncestor = event.target.closest('a[href^="/"]');
    if (closestAncestor && !closestAncestor.getAttribute('target')) {
      // Allow shift+click for new tabs, etc.
      // Also make sure it's not a right click (event.button === 2)
      if (!event.altKey && !event.ctrlKey && !event.metaKey && !event.shiftKey && event.button !== 2) {
        event.preventDefault();
        const url = closestAncestor.getAttribute('href');
        App.router.navigate(url, {trigger: true});

        // Bring the user back to the top of the page
        window.scrollTo(0, 0);
        return false;
      }
    }
  });
}
