import HistoryTable from 'components/History/Table';

export default function HistoryTab({id, objCls, history = []}) {
  return (
    <>
      <h3>History</h3>
      <HistoryTable history={history} parentCls={objCls} parentId={id} />
    </>
  );
}
