import {isArray} from 'lodash';

export function sortLaLevels(l1, l2, columnId) {
  let a;
  let b;
  if (columnId) {
    a = l1.values[columnId];
    b = l2.values[columnId];

    if (!a) {
      if (!b) {
        return 0;
      }
      return 1;
    } else if (!b) {
      return -1;
    }
  } else {
    a = l1.status;
    b = l2.status;
  }

  let aValue;
  let bValue;
  if (isArray(a)) {
    aValue = getLevelId(a?.[0]);
    bValue = getLevelId(b?.[0]);
  } else {
    aValue = getLevelId(a);
    bValue = getLevelId(b);
  }

  if (!aValue) {
    if (!bValue) {
      return 0;
    } else if (bValue === 'na') {
      return -1;
    }
    return 1;
  } else if (!bValue) {
    if (aValue === 'na') {
      return 1;
    }
    return -1;
  }

  if (aValue === 'na') {
    if (bValue === 'na') {
      return 0;
    } else if (bValue) {
      return 1;
    }
  } else if (bValue === 'na') {
    if (aValue === 'na') {
      return 0;
    } else if (aValue) {
      return -1;
    }
  }

  if (aValue > bValue) {
    return 1;
  } else if (aValue < bValue) {
    return -1;
  }
  return 0;
}


export function getLevelId(level) {
  switch (level) {
    case 'Testing Required':
    case 'Testing required':
      return 1;
    case 'Testing Recommended':
    case 'Testing recommended':
      return 2;
    case 'Actionable PGx':
      return 3;
    case 'Informative PGx':
      return 4;
    case 'No Clinical PGx':
      return 5;
    case 'Criteria Not Met':
      return 0;
    case 'No label found':
      return 'na';
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
      return level;
    default:
      return '';
  }
}

export function getLevelName(level) {
  switch (level) {
    case 1:
      return 'Testing Required';
    case 2:
      return 'Testing Recommended';
    case 3:
      return 'Actionable PGx';
    case 4:
      return 'Informative PGx';
    case 5:
      return 'No Clinical PGx';
    case 0:
      return 'Criteria Not Met';
    case 'na':
      return 'No label found';
    default:
      return 'unknown level';
  }
}
