import {find, forEach, map, set, size, values} from 'lodash';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';

import RecommendationAnnotation from 'components/RecommendationAnnotation';
import KyError from 'components/errors/KyError';
import InfoLink from 'components/links/Info';
import FactSection from 'components/shared/fact_section';
import useAppContext from 'conf/AppContext';

import './GuidelinePicker.scss';

const propTypes = {
  guidelineId: PropTypes.string.isRequired,
  note: PropTypes.element,
  source: PropTypes.string,
  alleles: PropTypes.object,
  chemicalId: PropTypes.string,
};
export default function GuidelineAnnotationPicker({guidelineId, note, source, alleles, chemicalId}) {
  const appContext = useAppContext();
  const [drugs, setDrugs] = useState(undefined);
  const [picks, setPicks] = useState({});
  const genes = Object.keys(alleles);

  useEffect(() => {
    const initPicks = {};
    forEach(
      Object.keys(alleles),
      (gene) => {
        if (!singlePloidy(gene)) {
          initPicks[gene] = [null, null];
        } else {
          initPicks[gene] = [null];
        }
      });
    setPicks(initPicks);
  }, [alleles]);

  const renderOption = (alleleName) => <option value={alleleName} key={alleleName}>{alleleName}</option>;

  const onChange = (idx, symbol) => async (e) => {
    const pickedAllele = e.target.value;
    const newPicks = set(picks || {}, `${symbol}.${idx}`, pickedAllele);
    setPicks(newPicks);

    try {
      const r = await appContext.api.post(
        `site/gsi/${guidelineId}/${chemicalId}`,
        {json: newPicks, parseJson: true},
      );
      setDrugs(find(values(r?.data?.drugs), (d) => d.drug.id === chemicalId) || null);
    } catch (err) {
      appContext.toastError(<KyError kyError={err} />);
    }
  };

  const renderGenePicker = (symbol) => (
    <div className="genotype-specific-annotations-picker" key={symbol}>
      <div>Pick alleles for {symbol}</div>
      <select onChange={onChange(0, symbol)}>
        <option value="">--</option>
        {map(alleles[symbol], renderOption)}
      </select>
      {!singlePloidy(symbol) && (
        <select onChange={onChange(1, symbol)}>
          <option value="">--</option>
          {map(alleles[symbol], renderOption)}
        </select>
      )}
    </div>
  );

  let resultSection;
  if (drugs) {
    const {cpic, dpwg} = drugs;
    if (cpic && dpwg) {
      const guidelines = [...cpic.annotations, ...dpwg.annotations];
      const resultGuideline = find(guidelines, (g) => g.id === guidelineId);
      if (resultGuideline) {
        const {genotypes, phenotypes, recommendationAnnotations} = resultGuideline;
        if (size(recommendationAnnotations) > 0) {
          resultSection = (
            <div className="guidelinePickerResultSection">
              {map(recommendationAnnotations, (r) =>
                <RecommendationAnnotation key={r.id} phenotypes={phenotypes} genotypes={genotypes} {...r} />)}
            </div>
          );
        }
      }
    }
  }

  return (
    <FactSection title="Specify a genotype for specific annotations" id="tour-annotation-picker">
      {map(genes, renderGenePicker)}
      <small>
        <p>Alleles not present in the above pull-down menus have no guideline recommendation.</p>
        {source === 'DPWG' && (
          <p>
            <InfoLink href="/page/dpwgMapping">Important information about alleles in DPWG guidelines.</InfoLink>
          </p>
        )}
      </small>
      {note}
      {resultSection}
    </FactSection>
  );
}
GuidelineAnnotationPicker.propTypes = propTypes;

function singlePloidy(gene) {
  return gene === 'MT-RNR1';
}
