import PropTypes from 'prop-types';

import Tag from 'components/Tag/index';


export default function RareVariantTag({className, source}) {
  let sourceComp = null;
  if (source) {
    sourceComp = <small className="ml-1 text-muted">({source})</small>;
  }
  return (
    <Tag
      tooltipText="Learn more about rare variants"
      tooltipUrl="/page/rareVariants"
      className={className}
    >
      Rare Variant
      {sourceComp}
    </Tag>
  );
}
RareVariantTag.propTypes = {
  className: PropTypes.string,
};
