import {map, size} from 'lodash';
import PropTypes from 'prop-types';

import 'components/ClinicalAnnotation/Evidence.scss';
import LiteratureCitation from 'components/Literature/Citation';
import ResourceIcon from 'components/icons/Resource';
import Fact from 'components/shared/fact';
import FactSection from 'components/shared/fact_section';
import HtmlContainer from 'components/shared/html_container';
import Types, {literatureProps, markdownProps} from 'conf/types';

const propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  summaryMarkdown: markdownProps,
  literature: PropTypes.arrayOf(literatureProps),
  index: PropTypes.number,
  recommendation: PropTypes.bool,
};
/**
 * A section to show a guideline annotation as evidence for a clinical annotation
 *
 * @param {object} props - props container
 * @param {string} props.id - accession ID
 * @param {string} props.name - name of guideline
 * @param {object} props.summaryMarkdown - prescribing Markdown object
 * @param {Array<object>} props.literature - array of Literature objects
 * @param {number} props.index - the numerical place of this evidence in the list of evidence
 * @param {boolean} props.recommendation
 * @return {React.ReactNode} an evidence section div
 */
export default function GuidelineEvidence({id, name, summaryMarkdown, literature, index, recommendation}) {
  return (
    <div className="evidence-section">
      <h3 className="fact-section-header">
        {`${index}. `}
        <ResourceIcon type="guidelineAnnotation" inline={true} />
        {' '}<a href={Types.guidelineAnnotation.url(id)}>{name}</a>
      </h3>

      {!recommendation && <div className="alert alert-danger">No recommendation</div>}

      <HtmlContainer html={summaryMarkdown?.html} />

      <div className="mt-3">
        {size(literature) > 0 && <h5>From Publications</h5>}
        {map(literature, (l) => <LiteratureCitation key={l.id} firstAuthorOnly={true} {...l} />)}
      </div>

      <p className="mt-3"><a href={Types.guidelineAnnotation.url(id)}>More information on this annotation</a></p>

      <FactSection halfWidth={true}>
        <Fact halfWidth={true} inline={true} label="Score">{recommendation ? '100' : '0'}</Fact>
        <Fact halfWidth={true} inline={true} label="PharmGKB ID">{id}</Fact>
      </FactSection>
    </div>
  );
}
GuidelineEvidence.propTypes = propTypes;
