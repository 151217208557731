import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import EditButton from 'components/Button/Edit';
import VipTierTag from 'components/Tag/VipTier';
import EditControls from 'components/edit/EditControls';
import Fact from 'components/shared/fact';
import Types from 'conf/types';

/**
 * A component for showing VIP information in a visually distinct and highlighted section in a gene overview tab
 *
 * For chemicals or variants, use VipRelatedSummary
 *
 * @param {object} props - props container
 * @param {string} props.id - the PA ID of the VIP annotation, used to construct link
 * @param {string} props.tier - the Tier descriptor for this VIP, optional
 * @param {string} props.summary - a short summary, in HTML, optional
 * @param {string} props.geneId - the PA ID of the gene, required
 * @return {React.ReactNode} a block VIP summary node
 */
export default function VipSummary({id, tier, summary, geneId}) {
  if (!id) {
    return (
      <EditControls>
        <Button href={`/edit/vip?targetId=${geneId}`}><FontAwesomeIcon icon="plus" /> Add VIP</Button>
      </EditControls>
    );
  } else {
    const label = <span>Overview {tier && tier !== 'Tier 2' && <VipTierTag tier={tier} />}</span>;
    return (
      <Fact
        label={label}
        labelBtns={<EditControls className="editControls--compact"><EditButton href={Types.vip.url(id)}>Edit VIP</EditButton></EditControls>}
        html={summary}
      />
    );
  }
}
VipSummary.propTypes = {
  id: PropTypes.string,
  tier: PropTypes.string,
  summary: PropTypes.string,
  geneId: PropTypes.string,
};
