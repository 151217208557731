import {sortBy} from 'lodash';

import Section from 'components/Section';
import Link from 'components/links/Link';
import HtmlContainer from 'components/shared/html_container';

export default function FdaPgxAssociationTable({fdaPgxAssociations = []}) {
  if (fdaPgxAssociations.length === 0) {
    return null;
  }
  
  return (
    <Section title="FDA PGx Associations" id="fda-pgx-associations">
      <div className="stylized-table">
        <table className="caption-top">
          <caption>From the <Link newTab={true} href="/fdaPgxAssociations">FDA Table of Pharmacogenetic Associations</Link>:</caption>
          <thead>
            <tr>
              <th>Category</th>
              <th>Drug</th>
              <th>Gene</th>
              <th>Affected Subgroup</th>
              <th>Interaction Description</th>
            </tr>
          </thead>
          <tbody>
            {sortBy(fdaPgxAssociations, ['subsection', 'originalDrugText', 'originalGeneText']).map((a) => {
              return (
                <tr key={a.id}>
                  <td>{a.subsection}</td>
                  <td>{a.originalDrugText}</td>
                  <td>{a.originalGeneText}</td>
                  <td>{a.affectedSubgroup}</td>
                  <td><HtmlContainer html={a?.summaryMarkdown?.html} /></td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Section>
  );
}
