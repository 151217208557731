import clsx from 'clsx';
import {compact, isArray} from 'lodash';
import PropTypes from 'prop-types';

import './Header.scss';
import useEditContext from 'components/edit/EditContext';


const propTypes = {
  belowEditControls: PropTypes.bool,
  children: PropTypes.node,
};

export default function TagHeader({belowEditControls = false, children}) {
  const editContext = useEditContext();

  if (!children || (isArray(children) && compact(children).length === 0)) {
    return null;
  }

  return (
    <div className={clsx('tagHeader', 'tagContainer', {'tagHeader--editMode': belowEditControls && editContext.isEditMode})}>{children}</div>
  );
}
TagHeader.propTypes = propTypes;
