import {map} from 'lodash';
import PropTypes from 'prop-types';

import Loading from 'components/Loading';
import SearchHit from 'components/Search/Hit';
import KyError from 'components/errors/KyError';
import ResourceCounts, {totalOnlyResourceCount} from 'components/resource/resourceCounts';
import FactSection from 'components/shared/fact_section';
import useAppContext from 'conf/AppContext';
import Types from 'conf/types';
import {useGet} from 'helpers/KyHooks';


const propTypes = {
  id: PropTypes.string,
  objCls: PropTypes.string,
};

export default function ConnectionsTab({id, objCls}) {
  const appContext = useAppContext();

  const {response, error} = useGet(`site/connections/tab/${objCls}/${id}`, {
    cacheResponse: !appContext.isPreview,
  });

  let body = <Loading />;
  if (error) {
    body = <KyError kyError={error} />;
  } else if (response) {
    const counts = [];
    if (objCls !== 'chemical') {
      counts.push({
        label: `${Types.chemical.displayType}s`,
        amount: totalOnlyResourceCount(response.data.chemical.length),
        icon: 'chemical',
        href: '#chemicals',
      });
    }
    if (objCls !== 'gene') {
      counts.push({
        label: `${Types.gene.displayType}s`,
        amount: totalOnlyResourceCount(response.data.gene.length),
        icon: 'gene',
        href: '#genes',
      });
    }
    if (objCls !== 'disease') {
      counts.push({
        label: `${Types.disease.displayType}s`,
        amount: totalOnlyResourceCount(response.data.disease.length),
        icon: 'disease',
        href: '#diseases',
      });
    }

    body = (
      <>
        <FactSection>
          <ResourceCounts counts={counts} />
        </FactSection>
        <FactSection>
          <p>
            Relationships shown below are based on (1) prescribing information, (2) drug label annotations,
            (3) clinical annotations, (4) variant annotations, or (5) pathways found in PharmGKB. The type of supporting
            information is indicated below each related pair.
          </p>
        </FactSection>

        {(objCls !== 'chemical') && (
          <>
            <div id="chemicals" className="link-anchor" />
            <FactSection title="Molecules">
              {renderHits(response.data?.chemical)}
            </FactSection>
          </>
        )}

        {(objCls !== 'gene') && (
          <>
            <div id="genes" className="link-anchor" />
            <FactSection title="Genes">
              {renderHits(response.data?.gene)}
            </FactSection>
          </>
        )}

        {(objCls !== 'disease') && (
          <>
            <div id="diseases" className="link-anchor" />
            <FactSection title="Diseases">
              {renderHits(response.data?.disease)}
            </FactSection>
          </>
        )}
      </>
    );
  }

  return (
    <div className="connectionsTab">
      {body}
    </div>
  );
}
ConnectionsTab.propTypes = propTypes;


function renderHits(hits) {
  if (!hits || hits.length === 0) {
    return <p className="empty">None.</p>;
  }
  return map(hits, (hit) => <SearchHit key={hit.id} hit={hit} />);
}
