import clsx from 'clsx';
import {map, reduce} from 'lodash';
import PropTypes from 'prop-types';

import './Header.scss';
import ResourceIcon from 'components/icons/Resource';
import {hasIcon} from 'conf/types';


export const headerDataProps = PropTypes.shape({
  id:  PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // name and type not available until full load
  type: PropTypes.string,
  name: PropTypes.string,
  symbol: PropTypes.string,
});

const propTypes = {
  data: PropTypes.arrayOf(headerDataProps).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

/**
 * Prints resource page's header/title.
 */
export default function ResourceHeader({data, subtitle}) {
  let type = 'none';
  if (data.length === 1) {
    type = data[0].type;
  } else if (data.length > 1) {
    type = 'combination';
  }
  const length = reduce(data, (sum, value) => {
    const {name, symbol} = value;
    const title = symbol || name;
    return sum + (title ? title.length : 0);
  }, 0);
  const comps = map(data, renderResource);
  const resources = {
    'resource-header-items--long': length >= 48 && length < 64,
    'resource-header-items--longer': length >= 64,
    'resource-header-items--single': data?.length === 1,
  };
  return (
    <div className={`resource-header ${type}-header`}>
      <div className="container">
        <h1 className={clsx('resource-header-items', resources)}>
          {comps}
        </h1>
        {renderSubtitle(subtitle)}
      </div>
    </div>
  );
}
ResourceHeader.propTypes = propTypes;


function renderResource(data, index, collection) {
  const {id, name, symbol} = data;
  if (!name && !symbol) {
    return '';
  }
  const type = data.objCls || data.type;
  const icon = hasIcon(type);
  const isSingleResource = collection.length === 1;
  return (
    <span className="resource-header-item" key={id || index}>
      { icon && (
        <span className="resource-icon">
          <ResourceIcon type={type} />
        </span>
      )}
      <span className="resource-name">{symbol || name}</span>
      { icon && isSingleResource && (
        <span className="resource-icon-bg">
          <ResourceIcon type={type} />
        </span>
      )}
    </span>
  );
}

function renderSubtitle(subtitle) {
  if (subtitle) {
    return (
      <div className="resource-header-subtitle container">
        {subtitle}
      </div>
    );
  }
}
