import clsx from 'clsx';
import {isUndefined, map} from 'lodash';
import PropTypes from 'prop-types';

import LoadingIcon from 'components/icons/Loading';
import ResourceIcon from 'components/icons/Resource';
import Types from 'conf/types';

export const EMPTY_RESOURCE_COUNT = {total: 0, pediatric: 0};

export function totalOnlyResourceCount(total = 0) {
  return {total, pediatric: 0};
}

export const countProps = PropTypes.shape({
  pediatric: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
});

export const resourceCountsProps = PropTypes.shape({
  clinicalAnnotation: countProps,
  haplotype: countProps,
  labelAnnotation: countProps,
  pathway: countProps,
  literature: countProps,
  prescribingInfo: countProps,
  variantAnnotation: countProps,
});

const resourceNames = {
  chemical: 'Chemicals',
  clinicalAnnotation: 'Clinical Annotations',
  disease: 'Diseases',
  gene: 'Genes',
  haplotype: 'Haplotypes',
  labelAnnotation: 'Drug Label Annotations',
  literature: 'Publications',
  pathway: 'Pathways',
  prescribingInfo: 'Prescribing Info',
  variantAnnotation: 'Variant Annotations',
};


/**
 * Converts counts from API into expected shape for <ResourceCounts />.
 *
 * @return {object[]}
 */
export const convertTypedCounts = (objCls, objId, counts) => {
  const baseUrl = Types[objCls].url(objId);
  return map(Types[objCls].overviewCounts, (type) => {
    let label = resourceNames[type];
    if (objCls === type) {
      label = `Related ${label}`;
    }
    const amount = counts?.[type] || EMPTY_RESOURCE_COUNT;
    const href = baseUrl + '/' + type;
    return {label, amount, icon: type, href};
  });
};


const propTypes = {
  counts: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    amount: countProps.isRequired,
    href: PropTypes.string.isRequired,
  })).isRequired,
  className: PropTypes.string,
};
/**
 * Render resource counts.
 */
export default function ResourceCounts({counts, className}) {
  const comps = map(counts, renderResourceCount);

  return <div className={clsx('resourceCounts', className)}>{comps}</div>;
}
ResourceCounts.propTypes = propTypes;


function renderResourceCount(c) {
  const {label, href} = c;
  if (href) {
    return (
      <a key={label} href={href}>
        {renderCount(c)}
      </a>
    );
  }
  return renderCount(c);
}

function renderCount(c) {
  const {label, icon, amount} = c;
  const loading = isUndefined(amount);
  return (
    <div key={label} className="resourceCount">
      <header>{label}</header>
      <div className="resourceCount_pane">
        <div className="resourceCount_icon"><ResourceIcon type={icon} /></div>
        {loading ? <LoadingIcon /> : <span className="resourceCount_amount">{amount.total}</span>}
      </div>
    </div>
  );
}
