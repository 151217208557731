// These variables are set by Webpack's DefinePlugin:
/* global ENV */
// ENV  - production, preview, beta, development or local, or test (when run by Jest)
import loglevel from 'loglevel';


const logger = loglevel.getLogger('app');
if (ENV === 'production' || ENV === 'test') {
  logger.setLevel(loglevel.levels.WARN);
} else if (ENV === 'preview') {
  logger.setLevel(loglevel.levels.INFO);
} else {
  logger.setLevel(loglevel.levels.TRACE);
}

export default logger;
