import PropTypes from 'prop-types';

import Link from 'components/links/Link';
import OutreachTemplate from 'components/templates/Outreach';

const _data = {
  canCollapse: true,
  initialIsCollapsed: true,
  sections: [
    {
      name: 'genetics-101',
      title: 'Genetics 101',
      text: (
        <>
          <p>
            You can think of your DNA as a code made up of the letters A, C, G and T. These four letters can be used to
            spell out many different instructions, known as <Link newTab={true} href="/page/glossary#gene">genes</Link>.
          </p>
          <p>
            Genes tell your cells how to make <Link newTab={true} href="/page/glossary#protein">proteins</Link>, the
            building blocks of the body. When there is a change in how a gene is &quot;spelled&quot;, the resulting
            protein may be made differently. This can change how it works and can affect how you respond to medication.
          </p>
          <p>
            Each individual person will have different spelling changes in their DNA, which is why we
            see <Link newTab={true} href="/page/glossary#genetic-variation">genetic variation</Link> between people.
          </p>
        </>
      ),
      image: 'gene',
      sections: [
        {
          text: (
            <>
              <p>
                Humans have over 20,000 genes, coding for proteins that play different roles in the body.
                Every person has two copies of each gene, one inherited or passed down from each parent.
                Because each of your parents has different spelling changes in their DNA, the two copies
                are different from each other. These different versions of the same gene are known
                as <Link new={true} href="/page/glossary#alleles">alleles</Link>.
              </p>
              <p>
                Genetic variation is what makes us different from one another, such as the
                differences in our height and eye color. Most genetic variation doesn&apos;t affect our
                health, but sometimes changes can happen in important genes and lead to
                consequences such as diseases or an altered response to medication.
              </p>
              <p>
                Changes to your DNA can be small, like
                {' '}<Link newTab={true} href="/page/glossary#SNP">single nucleotide polymorphisms (SNPs)</Link>, where
                a single letter is changed to a different letter. Or they can be a lot bigger where
                many DNA letters are added, deleted or rearranged. Scientists use
                {' '}<Link newTab={true} href="/page/glossary#rsid-number">rsID numbers</Link> to identify different
                types of DNA changes and where they are in the genome.
              </p>
              <p>
                For example, <Link newTab={true} href="/variant/PA166154579">rs4149056</Link> identifies
                where the letter C has replaced the letter T at a certain position in the gene known
                as <i>SLCO1B1</i>. This change affects how well the protein coded for by this gene
                works. In this case, individuals who have the letter C have an increased risk of
                developing muscle disease when they are treated with the drug
                {' '}<Link newTab={true} href="/chemical/PA451363">simvastatin</Link>.
              </p>
              <p>
                In genetics, it is important to know what is going on in both alleles, so genetic information
                is often reported as a <Link newTab={true} href="/page/glossary#genotype">genotype</Link>, which
                describes the gene spellings found on both copies of your genes. For example, if you wanted to find out
                your genotype at <Link href="https://www.pharmgkb.org/variant/PA166154579">rs4149056</Link>,
                a genetic test would look at whether you had a &quot;C&quot; or a &quot;T&quot; on each of
                your two <i>SLCO1B1</i> copies. Your test results could be &quot;CT&quot;, meaning you had
                one <i>SLCO1B1</i> copy with the letter C and one copy with the letter T. The results could
                also be &quot;CC&quot;, if both your <i>SLCO1B1</i> copies had the letter C, or &quot;TT&quot;
                if both copies had the letter T.
              </p>
              <p>
                Sometimes, the function of a gene is not affected unless there are multiple changes all
                present within the gene. Instead of describing each one of these changes, scientists can group
                them all together and give this group a specific name or number – this group is called
                a <Link newTab={true} href="/page/glossary#haplotype">haplotype</Link>. For example, the *17 haplotype
                in the gene <i>CYP2C19</i> consists of four changes at different positions in the gene. When describing
                the haplotype state of both gene copies, the
                term <Link newTab={true} href="/page/glossary#diplotype">diplotype</Link> is used instead of genotype.
              </p>
            </>
          ),
        },
        {
          interested: [
            {
              type: 'Read',
              text: "University of Utah's Genetic Science Learning Center",
              url: 'https://learn.genetics.utah.edu/',
            },
            {
              type: 'Read',
              text: 'Understanding Genetics at The Tech Museum of Innovation',
              url: 'https://genetics.thetech.org/',
            },
            {
              type: 'Read',
              text: 'The National Library of Medicine\'s (NLM) Genetics Home Reference',
              url: 'https://ghr.nlm.nih.gov',
            },
          ],
        },
      ],
    },
    {
      name: 'response',
      title: 'How does genetic variation affect how I respond to a medicine?',
      text: (
        <>
          <p>
            Many things can affect how you respond to a medicine, including your gender,
            age, and diet. However, genetics is also an important factor in medicine response.
          </p>
          <p>
            <Link newTab={true} href="/page/glossary#genetic-variation">Genetic variation</Link> can change how
            much of a medicine is absorbed by your body, how well it gets to where it is needed,
            and how quickly it gets broken down. If any of these processes are altered, a drug
            may not work as well for you, or you may have a bad reaction.
          </p>
          <p>
            The study of this relationship between genetic variation and response to medicine is
            known as <Link newTab={true} href="/page/glossary#pharmacogenomics">pharmacogenomics</Link>.
          </p>
        </>
      ),
      image: 'labelAnnotation',
      sections: [
        {
          text: (
            <>
              <p>
                Many medicines are currently prescribed as though they work equally well in
                all people, or the dose is adjusted depending on the age, size, or gender of a
                patient. However, even if patients are the same age, size or gender, they may
                still have different responses to the same medicine because of genetic variation.
              </p>
              <p>
                Pharmacogenomic testing gives insight into how an individual patient may respond
                to a medicine. As a result, the choice or dose of a medicine can be tailored to
                that individual, personalizing their treatment. By prescribing medication based on
                a patient&apos;s genetics, that patient is more likely to get a treatment that works.
              </p>
            </>
          ),
          sections: [
            {
              title: 'How does my ancestry affect my pharmacogenomics?',
              text: (
                <>
                  <p>
                    You inherit your genetic code from your ancestors. That means that many
                    of the changes in your DNA that affect your response to a drug are shared with
                    your family, including your parents and your children. Some of the genetic
                    variation that causes differences in medicine response are found more commonly
                    in people of certain ancestries or ethnicities than in others.
                  </p>
                  <p>
                    However, your ancestry can only suggest how <b><i>likely</i></b> it is that
                    you will have a particular change in your DNA – you will still need to have a
                    genetic test to confirm that the change is indeed in your DNA. Therefore, if
                    you are curious about how your DNA might affect your response to a medicine,
                    you should get a pharmacogenomic test.
                  </p>
                </>
              ),
            },
          ],
        },

        {
          text: (
            <>
              <p>
                Genetic variation affects whether a patient has a bad reaction to a medicine or how well a patient
                responds to a medicine by changing a drug&apos;s
                {' '}<Link newTab={true} href="/page/glossary#pharmacokinetics">pharmacokinetics</Link> and
                {' '}<Link newTab={true} href="/page/glossary#pharmacodynamics">pharmacodynamics</Link>. Genetic
                variation in <Link newTab={true} href="/page/glossary#gene">genes</Link> encoding
                {' '}<Link newTab={true} href="/page/glossary#protein">proteins</Link> involved in a drug&apos;s
                pharmacokinetics can alter how a drug is metabolized. If a drug is metabolized too quickly, it might not
                be as effective. Alternatively, if the drug is metabolized too slowly, it may build up in the
                body and cause dangerous side effects.
              </p>
              <p>
                Genes involved in a drug&apos;s pharmacodynamics can encode proteins that the drug
                may need to bind to in order to have an effect on the body. Genetic variation can
                change the structure of a protein, affecting how well the drug binds to the
                protein. This can change how well the drug works.
              </p>
              <p>
                Below, find out more about how variation in different types of genes can affect
                pharmacokinetics and pharmacodynamics:
              </p>
            </>
          ),
          sections: [
            {
              title: 'Drug transporters',
              text: (
                <>
                  <p>
                    Transporters move molecules in and out of cells. Variation in genes
                    encoding drug transporters can affect their function, changing how well drugs
                    can enter or exit cells and increasing or decreasing concentrations of a drug
                    in different parts of the body. If the concentration of the drug at the site
                    of action is too low, the drug may not work as well. If the drug concentration
                    becomes too high, it could cause toxic side effects.
                  </p>
                  <div className="alert alert-info">
                    <p>Examples on PharmGKB: </p>
                    <p>
                      <Link newTab={true} href="/gene/PA134865839">SLCO1B1</Link>,
                      {' '}<Link newTab={true} href="/gene/PA267">ABCB1</Link>,
                      {' '}<Link newTab={true} href="/gene/PA390">ABCG2</Link>
                    </p>
                  </div>
                </>
              ),
            },
            {
              title: 'Drug metabolizing enzymes',
              text: (
                <>
                  <p>
                    Variation in drug metabolizing enzymes can affect how quickly a drug is
                    broken down in the body. This can have different effects depending on the
                    specific drug. If an active drug is metabolized too quickly, it will be
                    inactivated too quickly and may not work well. If it is metabolized too
                    slowly, toxic concentrations of the drug might build up. Alternatively, some
                    drugs have to be metabolized in order to become active. For drugs like these,
                    being metabolized too quickly increases the concentrations of active molecules
                    in the body and increases the risk of toxic side effects while being
                    metabolized too slowly can reduce how effective the drug is.
                  </p>
                  <div className="alert alert-info">
                    <p>Examples on PharmGKB: </p>
                    <Link newTab={true} href="/gene/PA126">CYP2C9</Link>,
                    {' '}<Link newTab={true} href="/gene/PA128">CYP2D6</Link>,
                    {' '}<Link newTab={true} href="/gene/PA124">CYP2C19</Link>,
                    {' '}<Link newTab={true} href="/gene/PA131">CYP3A5</Link>,
                    {' '}<Link newTab={true} href="/gene/PA356">TPMT</Link>,
                    {' '}<Link newTab={true} href="/gene/PA420">UGT1A1</Link>
                  </div>
                </>
              ),
            },
            {
              title: 'Human Leukocyte Antigen (HLA) genes',
              text: (
                <>
                  <p>
                    HLA proteins form part of your immune system. Some HLA alleles are
                    associated with increased risk for an allergic response to certain
                    medications, which can result in severe skin reactions such as
                    {' '}<Link newTab={true} href="/disease/PA445738">Stevens-Johnson syndrome (SJS)</Link> or
                    {' '}<Link newTab={true} href="/disease/PA444059">toxic epidermal necrolysis (TEN)</Link>.
                  </p>
                  <div className="alert alert-info">
                    <p>
                      Examples on PharmGKB:
                    </p>
                    <p>
                      <Link newTab={true} href="/gene/PA35055">HLA-A</Link>,
                      {' '}<Link newTab={true} href="/gene/PA35056">HLA-B</Link>
                    </p>
                  </div>
                </>
              ),
            },
            {
              title: 'Drug targets',
              text: (
                <>
                  <p>
                    Variation in genes coding for proteins which are drug targets can affect
                    how well a drug works by altering the amount of the target protein in the body
                    or by preventing the drug from being able to bind to the protein. For example,
                    the anti-coagulant drug
                    {' '}<Link newTab={true} href="/chemical/PA451906">warfarin</Link> prevents
                    the vitamin K recycling needed for blood clotting. Warfarin does this by blocking
                    the protein that controls the recycling (VKORC1). Genetic variation that
                    increases or decreases the amount of VKORC1 can affect the dose of warfarin
                    needed to prevent blood clotting.
                  </p>
                  <div className="alert alert-info">
                    <p>
                      Examples on PharmGKB:
                    </p>
                    <p>
                      <Link newTab={true} href="/gene/PA133787052">VKORC1</Link>,
                      {' '}<Link newTab={true} href="/gene/PA109">CFTR</Link>
                    </p>
                  </div>
                </>
              ),
            },
          ],
        },
        {
          text: (
            <p>
              Most work in pharmacogenomics focuses on variation in DNA that passed down through your
              family (<Link newTab={true} href="/page/glossary#germline-variation">germline variation</Link>).
              However, <Link newTab={true} href="/page/glossary#somatic-variation">somatic variation</Link> is also
              important within pharmacogenomics. Somatic variations are genetic changes that
              arise spontaneously within cells but are only passed on to other cells and are not
              passed on to your children. Somatic variation is involved in the development of
              cancer, and some anticancer drugs target specific somatic variations to try to
              treat the cancer.
            </p>
          ),
        },
        {
          interested: [
            {
              type: 'Read',
              text: 'Overview of Pharmacogenomics',
              by: 'yourgenome.org',
              url: 'https://www.yourgenome.org/facts/what-is-pharmacogenomics',
            },
            {
              type: 'Watch',
              text: 'Principles and Concepts of Pharmacogenomics',
              by: <>PharmGenEd&trade;</>,
              url: 'https://youtu.be/szTXPckk-co',
            },
            {
              type: 'Read',
              text: 'Pharmacogenomics: Precision Medicine and Drug Response',
              by: 'Weinshilboum and Wang',
              url: 'https://pubmed.ncbi.nlm.nih.gov/29101939/',
            },
            {
              type: 'Read',
              text: 'Pharmacogenomics',
              by: <>Roden <i>et al</i>.</>,
              url: 'https://pubmed.ncbi.nlm.nih.gov/31395440/',
            },
          ],
        },
      ],
    },
    {
      name: 'information',
      title: 'How can I get my pharmaco\u00ADgenomic information?',
      text: (
        <>
          <p>
            A genetic test can provide important information about how
            {' '}<Link newTab={true} href="/page/glossary#genetic-variation">genetic variation</Link> may affect your
            response to a medication and suggest ways to change the medicine dose or choice.
          </p>
          <p>
            PharmGKB can be used as a resource to independently research genetic variation and
            its effects on medicine response - visit our
            {' '}<Link newTab={true} href="/whatIsPharmgkb">What is PharmGKB?</Link> page
            to get started learning how to use the website.
          </p>
          <p>
            However, you should always <b>speak to your doctor</b> regarding any medical questions related
            to <Link newTab={true} href="/page/glossary#pharmacogenomics">pharmacogenomics</Link>;
            {' '}<b>PharmGKB is intended for informational purposes only</b>.
          </p>
        </>
      ),
      image: 'guidelineAnnotation',
      sections: [
        {
          text: (
            <>
              <p>
                Genetic tests, including pharmacogenomic tests, can be ordered by your
                doctor. We strongly recommend you talk with your doctor about your concerns and
                about your interest in having your genes tested. Often, these tests are ordered
                when you are starting a specific medication that is known to be affected by
                genetic variation, but they can be done at any time.
              </p>
              <p>
                Some companies offer genetic tests that you can order yourself. These tests can
                provide information about your ancestry, wellness traits such as caffeine
                tolerance or other health information. However, most of these tests do not tell
                you about how you might respond to a medicine.
              </p>
            </>
          ),
          sections: [
            {
              title: 'How do I talk to my doctor about pharmacogenomics?',
              text: (
                <>
                  <p>
                    You can tell your doctor that you are interested in how changes in your
                    genes may affect your response to any medicines you are currently taking or
                    will be taking. You can share your concern for being at risk of not responding
                    to a medicine or of having a bad reaction to a medicine, and that a
                    pharmacogenetic test may be able to give you information about some medicines.
                  </p>
                  <p>
                    Be aware that only a few drugs currently have strong enough scientific
                    evidence to support changing the drug choice or dose based on your genetics.
                    If your doctor would like more information, you can suggest they visit
                    {' '}<Link newTab={true} href="/">www.pharmgkb.org</Link> or
                    {' '}<Link newTab={true} href="https://www.cpicpgx.org">www.cpicpgx.org</Link>,
                    where information and resources for physicians can be found.
                  </p>
                </>
              ),
            },
            {
              title: 'How is a pharmacogenomic test result different from other test results?',
              text: (
                <p>
                  A pharmacogenomic test is a genetic test that specifically attempts to
                  predict how a person will respond to a medication. It may also provide
                  information about a person&apos;s risk of developing a disease. The results of
                  pharmacogenomic tests show how likely it is that an individual will have a
                  particular response to a certain medicine. Many genetic and environmental
                  factors can contribute to response to medicine, as well as interactions with
                  other medications taken at the same time.
                </p>
              ),
            },
            {
              title: 'What are the limitations of pharmacogenomic testing?',
              text: (
                <p>
                  Anyone interested in getting a pharmacogenomic test or anyone who has pharmacogenomic test results
                  should be aware that there are limitations to this kind of testing. You can find a summary of some of
                  these limitations <Link newTab={true} href="/page/testingLimitations">here</Link>.
                  PharmGKB does not offer advice on the advantages or disadvantages of specific pharmacogenomic tests.
                </p>
              ),
            },
          ],
        },
        {
          interested: [
            {
              type: 'Read',
              text: 'Business Insider on comparing different direct-to-consumer genetic tests',
              url: 'https://www.businessinsider.com/best-dna-test-23andme-ancestry-national-geographic-2017-4',
            },
            {
              type: 'Read',
              text: 'Drug-gene testing',
              by: 'the Mayo Clinic',
              url: 'https://mayoresearch.mayo.edu/center-for-individualized-medicine/drug-gene-testing.asp',
            },
            {
              type: 'Read',
              text: 'Pharmacogenomics in the clinic',
              by: 'Relling and Evans',
              url: 'https://www.ncbi.nlm.nih.gov/pubmed/26469045',
            },
            {
              type: 'Read',
              text: 'Preemptive clinical pharmacogenetics implementation: current programs in five US medical centers',
              by: <>Dunnenberger <i>et al</i>.</>,
              url: 'https://www.ncbi.nlm.nih.gov/pubmed/25292429',
            },
          ],
        },
      ],
    },
    {
      name: 'action',
      title: 'Pharmaco\u00ADgenomics in action',
      text: (
        <>
          <p>
            Although the field is still young, doctors are already
            using <Link newTab={true} href="/page/glossary#pharmacogenomics">pharmacogenomics</Link> to treat
            their patients. Acute lymphoblastic leukemia (ALL) is a type of cancer
            that mainly affects children and is often treated with the medicine
            {' '}<Link newTab={true} href="/chemical/PA450379">mercaptopurine</Link>.
          </p>
          <p>
            Children with certain genetic changes in the
            {' '}<Link newTab={true} href="/page/glossary#gene">gene</Link>
            {' '}<Link newTab={true} href="/gene/PA356">TPMT</Link> can have a severe reaction to this
            {' '}<Link newTab={true} href="/page/glossary#drug">drug</Link>. Doctors will often test for these
            changes, and if they are present, will give the child a lower dose of
            mercaptopurine or use a different medicine.
          </p>
        </>
      ),
      image: 'disease',
      sections: [
        {
          text: (
            <>
              <p>
                Mercaptopurine belongs to a class of medicines called thiopurines. It is broken down in the
                body by several <Link newTab={true} href="/page/glossary#protein">proteins</Link>, some of which convert
                mercaptopurine to active molecules that help kill cancer cells. Mercaptopurine is also
                broken down by a protein called TPMT, encoded for by the <i>TPMT</i> gene.
              </p>
              <p>
                When mercaptopurine is broken down by TMPT, it is inactivated. This inactivation prevents dangerous
                concentrations of active molecules from building up. Changes in the <i>TPMT</i> gene, such as
                the <Link newTab={true} href="/page/glossary#haplotype">haplotypes</Link> *2, *3A or *3C, can reduce or
                stop the inactivation of mercaptopurine by the TPMT protein. This causes too many active molecules to
                build up in the body and can lead to a severe decrease in immune system activity, known as
                myelosuppression. Myelosuppression can be fatal.
              </p>
              <p>
                Testing for <i>TPMT</i> <Link newTab={true} href="/page/glossary#genetic-variant">variants</Link> is
                widely available: the
                {' '}<Link newTab={true} href="https://www.ncbi.nlm.nih.gov/gtr/">Genetic Testing Registry</Link>{' '}
                provides over 20 laboratories that currently offer this test. In Europe, testing prior to
                administration of thiopurines is becoming routine clinical practice – a survey in the
                United Kingdom found that 67% of clinicians ordered a <i>TPMT</i> test before
                prescribing azathioprine, another drug that is broken down by TPMT.
              </p>
              <p>
                In the United Kingdom, testing for <i>TPMT</i> status is mandatory for children
                and young adults prior to treatment on the
                {' '}<Link newTab={true} href="https://www.ncbi.nlm.nih.gov/pubmed/23962279">ALL2011 trial protocol</Link>,
                and the British Association of Dermatologists&apos; guidelines for the
                safe and effective prescribing of azathioprine states that TPMT activity should be
                checked in all patients
                {' '}<Link newTab={true} href="https://www.ncbi.nlm.nih.gov/pubmed/21950502">prior to receiving azathioprine</Link>.
                The Clinical Pharmacogenetics Implementation Consortium (CPIC)
                has also provided
                {' '}<Link newTab={true} href="https://www.pharmgkb.org/guidelineAnnotation/PA166104945">dosing guidance</Link> based
                on <i>TPMT</i> genotype for thiopurines.
              </p>
            </>
          ),
        },
        {
          interested: [
            {
              type: 'Read',
              text: 'The Latin Post on TPMT variations in Hispanic and Asian children',
              url: 'https://www.latinpost.com/articles/35175/20150131/hispanic-and-asian-children-at-higher-rate-for-leukemia-treatment-resistance.htm',
            },
            {
              type: 'Read',
              text: 'St Jude Children&apos;s Research Hospital patient education page on TPMT and thiopurines',
              url: 'https://www.stjude.org/treatment/patient-resources/caregiver-resources/patient-family-education-sheets/pharmacy-and-medicines/thiopurine-methyltransferase-tpmt-and-medicines.html',
            },
            {
              type: 'Read',
              text: <>Mercaptopurine therapy and <i>TPMT</i> genotype</>,
              by: 'L. Dean',
              url: 'https://www.ncbi.nlm.nih.gov/pubmed/28520348',
            },
            {
              type: 'Read',
              text: 'Inherited genetic variation in acute childhood lymphoblastic leukemia',
              by: <>Moriyama <i>et al</i>.</>,
              url: 'https://www.ncbi.nlm.nih.gov/pubmed/25999454',
            },
          ],
        },
      ],
    },
  ],
};


const propTypes = {
  baseUrl: PropTypes.string.isRequired,
  section: PropTypes.string,
};
export default function WhatIsPharmacogenomicsPage({baseUrl, section}) {
  return (
    <OutreachTemplate
      title="What is Pharmacogenomics?"
      subtitle="Pharmacogenomics is the study of how your genetics change your response to medicine."
      alert={(
        <div className="alert alert-info" role="alert">
          <p>
            Are you a clinician? Visit
            our <Link newTab={true} href="/page/clinicianFAQ">Pharmacogenomics for Clinicians</Link> page.
          </p>
          <p>
            Check the <Link newTab={true} href="/page/glossary">PharmGKB Glossary</Link> for definitions of common
            genetic and pharmacogenetic terms.
          </p>
        </div>
      )}
      data={_data}
      section={section}
      baseUrl={baseUrl}
    />
  );
}
WhatIsPharmacogenomicsPage.propTypes = propTypes;
