import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {diffJson} from 'diff';
import {get, map, reduce, trim} from 'lodash';
import PropTypes from 'prop-types';

import 'components/Diff/Json.scss';

const propTypes = {
  /**
   * The previous version of the object to compare.
   */
  oldObj: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  /**
   * The new version of the object to compare.
   */
  newObj: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};
/**
 * Component to show a colorized diff in fixed-width font of two blocks of text. The diff is
 * handled by line, not by character so works best with multi-line text/JSON.
 */
export default function DiffJson({oldObj = {}, newObj = {}}) {

  const reduceCount = (lines, prop) =>
    reduce(map(lines, (l) => (get(l, prop) ? 1 : 0)), (sum, n) => sum + n);

  const renderDiff = (line, i) => {
    const text = trim(line.value, '\n');
    let lineNode;
    if (line.added) {
      lineNode = <ins>{text}</ins>;
    } else if (line.removed) {
      lineNode = <del>{text}</del>;
    } else {
      lineNode = text;
    }
    return <span key={i}>{lineNode}{'\n'}</span>;
  };

  const lines = newObj ? diffJson(oldObj, newObj, {newlineIsToken: true}) : [];
  const additions = reduceCount(lines, 'added');
  const deletions = reduceCount(lines, 'removed');
  if (additions + deletions === 0) {
    return <div className="alert alert-info"><FontAwesomeIcon icon={['far', 'thumbs-up']} /> No diffs found</div>;
  }

  return (
    <div className="diff">
      <p>
        <ins>Additions: {additions}</ins> <del>Deletions: {deletions}</del>
      </p>
      <pre>
        {map(lines, (l, i) => renderDiff(l, i))}
      </pre>
    </div>
  );
}
DiffJson.propTypes = propTypes;
