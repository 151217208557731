import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Suspense} from 'react';

import './EditControls.scss';
import Loading from 'components/Loading';
import useEditContext from 'components/edit/EditContext';


const propTypes = {
  alwaysShow: PropTypes.bool,
  /**
   * Classes to apply to the div around the children.
   */
  className: PropTypes.string,
  children: PropTypes.node,
};
/**
 * Only renders children if in preview mode or alwaysShow is true.
 * Children will be rendered within a Suspense block.
 */
export default function EditControls({alwaysShow = false, className, children}) {
  const editContext = useEditContext();
  if ((!alwaysShow && !editContext.isEditMode) || !children) {
    return null;
  }

  return (
    <Suspense fallback={<div><Loading /></div>}>
      <div className={clsx('editControls', className)}>
        {children}
      </div>
    </Suspense>
  );
}

EditControls.propTypes = propTypes;
