import React from 'react';
import PropTypes from 'prop-types';

import Fact from 'components/shared/fact';

const propTypes = {
  significance: PropTypes.string,
};
/**
 * Display of significance text for Variant Annotation detail display
 *
 * @param {string} significance - significance status: yes, no, or not stated
 * @return {React.ReactNode} - Fact component with text
 */
export default function SignificanceFact({significance}) {
  if (!significance) {
    return null;
  }

  let content;
  switch (significance.toLowerCase()) {
    case 'not stated':
      content = 'The study does not report on the significance of this association';
      break;
    case 'yes':
      content = 'The study reports this association is significant';
      break;
    case 'no':
      content = 'The study reports this association is not significant';
      break;
    default:
      content = 'N/A';
  }

  return (
    <Fact label="Association Significance" hideWhenEmpty={false} halfWidth={true} inline={true}>{content}</Fact>
  );
}
SignificanceFact.propTypes = propTypes;
