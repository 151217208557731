import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import {extractIconProps, extractProps} from 'utils/propUtils';


const propTypes = {
  // iconProps
  className: PropTypes.string,
  size: PropTypes.string,
  transform: PropTypes.string,
};

export default function LoadingIcon(props) {
  return (
    <FontAwesomeIcon
      icon={['fad', 'spinner-third']}
      spin={true}
      {...extractIconProps(props)}
      {...extractProps(props)}
    />
  );
}
LoadingIcon.propTypes = propTypes;
