import clingenImage from 'images/homepage/partner-clingen.svg';
import cpicImage from 'images/homepage/partner-cpic.svg';
import gbcrImage from 'images/homepage/partner-gcbr.svg';
import pgrnImage from 'images/homepage/partner-pgrn.png';
import pharmcatImage from 'images/homepage/partner-pharmcat.svg';
import pharmvarImage from 'images/homepage/partner-pharmvar.svg';

import Link from 'components/links/Link';


export default function Partners() {

  // specify img width/height (140/90) to reserve space - it will scale based on CSS
  // width of 140px is based on size of column in col-sm-3
  return (
    <div className="container home-section partners">
      <h3 className="section-heading">Partners</h3>
      <div className="row align-items-center justify-content-center">

        <div className="col-6 col-md-4 col-lg text-center">
          <Link href="https://cpicpgx.org">
            <img src={cpicImage} alt="CPIC Logo" width={140} height={90} />
          </Link>
        </div>

        <div className="col-6 col-md-4 col-lg text-center">
          <Link href="https://www.pharmgkb.org/page/pharmvar">
            <img src={pharmvarImage} alt="PharmVar Logo" width={140} height={90} />
          </Link>
        </div>

        <div className="col-6 col-md-4 col-lg text-center">
          <Link href="https://pharmcat.org/">
            <img src={pharmcatImage} alt="PharmCAT Logo" width={140} height={90} />
          </Link>
        </div>

        <div className="col-6 col-md-4 col-lg text-center">
          <Link href="https://www.pgrn.org/">
            <img src={pgrnImage} alt="PGRN Logo" width={140} height={90} />
          </Link>
        </div>

        <div className="col-6 col-md-4 col-lg text-center">
          <Link href="https://clinicalgenome.org/about/clingen-cpic-pharmgkb/">
            <img src={clingenImage} alt="ClinGen Logo" width={140} height={90} />
          </Link>
        </div>

        <div className="col-6 col-md-4 col-lg text-center">
          <Link href="https://globalbiodata.org/scientific-activities/global-core-biodata-resources/">
            <img src={gbcrImage} alt="GBCR Logo" width={140} height={90} />
          </Link>
        </div>

      </div>
    </div>
  );
}
