
/**
 * Truncate string by chopping it in the middle, preferring to cut at word boundaries (whitespace and punctuation),
 * then re-joining with the character join.
 *
 * Provided text will be trimmed, with as much internal spacing removed as possible.
 *
 * Based on https://github.com/Coggle/friendly-truncate.
 *
 * @param {string} text
 * @param {number} length
 * @param {object} [opts]
 * @param {string} [opts.join] - join symbol (defaults to ellipsis)
 * @param {RegExp} [opts.boundary] - regular expression matching boundaries to prefer breaking at. Must match globally.
 * Defaults to /[\s.\-_,;:]/g
 * @param {number} [opts.tolerance] - amount truncated length is allowed to differ from requested length. Defaults to
 * the lower of length/4 or 20; If the string contains surrogate pairs, the tolerance may be exceeded by 1 if necessary
 * to avoid cutting a surrogate pair in half.
 */
export function truncateMiddle(text, length, opts = {}) {
  if (!text) {
    return '';
  }
  const string = text.trim().replace(/\s+/g, ' ');
  // nothing to do if the length is longer than string:
  if (string.length <= length) {
    return string;
  }
  // special case negative and zero lengths for robustness:
  if (length <= 0) {
    return '';
  }

  const join = opts.join ?? '…';
  const boundary = opts.boundary ?? /[\s.\-_,;:]/g;
  const tolerance = opts.tolerance ?? Math.min(20, Math.round(length / 4));

  const wordBoundariesStart = [];
  const wordBoundariesEnd = [];
  for (let next = boundary.exec(string); next; next = boundary.exec(string)) {
    // prune word boundaries to those within length/2 + tolerance of the start/end of the string:
    const wiggle = (length / 2) + tolerance;
    if (next.index < wiggle) {
      wordBoundariesStart.push(next.index);
    } else if (next.index < Math.floor(string.length - (wiggle + 1))) {
      // skip ahead to the end of the string, there's no point testing against all of the middle:
      boundary.lastIndex = Math.floor(string.length - (wiggle + 1));
    }

    if ((next.index > string.length - wiggle)) {
      wordBoundariesEnd.push(next.index);
    }
  }

  // reset regex state in case caller re-uses it
  boundary.lastIndex = 0;

  let firstCut;
  let secondCut;
  const possibleCuts = [];
  let resultLength;
  for (let i = wordBoundariesStart.length - 1; i >= 0; i -= 1) {
    // search for a suitable second cut
    firstCut = wordBoundariesStart[i];
    for (let j = wordBoundariesEnd.length - 1; j >= 0; j -= 1) {
      resultLength = (string.length - (wordBoundariesEnd[j] + 1)) + wordBoundariesStart[i] + 1;
      secondCut = null;
      if ((resultLength <= length) && resultLength > length - tolerance) {
        secondCut = wordBoundariesEnd[j] + 1;
      }
      if (secondCut) {
        possibleCuts.push({length: resultLength, first: firstCut, second: secondCut});
        // The worst case for performance is where the boundary expression matched
        // at every single character and the tolerance is big. To avoid this being
        // quadratic in the tolerance, break as soon as we have an exact
        // match on length:
        if (resultLength === length) {
          i = -1;
          break;
        }
      }
    }
  }

  // sort preferring overall length and approximately equal length of both parts:
  possibleCuts.sort((a, b) => {
    // equalness value, [0, 0.999]:
    const equalnessA = 1 / ((Math.abs(a.first - (string.length - a.second)) + 1.001));
    const equalnessB = 1 / ((Math.abs(b.first - (string.length - b.second)) + 1.001));
    return (b.length + equalnessB) - (a.length + equalnessA);
  });

  if (possibleCuts.length) {
    firstCut = possibleCuts[0].first;
    secondCut = possibleCuts[0].second;
  } else {
    firstCut = Math.floor(length / 2);
    secondCut = string.length - (length - 1 - firstCut);
  }

  // check if we would cut a surrogate pair in half, if so adjust the cut:
  // (NB: we're assuming string containing only valid surrogate pairs here)
  if (/[\uD800-\uDBFF]/.exec(string[firstCut - 1])) {
    if (secondCut < string.length) {
      firstCut += 1;
      secondCut += 1;
    } else {
      firstCut -= 1;
      secondCut -= 1;
    }
  }
  if (/[\uDC00-\uDFFF]/.exec(string[secondCut])) {
    secondCut += 1;
  }

  const firstPart = string.substring(0, firstCut);
  const secondPart = string.substring(secondCut);

  return firstPart + join + secondPart;
}
