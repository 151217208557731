import {useEffect} from 'react';

import Loading from 'components/Loading';
import Template from 'components/templates/ReadableWidth';
import useAppContext from 'conf/AppContext';


/**
 * Renders an interstitial sign out page.
 *
 * @return {React.ReactNode}
 */
export default function Logout({}) {
  const appContext = useAppContext();

  useEffect(() => {
    appContext.logout();
  }, []);

  return (
    <Template title="Signing Out...">
      <Loading />
    </Template>
  );
}
