import clsx from 'clsx';
import {compact, forEach, isArray, isUndefined} from 'lodash';
import PropTypes from 'prop-types';

import './Link.scss';
import NewTabIcon from 'components/icons/NewTab';
import logger from 'conf/Logger';
import {extractEventProps, extractProps} from 'utils/propUtils';


const propTypes = {
  href: PropTypes.string.isRequired,
  /**
   * Forces link to be opened in new tab if true, in same tab if false.
   * If undefined, it will default to opening external links in new tab.
   */
  newTab: PropTypes.bool,
  /**
   * Show new tab icon if this is true and link will open in new tab.
   */
  showNewTabIcon: PropTypes.bool,
  /**
   * The relationship of the linked URL as space-separated link types (used to set the `rel` attribute).
   */
  rel: PropTypes.string,
  role: PropTypes.string,
  download: PropTypes.bool,
  /**
   * If specified, this function will be used for`onClick` and `onKeyPress`.
   */
  actionHandler: PropTypes.func,
  /**
   * Used to set the `title` attribute as well as `aria-label` attribute if `aria-label` is not defined.
   */
  title: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  /**
   * Indicates that this link only contains an icon (will add `link--iconOnly` class).
   */
  iconOnly: PropTypes.bool,
  children: PropTypes.node,
};

/**
 * This renders an anchor link (<a />).
 * If href does not start with "/", the link will be opened in a new tab unless it is overridden with `props.newTab`.
 * If `aria-hidden` is true, then `tabindex` will be set to -1.
 */
export default function Link({href, newTab, showNewTabIcon = false, rel, role, download, actionHandler,
  title, className, style, iconOnly, children, ...otherProps}) {
  if (!href) {
    logger.error('Link requires an href');
    return <a className="text-danger">error - check console</a>;
  }

  const localHref = href.startsWith('/') || href === '#';
  const openInNewTab = isUndefined(newTab) ? !localHref : newTab;
  const newTabIcon = openInNewTab && showNewTabIcon
    ? (<NewTabIcon size="2xs" className="link__extIcon" />) : null;

  let isIconOnly = iconOnly;
  if (children !== 0 && (!children || (isArray(children) && compact(children).length === 0))) {
    // no children
    if (newTabIcon) {
      isIconOnly = true;
    } else {
      logger.error('Link requires children or new tab icon');
      return <a className="text-danger">error - check console</a>;
    }
  }

  const attributes = {
    href,
    className: clsx('link', {'link--iconOnly': isIconOnly}, className),
    style,
    role,
    download,
    title,
    ...extractEventProps(otherProps),
    ...extractProps(otherProps),
  };
  if (actionHandler) {
    attributes.onClick = actionHandler;
    attributes.onKeyPress = actionHandler;
  }
  const relSet = new Set();
  if (rel) {
    forEach(rel.split(' '), (r) => relSet.add(r));
  }
  let srText = '';
  if (openInNewTab) {
    relSet.add('noopener');
    relSet.add('noreferrer');
    attributes.target = '_blank';
    srText = <span className="screen-reader-text">(opens in new window)</span>;
  }

  if (attributes['aria-hidden']) {
    attributes.tabIndex = -1;
  } else if (title && !attributes['aria-label']) {
    attributes['aria-label'] = title;
  }

  if (!localHref && !href.includes('pharmgkb.org')) {
    relSet.add('external');
  }
  if (relSet.size > 0) {
    attributes.rel = Array.from(relSet).join(' ');
  }

  return <a {...attributes}>{children}{newTabIcon}{srText}</a>;
}

Link.propTypes = propTypes;
