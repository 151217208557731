import {isUndefined} from 'lodash';
import {useEffect, useState} from 'react';

import {stringifySearchParams} from 'components/links/utils';
import useAppContext from 'conf/AppContext';


/**
 * @typedef GetResult
 * @inner
 *
 * @property {*} response
 * @property {Error} error
 */


/**
 * This hook runs an async GET api request.
 *
 * @param {string|Request} target - resource to fetch
 *
 * @param {object} [getOptions] - options object containing custom settings to apply to the request
 * -- passed through to ky --
 * @param {object} [getOptions.searchParams] - query parameters
 * @param {number|boolean} [getOptions.timeout] - time to wait in milliseconds for getting a response, false to disable
 * @param {boolean} [getOptions.throwHttpErrors] - throw non-2xx responses as errors, default true
 * -- custom options --
 * @param {boolean} [getOptions.cacheResponse] - true if response should be cached
 * @param {number} [getOptions.cacheTtl] - time (in seconds) the resource will be available for once it's been cached
 * @param {boolean} [getOptions.parseJson] - parse JSON out of response (defaults to true if neither parseJson nor
 * parseText is defined)
 * @param {boolean} [getOptions.parseText] - parse text out of response
 * @param {boolean} [getOptions.forceLive] - should only be used on preview to force a query to be run on live (this
 * request cannot be cached)
 *
 * --- hook-specific options ---
 * @param {object} [hookOptions] - hook-specific options
 * @param {boolean} [hookOptions.cacheOnLive] - cache response if running on live
 * @param {boolean} [hookOptions.previewOnly] - only run request on preview (returns false if not)
 * @param {Function} [hookOptions.conditional] - method to check if fetch should be performed (false skips fetch,
 * returning null response (unless conditionalFailureMsg is provided))
 * @param {string} [hookOptions.conditionalFailureMsg] - if conditional is provided returns false, this error message is
 * returned; otherwise, null response is returned
 * @param {string|number} [hookOptions.loadId] - use this to force a re-fetch of the data
 * @param {Function} [hookOptions.transform] - transform response before (caching)
 * @return {GetResult}
 */
// eslint-disable-next-line import/prefer-default-export
export const useGet = (target, getOptions = {}, hookOptions = {}) => {
  const appContext = useAppContext();
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const loadId = hookOptions.loadId || 0;
  const searchParams = stringifySearchParams(getOptions.searchParams);

  useEffect(() => {
    if (response) {
      setResponse(null);
    }
    if (hookOptions.previewOnly && !appContext.isPreview) {
      return;
    }
    if (hookOptions.conditional && !hookOptions.conditional()) {
      if (hookOptions.conditionalFailureMsg) {
        setError(new Error(hookOptions.conditionalFailureMsg));
      }
      return;
    }
    const abortController = new AbortController();

    async function loadData() {
      try {
        let options = getOptions;
        if (isUndefined(getOptions.parseJson) && isUndefined(getOptions.parseText)) {
          options = {
            parseJson: true,
            ...getOptions,
          };
        }
        if (hookOptions?.cacheOnLive) {
          options.cacheResponse = !appContext.isPreview;
        }
        const rez = await appContext.api.get(target, options, abortController);
        if (hookOptions.transform) {
          setResponse(hookOptions.transform(rez));
        } else {
          setResponse(rez);
        }
      } catch (kyError) {
        setError(kyError);
      }
    }

    // noinspection JSIgnoredPromiseFromCall
    loadData();

    return () => {
      abortController.abort();
    };
  }, [target, searchParams, loadId]);

  return {response, error};
};
