import {map, size} from 'lodash';
import PropTypes from 'prop-types';

import 'components/ClinicalAnnotation/Evidence.scss';
import LiteratureCitation from 'components/Literature/Citation';
import ResourceIcon from 'components/icons/Resource';
import Fact from 'components/shared/fact';
import FactSection from 'components/shared/fact_section';
import HtmlContainer from 'components/shared/html_container';
import Types, {literatureProps, markdownProps} from 'conf/types';

const propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  prescribingMarkdown: markdownProps,
  literature: PropTypes.arrayOf(literatureProps),
  index: PropTypes.number,
};
/**
 * A section to show a label annotation as evidence for a clinical annotation
 *
 * @param {object} props - props container
 * @param {string} props.id - accession ID
 * @param {string} props.name - name of label
 * @param {object} props.prescribingMarkdown - prescribing Markdown object
 * @param {Array<object>} props.literature - array of Literature objects
 * @param {number} props.index - the numerical place of this evidence in the list of evidence
 * @return {React.ReactNode} an evidence section div
 */
export default function LabelEvidence({id, name, prescribingMarkdown, literature, index}) {
  return (
    <div className="evidence-section">
      <h3 className="fact-section-header">
        {`${index}. `}
        <ResourceIcon type="labelAnnotation" inline={true} />
        {' '}<a href={Types.labelAnnotation.url(id)}>{name}</a>
      </h3>

      {prescribingMarkdown?.html ? <HtmlContainer html={prescribingMarkdown.html} /> : null}

      <div className="mt-3">
        {size(literature) > 0 && <h5>From Label</h5>}
        {map(literature, (l) => <LiteratureCitation key={l.id} firstAuthorOnly={true} {...l} />)}
      </div>

      <p className="mt-3"><a href={Types.labelAnnotation.url(id)}>More information on this annotation</a></p>

      <FactSection halfWidth={true}>
        <Fact halfWidth={true} inline={true} label="Score">100</Fact>
        <Fact halfWidth={true} inline={true} label="PharmGKB ID">{id}</Fact>
      </FactSection>
    </div>
  );
}
LabelEvidence.propTypes = propTypes;
