import {map, sortBy} from 'lodash';

import LiteratureCitation from 'components/Literature/Citation';
import KyError from 'components/errors/KyError';
import HtmlContainer from 'components/shared/html_container';
import FullWidthTemplate from 'components/templates/FullWidth';
import useAppContext from 'conf/AppContext';
import {useGet} from 'helpers/KyHooks';

import 'pages/Vips/index.scss';

export default function VipTier2List({}) {
  const appContext = useAppContext();

  const {response, error} = useGet('data/vipGene', {
    searchParams: {tier: 'tier2', view: 'list'},
    cacheResponse: !appContext.isPreview,
  });
  if (error) {
    appContext.toastError(<KyError kyError={error} />);
  }
  const vips = sortBy(response?.data, (v) => v.target.symbol);

  const makeVipItem = (vip) => {
    const {id, target, citation, summary} = vip;
    const citationLink = !citation ? null : (
      <div className="vipList-litLink">
        <LiteratureCitation {...citation} />
      </div>
    );
    const summarySection = summary ? <HtmlContainer html={summary.html} /> : null;
    return (
      <li key={id} className="mb-4">
        <h5><a href={`/gene/${target.id}`}>{target.symbol}</a></h5>
        <div className="ml-4">
          {summarySection}
          {citationLink}
        </div>
      </li>
    );
  };

  return (
    <FullWidthTemplate title="Tier 2 VIPs">
      <div>
        <ol className="vip-list">
          {map(vips, makeVipItem)}
        </ol>
      </div>
    </FullWidthTemplate>
  );
}
