import {format as dateFormat, parseISO} from 'date-fns';
import PropTypes from 'prop-types';

/**
 * A table that breaks down the different parts of a variant annotation's score
 *
 * @param {object} props - props container
 * @param {number} props.step1Score - step 1 score
 * @param {number} props.step2Score - step 2 score
 * @param {number} props.step3Score - step 3 score
 * @param {number} props.step4Score - step 4 score
 * @param {number} props.step5A - step 5A multiplier
 * @param {number} props.step5B - step 5B multiplier
 * @param {number} props.totalScore - total score calculated
 * @param {string} props.generatedDate - date generated in ISO 8601
 */
const propTypes = {
  step1Score: PropTypes.number,
  step2Score: PropTypes.number,
  step3Score: PropTypes.number,
  step4Score: PropTypes.number,
  step5A: PropTypes.number,
  step5B: PropTypes.number,
  generatedDate: PropTypes.string,
  totalScore: PropTypes.number,
};
export default function ScoreBreakdown({
  step1Score, step2Score, step3Score, step4Score, step5A, step5B, generatedDate, totalScore,
}) {
  return (
    <table className="table table-sm mb-0">
      <caption>Scored on {dateFormat(parseISO(generatedDate), 'MM/dd/yyyy')}</caption>
      <thead>
        <tr>
          <th>1</th>
          <th>+ 2</th>
          <th>+ 3</th>
          <th>+ 4</th>
          <th>* 5A</th>
          <th>* 5B</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{step1Score}</td>
          <td>{step2Score}</td>
          <td>{step3Score}</td>
          <td>{step4Score}</td>
          <td>{step5A}</td>
          <td>{step5B}</td>
          <td>{totalScore}</td>
        </tr>
      </tbody>
    </table>
  );
}
ScoreBreakdown.propTypes = propTypes;
