import PropTypes from 'prop-types';

import FeedbackLink from 'components/links/Feedback';
import ReadableWidthTemplate from 'components/templates/ReadableWidth';

const propTypes = {
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
};
/**
 * Renders a generic error page.
 */
export default function ErrorPage({title = 'Oh No! Something Went Wrong!', message}) {

  return (
    <ReadableWidthTemplate title={title}>
      <div className="alert alert-danger">
        <p>{message}</p>
      </div>
      <p>If you would like assistance with this problem, please <FeedbackLink />.</p>
    </ReadableWidthTemplate>
  );
}
ErrorPage.propTypes = propTypes;
