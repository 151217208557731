import clsx from 'clsx';
import PropTypes from 'prop-types';
import InlineSVG from 'react-inlinesvg';

import './Resource.scss';
import Types, {normalizeType} from 'conf/types';
import {extractProps} from 'utils/propUtils';
import {isUndefined} from 'lodash';


const propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  /** This applies the alternate style to the icon. */
  altStyle: PropTypes.bool,
  style: PropTypes.object,
  inline: PropTypes.bool,
};

/**
 * Renders the requested resource icon.
 * By default, aria-hidden is true.
 */
export default function ResourceIcon({type, className, altStyle = false, style, inline = true, ...otherProps}) {

  const normalizedType = normalizeType(type);
  if (!normalizedType) {
    return <span className="text-danger">Invalid type: {type}</span>;
  }
  const {icon} = Types[normalizedType];
  if (!icon) {
    return <span className="text-danger">No icon for {type}</span>;
  }

  const attribs = extractProps(otherProps);
  // aria-hidden defaults to true
  if (isUndefined(attribs['aria-hidden'])) {
    attribs['aria-hidden'] = true;
  }
  if (!attribs['aria-hidden']) {
    attribs.role = 'img';
    if (!attribs['aria-label']) {
      attribs['aria-label'] = Types[normalizedType].displayType;
    }
  }

  const classes = {
    resourceIcon: !inline,
    'resourceIcon--inline': inline,
  };
  if (altStyle) {
    const altStyleClass = `resourceIcon--${normalizedType}--alt`;
    classes[altStyleClass] = true;
  }

  return (
    <InlineSVG
      src={icon}
      cacheRequests={true}
      className={clsx(className, `resourceIcon--${normalizedType}`, classes)}
      style={style}
      {...attribs}
    />
  );
}
ResourceIcon.propTypes = propTypes;
