import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {isUndefined} from 'lodash';
import PropTypes from 'prop-types';

import './Download.scss';
import {extractIconProps, extractProps} from 'utils/propUtils';


const propTypes = {
  // iconProps
  className: PropTypes.string,
  size: PropTypes.string,
  transform: PropTypes.string,
};
/**
 * Renders the download icon.
 * By default, aria-hidden is true.
 */
export default function DownloadIcon(props) {
  const otherProps = extractProps(props);
  // aria-hidden defaults to true
  if (isUndefined(otherProps['aria-hidden'])) {
    otherProps['aria-hidden'] = true;
  }

  return <FontAwesomeIcon icon={['fad', 'download']} {...extractIconProps(props)} {...otherProps} />;
}
DownloadIcon.propTypes = propTypes;
