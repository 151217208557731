import {map} from 'lodash';
import PropTypes from 'prop-types';

import Link from 'components/links/Link';
import FactSection from 'components/shared/fact_section';
import HtmlContainer from 'components/shared/html_container';

const propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  tier: PropTypes.string,
  sections: PropTypes.arrayOf(PropTypes.string),
};
/**
 * This components displays VIP content for non-Genes (e.g. chemicals & variants).
 *
 * @param {object} props - props contianer
 * @param {string} props.name - the name of the VIP
 * @param {string} props.url - the URL of the VIP
 * @param {string} props.tier - the tier descriptor of the VIP
 * @param {Array<string>} props.sections - the array of HTML sections
 * @return {JSX.Element} a VIP section as a FactSection
 */
export default function VipRelatedSummary({name, url, tier, sections}) {
  return (
    <FactSection title={`Description from ${name}`}>
      {map(sections, (s, i) => <HtmlContainer key={`md${url}-${i}`} html={s} />)}
      <p className="mt-3"><Link href={url}>Read more of {name}</Link></p>
    </FactSection>
  );
}
VipRelatedSummary.propTypes = propTypes;
