import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useState} from 'react';

import './GuidelineAnnotations.scss';
import GuidelineAnnotationsBarChart from 'components/GuidelineAnnotation/BarChart';
import GuidelineAnnotationTable from 'components/GuidelineAnnotation/Table';
import Loading from 'components/Loading';
import InlinePage from 'components/Page/Inline';
import KyError from 'components/errors/KyError';
import Link from 'components/links/Link';
import FullFluidWidthTemplate from 'components/templates/FullFluidWidth';
import useAppContext from 'conf/AppContext';
import {useGet} from 'helpers/KyHooks';

/**
 * Renders guideline annotations list page.
 */
export default function GuidelineAnnotationsPage() {
  const appContext = useAppContext();

  const {response, error} = useGet('site/guidelinesByDrugs', {
    cacheResponse: !appContext.isPreview,
  });

  const [filter, setFilter] = useState(null);

  let table = <Loading />;
  let barChart = (
    <div className="gaFigure gaFigure--loading figure figure--floatRight">
      <Loading />
    </div>
  );

  if (error) {
    table = <KyError kyError={error} />;
  } else if (response) {
    table = (
      <GuidelineAnnotationTable
        data={response?.data}
        sourceFilter={filter}
      />
    );
    barChart = (
      <GuidelineAnnotationsBarChart
        guidelineSources={response?.data}
        filterChangeFn={setFilter}
        className="figure--floatRight"
      />
    );
  }

  return (
    <FullFluidWidthTemplate className="guidelineAnnotationsPage" title="Clinical Guideline Annotations">
      <div className="container mb-5">
        {barChart}
        <InlinePage id="dosingGuideHeader" />
        <p className="mt-3">
          <FontAwesomeIcon icon={['fab', 'youtube']} size="lg" className="icon-youtube" /> Guideline Videos:
          PharmGKB has recorded <Link href="https://www.youtube.com/playlist?list=PLbP5DJELA1WM1mgVf0OHfhxRoQtyb-QJh">video overviews of the CPIC clinical guidelines</Link>.
          The video overview of a guideline can also be seen on the individual guideline page.
        </p>
      </div>
      {table}
    </FullFluidWidthTemplate>
  );
}
