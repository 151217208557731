import {parseISO} from 'date-fns';
import pluralize from 'pluralize';

export const TableType = {
  SIMPLE: 'simple',
  VIRTUALIZED: 'virtualized',
};


export const renderTableRow = (row) => (
  row.cells.map((cell) => {
    const props = {};
    if (cell.column.className) {
      props.className = cell.column.className;
    }
    if (cell.column.style) {
      props.style = cell.column.style;
    }
    return (
      <td {...cell.getCellProps(props)} key={cell.column.id}>
        {cell.render('Cell')}
      </td>
    );
  })
);


export const renderTotalDataFn = (data, numVisible, singularNoun = 'annotation') => {
  if (data.length === 0) {
    return '';
  }
  if (data.length === numVisible) {
    return `${numVisible} ${pluralize(singularNoun, numVisible)}`;
  }
  if (numVisible === 0) {
    return `No ${pluralize(singularNoun, 2)} match your query.`;
  }
  return `${numVisible} out of ${data.length} ${pluralize(singularNoun, numVisible)} match your query`;
};

export const createdDateAccessor = (row) => row.createdDate && parseISO(row.createdDate);
export const updatedDateAccessor = (row) => row.updatedDate && parseISO(row.updatedDate);
