/*
 * These functions help with cookie operations in the browser
 *
 * Cribbed from https://www.quirksmode.org/js/cookies.html
 */


/**
 * Checks if cookies are enabled in the browser.
 *
 * @return {boolean} true if cookies are enabled, false otherwise
 */
export function checkCookiesEnabled() {
  // fail fast if not in browser
  if (typeof window === 'undefined') {
    return false;
  }
  // taken from Modernizr: https://github.com/Modernizr/Modernizr/blob/master/feature-detects/cookies.js
  try {
    // create cookie
    document.cookie = 'cookieTest=1; SameSite=Lax';
    const ret = document.cookie.indexOf('cookieTest=') !== -1;
    // delete cookie
    document.cookie = 'cookieTest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT; SameSite=Lax';
    return ret;
  } catch (e) {
    return false;
  }
}


/**
 * Sets a cookie with the name and value given that will expire after the days specified. Also,
 * sets the cookie for the entire wildcard domain .pharmgkb.org.
 *
 * @param {string} name - the name of the cookie
 * @param {(string|number)} value - the value of the cookie
 * @param {number} days - the number of days before expiring
 */
export function createCookie(name, value, days) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = '; expires=' + date.toGMTString();
  }
  document.cookie = `${name}=${value}${expires}; path=/; domain=.pharmgkb.org; SameSite=Lax`;
}

/**
 * Gets a cookie with the given name, returns null if it doesn't exist.
 *
 * @param {string} name - the name of the cookie
 * @return {(string|number|null)} the value of the cookie, null if not found
 */
export function readCookie(name) {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

/**
 * Removes the cookie identified by name by expiring it.
 *
 * @param {string} name
 */
export function eraseCookie(name) {
  createCookie(name, '', -1);
}
