import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {format as dateFormat} from 'date-fns';
import {map} from 'lodash';
import {toast} from 'react-toastify';

import Button from 'components/Button';
import Loading from 'components/Loading';
import TextFilter from 'components/Table/Filter/Text';
import SimpleTable from 'components/Table/Simple';
import {getBooleanCol} from 'components/Table/columnHelpers';
import KyError from 'components/errors/KyError';
import Link from 'components/links/Link';
import FullFluidWidthTemplate from 'components/templates/FullFluidWidth';
import Types from 'conf/types';
import {useGet} from 'helpers/KyHooks';
import ResourceLink from 'components/links/Resource';
import useAppContext from 'conf/AppContext';

export default function FdaBiomarkerPage({}) {
  const {response, error} = useGet('curation/fdaBiomarker');
  const appContext = useAppContext();

  const columns = [
    {
      id: 'drug',
      Header: 'Drug',
      accessor: 'fdaBiomarker.drugText',
      sortable: true,
      Filter: TextFilter,
    },
    {
      id: 'biomarkers',
      Header: 'Biomarkers',
      accessor: 'fdaBiomarker.biomarkers',
      sortable: true,
      Filter: TextFilter,
    },
    {
      id: 'therapeuticArea',
      Header: 'Therapeutic Area',
      accessor: 'fdaBiomarker.therapeuticArea',
      sortable: true,
      Filter: TextFilter,
    },
    {
      id: 'labelingSections',
      Header: 'Labeling Sections',
      accessor: 'fdaBiomarker.labelingSections',
      sortable: true,
      Filter: TextFilter,
      Cell: ({value}) => <ul className="m-0">{map(value, (v) => <li key={v}>{v}</li>)}</ul>,
    },
    {
      id: 'annotated',
      Header: 'Annotated?',
      accessor: 'annotated',
      ...getBooleanCol(150),
    },
    {
      id: 'labelLinkMatch',
      Header: 'Label Link Match?',
      accessor: 'labelLinkMatch',
      ...getBooleanCol(150),
    },
    {
      id: 'statusCorrect',
      Header: 'Correct Status?',
      accessor: 'statusCorrect',
      ...getBooleanCol(150),
    },
    {
      id: 'labelAnnotations',
      Header: 'Annotation',
      accessor: 'labelAnnotations',
      Cell: ({value}) => <ul className="list-unstyled">{map(value, (v) => <li key={v.id}><Link href={Types.labelAnnotation.url(v.id)}>{v.id}</Link></li>)}</ul>,
    },
    {
      id: 'fdaLink',
      Header: 'Link on FDA',
      accessor: 'fdaBiomarker.labelLink',
      Cell: ({value}) => <Link href={value} iconOnly={true} title="label link"><FontAwesomeIcon icon={['fad', 'arrow-up-right-from-square']} /></Link>,
    },
    {
      id: 'labelLinksPgkb',
      Header: 'Links used on PGKB',
      accessor: 'labelLinksPgkb',
      Cell: ({value}) => <ul className="list-unstyled">{map(value, (v) => <li key={v}><Link href={v} iconOnly={true} title="label link"><FontAwesomeIcon icon={['fad', 'arrow-up-right-from-square']} /></Link></li>)}</ul>,
    },
  ];

  const handleUpdate = async () => {
    try {
      const rez = await appContext.api.put('curation/fdaBiomarker/_sync', {
        timeout: false,
        parseJson: true,
      });
      let successMessage;
      let autoClose = 2000;
      if (rez.status === 'success') {
        successMessage = (
          <div>
            <strong>Data changes found!</strong> Refresh to see changes.
            <div><Button className="btn btn-xs btn-outline-success" actionHandler={() => window.location.reload()}>Refresh</Button></div>
          </div>
        );
        autoClose = false;
      } else {
        successMessage = <div>No update detected.</div>;
      }
      toast.success(successMessage, {autoClose});
    } catch (kyError) {
      appContext.toastError(<KyError kyError={kyError} />);
    }
  };

  let body = <Loading />;
  if (error) {
    body = <KyError kyError={error} />;
  } else if (response) {
    const {snapshot, results, missingAnnotations} = response.data;
    body = (
      <div>
        <p>Based on FDA content date: {snapshot.fdaContentCurrentDate} (stored in PGKB on {dateFormat(snapshot.collectedOn, 'MM/dd/yyyy')})</p>
        <div className="p-2">
          <Button actionHandler={handleUpdate}>
            Check for Content Update
          </Button>
          <Button href="/edit/fdaBiomarker/diff" className="btn-outline-info ml-2">Compare Versions</Button>
        </div>
        <SimpleTable data={results} columns={columns} />
        <div>
          <h4>Possibly Not On Biomarker List</h4>
          <p>These are annotations that we marked as currently being on the Biomarker list but do not appear on the current version of the FDA Biomarker list.</p>
          <p><strong>NOTE:</strong> These may be false-positives if our annotation doesn't match the label Application ID or drug-gene pair.</p>
          <ol>
            {map(missingAnnotations, (a) => <li key={a.id}><ResourceLink resource={a} /></li>)}
          </ol>
        </div>
      </div>
    );
  }

  return (
    <FullFluidWidthTemplate title="FDA Biomarker Table Coverage">
      {body}
    </FullFluidWidthTemplate>
  );
}
