import PropTypes from 'prop-types';
import React from 'react';
import {map} from 'lodash';
import clsx from 'clsx';


import Link from 'components/links/Link';
import {getBaseUrl} from 'conf/types';


const propTypes = {
  collection: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  renderFn: PropTypes.func,
  commaSeparated: PropTypes.bool,
  emptyText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
/**
 * Renders a list.
 *
 * @param {object} props - component properties
 * @param {Array} [props.collection]
 * @param {Function} [props.renderFn] - function to render line item
 * @param {boolean} [props.commaSeparated]
 * @param {string|React.ReactNode} [props.emptyText]
 * @return {React.ReactNode}
 */
export default function InlineList({collection = [], renderFn = renderEntityList,
  commaSeparated = true, emptyText = <p className="empty">None</p>}) {
  if (collection.length === 0) {
    return emptyText;
  }

  return (
    <ul className={clsx('list-inline', {commaSeparated})}>
      {map(collection, renderFn)}
    </ul>
  );
}
InlineList.propTypes = propTypes;


/**
 * Renders a value.
 *
 * @param {string|number} value
 * @param {number} idx
 * @return {React.ReactNode}
 */
export function renderValueList(value, idx) {
  return (
    <li className="list-inline-item" key={idx}>
      {value}
    </li>
  );
}

/**
 * Renders a basic object.
 *
 * @param {object} obj
 * @param {string|number} obj.id
 * @param {string} obj.name
 */
export function renderBasicObjectList(obj) {
  return (
    <li className="list-inline-item" key={obj.id}>
      {obj.name}
    </li>
  );
}

/**
 * Renders an entity (should match Types.accessionIdProps propTypes).
 *
 * @param {object} entity
 * @param {string} entity.objCls
 * @param {string} entity.id
 * @param {string} entity.name
 * @param {string} [entity.symbol]
 * @return {React.ReactNode}
 */
export function renderEntityList(entity) {
  const url = getBaseUrl(entity.id, entity.objCls);
  const text = entity.symbol ? entity.symbol : entity.name;
  return (
    <li className="list-inline-item" key={entity.id}>
      <Link href={url}>{text}</Link>
    </li>
  );
}
