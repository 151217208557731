import {find} from 'lodash';
import PropTypes from 'prop-types';

import ApproveAndDiffButtons from 'components/Button/ApproveAndDiff';
import EditButton from 'components/Button/Edit';
import HistoryTable from 'components/History/Table';
import LiteratureCitation from 'components/Literature/Citation';
import VipTierTag from 'components/Tag/VipTier';
import EditControls from 'components/edit/EditControls';
import ResourceLink from 'components/links/Resource';
import Fact from 'components/shared/fact';
import FactSection from 'components/shared/fact_section';
import HTMLContainer from 'components/shared/html_container';
import {accessionIdProps, historyProps, literatureProps, markdownProps} from 'conf/types';


const propTypes = {
  vip: PropTypes.shape({
    attribution: PropTypes.string,
    id: PropTypes.string,
    citation: literatureProps,
    sections: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
    })),
    summary: markdownProps,
    history: PropTypes.arrayOf(historyProps),
    tier: PropTypes.string,
    keyLiterature: PropTypes.arrayOf(literatureProps),
    target: accessionIdProps,
  }),
  approved: PropTypes.bool,
  published: PropTypes.bool,
};

export default function VipOverviewTab({vip, approved, published}) {
  const {citation, history, id, keyLiterature, summary, target, tier} = vip;
  
  const geneFocusLit = find(keyLiterature, (l) => /PharmVar GeneFocus/.test(l.title));

  const showVipTag = tier !== 'Tier 2';

  return (
    <div id="tour-vip-main-text">
      <EditControls>
        <EditButton href={`/edit/vip/${id}`} label="Edit" />
        <ApproveAndDiffButtons objCls="VipGene" objId={id} approved={approved} published={published} iconOnly={false} />
      </EditControls>

      {showVipTag && (
        <div className="mb-3">
          <VipTierTag tier={tier} />
        </div>
      )}

      {summary?.html && (
        <FactSection title="Summary">
          <HTMLContainer html={summary.html} />
          <p>
            For more information, see the gene page for <ResourceLink resource={target} />.
          </p>
        </FactSection>
      )}

      {citation?.id
        ? (
          <FactSection sectionClasses="vip__citation" title="Citation" id="vip-citation">
            <LiteratureCitation {...citation} />
          </FactSection>
        )
        : ''}

      {geneFocusLit && (
        <FactSection title="See Also">
          <LiteratureCitation {...geneFocusLit} />
        </FactSection>
      )}

      <FactSection title="Metadata">
        <Fact label="PharmGKB ID" literal={id} inline={true} />
      </FactSection>

      <FactSection title="History">
        <HistoryTable history={history} parentCls="VipGene" parentId={id} />
      </FactSection>
    </div>
  );
}
VipOverviewTab.propTypes = propTypes;
