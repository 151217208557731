import './index.scss';
import TableFrame from 'components/Table/LazyFrame';
import SimpleTableLayout from 'components/Table/SimpleLayout';
import {tablePropTypes} from 'components/Table/frameProps';


const propTypes = {
  ...tablePropTypes,
};

/**
 * This renders a simple table based on react-table.
 *
 * This supports: sorting, expanding
 */
export default function SimpleTable({
  // standard table props
  id,
  title,
  className,
  rowClassNameFn,
  canShowFullscreen = false,
  canEditColumns = false,
  canResizeColumns = true,
  canHideFilters = false,
  customFilters,
  updatedFilters,
  onResetFilters,
  customButtons,
  disableExpansion = false,
  updateDataFn,
  renderTotalFn,
  // reactTableProps
  tableProps = {},
  columns = [],
  data,
  initialState,
  sortTypes = {},
  autoResetFilters = false,
  // downloadTableProps
  disableExport = true,
  getExportFileName,
  getExportFileBlob,
  parserConfig,
}) {
  const frameProps = {
    renderTableFn: (props) => <SimpleTableLayout {...props} />,
    // standard table props
    id,
    title,
    className,
    rowClassNameFn,
    canShowFullscreen,
    canEditColumns,
    canResizeColumns,
    canHideFilters,
    customFilters,
    updatedFilters,
    onResetFilters,
    customButtons,
    disableExpansion,
    updateDataFn,
    renderTotalFn,
    // reactTableProps
    tableProps,
    columns,
    data,
    initialState,
    sortTypes,
    autoResetFilters,
    // downloadTableProps
    disableExport,
    getExportFileName,
    getExportFileBlob,
    parserConfig,
  };

  // use btn-sm in tableControls so that buttons don't wrap on small screens
  return (
    <TableFrame {...frameProps} />
  );
}
SimpleTable.propTypes = propTypes;
