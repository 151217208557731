import {lazy, Suspense} from 'react';

import Loading from 'components/Loading';
import {propTypes} from 'components/Table/frameProps';

// noinspection JSCheckFunctionSignatures
const TableFrame = lazy(() =>
  import(/* webpackChunkName: "table" */'components/Table/Frame'));


/**
 * Lazy wrapper around TableFrame.
 */
export default function LazyTableFrame(args) {
  return (
    <Suspense fallback={<Loading />}>
      <TableFrame {...args} />
    </Suspense>
  );
}
LazyTableFrame.propTypes = propTypes;
