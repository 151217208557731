import {map} from 'lodash';
import PropTypes from 'prop-types';


const propTypes = {
  items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.node, PropTypes.object])),
  renderFn: PropTypes.func,
  joiningWord: PropTypes.string,
};
/**
 * Renders an array of objects, separated by commas.
 *
 * @return {ReactElement}
 */
export default function CommaSeparatedList({items, renderFn, joiningWord = ''}) {
  if (!items || items.length === 0) {
    return null;
  }

  const total = items.length;
  return (
    <>
      {
        map(items, (r, idx) => {
          let glue = '';
          if (total > 1 && (idx + 1) < total) {
            if ((idx + 2) < total) {
              glue = ', ';
            } else {
              glue = joiningWord ? ` ${joiningWord} ` : ', ';
            }
          }
          const item = renderFn ? renderFn(r, idx) : r;
          return (
            <span key={r?.id || idx}>{item}{glue}</span>
          );
        })
      }
    </>
  );
}
CommaSeparatedList.propTypes = propTypes;
