import {findObject} from 'components/DrugTarget/Item';
import Link from 'components/links/Link';

export default function LabelOccurrenceItem({id, object1, object2, sources, subjectId}) {
  const obj = findObject(object1, object2, subjectId);
  return (
    <li>
      <Link href={`/linkAnnotation/${id}`}>{obj.symbol || obj.name}</Link>
      {' '}
      <span className="text-muted small">(source: {sources})</span>
    </li>
  );
}
