import PropTypes from 'prop-types';

import Tag from 'components/Tag/index';

const propTypes = {
  className: PropTypes.string,
};

export default function TestingInfoTag({className}) {
  return (
    <Tag
      className={className}
      tooltipText="Learn more about the Testing Info tag"
      tooltipUrl="/page/clinicalGuidelineLegend#clinical-guideline-annotation-tags"
    >
      Testing Info
    </Tag>
  );
}
TestingInfoTag.propTypes = propTypes;
