import InlineSVG from 'react-inlinesvg';
import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

const propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
  inline: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  ariaLabel: PropTypes.string,
  ariaHidden: PropTypes.bool,
};

/**
 * This is a span wrapper around react-inlinesvg because we need to additional CSS to support bad
 * behavior by IE.
 *
 * @param {object} props - arguments
 * @param {string} props.src - the SVG source code, usually imported from icons package
 * @param {string} [props.className] - any additional classes to apply to the <svg> element
 * @param {boolean} [props.inline] - true to make this display inline, will do "flex" otherwise
 * @param {string} [props.title] - title for SVG
 * @param {string} [props.description] - description of SVG
 * @param {string} [props.ariaLabel]
 * @param {string} [props.ariaHidden]
 */
const Svg = ({src, className, inline, title, description, ariaLabel, ariaHidden}) => {
  const attribs = {};
  if (ariaHidden) {
    attribs['aria-hidden'] = true;
  } else {
    attribs.role = 'img';
  }
  return (
    <span className={clsx('isvg', className, {'isvg--inline': inline})}>
      <InlineSVG
        src={src}
        cacheRequests={true}
        title={title}
        description={description}
        aria-label={ariaLabel}
        uniquifyIDs={true}
        {...attribs}
      />
    </span>
  );
};
Svg.propTypes = propTypes;
export default Svg;
