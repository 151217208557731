import {map, reduce} from 'lodash';
import PropTypes from 'prop-types';

import 'components/Diff/Json.scss';

const propTypes = {
  diffs: PropTypes.arrayOf(PropTypes.object),
};
/**
 * Take an array of diff data from the `diff` package and display it as one big block of text.
 *
 * @param {object} props - props container
 * @param {Array<object>} props.diffs - diff result array
 */
export default function DiffWords({diffs = []}) {
  const nAdded = reduce(diffs, (a, v) => a + (v.added ? 1 : 0), 0);
  const nRemoved = reduce(diffs, (a, v) => a + (v.removed ? 1 : 0), 0);

  const renderWord = (word) => {
    const text = word.value;
    if (word.added) {
      return <ins>{text}</ins>;
    } else if (word.removed) {
      return <del>{text}</del>;
    } else {
      return text;
    }
  };
  
  if (!nAdded && !nRemoved) {
    return <div className="text-muted font-italic ml-3">No diffs found</div>;
  }

  return (
    <div className="diff">
      <p><ins>Added:</ins> {nAdded}, <del>Removed:</del> {nRemoved}</p>
      {map(diffs, (w, i) => <span key={`${w.value}${i}`}>{renderWord(w)}</span>)}
    </div>
  );
}
DiffWords.propTypes = propTypes;
