import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {format as dateFormat, parseISO} from 'date-fns';
import {filter, find, findIndex, toNumber} from 'lodash';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';

import Button from 'components/Button';
import DeleteButton from 'components/Button/Delete';
import HistoryForm from 'components/History/Form';
import SimpleTable from 'components/Table/Simple';
import {fixedColumnStyle, flexColumnStyle} from 'components/Table/columnHelpers';
import useEditContext from 'components/edit/EditContext';
import EditControls from 'components/edit/EditControls';
import KyError from 'components/errors/KyError';
import useModalService from 'components/shared/ModalService';
import useAppContext from 'conf/AppContext';


const propTypes = {
  parentCls: PropTypes.string.isRequired,
  parentId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  history: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string,
    type: PropTypes.string,
    description: PropTypes.string,
    visibleToAll: PropTypes.bool,
    user: PropTypes.shape({
      userId: PropTypes.string,
      name: PropTypes.string,
    }),
    id: PropTypes.number,
  })),
  /**
   * Function to call if history is modified.
   */
  callOnChange: PropTypes.func,
};
/**
 * Renders history events in a table.
 * Supports editing on preview.
 *
 * @return {JSXElement}
 */
export default function HistoryTable({parentCls, parentId, history = [], callOnChange}) {
  const appContext = useAppContext();
  const editContext = useEditContext();
  const modalService = useModalService();
  const [events, setEvents] = useState([]);
  const [kyError, setKyError] = useState(false);

  useEffect(() => {
    setEvents(history);
  }, [parentCls, parentId]);

  const columns = [
    {
      id: 'date',
      accessor: (row) => parseISO(row.date),
      Header: 'Date',
      Cell: HistoryDateCell,
      sortable: true,
      sortType: 'datetime',
      ...fixedColumnStyle(100),
    },
    {
      accessor: 'type',
      Header: 'Type',
      ...fixedColumnStyle(90),
    },
    {
      accessor: 'description',
      Header: 'Comment',
      ...flexColumnStyle(120),
    },
  ];

  let newEventButton = '';
  if (appContext.isPreview) {
    const removeEvent = (id) => {
      setEvents(filter(events, (evt) => evt.id !== id));
      if (callOnChange) {
        callOnChange();
      }
    };
    const deleteSuccessHandler = (id) => {
      removeEvent(id);
      toast.success('Delete successful.');
    };
    const deleteFailureHandler = async (id, error) => {
      if (error?.response?.status === 404) {
        removeEvent(id);
        toast.info('Already deleted');
      } else {
        appContext.toastError(<KyError kyError={error} />);
      }
    };

    const editSuccessHandler = (event) => {
      const idx = findIndex(events, (e) => e.id === event.id);
      if (idx === -1) {
        events.push(event);
        toast.success('Event added.', {autoClose: 2000});
      } else {
        events.splice(idx, 1, event);
        toast.success('Event updated.', {autoClose: 2000});
      }
      setEvents([...events]);
      modalService.close();
    };
    const closeHandler = () => {
      modalService.close();
    };

    const openEditor = async (evt) => {
      evt.preventDefault();
      evt.stopPropagation();
      let id;
      let event;
      const closestAncestor = evt.target.closest('button[data-id]');
      if (closestAncestor && closestAncestor.getAttribute('data-id')) {
        id = toNumber(closestAncestor.getAttribute('data-id'));
        if (id) {
          event = find(events, (e) => e.id === id);
          if (!event) {
            appContext.toastError(`Cannot find event ID ${id}!  Try reloading the page.`);
            return;
          }
        }
      }

      if (kyError) {
        setKyError(false);
      }

      modalService.open({
        closeHandler,
        style: {
          width: '30em',
        },
        content: (
          <HistoryForm
            parentCls={parentCls}
            parentId={parentId}
            event={event}
            onSave={editSuccessHandler}
            onCancel={closeHandler}
          />
        ),
      });
    };

    newEventButton = (
      <EditControls>
        <Button actionHandler={openEditor}>
          <FontAwesomeIcon icon="plus" /> Add Event
        </Button>
      </EditControls>
    );

    const makeControls = (row) => {
      const {type, id, majorChange} = row;
      return (
        <>
          <Button
            key={`edit${id}`}
            data-id={id}
            actionHandler={openEditor}
            iconOnly={true}
            className="text-primary"
            disabled={type === 'Approve' || majorChange}
            aria-label="edit"
          >
            <FontAwesomeIcon icon="edit" />
          </Button>
          <DeleteButton
            url={`curation/${parentCls}/${parentId}/history/${id}`}
            id={id}
            onSuccess={deleteSuccessHandler}
            onFailure={deleteFailureHandler}
            label="Delete event"
            iconOnly={true}
          />
        </>
      );
    };

    if (editContext.isEditMode) {
      columns.push({
        accessor: 'user.name',
        Header: 'User',
        ...fixedColumnStyle(100),
      });
      columns.push({
        accessor: 'visibleToAll',
        Header: 'Public',
        // eslint-disable-next-line
        Cell: ({value}) => (value ? <FontAwesomeIcon icon="eye" />
          : <FontAwesomeIcon icon="eye-slash" className="text-secondary" />),
        ...fixedColumnStyle(80),
        className: 'text-center',
      });
      columns.push({
        id: 'editControls',
        accessor: makeControls,
        Header: 'Edit',
        ...fixedColumnStyle(90),
        className: 'text-center',
      });
    }
  }

  if (!events || events.length === 0) {
    return (
      <>
        <p className="empty">No history available.</p>
        { newEventButton }
      </>
    );
  }

  const isOnLive = (e) => !Object.prototype.hasOwnProperty.call(e, 'visibleToAll');

  return (
    <>
      { kyError ? <KyError kyError={kyError} /> : '' }
      { newEventButton }
      <SimpleTable
        className="table-sm"
        canResizeColumns={false}
        // reactTableProps
        tableProps={{
          initialState: {
            sortBy: [{
              id: 'date',
              desc: true,
            }],
          },
        }}
        columns={columns}
        data={filter(events, (e) => isOnLive(e) || e.visibleToAll || editContext.isEditMode)}
      />
    </>
  );
}
HistoryTable.propTypes = propTypes;

function HistoryDateCell({value}) {
  return <span title={dateFormat(value, 'M/d/yyyy H:mm:ss')}>{dateFormat(value, 'M/d/yyyy')}</span>;
}
