import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet-async';

import {renderInfoLink} from 'components/templates/utils';


const propTypes = {
  title: PropTypes.string.isRequired,
  /**
   * Display this instead of title in body if provided.
   */
  titleJsx: PropTypes.node,
  helpLink: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

/**
 * Basic readable-width page template.
 */
export default function ReadableWidthTemplate({title, titleJsx, helpLink, className, children}) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="page-wrap">
        <main className={clsx('container', 'readable-width', className)}>
          <header>
            <h1 className="pageTitle">
              {titleJsx || title}
              {renderInfoLink(helpLink)}
            </h1>
          </header>
          <div>
            {children}
          </div>
        </main>
      </div>
    </>
  );
}
ReadableWidthTemplate.propTypes = propTypes;
