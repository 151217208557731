import App from 'conf/app';


/**
 * Helper function for setting the 'top' property of Sticky components so it doesn't get
 * overlapped by the sticky header.
 *
 * @param {number} [additionalOffset]
 * @return {number}
 */
export default function getYOffset(additionalOffset = 0) {
  if (!App.inBrowser) {
    return 0;
  }
  // eslint-disable-next-line no-undef
  return document.querySelector('.page-header')
    // eslint-disable-next-line no-undef
    ? document.querySelector('.page-header').clientHeight + additionalOffset
    : 0;
}
