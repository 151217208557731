import ClinicalAnnotationsBarChart from 'components/ClinicalAnnotation/BarChart';
import ClinicalAnnotationTable from 'components/ClinicalAnnotation/Table';
import Loading from 'components/Loading';
import InlinePage from 'components/Page/Inline';
import {TableType} from 'components/Table/utils';
import KyError from 'components/errors/KyError';
import AlleleNote from 'components/shared/AlleleNote';
import FullFluidWidthTemplate from 'components/templates/FullFluidWidth';
import {useGet} from 'helpers/KyHooks';
import './ClinicalAnnotations.scss';

export default function ClinicalAnnotationsPage() {
  const {response, error} = useGet('site/clinicalAnnotations/overview', {cacheResponse: true});

  let content = <Loading />;
  let barChart = <div className="caFigure caFigure--loading figure figure--floatRight"><Loading /></div>;

  if (response) {
    content = (
      <ClinicalAnnotationTable
        data={response.data.clinicalAnnotations}
        type={TableType.SIMPLE}
        canEditColumns={false}
        canHideFilters={false}
        canShowFullscreen={false}
        disableExport={true}
      />
    );
    barChart = (
      <ClinicalAnnotationsBarChart
        pgxLevels={response?.data}
        className="figure--floatRight"
      />
    );
  } else if (error) {
    content = <KyError kyError={error} />;
  }

  return (
    <FullFluidWidthTemplate className="clinicalAnnotationsPage" title="Clinical Annotations">
      <div className="container mb-5">
        <div className="readable-width">
          {barChart}
          <InlinePage id="clinicalAnnotationsHeader" />
          <AlleleNote />
        </div>
      </div>
      {content}
    </FullFluidWidthTemplate>
  );
}
