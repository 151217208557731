import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import './NewTab.scss';
import {extractProps} from 'utils/propUtils';


const propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  transform: PropTypes.string,
};

export default function NewTabIcon({className, size, transform, ...otherProps}) {
  const params = {};
  if (size) {
    params.size = size;
  }
  if (transform) {
    params.transform = transform;
  }
  return (
    <FontAwesomeIcon
      icon={['fad', 'arrow-up-right-from-square']}
      className={clsx('newTab', className)}
      {...params}
      {...extractProps(otherProps)}
    />
  );
}
NewTabIcon.propTypes = propTypes;
