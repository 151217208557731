/* eslint-disable import/prefer-default-export */

import {forEach, isNil} from 'lodash';

/**
 * Converts an object representing a query string to a string.
 *
 * @param {object|URLSearchParams} searchParams
 * @return {string}
 */
export function stringifySearchParams(searchParams) {
  if (!searchParams) {
    return '';
  }
  if (searchParams instanceof URLSearchParams) {
    return searchParams.toString();
  }
  const params = new URLSearchParams();
  forEach(Object.entries(searchParams), ([key, value]) => {
    if (Array.isArray(value)) {
      forEach(value, (v) => params.append(key, v));
    } else if (!isNil(value)) {
      params.append(key, value.toString());
    }
  });
  return params.toString();
}


/**
 * Converts a string representing a query string to an object.
 *
 * @param {string|URLSearchParams} searchParams
 * @return {object}
 */
export function parseSearchParams(searchParams) {
  if (!searchParams) {
    return {};
  }
  let urlSearchParams;
  if (searchParams instanceof URLSearchParams) {
    urlSearchParams = searchParams;
  } else {
    urlSearchParams = new URLSearchParams(searchParams);
  }
  const params = {};
  forEach(Array.from(urlSearchParams.keys()), (key) => {
    const values = urlSearchParams.getAll(key);
    if (values.length > 1) {
      params[key] = values;
    } else {
      params[key] = values[0];
    }
  });
  return params;
}


/**
 * Replace the search param in a fragment (path + query string).
 *
 * @param {string} fragment
 * @param {string} param
 * @param {string} value
 * @return {string}
 */
export function replaceSearchParam(fragment, param, value) {
  const vals = fragment.split('?');
  let params;
  if (vals.length === 2) {
    params = parseSearchParams(vals[1]);
  } else {
    params = {};
  }
  params[param] = value;
  return vals[0] + '?' + stringifySearchParams(params);
}
