import clsx from 'clsx';
import {map, uniqueId} from 'lodash';
import {Fragment, lazy, Suspense, useCallback, useEffect, useRef, useState} from 'react';

import Loading from 'components/Loading';
import ColumnHeader from 'components/Table/ColumnHeader';
import {tableLayoutProps} from 'components/Table/frameProps';
import {renderTableRow} from 'components/Table/utils';

// noinspection JSCheckFunctionSignatures
const SimpleBar = lazy(() =>
  import(/* webpackChunkName: "table" */'simplebar-react'));


const propTypes = {
  ...tableLayoutProps,
};

export default function SimpleTableLayout({
  // from TableFrame
  rowClassNameFn,
  showFilters,
  isFullscreen,
  hasStickyColumns,
  hasFilters,
  memoizedColumns,
  memoizedMaxDepth,
  renderNoResultsFoundFn,
  // from react-table
  getTableProps, // table props from react-table
  getTableBodyProps, // table body props from react-table
  headerGroups, // headerGroups if your table have groupings
  rows, // rows for the table based on the data passed
  prepareRow, // Prepare the row (this function need to called for each row before getting the row props)
  state, // Controlled state of the table
}) {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [tableId] = useState(uniqueId('simpleTable'));
  const [scrollState, setScrollState] = useState({width: 'auto', isOverflow: false});
  const layoutRef = useRef(null);
  const topScrollerRef = useRef(null);
  const tableScrollerRef = useRef(null);


  const calculateWidth = useCallback(() => {
    // eslint-disable-next-line prefer-destructuring
    let width = scrollState.width;
    // eslint-disable-next-line prefer-destructuring
    let isOverflow = scrollState.isOverflow;
    if (tableScrollerRef.current && tableScrollerRef.current?.scrollWidth) {
      width = tableScrollerRef.current.scrollWidth;

      // if table has horizontal overflow, we need to apply some additional styles
      // don't change if clientWidth == scrollWidth
      // because scrollWidth == clientWidth when we're in overflow mode and next check will remove overflow mode
      if (layoutRef.current && layoutRef.current?.clientWidth < width) {
        isOverflow = true;
      } else if (layoutRef.current && layoutRef.current?.clientWidth > width) {
        isOverflow = false;
      }
    }
    if (width !== scrollState.width) {
      setScrollState({width, isOverflow});
    }
  }, [scrollState.width]);

  useEffect(() => {
    window.removeEventListener('resize', calculateWidth);
    if (isFullscreen) {
      return;
    }
    // update width when window size changes
    window.addEventListener('resize', calculateWidth);

    // add onScroll event for syncing scroll position
    if (topScrollerRef.current) {
      topScrollerRef.current.onscroll = (e) => {
        tableScrollerRef.current.scrollLeft = e.target.scrollLeft;
      };
    }

    if (tableScrollerRef.current) {
      tableScrollerRef.current.onscroll = (e) => {
        if (topScrollerRef.current) {
          topScrollerRef.current.scrollLeft = e.target.scrollLeft;
        }
      };
    }

    // clean up the event listener
    return () => window.removeEventListener('resize', calculateWidth);
  }, [scrollState.width, isFullscreen]);

  useEffect(() => {
    calculateWidth();
  });


  const headerRowRef = useCallback((node) => {
    if (node !== null) {
      setHeaderHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const renderResults = () => {
    if (rows.length === 0) {
      return renderNoResultsFoundFn();
    }
    return map(rows, (row) => {
      prepareRow(row);
      const className = rowClassNameFn ? rowClassNameFn(row) : '';
      const rowProps = row.getRowProps({className});
      return (
        <tr {...rowProps} key={rowProps?.key}>
          {renderTableRow(row)}
        </tr>
      );
    });
  };

  return (
    <div ref={layoutRef}>
      <Suspense fallback={<Loading />}>
        {scrollState.isOverflow && (
          <SimpleBar scrollableNodeProps={{ref: topScrollerRef}} autoHide={false}>
            <div style={{height: '11px', width: scrollState.width}} />
          </SimpleBar>
        )}
        <SimpleBar scrollableNodeProps={{ref: tableScrollerRef}} autoHide={false}>
          <table
            id={tableId}
            {...getTableProps({
              className: clsx('reactTable', 'simpleTable', {
                'reactTable--stickyColumns': hasStickyColumns,
              }),
              style: {minWidth: 'unset'},
            })}
          >
            <thead>{
              map(headerGroups, (headerGroup, hdrIdx) => {
                const lastRow = hdrIdx === headerGroups.length - 1;
                const isGroup = headerGroups.length > 1 && !lastRow;
                const headerGroupProps =  headerGroup.getHeaderGroupProps({
                  className: clsx({reactTable__group: isGroup}),
                });
                return (
                  <Fragment key={hdrIdx}>
                    <tr
                      ref={headerRowRef}
                      {...headerGroupProps}
                      key={headerGroupProps?.key}
                    >
                      {map(headerGroup.headers, (column, idx) => (
                        <ColumnHeader
                          key={column.id}
                          headerGroup={headerGroup}
                          isGroup={isGroup}
                          memoizedMaxDepth={memoizedMaxDepth}
                          memoizedColumns={memoizedColumns}
                          column={column}
                          colNum={idx}
                        />
                      ))}
                    </tr>
                    {lastRow && hasFilters && renderFilters(headerGroup, hdrIdx, isFullscreen, headerHeight)}
                  </Fragment>
                );
              })
            }
            </thead>
            <tbody {...getTableBodyProps()}>{renderResults()}</tbody>
          </table>
        </SimpleBar>
      </Suspense>
    </div>
  );
}
SimpleTableLayout.propTypes = propTypes;

function renderFilters(headerGroup, i, isFullscreen, headerRowHeight) {
  const top = isFullscreen ? headerRowHeight : 0;
  return (
    <tr {...headerGroup.getHeaderGroupProps({className: 'tr--filter', style: {top}})} key={`${i}-filters`}>
      {map(headerGroup.headers, (column) => {
        const props = {
          className: clsx(column.filterClassName || column.headerClassName || column.className),
        };
        if (column.style) {
          props.style = column.style;
        }
        return (
          <th {...column.getHeaderProps(props)} key={column.id}>
            {column.canFilter ? column.render('Filter') : null}
          </th>
        );
      })}
    </tr>
  );
}
