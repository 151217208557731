import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {map, toLower, toUpper} from 'lodash';
import PropTypes from 'prop-types';
import {useEffect, useRef, useState} from 'react';
import {Virtuoso} from 'react-virtuoso';
import './index.scss';

import Button from 'components/Button';
import Loading from 'components/Loading';
import SearchHit from 'components/Search/Hit';
import KyError from 'components/errors/KyError';
import ReadableWidthTemplate from 'components/templates/ReadableWidth';
import useAppContext from 'conf/AppContext';


const typeToName = {
  geneticInfoChemicals: 'Molecules with Genetic Information',
  annotatedDrugs: 'Drugs with Variant Annotations',
  annotatedGenes: 'Genes with Variant Annotations',
  pediatricSummaryDrugs: 'Drugs with Pediatric Summaries',
};
const defaultOffsetStepSize = 25;
// noinspection SpellCheckingInspection
const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ#'.split('');


const propTypes = {
  listName: PropTypes.string,
  prefix: PropTypes.string,
};
/**
 * Renders site-wide list pages.
 *
 * @param {object} props - props container
 * @param {string} props.listName
 * @param {string} [props.prefix]
 * @return {JSX.Element}
 */
export default function Browse({listName, prefix = 'a'}) {
  const appContext = useAppContext();
  const [counts, setCounts] = useState({});
  const [countError, setCountError] = useState(null);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(null);
  const [query, setQuery] = useState({prefix, offset: 0});
  const virtuosoRef = useRef(null);

  const handleLetter = (letter) => () => {
    setData([]);
    setQuery({prefix: letter, offset: 0});
    window.history.pushState({}, '', `${listName}?prefix=${encodeURIComponent(letter)}`);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const curPrefix = searchParams.get('prefix') || 'a';
    if (curPrefix !== query.prefix) {
      setData([]);
      setQuery({prefix: curPrefix, offset: 0});
    }
  });

  useEffect(() => {
    const abortController = new AbortController();
    const loadCounts = async () => {
      try {
        const rez = await appContext.api.get(`site/browse/counts/${listName}`, {
          parseJson: true,
          cacheResponse: true,
        }, abortController);
        setCounts(rez.data);
        if (prefix === 'a' && !rez.data[toUpper(prefix)]) {
          // default start alpha has no results, go to next one with data
          for (let x = 1; x < letters.length; x += 1) {
            const alpha = letters[x];
            if (rez.data[alpha]) {
              const lcAlpha = toLower(alpha);
              setData([]);
              setQuery({prefix: lcAlpha, offset: 0});
              window.history.pushState({}, '', `${listName}?prefix=${encodeURIComponent(lcAlpha)}`);
              break;
            }
          }
        }
      } catch (err) {
        setCountError(<KyError kyError={err} />);
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    loadCounts();
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      try {
        const rez = await appContext.api.post(`site/browse/${listName}`, {
          json: {
            prefix: query.prefix,
            from: query.offset,
            sort: true,
          },
          parseJson: true,
        });
        if (mounted) {
          if (rez.data) {
            setData([...data, ...rez.data.hits]);
            setTotal(rez.data.total);
          }
        }
      } catch (err) {
        if (mounted) {
          setError(<KyError kyError={err} />);
        }
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    loadData();
    return () => {
      mounted = false;
    };
  }, [listName, query.prefix, query.offset]);


  const renderLetters = () => {
    let lettersContent;
    if (countError) {
      lettersContent = countError;
    } else {
      lettersContent = (
        <ul className="letter-filter">
          {map(letters, (letter) => {
            if (counts?.[letter] > 0) {
              const lcLetter = letter.toLowerCase();
              if (lcLetter === query.prefix) {
                return (
                  <li key={letter}>
                    <b className="mr-1">{letter}</b>
                    <small className="text-muted">({counts[letter]})</small>
                  </li>
                );
              }
              return (
                <li key={letter}>
                  <Button actionHandler={handleLetter(lcLetter)} className="btn-link mr-1">{letter}</Button>
                  <small className="text-muted">({counts[letter]})</small>
                </li>
              );
            } else {
              return (
                <li key={letter}><span className="text-muted">{letter}</span></li>
              );
            }
          })}
        </ul>
      );
    }
    return lettersContent;
  };

  const renderItems = (hit) => <SearchHit key={hit.id} hit={hit} />;
  const scrollToTop = () => virtuosoRef.current.scrollToIndex({index: 0, behavior: 'auto'});

  const loadMore = () => {
    const newOffset = query.offset + defaultOffsetStepSize;
    if (newOffset <= total) {
      setQuery({prefix: query.prefix, offset: newOffset});
    }
  };

  let content = <Loading />;
  const label = typeToName[listName] || listName;
  if (data) {
    const msg = `${total} results for ${toLower(label)} that start with the letter "${toUpper(query.prefix)}".`;

    content = (
      <>
        <p className="intro">{msg}</p>
        <Virtuoso
          style={{height: 'calc(100vh - 350px)', minHeight: '420px', width: '100%'}}
          ref={virtuosoRef}
          data={data}
          useWindowScroll={true}
          itemContent={(index, item) => renderItems(item)}
          endReached={loadMore}
          components={{
            // eslint-disable-next-line react/no-unstable-nested-components
            Footer: () => (
              <div className="mt-3 text-right">
                <Button key="go to top" actionHandler={scrollToTop} className="btn-link">
                  <FontAwesomeIcon icon={['fas', 'arrow-to-top']} />back to top
                </Button>
              </div>
            ),
          }}
        />
      </>
    );
  } else if (error) {
    content = <KyError kyError={error} />;
  }

  return (
    <ReadableWidthTemplate title={`Browse ${label}`} className="browsePage">
      {renderLetters()}
      {content}
    </ReadableWidthTemplate>
  );
}
Browse.propTypes = propTypes;
