import clsx from 'clsx';
import {domToReact} from 'html-react-parser';
import {filter, forEach, isString, remove} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Measure from 'react-measure';

import InfoIcon from 'components/icons/Info';

export default class StyledTable extends React.Component {
  static displayName = 'Styled Table';

  static propTypes = {
    isInSidebarLayout: PropTypes.bool,
    tableHTML: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    isInSidebarLayout: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      width: null,
    };
  }

  tableDidScroll = (ev) => {
    this.dummy.scrollLeft = ev.target.scrollLeft;
  };

  dummyDidScroll = (ev) => {
    this.table.scrollLeft = ev.target.scrollLeft;
  };

  onResize = (contentRect) => {
    this.setState({width: contentRect.bounds.width});
  };

  render() {
    const {isInSidebarLayout, tableHTML} = this.props;
    // convert HTML to React
    const dtr = domToReact(tableHTML);
    // must remove empty strings from in between table tags or React will complain
    // first remove empty strings from top level JSX nodes
    const tableDom = Array.isArray(dtr) ? filter(dtr, (node) => !isString(node)) : [dtr];
    // then remove empty strings from their children
    // eslint-disable-next-line lodash/collection-method-value
    const tableJsx = forEach(tableDom,
      // eslint-disable-next-line lodash/prefer-immutable-method
      (node) => { remove(node.props.children, (n) => isString(n)); });
    return (
      <div className={clsx('stylized-table-container', isInSidebarLayout ? 'sidebar-table' : null)}>
        <div className="table-scroll-text-section">
          <InfoIcon />
          <div className="table-scroll-text">Scroll for more</div>
        </div>
        <div
          ref={(dummy) => { this.dummy = dummy; }}
          className="table-scrollbar-container"
          onScroll={this.dummyDidScroll}
        >
          <div
            className="table-scrollbar-dummy"
            style={{width: this.state.width}}
          />
        </div>
        <div
          className="stylized-table"
          ref={(table) => { this.table = table; }}
          onScroll={this.tableDidScroll}
        >
          <Measure
            bounds={true}
            onResize={this.onResize}
          >
            {({measureRef}) => (
              <table ref={measureRef}>{tableJsx}</table>
            )}
          </Measure>
        </div>
      </div>
    );
  }
}
