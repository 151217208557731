import clsx from 'clsx';
import {isArray, isString, map} from 'lodash';
import PropTypes from 'prop-types';
import {useEffect, useMemo, useState} from 'react';
import {useFormContext} from 'react-hook-form';

import './CheckboxGroup.scss';
import CheckboxLabeledInput from 'components/form/CheckboxLabeledInput';
import TextInput from 'components/form/TextInput';
import logger from 'conf/Logger';
import {formOptionsArray} from 'conf/types';
import {buildCustomValidation, checkErrors, renderLabel} from 'utils/formUtils';

const OTHER_VALUE = 'other';

const propTypes = {
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  options: formOptionsArray,
  values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  help: PropTypes.string,
  helpLink: PropTypes.string,
  validation: PropTypes.object,
  required: PropTypes.bool,
  /** Marks input field as read-only.  Will submit. */
  readOnly: PropTypes.bool,
  /** Disables input field.  Will not submit. */
  disabled: PropTypes.bool,
  className: PropTypes.string,
  inline: PropTypes.bool,
  /**
   * If provided, an "other" checkbox will be added. A text input field will be created to capture the other value using
   * this value as it's field name.
   */
  otherFieldName: PropTypes.string,
  /**
   * Default value of other text input field.
   */
  otherFieldValue: PropTypes.string,
  /**
   * If `otherFieldName` is provided, this will require the other text input to be specified.
   * This uses RHF validation.
   */
  otherRequired: PropTypes.bool,
};
/**
 * This renders a group of checkboxes for use with react-hook-form.
 * It supports an optional "Other" checkbox with associated input field.
 */
export default function FormCheckboxGroup({label, name, options, values, help, helpLink, validation, required = false,
  readOnly = false, disabled = false, className, inline = false,
  otherFieldName, otherFieldValue, otherRequired = false}) {
  const {formState: {errors}, getValues, unregister} = useFormContext();
  const [otherInputDisabled, setOtherInputDisabled] = useState(true);

  const customValidation = useMemo(() => buildCustomValidation({validation, required}),
    [validation, required]);

  useEffect(() => {
    if (!disabled && (isChecked(name, OTHER_VALUE, values, getValues) || !otherInputDisabled)) {
      setOtherInputDisabled(false);
    } else {
      setOtherInputDisabled(true);
    }
  }, [disabled, values]);

  useEffect(() => {
    if (readOnly && disabled) {
      unregister(name, {keepDefaultValue: true});
    }
  }, [readOnly, disabled]);

  if (readOnly && disabled) {
    logger.error('FormCheckboxGroup cannot be both readOnly and disabled');
    return <div className="text-danger">error - check console</div>;
  }

  const addOtherOption = () => {
    if (!otherFieldName) {
      return null;
    }

    const otherCbChangeHandler = (event) => {
      const isDisabled = disabled || !event.target.checked;
      setOtherInputDisabled(isDisabled);
      if (isDisabled) {
        errors[otherFieldName] = null;
      }
    };

    const otherFieldValidator = (otherValue) => {
      if (getValues(name)?.includes(OTHER_VALUE)) {
        if (!otherValue || !otherValue.trim().length > 0) {
          return 'Other value is required.';
        }
      }
    };

    return (
      <div className={clsx('checkboxGroup__other', {'checkboxGroup__other--inline': inline})}>
        <CheckboxLabeledInput
          label="Other:"
          name={name}
          value={OTHER_VALUE}
          defaultChecked={isChecked(name, OTHER_VALUE, values, getValues)}
          validation={customValidation}
          disabled={disabled}
          inline={inline}
          onChange={otherCbChangeHandler}
        />
        <TextInput
          name={otherFieldName}
          defaultValue={otherFieldValue}
          validation={otherRequired ? {validate: otherFieldValidator} : undefined}
          readOnly={readOnly}
          disabled={otherInputDisabled}
          className="checkboxGroup__otherInput form-control-sm"
        />
      </div>
    );
  };

  const fieldsetProps = {};
  if (readOnly) {
    fieldsetProps.disabled = true;
  }
  const {invalid, errorMsg} = checkErrors({disabled, errors, name: [name, otherFieldName]});
  return (
    <fieldset
      className={clsx('checkboxGroup form-group', className, {
        'is-invalid': invalid,
        'text-muted': disabled || readOnly,
      })}
      {...fieldsetProps}
    >
      <label
        className={clsx({'checkboxGroup__label--inline': inline, 'text-danger': invalid})}
      >
        {renderLabel(label, helpLink)}
      </label>
      {map(options, (o) => {
        const optionLabel = o?.label || o;
        const optionValue = o?.value || o;
        const fldId = `${name}-${optionValue}`;
        return (
          <CheckboxLabeledInput
            key={fldId}
            label={optionLabel}
            name={name}
            value={optionValue}
            defaultChecked={isChecked(name, optionValue, values, getValues)}
            validation={customValidation}
            disabled={disabled}
            inline={inline}
          />
        );
      })}
      {addOtherOption()}
      {help && <small className="form-text text-muted">{help}</small>}
      {errorMsg}
    </fieldset>
  );
}
FormCheckboxGroup.propTypes = propTypes;

function isChecked(name, val, values, getValues) {
  if (values?.includes(val)) {
    return true;
  }
  const vals = getValues(name);
  if (isArray(vals)) {
    return vals.includes(val);
  } else if (isString(vals)) {
    return vals === val;
  } else {
    // can resolve to true/false
    return vals;
  }
}
