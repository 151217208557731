import PropTypes from 'prop-types';

import FullWidthResourceTemplate from 'components/templates/FullWidthResource';
import VariantAnnotationDetail from 'components/VariantAnnotation/Detail';
import Loading from 'components/Loading';
import KyError from 'components/errors/KyError';
import {useGet} from 'helpers/KyHooks';
import useAppContext from 'conf/AppContext';
import NotFound from 'components/shared/error/not_found';

export default function VariantAnnotationPage({id}) {
  const appContext = useAppContext();
  const {response, error} = useGet(
    `site/variantAnnotation/${id}`,
    {searchParams: {view: 'most'}, cacheResponse: !appContext.isPreview},
  );
  const variantAnnotation = response?.data;
  let content = <Loading />;
  if (error) {
    if (error?.response?.status === 404) {
      return <NotFound />;
    }
    content = <KyError kyError={error} />;
  } else {
    content = variantAnnotation?.id && <VariantAnnotationDetail variantAnnotation={response.data} />;
  }
  const pageName = !variantAnnotation?.location ? 'Loading…' : `Annotation of ${variantAnnotation?.location?.displayName}`;

  return (
    <FullWidthResourceTemplate
      id={id}
      name={pageName}
      type="Variant Annotation"
    >
      {content}
    </FullWidthResourceTemplate>
  );
}
VariantAnnotationPage.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
