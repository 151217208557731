import {find, includes} from 'lodash';
import PropTypes from 'prop-types';

import {baseAccIdColumn} from 'components/Table/Cell/AccId';
import StringsCell from 'components/Table/Cell/Strings';
import {baseVariantLocationColumn} from 'components/Table/Cell/VarianLocation';
import MultiSelectFilter, {multiselectOrFilter} from 'components/Table/Filter/MultiSelect';
import SimpleTable from 'components/Table/Simple';
import VirtualizedTable from 'components/Table/Virtualized';
import {
  defaultPediatricColumn,
  defaultRelatedChemicalsColumn,
  defaultViewLinkColumn,
  fixedColumnStyle,
  flexColumnStyle,
  getCheckboxCol,
} from 'components/Table/columnHelpers';
import {sortArrayOfStrings} from 'components/Table/comparators';
import {renderTotalDataFn, TableType} from 'components/Table/utils';
import ClinicalAnnotationLevelTag from 'components/Tag/ClinicalAnnotationLevel';


const levelOptions = ['1A', '1B', '2A', '2B', '3', '4'];

const introColumns = [
  defaultViewLinkColumn,
  {
    id: 'level',
    Header: 'Level',
    accessor: (originalRow) => {
      if (originalRow?.properties?.level) {
        return originalRow.properties.level;
      } else if (originalRow?.levelOfEvidence?.term) {
        return originalRow.levelOfEvidence.term;
      }
      return '';
    },
    Cell: LevelCell,
    sortable: true,
    Filter: (props) => <MultiSelectFilter {...props} options={levelOptions} />,
    filter: multiselectOrFilter,
    ...fixedColumnStyle(135),
  },
];
const mainColumns = [
  {
    id: 'variant',
    Header: 'Variant',
    accessor: (originalRow) => {
      if (originalRow?.properties?.location) {
        return originalRow.properties.location;
      } else if (originalRow?.location) {
        return originalRow.location;
      }
    },
    ...baseVariantLocationColumn,
    ...flexColumnStyle({width: 150}),
  },
  {
    id: 'gene',
    Header: 'Gene',
    accessor: (originalRow) => {
      if (originalRow?.properties?.location?.genes) {
        return originalRow.properties.location.genes;
      } else if (originalRow?.location.genes) {
        return originalRow.location.genes;
      }
    },
    ...baseAccIdColumn,
    ...flexColumnStyle({width: 110}),
  },
  defaultRelatedChemicalsColumn,
  {
    id: 'type',
    Header: 'Phenotype Categories',
    accessor: (originalRow) => {
      if (originalRow.categories) {
        return originalRow.categories;
      } else if (originalRow.types) {
        return originalRow.types;
      }
    },
    Cell: StringsCell,
    sortable: true,
    sortType: sortArrayOfStrings,
    Filter: MultiSelectFilter,
    filter: multiselectOrFilter,
    ...flexColumnStyle({width: 150}),
  },
  {
    id: 'phenotype',
    Header: 'Phenotype',
    accessor: (originalRow) => {
      if (originalRow?.properties?.phenotypes) {
        return originalRow?.properties?.phenotypes;
      } else if (originalRow?.relatedDiseases) {
        return originalRow.relatedDiseases;
      }
    },
    ...baseAccIdColumn,
    ...flexColumnStyle({width: 150, grow: 2}),
  },
];

const pediatricOnlyCol = {
  id: 'pediatricOnly',
  Header: <>Pediatric<br />Only</>,
  accessor: (originalRow) => {
    if (originalRow?.tags) {
      return includes(originalRow.tags, 'pediatric-only');
    }
    return false;
  },
  ...getCheckboxCol(130),
};

const initialState = {
  sortBy: [
    {
      id: 'level',
      desc: false,
    },
    {
      id: 'gene',
      desc: false,
    },
  ],
};


const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  pediatricFocus: PropTypes.bool,
  showPediatricColumn: PropTypes.bool,
  showPediatricOnlyColumn: PropTypes.bool,
  type: PropTypes.oneOf([TableType.SIMPLE, TableType.VIRTUALIZED]),
  height: PropTypes.string,

  // standard table props
  id: PropTypes.string,
  canShowFullscreen: PropTypes.bool,
  canEditColumns: PropTypes.bool,
  canHideFilters: PropTypes.bool,
  updatedFilters: PropTypes.arrayOf(PropTypes.object),
  // downloadTableProps
  disableExport: PropTypes.bool,
};
/**
 * Renders a table of clinical annotations.
 */
export default function ClinicalAnnotationTable({data, pediatricFocus = false, showPediatricColumn = true,
  showPediatricOnlyColumn = false, type = TableType.VIRTUALIZED, height,
  id, canShowFullscreen = true, canEditColumns = true, canHideFilters = true, disableExport = false, updatedFilters}) {


  const columns = [];
  if (pediatricFocus) {
    columns.push(...introColumns);
    if (showPediatricColumn) {
      columns.push(defaultPediatricColumn);
    }
    if (showPediatricOnlyColumn) {
      columns.push(pediatricOnlyCol);
    }
    columns.push(...mainColumns);
  } else {
    columns.push(...introColumns);
    columns.push(...mainColumns);
    if (showPediatricColumn) {
      columns.push(defaultPediatricColumn);
    }
    if (showPediatricOnlyColumn) {
      columns.push(pediatricOnlyCol);
    }
  }

  const rowClassNameFn = (row) => {
    const level = find(row.cells, (c) => c.column.id === 'level')?.value;
    return level ? `row-color-ca-level${level}` : '';
  };

  const renderTotalFn = (numVisible) => renderTotalDataFn(data, numVisible);

  if (type === TableType.SIMPLE || data.length <= 4) {
    return (
      <SimpleTable
        id={id}
        columns={columns}
        data={data}
        initialState={initialState}
        className="clinicalAnnotationTable"
        rowClassNameFn={rowClassNameFn}
        canShowFullscreen={canShowFullscreen}
        canEditColumns={canEditColumns}
        canHideFilters={canHideFilters}
        disableExport={disableExport}
        updatedFilters={updatedFilters}
        renderTotalFn={renderTotalFn}
      />
    );
  } else {
    return (
      <VirtualizedTable
        height={height}
        id={id}
        columns={columns}
        data={data}
        initialState={initialState}
        className="clinicalAnnotationTable"
        rowClassNameFn={rowClassNameFn}
        canShowFullscreen={canShowFullscreen}
        canEditColumns={canEditColumns}
        canHideFilters={canHideFilters}
        disableExport={disableExport}
        updatedFilters={updatedFilters}
        renderTotalFn={renderTotalFn}
      />
    );
  }
}
ClinicalAnnotationTable.propTypes = propTypes;


function LevelCell({value}) {
  return <ClinicalAnnotationLevelTag level={value} showHelp={false} />;
}
LevelCell.propTypes = {
  value: PropTypes.string,
};
