import PropTypes from 'prop-types';

import CommaSeparatedList from 'components/shared/CommaSeparatedList';
import Fact from 'components/shared/fact';
import FactSection from 'components/shared/fact_section';
import LitXrefLink from 'components/links/LitXref';
import ResourceLink, {renderResourceLinks} from 'components/links/Resource';
import SimpleTable from 'components/Table/Simple';
import React from 'react';


const propTypes = {
  genes: PropTypes.arrayOf(PropTypes.object),
  chemicals: PropTypes.arrayOf(PropTypes.object),
  diseases: PropTypes.arrayOf(PropTypes.object),
  components: PropTypes.arrayOf(PropTypes.object),
  interactions: PropTypes.arrayOf(PropTypes.object),
};
/**
 * Renders pathway components tab.
 */
export default function PathwayComponentsTab({genes, chemicals, diseases, components, interactions}) {
  return (
    <>
      <FactSection title="Entities in the Pathway">
        <Fact label="Genes">
          {renderResourceLinks(genes)}
        </Fact>

        <Fact label="Drugs/Drug Classes/Metabolites">
          {renderResourceLinks(chemicals)}
        </Fact>

        <Fact label="Diseases">
          {renderResourceLinks(diseases)}
        </Fact>
      </FactSection>

      {renderInteractions(interactions)}
      {renderComponents(components)}
    </>
  );
}
PathwayComponentsTab.propTypes = propTypes;


function renderInteractions(interactions) {
  if (!interactions || interactions.length === 0) {
    return null;
  }
  return (
    <FactSection title="Interactions">
      <SimpleTable
        columns={[
          {
            id: 'from',
            Header: 'Arrow From',
            accessor: 'inputs',
            // eslint-disable-next-line react/prop-types
            Cell: ({value}) => <CommaSeparatedList items={value} renderFn={(r) => <ResourceLink resource={r} />} />,
            sortable: true,
          },
          {
            id: 'to',
            Header: 'Arrow To',
            accessor: 'outputs',
            // eslint-disable-next-line react/prop-types
            Cell: ({value}) => <CommaSeparatedList items={value} renderFn={(r) => <ResourceLink resource={r} />} />,
            sortable: true,
          },
          {
            id: 'controllers',
            Header: 'Controllers',
            accessor: 'controls',
            // eslint-disable-next-line react/prop-types
            Cell: ({value}) => <CommaSeparatedList items={value} renderFn={(r) => <ResourceLink resource={r} />} />,
          },
          {
            id: 'literature',
            Header: 'Literature',
            accessor: 'literature',
            // eslint-disable-next-line react/prop-types
            Cell: ({value}) => <CommaSeparatedList items={value} renderFn={(l) => <LitXrefLink xref={l.xref} />} />,
          },
          {
            id: 'summary',
            Header: 'Summary',
            accessor: 'text',
            // eslint-disable-next-line react/prop-types
            Cell: ({value}) => <span dangerouslySetInnerHTML={{__html: value}} />,
          },
        ]}
        data={interactions}
      />
    </FactSection>
  );
}

function renderComponents(components) {
  if (!components || components.length === 0) {
    return null;
  }
  return (
    <FactSection title="Relationships in Pathway">
      <SimpleTable
        columns={[
          {
            id: 'from',
            Header: 'Arrow From',
            accessor: 'inputs',
            // eslint-disable-next-line react/prop-types
            Cell: ({value}) => <CommaSeparatedList items={value} renderFn={(r) => <ResourceLink resource={r} />} />,
            sortable: true,
          },
          {
            id: 'to',
            Header: 'Arrow To',
            accessor: 'outputs',
            // eslint-disable-next-line react/prop-types
            Cell: ({value}) => <CommaSeparatedList items={value} renderFn={(r) => <ResourceLink resource={r} />} />,
            sortable: true,
          },
          {
            id: 'controllers',
            Header: 'Controllers',
            accessor: 'controls',
            // eslint-disable-next-line react/prop-types
            Cell: ({value}) => <CommaSeparatedList items={value} renderFn={(r) => <ResourceLink resource={r} />} />,
          },
          {
            id: 'literature',
            Header: 'Literature',
            accessor: 'literature',
            // eslint-disable-next-line react/prop-types
            Cell: ({value}) => <CommaSeparatedList items={value} renderFn={(l) => <LitXrefLink xref={l.xref} />} />,
          },
        ]}
        data={components}
      />
    </FactSection>
  );
}
PathwayComponentsTab.propTypes = propTypes;
