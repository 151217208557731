import {size} from 'lodash';
import PropTypes from 'prop-types';

import HistoryTab from 'components/History/Tab';
import PublicationsTab from 'components/Literature/PublicationsTab';
import Loading from 'components/Loading';
import SideNav from 'components/SideNav';
import KyError from 'components/errors/KyError';
import FactSection from 'components/shared/fact_section';
import FullWidthResourceTemplate from 'components/templates/FullWidthResource';
import Types from 'conf/types';
import {useGet} from 'helpers/KyHooks';
import GuidelineOverviewTab from 'pages/Guideline/OverviewTab';
import GuidelineRelatedTab from 'pages/Guideline/RelatedTab';


const propTypes = {
  id: PropTypes.string.isRequired,
  section: PropTypes.string,
};

export default function GuidelinePage({id, section}) {
  const {response, error} = useGet(`site/guideline/${id}`, {
    searchParams: {view: 'base'},
    parseJson: true,
  });

  const baseUrl = Types.guideline.url(id);
  const menuItems = [
    {url: `${baseUrl}/overview`, label: 'Overview', otherUrls: [baseUrl]},
  ];
  
  let title = 'Guideline';
  let content = <Loading />;
  if (error) {
    content = <KyError kyError={error} />;
  } else if (response) {
    const {guideline, literature} = response.data;
    title = guideline.name;
    
    menuItems.push(
      {url: `${baseUrl}/publications`, label: size(literature) > 1 ? 'Publications' : 'Publication'},
      {url: `${baseUrl}/related`, label: 'Related'},
      {url: `${baseUrl}/history`, label: 'History'},
    );
    
    if (!section || section === 'overview') {
      content = <GuidelineOverviewTab {...response.data} />;
    } else if (section === 'history') {
      content = <HistoryTab {...guideline} />;
    } else if (section === 'publications') {
      content = (
        <PublicationsTab
          literature={literature}
          blurb={(
            <p>
              <i>
                A guideline may have more than one publication if it has been updated by the originating group since its
                original publication.
              </i>
            </p>
          )}
        />
      );
    } else if (section === 'related') {
      content = <GuidelineRelatedTab {...response.data} />;
    } else {
      content = <FactSection title="Not Found"><p>Section not found for this guideline</p></FactSection>;
    }
  }
  
  return (
    <FullWidthResourceTemplate id={id} type="guideline" name={title}>
      <SideNav items={menuItems}>
        {content}
      </SideNav>
    </FullWidthResourceTemplate>
  );
}
GuidelinePage.propTypes = propTypes;
