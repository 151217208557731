import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';

import './Approve.scss';
import WaitingButton from 'components/Button/Waiting';
import KyError from 'components/errors/KyError';
import useAppContext from 'conf/AppContext';


const propTypes = {
  /**
   * Class of object to approve.
   */
  objCls: PropTypes.string.isRequired,
  /**
   * ID of object to approve.
   */
  objId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /**
   * The button's label.
   */
  label: PropTypes.string,
  /**
   * Determines if button is icon-only.
   * This will render a frame-less button.
   */
  iconOnly: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Determines if button should be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * Function to call if button is clicked.
   */
  onClick: PropTypes.func,
  /**
   * Function to call on successful approval (called with `onSuccess(objId)`)
   */
  onSuccess: PropTypes.func,
  /**
   * Function to call on failed approval (called with `onFailure(objId, error)`).
   */
  onFailure: PropTypes.func,
};
/**
 * Renders a button that will approve an object.
 */
export default function ApproveButton({objCls, objId, label = 'Approve', iconOnly = false, className,
  disabled = false, onClick, onSuccess, onFailure}) {
  const appContext = useAppContext();
  const [clicked, setClicked] = useState(false);
  const [approved, setApproved] = useState(disabled);

  useEffect(() => {
    if (approved !== disabled) {
      setApproved(disabled);
    }
  }, [disabled]);

  const clickHandler = async () => {
    if (clicked) {
      return;
    }
    if (onClick) {
      onClick();
    }
    setClicked(true);
    try {
      await appContext.api.put(`curation/approve/${objCls}/${objId}`, {timeout: false});
      setClicked(false);
      if (onSuccess) {
        onSuccess(objId);
      } else {
        toast.success('Approve succeeded.');
      }
      setApproved(true);
    } catch (error) {
      setClicked(false);
      if (onFailure) {
        onFailure(objId, error);
      } else {
        appContext.toastError(<KyError kyError={error} />);
      }
    }
  };

  const icon = <FontAwesomeIcon icon={['far', 'thumbs-up']} />;
  const body = iconOnly ? icon : <span>{icon} {label}</span>;

  return (
    <WaitingButton
      actionHandler={clickHandler}
      disabled={approved}
      className={clsx('button--approve', className)}
      iconOnly={iconOnly}
      title={iconOnly ? label : undefined}
      aria-label={label}
      waiting={clicked}
    >
      {body}
    </WaitingButton>
  );
}

ApproveButton.propTypes = propTypes;
