import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {camelCase, includes, isArray, isString, map} from 'lodash';
import PropTypes from 'prop-types';
import {useState} from 'react';
import AnimateHeight from 'react-animate-height';
import InlineSVG from 'react-inlinesvg';
import {CSSTransition} from 'react-transition-group';


import PediatricIcon from 'components/icons/Pediatric';
import ResourceIcon from 'components/icons/Resource';
import Sticky from 'components/shared/sticky';
import useAppContext from 'conf/AppContext';
import usePediatricContext from 'context/Pediatric';
import getYOffset from 'helpers/sticky';


/**
 * An array of objects with keys: label, iconType, url and otherUrls.
 * otherUrls is an optional array of urls that map to the same component (e.g. /pathway/XXX to /pathway/XXX/overview).
 */
export const menuItemPropTypes = PropTypes.arrayOf(PropTypes.shape({
  label: PropTypes.string.isRequired,
  iconType: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
  url: PropTypes.string.isRequired,
  count: PropTypes.shape({
    total: PropTypes.number.isRequired,
    pediatric: PropTypes.number.isRequired,
  }),
  otherUrls: PropTypes.arrayOf(PropTypes.string),
}));

const propTypes = {
  items: menuItemPropTypes.isRequired,
};
/**
 * Renders side navigation menu.
 *
 * @param {object} props - component properties
 * @param {Array} props.items
 */
export default function SideNavMenu({items}) {
  const appContext = useAppContext();
  const pediatricContext = usePediatricContext();
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const hideDropdown = () => {
    setDropdownVisible(false);
  };
  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const renderBadge = (isActive, hasContent, hasPediatricContent) => {
    const activeArrow = isActive ? <FontAwesomeIcon icon={['far', 'chevron-right']} /> : ' ';
    const arrowContainer = <div className="sideNavMenu__item__badge__chevron">{activeArrow}</div>;

    let contentContainer = '';
    if (hasPediatricContent && pediatricContext.isPediatricMode) {
      contentContainer = (
        <div className="sideNavMenu__item__badge__child" title="Pediatric information available">
          <PediatricIcon size="lg" />
        </div>
      );
    } else if (hasContent) {
      contentContainer = (
        <div className="sideNavMenu__item__badge__dot" title="Information available">
          <FontAwesomeIcon icon="circle" />
        </div>
      );
    }

    return (
      <span className="sideNavMenu__item__badge">{contentContainer} {arrowContainer}</span>
    );
  };

  const renderItem = (item, idx) => {
    const {label, iconType, url, otherUrls} = item;
    const path = appContext.getCurrentPath();

    const isActive = path === url || includes(otherUrls, path);
    const hasContent = item?.count?.total > 0;
    const hasPediatricContent = item?.count?.pediatric > 0;

    // NOTE: we're adding sideNavMenu__item--label to support tours
    return (
      <a
        href={url}
        className={clsx('sideNavMenu__item', `sideNavMenu__item--${camelCase(label)}`, {
          'sideNavMenu__item--active': isActive,
        })}
        key={idx}
        onClick={hideDropdown}
      >
        {renderIcon(iconType, 'sideNavMenu__item__icon')}
        <span className="sideNavMenu__item__title">{label}</span>
        {renderBadge(isActive, hasContent, hasPediatricContent)}
      </a>
    );
  };

  const height = (isDropdownVisible) ? 'auto' : 0;
  const renderedItems = map(items, renderItem);

  return [
    <div className="sideNavMenu__desktop__container" key="desktop">
      <Sticky top={getYOffset()}>
        {renderedItems}
      </Sticky>
    </div>,

    <div className="sideNavMenu__mobile__container" key="mobile">
      { /*
          This menu is only visible on mobile, so it probably doesn't make sense
          to have keyboard navigation.
        */ }
      { /* eslint-disable jsx-a11y/click-events-have-key-events */ }
      <div
        className="sideNavMenu__mobile__navigate"
        role="button"
        tabIndex={0}
        onClick={toggleDropdown}
      >
        <div className="sideNavMenu__mobile__navigate__title">
          Navigate to...
        </div>

        <div className="sideNavMenu__mobile__navigate__trigger">
          <FontAwesomeIcon icon="chevron-down" aria-hidden={true} />
          <span className="sr-only">Show section selector dropdown</span>
        </div>
      </div>
      { /* eslint-enable jsx-a11y/click-events-have-key-events */ }

      <CSSTransition
        in={isDropdownVisible}
        timeout={500}
        classNames="sideNavMenu__mobile__overlay"
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <div
          aria-hidden="true"
          onClick={hideDropdown}
          className="sideNavMenu__mobile__overlay"
        />
      </CSSTransition>

      <AnimateHeight
        height={height}
        duration={500}
        className={clsx('side-nav', 'sideNavMenu__mobile__dropdown', {
        })}
      >
        <div className="sideNavMenu__mobile__dropdown__items">
          {renderedItems}
        </div>
      </AnimateHeight>
    </div>,
  ];
}
SideNavMenu.propTypes = propTypes;


/**
 * Render icon for sidenav.
 *
 * @param {string|Array|JSX.Element} icon
 * @param {string} [className]
 * @return {JSX.Element}
 */
export function renderIcon(icon, className) {
  if (!icon) {
    return null;
  }
  let content;
  if (isString(icon)) {
    if (icon.endsWith('.svg')) {
      content = (
        <InlineSVG
          src={icon}
          cacheRequests={true}
          className="resourceIcon--inline"
        />
      );
    } else {
      content = <ResourceIcon type={icon} />;
    }
  } else if (isArray(icon)) {
    content = <FontAwesomeIcon icon={icon} />;
  } else {
    content = icon;
  }
  return (
    <div className={className}>
      {content}
    </div>
  );
}
