import clsx from 'clsx';
import {compact, forEach, get, isString, map, size} from 'lodash';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {toast} from 'react-toastify';

import DeleteButton from 'components/Button/Delete';
import EditButton from 'components/Button/Edit';
import ClinicalAnnotationEvidenceList from 'components/ClinicalAnnotation/EvidenceList';
import {formatVaScore} from 'components/ClinicalAnnotation/Level';
import HistoryTable from 'components/History/Table';
import LiteratureCitation from 'components/Literature/Citation';
import Tag from 'components/Tag';
import PediatricTag from 'components/Tag/Pediatric';
import RareVariantTag from 'components/Tag/RareVariant';
import ScoreBreakdown from 'components/VariantAnnotation/ScoreBreakdown';
import SignificanceFact from 'components/VariantAnnotation/SignificanceFact';
import StudyParameterTable from 'components/VariantAnnotation/StudyParameterTable';
import ApproveControl from 'components/edit/ApproveControl';
import EditControls from 'components/edit/EditControls';
import KyError from 'components/errors/KyError';
import ResourceIcon from 'components/icons/Resource';
import ResourceLink, {renderResourceLinks} from 'components/links/Resource';
import AlleleNote from 'components/shared/AlleleNote';
import CuratorOnly from 'components/shared/curator_only';
import Fact from 'components/shared/fact';
import FactSection from 'components/shared/fact_section';
import {useTour} from 'components/tour/hook';
import useAppContext from 'conf/AppContext';
import Types, {variantAnnotationProps} from 'conf/types';
import InfoLink from 'components/links/Info';

const propTypes = {
  variantAnnotation: variantAnnotationProps,
  nested: PropTypes.bool,
  hideHistory: PropTypes.bool,
  conflicting: PropTypes.bool,
  index: PropTypes.number,
};
/**
 * Variant annotation details.
 *
 * @param {object} props - props container
 * @param {object} props.variantAnnotation - variant annotation data from API
 * @param {boolean} props.nested - true if this annotation is shown as part of another annotation, defaults false
 * @param {boolean} props.hideHistory - true if history should be hidden from display, defaults false
 * @param {boolean} props.conflicting - true if this VA is conflicting in the context of a CA, defaults false
 * @param {number} props.index - numerical index to display next to title, optional. good for display in a clinical annotation
 */
export default function VariantAnnotationDetail({
  variantAnnotation,
  nested = false,
  hideHistory = false,
  index,
  conflicting = false,
}) {
  const appContext = useAppContext();

  const readyForTour = useTour('variantAnnotation');
  useEffect(() => {
    readyForTour();
  }, [readyForTour]);


  const renderHeader = () => {
    const {id, location} = variantAnnotation;
    const href = Types.variantAnnotation.url(id);

    return (
      <h3 className="fact-section-header">
        {`${index}. `}
        <ResourceIcon type="variantAnnotation" inline={true} />
        {' '}<a href={href}>Annotation of <VariantLocationTitle {...location} /></a>
      </h3>
    );
  };

  const va = variantAnnotation;
  const {
    sentence, alleleGroupingDescription, description, id, literature,
    pediatric, history, objCls, phenotypeCategories, studyParameters,
    status, rsidInPaper, directionOfEffectClear, riskAlleleClear, useForCaScoring,
    scoringRationale, location, rareVariant, significance, score, scoreDetails,
  } = variantAnnotation;
  const {genes, haplotypes, variant} = location;
  const {chosenSpId} = scoreDetails;
  const scoreDisplay = formatVaScore(score, conflicting);

  const header = nested && renderHeader();
  const approveButton = nested ? null : <ApproveControl type="VariantAnnotation" id={id} />;
  const userId = va?.userId || 'unknown';

  return (
    <div>
      <div className={clsx('variant-annotation-detail', {'trim-facts': nested})}>
        {header}
        <EditControls>
          <EditButton href={`/edit/variantAnnotation/${va.id}`} label="Edit / Clone" />
          {approveButton}
          {!nested && (
            <DeleteButton
              url={`curation/variantAnnotation/${id}`}
              onSuccess={() => toast.success('Variant Annotation Deleted', {autoClose: 5000})}
              onFailure={(vaId, error) => appContext.toastError(<KyError kyError={error} />)}
              confirmMessage="Delete this Variant Annotation?"
            />
          )}
          <span className="ml-2 mr-2 fixed-width-font" key={`userSpan${id}`}>
            owner: {userId || <em>unassigned</em>}
          </span>
        </EditControls>


        <h4 className="variant-annotation-title tour-variant-annotation-title">{sentence}</h4>

        {alleleGroupingDescription && <p>{alleleGroupingDescription}</p>}

        <p className="mb-5 tour-variant-ann-description">{description}</p>

        <FactSection>
          <Fact label="From Publication" pageTourClass="tour-variant-ann-publication">
            <LiteratureCitation firstAuthorOnly={true} {...literature} />
          </Fact>
        </FactSection>
        <FactSection halfWidth={true}>
          <Fact label="Gene" halfWidth={true} inline={true}>
            {size(genes) > 0 && renderResourceLinks(genes)}
          </Fact>
          <Fact label="Variant" halfWidth={true} inline={true}>
            {variant && <ResourceLink resource={variant} />}
            {rareVariant && <div><RareVariantTag /></div>}
          </Fact>
          <Fact label="Haplotype" halfWidth={true} inline={true}>
            {size(haplotypes) > 0 && renderResourceLinks(haplotypes)}
          </Fact>
          <Fact label="Phenotype Category" halfWidth={true} inline={true}>
            {phenotypeCategories.map((c) => c.term).join(', ')}
          </Fact>
          <SignificanceFact significance={significance?.term} />
          <Fact label="Specialty Population" halfWidth={true} inline={true}>
            {pediatric && <PediatricTag />}
          </Fact>
          <Fact label="PharmGKB ID" literal={id} halfWidth={true} inline={true} />
          {useForCaScoring && (
            <Fact label="Score" inline={true} labelBtns={<InfoLink href="/page/clinAnnScoring" tooltip="More info on scoring" />}>
              {scoreDisplay} {conflicting && <span className="ml-2"><Tag>Conflicting Evidence</Tag></span>}
            </Fact>
          )}
          {!useForCaScoring && (
            <Fact label="Score"><p>This annotation is not used for clinical annotation scoring.</p><p>{scoringRationale}</p></Fact>
          )}
        </FactSection>

        <CuratorOnly>
          <FactSection compact={true} halfWidth={true} contentClasses="mb-1" sectionClasses="mb-0">
            <Fact halfWidth={true} inline={true} label="Status">{status}</Fact>
            <Fact halfWidth={true} inline={true} label="RSID In Paper?">{rsidInPaper}</Fact>

            <Fact halfWidth={true} inline={true} label="Direction of Effect Clear?">{directionOfEffectClear}</Fact>
            <Fact halfWidth={true} inline={true} label="Risk Allele Clear">{riskAlleleClear}</Fact>

            {!nested && (
              <>
                <Fact
                  halfWidth={true}
                  label="Use for Scoring?"
                  pageTourClass="tour-use-for-scoring"
                >
                  {useForCaScoring ? 'Yes' : 'No'}
                </Fact>
                <Fact
                  halfWidth={true}
                  label="Scoring Rationale"
                  hideWhenEmpty={false}
                >
                  {scoringRationale || 'N/A'}
                </Fact>

                <Fact
                  label="Score Breakdown"
                  labelBtns={<InfoLink href="https://pharmgkb.atlassian.net/wiki/spaces/PGKB/pages/546930786/Variant+and+Clinical+Annotation+scoring+system" tooltip="More info on scoring" />}
                >
                  <ScoreBreakdown {...scoreDetails} />
                </Fact>
              </>
            )}
          </FactSection>
        </CuratorOnly>

        {!nested && <ClinicalAnnotationEvidenceList id={id} objCls={objCls} />}

        <div className="tour-variant-ann-study-params">
          <h3 className="tour-variant-ann-study-params-header">Study Parameters</h3>
          {size(studyParameters) === 0 && <div className="text-muted">Not Available</div>}
          {size(studyParameters) > 0 && map(studyParameters, (sp, i, c) => (
            <StudyParameterTable studyParameter={sp} key={sp.id} index={size(c) > 1 && (i + 1)} nested={nested} usedForScoring={sp.id === chosenSpId} />
          ))}
        </div>

        {!nested && <AlleleNote />}

        {!hideHistory && (
          <FactSection title="History">
            <HistoryTable history={history} parentCls={objCls} parentId={id} />
          </FactSection>
        )}
      </div>
    </div>
  );
}
VariantAnnotationDetail.propTypes = propTypes;

function CommaList({words}) {
  const wordArray = [];
  forEach(compact(words), (word) => {
    if (isString(word)) {
      wordArray.push(word);
    } else {
      wordArray.push(word?.symbol || word?.name);
    }
  });
  return (
    <span className="comma-join">
      {map(wordArray, (word, i) => <span key={`${i}${word}`}>{word}</span>)}
    </span>
  );
}
CommaList.propTypes = {
  words: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
};

function VariantLocationTitle({displayName, genes, haplotypes, variant, genePhenotype}) {
  const phenotype = genePhenotype?.term;
  let loc = 'Variant';

  // Haplotypes
  if (size(haplotypes) > 0 && size(genes) > 0) {
    loc = (
      <span>
        <CommaList words={genes} />{' '}
        {pluralize('haplotype', size(haplotypes))}{' '}
        <CommaList words={haplotypes} />
      </span>
    );
  } else if (size(haplotypes) > 0) {
    loc = <span><CommaList words={haplotypes} /></span>;

    // Variant
  } else if (variant && genes) {
    loc = (
      <span>
        <CommaList words={[variant]} /> in <CommaList words={genes} />
      </span>
    );
  } else if (variant) {
    loc = <span><CommaList words={[variant]} /></span>;

    // Gene (and possibly Phenotype)
  } else if (genes && phenotype) {
    loc = <span><CommaList words={genes} /> {phenotype}</span>;
  } else if (genes) {
    loc = <span><CommaList words={genes} /></span>;

    // Display name
  } else if (displayName) {
    loc = displayName;
  }
  return loc;
}
