import {filter, isNil, map, sum} from 'lodash';
import numeral from 'numeraljs';
import PropTypes from 'prop-types';

/**
 * A component to nicely display the CA level as a bootstrap badge
 *
 * @param {string} level - the level to display
 * @return {React.ReactNode}
 */
export default function Level({level}) {
  return (
    <span className="badge badge-info">Level: {level}</span>
  );
}
Level.propTypes = {
  level: PropTypes.string.isRequired,
};

/**
 * Calculates the CA score
 *
 * @param {Array<object>} vas - an array of variant annotation objects
 * @param {Array<number>} conflictIds - an array of conflicting variant annotation IDs
 * @param {number} sdSize - the number of dosing guidelines and drug labels used as evidence
 * @return {number} - the score for the given evidence
 */
export const calculateScore = (vas, conflictIds, sdSize) => sum(
  map(filter(vas, (v) => v.useForCaScoring),
    ({id: vaId, score: vaScore}) => {
      if (vaScore > 0 && conflictIds.indexOf(vaId) >= 0) {
        return vaScore * -1;
      } else {
        return vaScore;
      }
    },
  )) + (sdSize * 100);

/**
 * Calculates the CA Level of Evidence based on the given score
 *
 * @param {number} inputScore - the score to assign a level to
 * @param {boolean} inRareVariant - is the Variant rare?
 * @param {boolean} inTopTierVip - is the gene a top-tier VIP?
 * @param {boolean} usesSinglePublication - true if this CA is only on a single publication
 * @return {string} - the level of evidence
 */
export const calculateLevel = (inputScore, inRareVariant, inTopTierVip, usesSinglePublication = false) => {
  const rareVariantCutoffs   = [80, 10, 3, 0];
  const commonVariantCutoffs = [80, 25, 8, 0];
  const cutoffs = inRareVariant ? rareVariantCutoffs : commonVariantCutoffs;

  if (inputScore >= cutoffs[0]) {
    return '1A';
  } else if (inputScore >= cutoffs[1]) {
    if (usesSinglePublication) {
      return '3';
    }
    return '1B';
  } else if (inputScore >= cutoffs[2]) {
    if (usesSinglePublication) {
      return '3';
    }
    return inTopTierVip ? '2A' : '2B';
  } else if (inputScore >= cutoffs[3]) {
    return '3';
  } else {
    return '4';
  }
};

/**
 * Formats a VA score for display
 *
 * @param {number} score - a numerical VA score
 * @param {boolean} conflicting - boolean for whether the VA is marked as conflicting, optional and defaults false
 * @return {string} - a string formatted decimal representation of the score
 */
export const formatVaScore = (score, conflicting = false) => {
  if (isNil(score)) {
    return '';
  } else {
    return numeral(score * ((score > 0 && conflicting) ? -1 : 1)).format('0[.]0[000]');
  }
};
