import PropTypes from 'prop-types';
import {useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';

import Button from 'components/Button';
import KyError from 'components/errors/KyError';
import FormSubmit from 'components/form/Submit';
import FormText from 'components/form/Text';
import ReadableWidthTemplate from 'components/templates/ReadableWidth';
import useAppContext from 'conf/AppContext';

import './Login.scss';

const propTypes = {
  nextUrl: PropTypes.string,
};
/**
 * The login page presented to a user when they need to authenticate.
 *
 * @param {object} props - the props container
 * @param {string} [props.nextUrl] - the URL to forward the user to after success
 */
export default function LoginPage({nextUrl = '/'}) {
  const appContext = useAppContext();
  const [kyError, setKyError] = useState(undefined);
  const formMethods = useForm({defaultValues: {email: '', password: ''}});
  const {handleSubmit} = formMethods;

  const onSubmit = async (values) => {
    try {
      const rez = await appContext.api.post('auth/signIn', {json: values, parseJson: true});
      appContext.login(rez.data, nextUrl);
    } catch (error) {
      setKyError(error);
    }
  };

  return (
    <ReadableWidthTemplate title="Sign In" className="loginPage">
      <div className="row justify-content-md-center">
        <div className="col-md-8">
          {kyError && <KyError kyError={kyError} />}
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormText
                name="email"
                label="Email"
                autoComplete="email"
                required={true}
              />
              <FormText
                name="password"
                label="Password"
                type="password"
                autoComplete="password"
                required={true}
              />
              <div className="text-center">
                <FormSubmit className="btn-primary btn-lg">Sign In</FormSubmit>
              </div>
            </form>
          </FormProvider>

          <div className="island-top text-center">
            <p>Forgot your password?</p>
            <Button className="btn-secondary" href="/user/resetPassword">Reset Password</Button>
          </div>
        </div>
      </div>
    </ReadableWidthTemplate>
  );
}
LoginPage.propTypes = propTypes;
