import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {filter, find, size} from 'lodash';
import PropTypes from 'prop-types';
import {useMemo, useState} from 'react';

import './Table.scss';
import Button from 'components/Button';
import MultiSelectFilter, {multiselectOrFilter} from 'components/Table/Filter/MultiSelect';
import TextFilter from 'components/Table/Filter/Text';
import SimpleTable from 'components/Table/Simple';
import {
  defaultRelatedChemicalsColumn, defaultRelatedGenesColumn, defaultSourceColumn, defaultViewLinkColumn, flexColumnStyle,
} from 'components/Table/columnHelpers';
import {renderTotalDataFn} from 'components/Table/utils';
import {renderTags} from 'components/Tag';
import CriteriaNotMetTag from 'components/Tag/CriteriaNotFound';
import LabelAnnotationLevelTag from 'components/Tag/LabelAnnotationLevel';
import InfoIcon from 'components/icons/Info';
import Link from 'components/links/Link';
import {getLevelId, sortLaLevels} from 'utils/pgxLevelUtils';


const baseColumns = [
  defaultViewLinkColumn,
  {
    id: 'level',
    Header: 'PGx Level',
    accessor: (originalRow) => {
      if (originalRow?.properties?.testing) {
        return originalRow.properties.testing;
      } else if (originalRow?.testing?.term) {
        return originalRow.testing.term;
      }
      return '';
    },
    Cell: LevelCell,
    sortable: true,
    sortType: sortLaLevels,
    Filter: MultiSelectFilter,
    filter: multiselectOrFilter,
    ...flexColumnStyle({width: 230}),
  },
  defaultSourceColumn,
  {
    id: 'title',
    Header: 'Title',
    accessor: 'name',
    Cell: TitleCell,
    sortable: true,
    Filter: TextFilter,
    ...flexColumnStyle({width: 280, grow: 4}),
  },
  defaultRelatedGenesColumn,
  defaultRelatedChemicalsColumn,
];
const initialState = {
  sortBy: [
    {
      id: 'level',
      desc: false,
    },
    {
      id: 'source',
      desc: false,
    },
  ],
};

const customButtons = (
  <Button
    className="btn-sm btn-outline-secondary"
    href="/page/drugLabelLegend"
    newTab={true}
  >
    <InfoIcon />Legend
  </Button>
);


const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  showSourceCol: PropTypes.bool,

  // standard table props
  id: PropTypes.string,
  canShowFullscreen: PropTypes.bool,
  canEditColumns: PropTypes.bool,
  canHideFilters: PropTypes.bool,
  // downloadTableProps
  disableExport: PropTypes.bool,
};
/**
 * Renders a table of label annotations.
 */
export default function LabelAnnotationTable({data, showSourceCol = true,
  id, canShowFullscreen = false, canEditColumns = false, canHideFilters = false, disableExport = false}) {
  const [showRetired, setShowRetired] = useState(false);

  const customFilters = useMemo(() => {
    const controls = [];
    const retiredCount = size(filter(data, (l) => !!l.retirementReason));
    if (retiredCount > 0) {
      controls.push((
        <div key="filterRetired">
          <Button className="btn-outline-info" actionHandler={() => setShowRetired((p) => !p)}>
            <FontAwesomeIcon icon={['fal', showRetired ? 'check-square' : 'square']} /> Show {retiredCount} Retired
          </Button>
        </div>
      ));
    }
    return controls;
  }, [data, showRetired]);

  const columns = showSourceCol ? baseColumns : filter(baseColumns, (c) => c.id !== 'source');

  const renderTotalFn = (numVisible) => renderTotalDataFn(data, numVisible);

  return (
    <SimpleTable
      id={id}
      columns={columns}
      data={filter(data, (l) => showRetired || !l.retirementReason)}
      initialState={initialState}
      className="labelAnnotationTable"
      rowClassNameFn={rowClassNameFn}
      canShowFullscreen={canShowFullscreen}
      canEditColumns={canEditColumns}
      canHideFilters={canHideFilters}
      customButtons={customButtons}
      disableExport={disableExport}
      customFilters={customFilters}
      renderTotalFn={renderTotalFn}
    />
  );
}
LabelAnnotationTable.propTypes = propTypes;


function LevelCell({value, row}) {
  if (!value) {
    if (!row.original.pgxRelated) {
      return <span className="text-muted">Nothing to Annotate</span>;
    } else if (!row.original.labelDocumentAvailable) {
      return <span className="text-muted">No label found</span>;
    } else {
      return <CriteriaNotMetTag />;
    }
  }
  return <LabelAnnotationLevelTag level={value} />;
}

function TitleCell({value, row}) {
  return (
    <>
      <Link href={`/labelAnnotation/${row.original.id}`}>{value}</Link>
      {renderTags({obj: row.original, className: 'tag--xs'})}
    </>
  );
}


function rowClassNameFn(row) {
  const level = find(row.cells, (c) => c.column.id === 'level')?.value;
  return `row-level-${getLevelId(level)}`;
}
