import clsx from 'clsx';
import {find} from 'lodash';
import PropTypes from 'prop-types';

import './Xrefs.scss';
import LitXrefLink from 'components/links/LitXref';
import {CrossReferenceResource} from 'conf/enums';
import {crossReferenceProps} from 'conf/types';


const propTypes = {
  xrefs: PropTypes.arrayOf(crossReferenceProps),
  className: PropTypes.string,
};
/**
 * Renders a collection of external literature cross-references.
 */
export default function LiteratureXrefs({xrefs, className}) {
  const pubmedRef = find(xrefs, (x) => x.resource === CrossReferenceResource.shortName.pubMed);
  const doiRef = find(xrefs, (x) => x.resource === CrossReferenceResource.shortName.doi);
  const pmcRef = find(xrefs, (x) => x.resource === CrossReferenceResource.shortName.pmc);
  const fdaRef = find(xrefs, (x) => x.resource === CrossReferenceResource.shortName.fda);
  const dailyMedRef = find(xrefs, (x) => x.resource === CrossReferenceResource.shortName.DailyMed);
  const webRef = find(xrefs, (x) => x.resource === CrossReferenceResource.shortName.url);

  if (!pubmedRef && !doiRef && !pmcRef && !fdaRef && !dailyMedRef && !webRef) {
    return null;
  }

  const renderLink = (xref) => {
    if (!xref) {
      return null;
    }
    return (
      <LitXrefLink xref={xref} />
    );
  };

  return (
    <div className={clsx('literatureXrefs', className)}>
      {renderLink(pubmedRef)}
      {renderLink(pmcRef)}
      {renderLink(doiRef)}
      {renderLink(fdaRef)}
      {renderLink(dailyMedRef)}
      {!pubmedRef && !pmcRef && !doiRef && !fdaRef && !dailyMedRef && renderLink(webRef)}
    </div>
  );
}
LiteratureXrefs.propTypes = propTypes;
