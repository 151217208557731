import Button from 'components/Button';
import InlinePage from 'components/Page/Inline';
import InfoLink from 'components/links/Info';
import ReadableWidthTemplate from 'components/templates/ReadableWidth';

export default function PediatricTagsPage() {
  return (
    <ReadableWidthTemplate title="Pediatric Tags">
      <h3>
        Variant Annotations{' '}
        <InfoLink
          href="/whatIsPharmgkb/variantAnnotations"
          tooltip="Learn about variant annotations"
          iconClassName="icon--superscript"
          iconSize="xs"
        />
      </h3>
      <InlinePage
        id="pediatricVariantAnnotationsHeader"
        extraControls={<Button href="/pediatric/variantAnnotations" className="btn-secondary">Pediatric VA Page</Button>}
        className="mb-4"
      />

      <h3>
        Clinical Annotations{' '}
        <InfoLink
          href="/whatIsPharmgkb/clinicalAnnotations"
          tooltip="Learn about clinical annotations"
          iconClassName="icon--superscript"
          iconSize="xs"
        />
      </h3>
      <InlinePage
        id="pediatricClinicalAnnotationsHeader"
        extraControls={<Button href="/pediatric/clinicalAnnotations" className="btn-secondary">Pediatric CA Page</Button>}
        className="mb-4"
      />

      <h3>
        Label Annotations{' '}
        <InfoLink
          href="/whatIsPharmgkb/annotations"
          tooltip="Learn about label annotations"
          iconClassName="icon--superscript"
          iconSize="xs"
        />
      </h3>
      <InlinePage
        id="pediatricLabelAnnotationsHeader"
        extraControls={<Button href="/pediatric/labelAnnotations" className="btn-secondary">Pediatric Label Ann Page</Button>}
        className="mb-4"
      />

      <h3>
        Guideline Annotations{' '}
        <InfoLink
          href="/whatIsPharmgkb/prescribing"
          tooltip="Learn about guideline annotations"
          iconClassName="icon--superscript"
          iconSize="xs"
        />
      </h3>
      <InlinePage
        id="pediatricGuidelineAnnotationsHeader"
        extraControls={<Button href="/pediatric/guidelineAnnotations" className="btn-secondary">Pediatric Guideline Ann Page</Button>}
        className="mb-4"
      />


      <h3>Literature</h3>
      <InlinePage
        id="pediatricLiteratureHeader"
        extraControls={<Button href="/pediatric/literature" className="btn-secondary">Pediatric Literature Page</Button>}
        className="mb-4"
      />

      <h3>
        Automated Annotations{' '}
        <InfoLink
          href="/page/automatedAnnotationFaq"
          tooltip="Learn about automated annotations"
          iconClassName="icon--superscript"
          iconSize="xs"
        />
      </h3>
      <InlinePage
        id="pediatricAutomatedAnnotationsHeader"
        extraControls={<Button href="/pediatric/automatedAnnotations" className="btn-secondary">Pediatric Automated Ann Page</Button>}
        className="mb-4"
      />


      <h3>Chemicals</h3>
      <InlinePage
        id="pediatricChemicalsHeader"
        extraControls={<Button href="/pediatric/chemicals" className="btn-secondary">Pediatric Drugs Page</Button>}
      />

    </ReadableWidthTemplate>
  );
}
