import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet-async';

import {renderInfoLink} from 'components/templates/utils';

const propTypes = {
  /** The title of the page to show in the window chrome. */
  title: PropTypes.string.isRequired,
  helpLink: PropTypes.string,
  /** The classes to add to the main "container" div. */
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
/**
 * This a template that for full-width layout with margins.
 */
export default function FullWidthTemplate({title, helpLink, className, children}) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="page-wrap">
        <main className={clsx('container', className)}>
          <header>
            <h1 className="pageTitle">
              {title}
              {renderInfoLink(helpLink)}
            </h1>
          </header>
          <div>
            {children}
          </div>
        </main>
      </div>
    </>
  );
}
FullWidthTemplate.propTypes = propTypes;
