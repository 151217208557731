import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';


const propTypes = {
  text: PropTypes.node.isRequired,
};
export default function Answer({text}) {
  return (
    <div className="answer">
      <span className="answer__icon">
        <FontAwesomeIcon icon={['far', 'comment']} flip="horizontal" size="2x" />
      </span>
      {text}
    </div>
  );
}
Answer.propTypes = propTypes;
