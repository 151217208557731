import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import './Info.scss';
import {extractIconProps, extractProps} from 'utils/propUtils';


const propTypes = {
  // iconProps
  id: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  transform: PropTypes.string,
};

export default function InfoIcon({id, className, ...otherProps}) {
  return (
    <FontAwesomeIcon
      id={id}
      icon={['fas', 'circle-info']}
      {...extractIconProps({...otherProps, className: clsx('infoIcon', className)})}
      {...extractProps(otherProps)}
    />
  );
}
InfoIcon.propTypes = propTypes;
