import {parseISO} from 'date-fns';
import {format as dateFormat} from 'date-fns/format';
import {diffWords} from 'diff';
import {get, keys, map, orderBy, toNumber, uniq} from 'lodash';
import {FormProvider, useForm} from 'react-hook-form';

import KyError from 'components/errors/KyError';
import FormCombobox from 'components/form/Combobox';
import FormSubmit from 'components/form/Submit';
import useAppContext from 'conf/AppContext';
import {textify} from 'pages/LabelApplicationDiff/utils';


/**
 * Form to display available diff versions request a diff between them
 *
 * @param {object} props - props container
 * @param {Array<object>} props.versions - application version objects
 * @param {string} props.applicationId - FDA application ID
 * @param {Function} props.onSuccess - function to call after successful version comparison
 */
export default function LabelApplicationForm({versions, applicationId, onSuccess}) {
  const appContext = useAppContext();

  const sortedVersions = orderBy(versions, [(v) => toNumber(v.fdaVersion)], ['desc']);
  const versionOptions = map(
    sortedVersions,
    (v) => ({value: v.fdaVersion, label: v.fdaVersion + ' ' + dateFormat(parseISO(v.created), '(yyyy-MM-dd)')}));
  const lDefault = sortedVersions.length < 2 ? 0 : 1;
  const rDefault = 0;

  const formMethods = useForm({defaultValues: {
    lVersion: versionOptions[lDefault],
    rVersion: versionOptions[rDefault],
  }});
  const {handleSubmit} = formMethods;

  const onSubmit = async (d) => {
    const lVersion = d.lVersion.value;
    const rVersion = d.rVersion.value;
    
    if (lVersion === rVersion) {
      onSuccess([]);
    } else {
      try {
        const lResponse = await appContext.api.get(
          'data/LabelApplicationVersion',
          {searchParams: {applicationId, view: 'max', fdaVersion: lVersion}, parseJson: true});
        const rResponse = await appContext.api.get(
          'data/LabelApplicationVersion',
          {searchParams: {applicationId, view: 'max', fdaVersion: rVersion}, parseJson: true});

        const lData = getLabelText(lResponse?.data);
        const rData = getLabelText(rResponse?.data);
        const rawKeys = [...keys(lData), ...keys(rData)];
        const uniqKeys = uniq(rawKeys);

        const diffByKeys = {};
        for (let i = 0; i < uniqKeys.length; i += 1) {
          const key = uniqKeys[i];
          const lText = textify(get(lData, key, ''));
          const rText = textify(get(rData, key, ''));
          
          if (lText === rText) {
            diffByKeys[key] = false;
          } else {
            diffByKeys[key] = diffWords(lText, rText);
          }
        }
        onSuccess(diffByKeys);
      } catch (err) {
        appContext.toastError(<KyError kyError={err} />);
      }
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <div className="col-6">
            <FormCombobox label="Left" name="lVersion" options={versionOptions} />
          </div>
          <div className="col-6">
            <FormCombobox label="Right" name="rVersion" options={versionOptions} />
          </div>
        </div>
        <FormSubmit>Show Difference</FormSubmit>
      </form>
    </FormProvider>
  );
}

function getLabelText(labelObj) {
  const subsetObj = get(labelObj, '[0].labelText.results[0]');
  if (subsetObj) {
    delete subsetObj.openfda;
  }
  return subsetObj;
}
