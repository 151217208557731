import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import EditButton from 'components/Button/Edit';
import OnOffButton from 'components/Button/OnOff';
import EditControls from 'components/edit/EditControls';
import InfoIcon from 'components/icons/Info';
import AlleleNote from 'components/shared/AlleleNote';
import usePediatricContext from 'context/Pediatric';

const propTypes = {
  objCls: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  chart: PropTypes.node,
};
/**
 * Renders the intro blurb for the variant annotation tab.
 */
export default function VariantAnnotationsTabIntro({objCls, id, chart}) {
  const pediatricContext = usePediatricContext();

  let editControls;
  if (objCls === 'literature') {
    editControls = (
      <EditControls>
        <Button href={`/edit/variantAnnotation?litId=${id}`} className="btn btn-primary">
          <FontAwesomeIcon icon="plus" /> New Variant Annotation
        </Button>
        <EditButton href={`/edit/literature/${id}/variantAnnotations`} label="Edit Variant Annotations" />
      </EditControls>
    );
  }

  const pedFocusHandler = () => pediatricContext.togglePediatricMode('vaTab');

  return (
    <>
      <div className="float-right" style={{width: '15em', fontSize: '.94em'}}>
        <OnOffButton
          label="Focus on Pediatrics"
          on={pediatricContext.isPediatricMode}
          stateful={false}
          actionHandler={pedFocusHandler}
          tabIndex={0}
        />
      </div>
      <h3>Variant Annotations</h3>
      {editControls}
      <div className="clearfix">
        {chart}
        <div className="island-bottom">
          <p>
            PharmGKB variant annotations report the association between a variant and a drug
            phenotype from a publication. Annotations are created manually by Scientific Curators
            who curate key information and provide a structured, one-sentence summary of each
            association. More information about the association may be reported as free text in the
            &quot;More Details&quot; column of the table.
          </p>
          {!!chart && <AlleleNote />}
        </div>
        <div className="mb-5">
          <Button className="btn-secondary" href="/variantAnnotations"><InfoIcon /> Read more about variant annotations</Button>
        </div>
      </div>
    </>
  );
}
VariantAnnotationsTabIntro.propTypes = propTypes;
