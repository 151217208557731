import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';

import DiffButton from 'components/Button/Diff';
import KyError from 'components/errors/KyError';
import LoadingIcon from 'components/icons/Loading';
import useAppContext from 'conf/AppContext';
import logger from 'conf/Logger';


const propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  disabled: PropTypes.bool,
};
/**
 * This control is an approve button that will check for approval status and manage its own state.
 *
 * @param {object} props - props container
 * @param {string} props.type - the type of object to approve
 * @param {string} props.id - the ID of the object to approve
 * @param {boolean} props.disabled
 * @deprecated use ApproveAndDiffButton instead
 */
export default function ApproveControl({type, id, disabled = false}) {
  const appContext = useAppContext();
  const [needsApprove, setNeedsApprove] = useState(false);
  const [waiting, setWaiting] = useState(false);

  useEffect(() => {
    const checkNeedsApproval = async () => {
      try {
        const rez = await appContext.api.get(`curation/approve/${type}/${id}`, {parseJson: true});
        setNeedsApprove(rez.data.includes(id));
      } catch (error) {
        logger.error('Cannot get approval status', error);
      }
    };
    checkNeedsApproval();
  }, [type, id]);

  const onClick = async () => {
    if (waiting) return;
    setWaiting(true);
    try {
      await appContext.api.put(`curation/approve/${type}/${id}`);
      setNeedsApprove(false);
      toast.success('Approve Succeeded');
    } catch (err) {
      appContext.toastError(<KyError kyError={err} />);
    } finally {
      setWaiting(false);
    }
  };

  const icon = waiting
    ? <LoadingIcon />
    : <FontAwesomeIcon icon={needsApprove ? 'thumbs-up' : 'minus'} />;

  return (
    <>
      <button
        aria-label="Approve"
        className={clsx('btn', 'btn-sm', {'btn-warning': needsApprove})}
        type="button"
        disabled={!needsApprove || disabled}
        onClick={onClick}
      >
        {icon} Approve
      </button>
      <DiffButton objCls={type} objId={id} iconOnly={false} disabled={!needsApprove || disabled} />
    </>
  );
}
ApproveControl.propTypes = propTypes;
