import {map} from 'lodash';
import PropTypes from 'prop-types';

import Loading from 'components/Loading';
import PharmgkbTagForm from 'components/PharmgkbTag/Form';
import KyError from 'components/errors/KyError';
import {useGet} from 'helpers/KyHooks';


const propTypes = {
  objId: PropTypes.string,
  objCls: PropTypes.string,
  closeHandler: PropTypes.func.isRequired,
};
/**
 * Renders modal form for selecting PharmGKB Tags to apply to an entity.
 */
export default function PharmgkbTagEditor({objCls, objId, closeHandler}) {
  const {response, error} = useGet(`curation/tag/${objCls}/${objId}`, {
    cacheResponse: false,
  }, {
    transform: (r) => ({
      tags: makeOntologyTermOptions(r.data.tags),
      selected: r.data.selected,
    }),
  });


  let body = <Loading />;
  if (error) {
    body = <KyError kyError={error} />;
  } else if (response) {
    body = (
      <PharmgkbTagForm
        tags={response.tags}
        selected={response.selected}
        objCls={objCls}
        objId={objId}
        closeHandler={closeHandler}
      />
    );
  }

  return body;
}
PharmgkbTagEditor.propTypes = propTypes;

const officialRegex = /\[official]/i;
const forTypeRegex = /\[forType:(.*?)]/;

function mapOntologyTerm(ot) {
  const typeMatch = forTypeRegex.exec(ot.description);
  const forType = [];
  if (typeMatch) {
    const types = typeMatch[1].split(',');
    for (let x = 0; x < types.length; x += 1) {
      forType.push(types[x].trim().toLowerCase());
    }
  }
  return {
    label: ot.term,
    value: ot.termId,
    official: officialRegex.test(ot.description),
    forType,
  };
}

/**
 * Make options for use in a form component from terms
 *
 * @param {Array<object>} terms
 * @return {{label: *, value: *}[]|undefined}
 */
function makeOntologyTermOptions(terms) {
  if (!terms) {
    return undefined;
  }
  return map(terms, mapOntologyTerm);
}
