import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import InfoLink from 'components/links/Info';

export default class FactSection extends React.Component {
  static displayName = 'Fact Section';

  static propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    helpLink: PropTypes.string,
    id: PropTypes.string,

    sectionClasses: PropTypes.string,
    headerClasses: PropTypes.string,
    contentClasses: PropTypes.string,

    trim: PropTypes.bool,
    compact: PropTypes.bool,
    inline: PropTypes.bool,
    halfWidth: PropTypes.bool,

    /** Whether to display `Not Available` when empty (default is 'None'). */
    notAvailableWhenEmpty: PropTypes.bool,
  };

  static defaultProps = {
    trim: false,
    compact: false,
    inline: false,
    halfWidth: false,
    notAvailableWhenEmpty: true,
  };


  renderHelpLink = () => {
    const {helpLink} = this.props;
    if (helpLink) {
      return (
        <InfoLink tooltip="More information" href={helpLink} iconClassName="icon--superscript" iconSize="xs" />
      );
    }
    return null;
  };


  render = () => {
    const {title, children, trim, compact, inline, halfWidth, notAvailableWhenEmpty} = this.props;
    const {sectionClasses, headerClasses, contentClasses} = this.props;

    const scn = clsx('fact-section', sectionClasses, {
      'trim-facts': trim,
      'compact-facts': compact,
      'inline-facts': inline,
      'half-width-facts': halfWidth,
    });
    const hcn = clsx('fact-section-header', headerClasses);
    const ccn = clsx('fact-section-content', contentClasses, {
      'show-none-when-empty': !notAvailableWhenEmpty,
      'show-not-available-when-empty': notAvailableWhenEmpty,
    });


    return (
      <div className={scn} id={this.props.id}>
        {!!title && <h3 className={hcn}>{title} {this.renderHelpLink()}</h3>}
        <div className={ccn}>{children}</div>
      </div>
    );
  };
}
