import PropTypes from 'prop-types';

import './MeshTerm.scss';
import PediatricTag from 'components/Tag/Pediatric';

// this list should match MeshTerm.PEDIATRIC_TERMS
export const PEDIATRIC_MESH_TERMS = new Set([
  'Adolescent',
  'Adolescents',
  'Child',
  'Children',
  'Infant',
  'Infants',
  'Infant, Newborn',
  'Infants, Newborn',
  'Newborn',
  'Newborns',
  'Newborn Infant',
  'Newborn Infants',
  'Neonate',
  'Neonates',
  'Pediatrics',
]);

/**
 * Inline component for displaying a MeSH term. Will ensure term has no text wrapping and will add a badge after the
 * term if it is a known Pediatric term.
 *
 * @param {object} props - props container
 * @param {string} props.term - a String MeSH term
 * @return {JSX.Element} an inline term that prevents text wrapping
 */
export default function MeshTerm({term}) {
  return <span className="meshTerm">{term}{PEDIATRIC_MESH_TERMS.has(term) && <PediatricTag className="tag--xs ml-3" />}</span>;
}
MeshTerm.propTypes = {
  term: PropTypes.string.isRequired,
};
