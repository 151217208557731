import PropTypes from 'prop-types';

import SideNavMenu, {menuItemPropTypes} from 'components/SideNav/Menu';


const propTypes = {
  items: menuItemPropTypes.isRequired,
  children: PropTypes.node.isRequired,
};
/**
 * Renders the side navigation framework.
 *
 * @param {object} props - component properties
 * @param {Array} props.items
 * @param {React.ReactNode} props.children
 */
export default function SideNav({items, children}) {
  return (
    <div className="row">
      <SideNavMenu items={items} />
      <div className="sideNav__content">
        {children}
      </div>
    </div>
  );
}
SideNav.propTypes = propTypes;
