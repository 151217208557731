import {filter, map, size} from 'lodash';

import RecommendationAnnotationTags from 'components/RecommendationAnnotation/Tags';
import HtmlContainer from 'components/shared/html_container';
import Types from 'conf/types';

export default function RecommendationAnnotation({
  implications = [], text, genotypes, phenotypes, lookupKey,
  classification, population = 'unspecified', alternateDrugAvailable, dosingInformation, otherPrescribingGuidance,
  recommendationTitle = 'Recommendation', populationTitle = 'Group', multipopulation = false,
  parentCls, parentSource,
}) {
  const isPgxAssoc = parentCls === Types.pGxAssociation.displayType;
  const displayPopulation = isPgxAssoc ? population.replace('Affected subgroup: ', '') : population;
  return (
    <div className="recommendationAnnotation">
      <RecommendationAnnotationTags
        alternateDrugAvailable={alternateDrugAvailable}
        otherPrescribingGuidance={otherPrescribingGuidance}
        dosingInformation={dosingInformation}
      />
      <dl>
        {size(genotypes) > 0 && (
          <>
            <dt>Submitted Genotype</dt>
            <dd>
              <ul className="list-unstyled mb-0">
                {map(Object.keys(genotypes), (gene) => <li key={gene}>{gene}: {genotypes[gene]}</li>)}
              </ul>
            </dd>
          </>
        )}
        <dt>Matched Phenotype</dt>
        <dd>
          {size(phenotypes) > 0 && (
            <ul className="list-unstyled mb-0">
              {map(Object.keys(phenotypes), (gene) => <li key={gene}>{gene}: {phenotypes[gene]}</li>)}
            </ul>
          )}
          {/* HLA's (and only HLA's) can fall back to the lookupKey for the phenotype value */}
          {size(phenotypes) === 0 && (
            <ul className="list-unstyled mb-0">
              {map(filter(Object.keys(lookupKey), (gene) => gene.startsWith('HLA')), (gene) => <li key={gene}>{gene}: {lookupKey[gene]}</li>)}
            </ul>
          )}
        </dd>
        {(multipopulation || isPgxAssoc) && (
          <>
            <dt>{populationTitle}</dt>
            <dd>{displayPopulation}</dd>
          </>
        )}
        {implications?.length > 0 && (
          <>
            <dt>Implications</dt>
            <dd>
              <ul className="list-unstyled mb-0">
                {map(implications, (implication) => <li key={implication}>{implication}</li>)}
              </ul>
            </dd>
          </>
        )}
        <dt>{recommendationTitle}</dt>
        <dd><HtmlContainer html={text?.html} /></dd>
        {parentSource === 'CPIC' && classification?.term && (
          <>
            <dt>Recommendation Strength</dt>
            <dd>{classification?.term}</dd>
          </>
        )}
      </dl>
    </div>
  );
}
