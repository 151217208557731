import numeral from 'numeraljs';
import PropTypes from 'prop-types';

import './ResourceCounts.scss';

import fdaLabelIcon from 'images/icons/fdaLabel.svg';

import Svg from 'components/shared/Svg';
import Types from 'conf/types';


const propTypes = {
  pgkbPathways: PropTypes.number,
  dosingGuidelines: PropTypes.number,
  drugLabels: PropTypes.number,
  fdaDrugLabels: PropTypes.number,
};
/**
 * Renders resource counts on home page.
 */
export default function ResourceCounts({
  fdaDrugLabels = 0, pgkbPathways = 0, dosingGuidelines = 0, drugLabels = 0,
}) {

  return (
    <div className="container resource-counts">
      <div className="row">
        <a href="/guidelineAnnotations" className="col-6 col-md-3 stat dosing">
          <div className="stat-name">Clinical Guideline<br />Annotations</div>
          <div className="stat-count guideline">
            <Svg src={Types.guidelineAnnotation.icon} />
            {numeral(dosingGuidelines).format('0,0')}
          </div>
        </a>

        <a href="/labelAnnotations" className="col-6 col-md-3 stat labels">
          <div className="stat-name">Drug Label<br />Annotations</div>
          <div className="stat-count labelAnnotation">
            <Svg src={Types.labelAnnotation.icon} />
            {numeral(drugLabels).format('0,0')}
          </div>
        </a>

        <a href="/fdaLabelAnnotations" className="col-6 col-md-3 stat tour-fdaLabels">
          <div className="stat-name">FDA Drug Label<br />Annotations</div>
          <div className="stat-count labelAnnotation">
            <Svg src={fdaLabelIcon} />
            <span>{numeral(fdaDrugLabels).format('0,0')}</span>
          </div>
        </a>

        <a href="/pathways" className="col-6 col-md-3 stat pathways">
          <div className="stat-name">Curated<br />Pathways</div>
          <div className="stat-count pathway">
            <Svg src={Types.pathway.icon} />
            {numeral(pgkbPathways).format('0,0')}
          </div>
        </a>

      </div>
    </div>
  );
}
ResourceCounts.propTypes = propTypes;
