import {map} from 'lodash';
import PropTypes from 'prop-types';

import Answer from 'components/templates/Outreach/Answer';
import Question from 'components/templates/Outreach/Question';


const propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  text: PropTypes.node.isRequired,
  visible: PropTypes.bool,
};
export default function QuestionSection({title, sections, text, visible = false}) {
  const questionSections = sections
    ? map(sections, (section, key) =>
      (
        <Question
          key={key}
          className="nested-question"
          title={section.title}
          visible={false}
        >
          <Answer text={section.text} />
        </Question>
      ))
    : '';

  return (
    questionSections
      ? (
        <Question title={title} visible={visible}>
          {questionSections}
        </Question>
      )
      : (
        <Question title={title} visible={visible}>
          <Answer text={text} />
        </Question>
      )
  );
}
QuestionSection.propTypes = propTypes;
