import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {isError, map, without} from 'lodash';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';

import Loading from 'components/Loading';
import ErrorMessage from 'components/errors/ErrorMessage';
import Link from 'components/links/Link';
import useAppContext from 'conf/AppContext';
import logger from 'conf/Logger';
import copyToClipboard from 'helpers/clipboard';


const propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  kyError: PropTypes.instanceOf(Error),
};

export default function KyError({title, subtitle, kyError}) {
  const appContext = useAppContext();
  const [data, setData] = useState({loading: false, message: null, status: 0});

  useEffect(() => {
    if (!kyError) {
      return;
    }
    let mounted = true;

    if (kyError.name === 'AbortError') {
      setData({status: -1, loading: false, message: null});
      return;
    }
    if (kyError.name === 'TimeoutError') {
      setData({status: -2, loading: false, message: null});
      return;
    }
    if (kyError.name === 'FetchError') {
      logger.error(kyError);
    }

    if (kyError.name === 'HTTPError') {
      const resolveResponse = async () => {
        setData({loading: true, message: null, status: 0});
        const message = await appContext.api.readResponse(kyError.response);
        if (mounted) {
          setData({
            loading: false,
            status: kyError.response.status,
            isJson: appContext.api.isResponseJson(kyError.response),
            message,
            error: kyError,
          });
        }
      };
      // noinspection JSIgnoredPromiseFromCall
      resolveResponse();
    } else if (kyError.name === 'TypeError' && kyError.message === 'Failed to fetch') {
      const checkNetwork = async () => {
        if (await appContext.isApiUp()) {
          if (mounted) {
            setData({status: -3, loading: false, message: null});
          }
        } else {
          setData({status: 0, loading: false, message: kyError.message});
        }
      };
      // noinspection JSIgnoredPromiseFromCall
      checkNetwork();
    } else if (isError(kyError)) {
      if (mounted) {
        setData({status: 0, loading: false, message: kyError.message});
      }
    }

    return () => {
      mounted = false;
    };
  }, [kyError]);

  if (!kyError) {
    return null;
  }
  if (data.loading) {
    return <Loading iconOnly={true} />;
  }
  if (data.status === -1) {
    // AbortError
    return <ErrorMessage title={title} subtitle={subtitle} message="Sorry, the request was cancelled." />;
  }
  if (data.status === -2) {
    // TimeoutError
    return (
      <ErrorMessage
        title={title}
        subtitle={subtitle}
        message="Sorry, the server took too long to respond.  Please try again."
      />
    );
  }
  if (data.status === -3) {
    let curatorNote = '';
    if (appContext.user || appContext.isPreview) {
      curatorNote = (
        <>
          It is probably a networking error, but the only way to tell for sure is
          to <Link href="https://pharmgkb.atlassian.net/wiki/spaces/OPS/pages/512032769/How+to+open+the+developer+console">check the console</Link>.
        </>
      );
    }
    // networking error
    return (
      <ErrorMessage
        title={title || 'Network Error'}
        subtitle={subtitle}
        message={(
          <p>
            Something went wrong while connecting to the server. {curatorNote}
          </p>
        )}
      />
    );
  }

  if (data.status === 401 || data.status === 403) {
    return (
      <ErrorMessage
        title={title || <h4><FontAwesomeIcon icon={['fal', 'hand-paper']} /> Hold Up!</h4>}
        subtitle={subtitle}
        message="Sorry about that, but you do not have access to this page."
      />
    );
  }

  if (data.status === 404) {
    let msg = 'The resource you are looking for cannot be found.';
    if (data.isJson && data.message) {
      const jsend = data.message;
      const err = jsend?.data?.errors?.[0];
      if (err && err.message) {
        msg = err.message;
      }
    }
    return <ErrorMessage title={title} subtitle={subtitle} message={msg} />;
  }

  let msg = data?.message;
  if (data.isJson) {
    msg = renderJsend(data.status, data.message, appContext.isPreview);
  }

  return <ErrorMessage title={title} subtitle={subtitle} message={msg} />;
}

KyError.propTypes = propTypes;


function renderJsend(statusCode, jsend, isPreview) {
  if (!isPreview) {
    if (jsend.message) {
      return <p>{jsend.message}{endWithPeriod(jsend.message)}</p>;
    }
    return undefined;
  }

  let body;
  if (jsend.code === 'dependency') {
    body = renderJsendDependencyError(jsend);
  } else {
    const errors = renderJsendErrors(jsend.data.errors);
    const fieldErrors = renderJsendFieldErrors(jsend);
    if (errors || fieldErrors) {
      body = (
        <>
          {errors}
          {fieldErrors}
        </>
      );
    } else if (jsend.message) {
      body = <p>${jsend.message}</p>;
    } else {
      body = <p>Something went wrong (status code {statusCode}).</p>;
    }
  }
  return (
    <>
      {body}
      <button
        type="button"
        className="btn btn-secondary btn-sm copyButton"
        data-clipboard-text={JSON.stringify(jsend)}
        title="Copy error message(s) to clipboard"
        onClick={copyToClipboard}
      >
        <FontAwesomeIcon icon="copy" /> copy to clipboard
      </button>
    </>
  );
}

function renderStacktrace(stacktrace) {
  if (!stacktrace) {
    return null;
  }
  return (
    <>
      <div>Stacktrace:</div>
      <p className="stacktrace">{stacktrace}</p>
    </>
  );
}

/**
 * Returns a period if the specified message doesn't end with punctuation, or an empty string if it
 * does.
 *
 * @param {string} msg
 * @return {string}
 */
function endWithPeriod(msg) {
  return msg.match(/[,;\\.?!"']$/) ? '' : '.';
}

function renderJsendErrors(errors) {
  if (!errors || errors.length === 0) {
    return;
  }

  if (errors.length > 1) {
    return (
      <>
        <p>Some errors occurred:</p>
        <ul>
          {map(errors, (err, key) => <li key={key}>{err.message}{endWithPeriod(err.message)}</li>)}
        </ul>
      </>
    );
  } else {
    const error = errors[0];
    return (
      <>
        {error.message && <p>{error.message}{endWithPeriod(error.message)}</p>}
        {renderStacktrace(error.stacktrace)}
      </>
    );
  }
}

/**
 * Sample error:
 * ```
 {
  "status" : "error",
  "data" : {
    "errors" : [ {
      "message" : "aaaiiieee!"
    }, {
      "message" : "gaargh!"
    } ],
    "prop2" : [ {
      "message" : "oops"
    } ],
    "prop1" : [ {
      "message" : "your bad"
    } ]
  },
  "message" : "Multiple errors found."
}
 ```
 */
function renderJsendFieldErrors(jsend) {
  const dataKeys = without(Object.keys(jsend.data), 'dependencies', 'errors');
  if (dataKeys.length === 0) {
    return;
  }

  return (
    <>
      <p>There are errors with the following form fields:</p>
      <ul>
        {map(dataKeys, (field) => (
          <li key={field}>
            {field}:
            <ul>
              {map(jsend.data[field], (error, idx) => <li key={`${field}${idx}`}>{error.message}</li>)}
            </ul>
          </li>
        ))}
      </ul>
    </>
  );
}

/**
 * Checks whether JSEND response has field errors.
 *
 * @param {object} jsend
 * @return {boolean} true if has field errors
 */
export function hasJsendFieldErrors(jsend) {
  return without(Object.keys(jsend.data), 'dependencies', 'errors').length !== 0;
}


/**
 * Dependency errors cannot be combined with anything else.
 * Sample error:
 * ```
 {
  "status" : "error",
  "code" : "dependency",
  "data" : {
    "dependencies" : [ {
      "dependencyType" : "UNAPPROVED",
      "id" : "PA1",
      "name" : "GENE1",
      "objectType" : "Gene",
      "url" : "/gene/PA1"
    }, {
      "dependencyType" : "UNAPPROVED",
      "id" : "-10",
      "name" : "Clinical Annotation -10",
      "objectType" : "Clinical Annotation",
      "url" : "/clinicalAnnotation/-10"
    } ]
  },
  "message" : "Dependency problem."
}
 ```
 */
function renderJsendDependencyError(jsend) {
  if (!jsend?.data?.dependencies || jsend?.data?.dependencies?.length === 0) {
    if (jsend.message) {
      return <p>{jsend.message}</p>;
    }
    return <p>A dependency problem occurred.</p>;
  }

  const mapDependency = (dep) => {
    const label = dep.name ? dep.name : dep.id;
    const content = dep.url
      ? <Link href={dep.url} newTab={true}>{label}</Link>
      : label;
    return <li key={dep.id}>{content}</li>;
  };

  return (
    <>
      <p>
        {
          (jsend.data.dependencies[0].dependencyType === 'UNAPPROVED')
            ? 'Object cannot be approved because these dependencies have not yet been approved: '
            : 'Cannot perform requested action as this object is linked to the following' +
            ' entities: '
        }
      </p>
      <ul>
        {map(jsend.data.dependencies, mapDependency)}
      </ul>
    </>
  );
}
