import PropTypes from 'prop-types';

import './License.scss';
import fullIcon from 'images/cc-by-sa.svg';
import compactIcon from 'images/cc-by-sa_compact.svg';
import Svg from 'components/shared/Svg';


const propTypes = {
  compact: PropTypes.bool,
  ariaHidden: PropTypes.bool,
};

export default function LicenseIcon({compact = false, ariaHidden = false}) {
  const cls = compact ? 'licenseIcon_compact' : 'licenseIcon';
  const icon = compact ? compactIcon : fullIcon;

  return (
    <a href="/page/dataUsagePolicy" rel="license" className={cls} aria-hidden={ariaHidden}>
      <Svg src={icon} inline={true} /><span className="screen-reader-text">PharmGKB data usage policy</span>
    </a>
  );
}
LicenseIcon.propTypes = propTypes;
