import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {filter, find, join, map, sortBy} from 'lodash';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';

import Button from 'components/Button';
import DeleteButton from 'components/Button/Delete';
import EditButton from 'components/Button/Edit';
import Section from 'components/Section';
import EditControls from 'components/edit/EditControls';
import KyError from 'components/errors/KyError';
import Empty from 'components/shared/empty';
import useAppContext from 'conf/AppContext';
import {multilinkAnnProps} from 'conf/types';
import {englishJoin} from 'helpers/util';

const propTypes = {
  literatureId: PropTypes.number.isRequired,
  relationships: PropTypes.arrayOf(multilinkAnnProps),
  vipRelationships: PropTypes.object,
  newRel: PropTypes.number,
};
/**
 * A component for displaying Related in Paper
 *
 * @param {object} props - props container
 * @param {number} props.literatureId - the ID of the literature object
 * @param {Array} props.relationships - relationships of literature
 * @param {object} props.vipRelationships - contains vip key/value pair
 * @param {string} props.newRel - new relationship id
 * @return {ReactElement} list of all existing annotations
 */
export default function RelatedInPaper({literatureId, relationships = [], vipRelationships, newRel}) {
  const appContext = useAppContext();
  const [data, setData] = useState(sortRelationships(relationships, newRel));

  useEffect(() => {
    if (newRel) {
      const elem = document.getElementById('related_in_paper');
      elem.scrollIntoView({behavior: 'smooth'});
    }
  }, [newRel]);

  const deleteSuccessHandler = (id) => {
    toast.success('Deleted');
    setData(filter(data, (r) => r.id !== id));
  };

  const deleteFailureHandler = (id, err) => {
    appContext.toastError(<KyError kyError={err} />);
  };

  return (
    <Section
      title={`Related in Paper (${data.length})`}
      helpLink="/page/relatedInPaper"
      id="related_in_paper"
      editControls={(
        <Button href={`/edit/literature/${literatureId}/relatedInPaper`}><FontAwesomeIcon icon="plus" /> Add</Button>
      )}
    >
      {renderRelationships(data, vipRelationships, deleteSuccessHandler, deleteFailureHandler, newRel)}
    </Section>
  );
}
RelatedInPaper.propTypes = propTypes;


function sortRelationships(data, newRel) {

  let relationships = map(data, (r) => {
    const mapToDisplay = (o) => o.symbol || o.name;
    const objPhrase1 = englishJoin(map(r.object1s, mapToDisplay), r.operator1.toLowerCase());
    const objPhrase2 = englishJoin(map(r.object2s, mapToDisplay), r.operator2.toLowerCase());
    const types = join(map(r.terms, (t) => t.term));

    const termStr = types ? ` (${types})` : '';
    const verb = r.related ? 'is' : 'is not';
    return {
      id: r.id,
      text: `${objPhrase1} ${verb} related to ${objPhrase2}${termStr}`,
    };
  });

  relationships = sortBy(relationships, (r) => r.text.toLowerCase());
  if (newRel) {
    const rel = find(relationships, (r) => r.id === newRel);
    if (rel) {
      const filteredData = filter(relationships, (r) => r?.id !== newRel);
      filteredData.unshift(rel);
      return filteredData;
    }
  }
  return relationships;
}

function renderRelationships(data = [], vipRelationships, deleteSuccessHandler, deleteFailureHandler, newRel) {
  if (data.length === 0) {
    return <Empty>No relationships annotated.</Empty>;
  }
  return (
    <ul>
      {map(data, (r) => (
        <Relationship
          key={r.id}
          {...r}
          vipRelationships={vipRelationships}
          deleteSuccessHandler={deleteSuccessHandler}
          deleteFailureHandler={deleteFailureHandler}
          newRel={newRel}
        />
      ))}
    </ul>
  );
}

function Relationship({
  id,
  vipRelationships = {},
  deleteSuccessHandler,
  deleteFailureHandler,
  text,
  newRel,
}) {
  return (
    <li className={clsx({highlight: id === newRel})}>
      {text}
      <EditControls className="ml-2 editControls--inline">
        {vipRelationships[id] ? (
          <EditButton
            iconOnly={true}
            href={`/edit/vip/${vipRelationships[id]}#relationships`}
            label="Edit VIP"
          />
        ) : (
          <DeleteButton
            iconOnly={true}
            id={id}
            url={`curation/multilinkAnnotation/${id}`}
            confirmMessage="Delete this relation?"
            onSuccess={deleteSuccessHandler}
            onFailure={deleteFailureHandler}
          />
        )}
      </EditControls>
    </li>
  );
}
