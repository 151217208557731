import {lazy, Suspense} from 'react';

import Loading from 'components/Loading';


// noinspection JSCheckFunctionSignatures
const BarChart = lazy(() =>
  import(/* webpackChunkName: "chart" */'components/Chart/Bar'));


export default function LazyBarChart(args) {
  return (
    <Suspense fallback={<Loading />}>
      <BarChart {...args} />
    </Suspense>
  );
}
