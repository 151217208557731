import {isArray} from 'lodash';


/**
 * Sorts cells with arrays of strings using the first value in the array.
 */
export function sortArrayOfStrings(rowA, rowB, columnId) {
  let a = rowA.values[columnId] || '';
  let b = rowB.values[columnId] || '';
  if (isArray(a)) {
    if (a.length > 0) {
      a = a[0];
    } else {
      a = '';
    }
  }
  if (isArray(b)) {
    if (b.length > 0) {
      b = b[0];
    } else {
      b = '';
    }
  }
  return a.localeCompare(b);
}


/**
 * Sorts cells with boolean values.
 */
export function sortBooleans(rowA, rowB, columnId) {
  const a = !!rowA.values[columnId];
  const b = !!rowB.values[columnId];
  if (a === b) {
    return 0;
  }
  if (a) {
    return -1;
  }
  return 1;
}
