import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import useAppContext from 'conf/AppContext';
import License from 'components/License';
import DownloadLink from 'components/links/Download';


const propTypes = {
  pathway: PropTypes.shape({
    id: PropTypes.string.isRequired,
    biopaxLink: PropTypes.string,
    gpmlLink: PropTypes.string,
    pdfLink: PropTypes.string,
    illustratorLink: PropTypes.string,
  }),
};
/**
 * Renders pathway downloads tab.
 */
export default function PathwayDownloadsTab({pathway}) {
  const appContext = useAppContext();
  const {id, biopaxLink, gpmlLink, pdfLink, illustratorLink} = pathway;
  const tsvUrl = appContext.apiUrl(`/download/pathway/${id}?format=.tsv`);

  const renderEditWarning = () => {
    // only display if not on preview AND signed in
    if (appContext.isPreview || !appContext.user) {
      return '';
    }
    return (
      <div className="alert alert-danger" role="alert">
        If you are planning to edit these files, please download the latest versions from Preview.
      </div>
    );
  };

  const renderDownload = (url, icon, name, format) => {
    if (!url) {
      return '';
    }
    return (
      <p>
        <DownloadLink href={url} icon={icon}>{name}</DownloadLink> ({format})
      </p>
    );
  };

  const renderCuratorDownloads = () => {
    if (appContext.user) {
      return renderDownload(illustratorLink,
        <FontAwesomeIcon icon={['fal', 'file-image']} />, 'Download Pathway Diagram', 'Illustrator');
    }
  };

  const renderBiopaxDownload = () => {
    if (!biopaxLink) {
      return null;
    }
    return renderDownload(appContext.apiUrl(`/download/file/${biopaxLink}`),
      <FontAwesomeIcon icon={['fal', 'file-code']} />, 'Download Pathway Data', 'BioPax Format');
  };

  const renderGpmlDownload = () => {
    if (!gpmlLink) {
      return null;
    }
    return renderDownload(appContext.apiUrl(`/download/file/${gpmlLink}`),
      <FontAwesomeIcon icon={['fal', 'file-code']} />, 'Download Pathway Data', 'GPML Format');
  };

  return (
    <>
      <h3>Pathway Downloads</h3>
      <License />
      {renderEditWarning()}

      {renderCuratorDownloads()}
      {renderDownload(pdfLink, <FontAwesomeIcon icon={['fal', 'file-pdf']} />, 'Download Pathway Diagram', 'PDF')}
      {renderDownload(tsvUrl, <FontAwesomeIcon icon={['fal', 'file-excel']} />, 'Download Pathway Data', 'TSV Format')}
      {renderBiopaxDownload()}
      {renderGpmlDownload()}
    </>
  );
}
PathwayDownloadsTab.propTypes = propTypes;
