import React from 'react';
import PropTypes from 'prop-types';
import Parser from 'html-react-parser';

import StyledTable from 'components/shared/styledTable';
import Loading from 'components/Loading';

export default class TableStylizer extends React.Component {
  static displayName = 'Table Stylizer';

  static propTypes = {
    html: PropTypes.string,
    isInSidebarLayout: PropTypes.bool,
  };

  static defaultProps = {
    isInSidebarLayout: true,
  };

  render = () => {
    const {html, isInSidebarLayout} = this.props;
    if (!html) { return <Loading />; }

    const styledHTML = Parser(html, {
      replace: (domNode) => {
        if (domNode.name === 'table') {
          return (
            <StyledTable
              isInSidebarLayout={isInSidebarLayout}
              tableHTML={domNode.children}
            />
          );
        } else {
          return domNode;
        }
      },
    });

    return (
      <div>
        {styledHTML}
      </div>
    );
  };
}
