/* eslint-env browser */


/**
 * Send an analytics event.
 *
 * @param {string} name
 * @param {object} [data]
 */
export function analyticsEvent(name, data) {
  if (window.umami) {
    window.umami.track(name, data);
  }
}
