import {map} from 'lodash';
import numeral from 'numeraljs';
import PropTypes from 'prop-types';

import {baseAccIdColumn} from 'components/Table/Cell/AccId';
import {baseLiteratureColumn} from 'components/Table/Cell/Literature';
import {baseVariantLocationColumn} from 'components/Table/Cell/VarianLocation';
import SelectFilter from 'components/Table/Filter/Select';
import TextFilter from 'components/Table/Filter/Text';
import SimpleTable from 'components/Table/Simple';
import VirtualizedTable from 'components/Table/Virtualized';
import {defaultPediatricColumn, defaultViewLinkColumn, flexColumnStyle} from 'components/Table/columnHelpers';
import {renderTotalDataFn, TableType} from 'components/Table/utils';
import {sortArrayOfStrings} from 'components/Table/comparators';

const literatureCol = {
  id: 'literature',
  Header: 'Literature',
  accessor: (originalRow) => {
    if (originalRow?.properties?.literature) {
      return originalRow.properties.literature;
    }
    return originalRow.literature;
  },
  ...baseLiteratureColumn,
  ...flexColumnStyle({width: 180}),
};

const geneCol = {
  id: 'genes',
  Header: 'Genes',
  accessor: (originalRow) => {
    if (originalRow?.properties?.location?.genes) {
      return originalRow.properties.location.genes;
    }
    return originalRow?.location?.genes;
  },
  ...baseAccIdColumn,
  ...flexColumnStyle({width: 110}),
};

const chemicalCol = {
  id: 'chemicals',
  Header: 'Drugs',
  accessor: (originalRow) => {
    if (originalRow?.properties?.chemicals) {
      return originalRow.properties.chemicals;
    }
    return originalRow.chemicals;
  },
  ...baseAccIdColumn,
  ...flexColumnStyle({width: 150}),
};

const mapToFront = {
  chemical: [literatureCol, geneCol],
  gene: [literatureCol, chemicalCol],
  variant: [literatureCol, chemicalCol, geneCol],
  haplotype: [literatureCol, chemicalCol],
  disease: [literatureCol, geneCol],
  literature: [geneCol, chemicalCol],
};

const mapToEnd = {
  chemical: [chemicalCol],
  gene: [geneCol],
  variant: [], // Variant column is sticky, so it doesn't need to go at the end.
  haplotype: [geneCol],
  disease: [chemicalCol],
  literature: [literatureCol],
};


const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  objCls: PropTypes.string,
  showPediatricColumn: PropTypes.bool,
  type: PropTypes.oneOf([TableType.SIMPLE, TableType.VIRTUALIZED]),
  height: PropTypes.string,

  // standard table props
  id: PropTypes.string,
  canShowFullscreen: PropTypes.bool,
  canEditColumns: PropTypes.bool,
  canHideFilters: PropTypes.bool,
  updatedFilters: PropTypes.arrayOf(PropTypes.object),
  // downloadTableProps
  disableExport: PropTypes.bool,
};
/**
 * Renders a table of variant annotations.
 */
export default function VariantAnnotationTable({data, objCls = 'Variant', showPediatricColumn = true,
  type = TableType.VIRTUALIZED, height,
  id, canShowFullscreen = true, canEditColumns = true, canHideFilters = true, disableExport = false, updatedFilters}) {
  const mapPediatricCol = showPediatricColumn ? [defaultPediatricColumn] : [];
  const frontCols = objCls === 'Variant' ? [geneCol, chemicalCol] : map(mapToFront[objCls]);
  const endCols = objCls === 'Variant' ? [literatureCol] : map(mapToEnd[objCls]);
  const columns = [
    {
      ...defaultViewLinkColumn,
      sticky: 'left',
    },
    {
      id: 'variants',
      Header: 'Variant',
      accessor: (originalRow) => {
        if (originalRow?.properties?.location) {
          return {...originalRow.properties.location, metabolizers1: originalRow.properties.metabolizers1};
        }
        return {...originalRow?.location, metabolizers1: originalRow?.metabolizers1};
      },
      ...baseVariantLocationColumn,
      sticky: 'left',
      ...flexColumnStyle({width: 150}),
    },
    ...frontCols,
    {
      id: 'sentence',
      Header: 'Association',
      accessor: (originalRow) => {
        if (originalRow?.properties?.sentence) {
          return originalRow.properties.sentence;
        }
        return originalRow.sentence;
      },
      Filter: TextFilter,
      ...flexColumnStyle({width: 400, grow: 2}),
    },
    {
      id: 'significance',
      Header: 'Significance',
      accessor: (originalRow) => {
        if (originalRow?.properties?.significance) {
          return originalRow.properties.significance;
        }
        return originalRow.significance;
      },
      sortable: true,
      Filter: SelectFilter,
      ...flexColumnStyle({width: 150}),
    },
    {
      id: 'pValue',
      Header: 'P-Value',
      accessor: (originalRow) => {
        if (originalRow?.properties?.pValue) {
          return parseFloat(originalRow.properties.pValue);
        }
        return originalRow.pValue ? parseFloat(originalRow.pValue) : '';
      },
      // eslint-disable-next-line react/prop-types
      Cell: ({row, value}) => {
        let pValueOp;
        if (row.original?.properties?.pValueOp) {
          pValueOp = row.original.properties.pValueOp;
        } else {
          pValueOp = row.original.pValueOp;
        }
        return value ? `${pValueOp} ${value}` : null;
      },
      sortable: true,
      Filter: TextFilter,
      getCellExportValue: (row) => {
        // eslint-disable-next-line react/prop-types
        if (!row.original.pValue) {
          return '';
        }
        // eslint-disable-next-line react/prop-types
        return `${row.original.pValueOp} ${row.original.pValue}`;
      },
      ...flexColumnStyle({width: 100}),
    },
    {
      id: 'cases',
      Header: '# of Cases',
      accessor: (originalRow) => {
        if (originalRow?.properties?.cases) {
          return originalRow.properties.cases;
        }
        return originalRow.cases;
      },
      sortable: true,
      Cell: NumberCell,
      Filter: TextFilter,
      ...flexColumnStyle({width: 90}),
    },
    {
      id: 'controls',
      Header: '# of Controls',
      accessor: (originalRow) => {
        if (originalRow?.properties?.controls) {
          return originalRow.properties.controls;
        }
        return originalRow.controls;
      },
      sortable: true,
      Cell: NumberCell,
      Filter: TextFilter,
      ...flexColumnStyle({width: 130}),
    },
    {
      id: 'race',
      Header: 'Biogeographical Groups',
      accessor: (originalRow) => {
        if (originalRow?.properties?.race) {
          return originalRow.properties.race;
        }
        return originalRow.race;
      },
      sortable: true,
      Filter: TextFilter,
      ...flexColumnStyle({width: 180}),
    },
    {
      id: 'phenotypeCategories',
      Header: 'Phenotype Categories',
      accessor: (originalRow) => {
        if (originalRow?.properties?.phenotypeCategories) {
          return originalRow.properties.phenotypeCategories;
        }
        return originalRow.phenotypeCategories;
      },
      Cell: CategoriesCell,
      sortable: true,
      sortType: sortArrayOfStrings,
      Filter: TextFilter,
      ...flexColumnStyle({width: 160}),
    },
    ...mapPediatricCol,
    {
      id: 'notes',
      Header: 'More Details',
      accessor: (originalRow) => {
        if (originalRow?.properties?.description) {
          return originalRow.properties.description;
        }
        return originalRow.notes;
      },
      Filter: TextFilter,
      ...flexColumnStyle({width: 400, grow: 2}),
    },
    ...endCols,
  ];

  const renderTotalFn = (numVisible) => renderTotalDataFn(data, numVisible);

  if (type === TableType.SIMPLE || data.length < 10) {
    return (
      <SimpleTable
        id={id}
        columns={columns}
        data={data}
        canEditColumns={canEditColumns}
        canHideFilters={canHideFilters}
        canShowFullscreen={canShowFullscreen}
        disableExport={disableExport}
        className="variantAnnotationsTable"
        updatedFilters={updatedFilters}
        renderTotalFn={renderTotalFn}
      />
    );
  } else {
    return (
      <VirtualizedTable
        height={height}
        id={id}
        columns={columns}
        data={data}
        canEditColumns={canEditColumns}
        canHideFilters={canHideFilters}
        canShowFullscreen={canShowFullscreen}
        disableExport={disableExport}
        className="variantAnnotationsTable"
        updatedFilters={updatedFilters}
        renderTotalFn={renderTotalFn}
      />
    );
  }
}
VariantAnnotationTable.propTypes = propTypes;


function NumberCell({value}) {
  return <div className="text-right">{numeral(value).format('0,0')}</div>;
}

function CategoriesCell({value}) {
  if (!value || value.length === 0) {
    return null;
  }
  return <ul className="pl-3">{map(value, (v) => <li key={v}>{v}</li>)}</ul>;
}
