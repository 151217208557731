import {find} from 'lodash';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet-async';

import SideNav from 'components/SideNav';
import OutreachSection from 'components/templates/Outreach/Section';
import ResourceHeader from 'components/templates/Resource/Header';


const propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  alert: PropTypes.node,
  data: PropTypes.shape({
    canCollapse: PropTypes.bool.isRequired,
    initialIsCollapsed: PropTypes.bool.isRequired,
    sections: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      image: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),

      sections: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
        interested: PropTypes.arrayOf(PropTypes.shape({
          type: PropTypes.string,
          text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
          by: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
          url: PropTypes.string.isRequired,
        }).isRequired),
        sections: PropTypes.arrayOf(PropTypes.shape({
          title: PropTypes.string.isRequired,
          text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
        }).isRequired),
      }).isRequired).isRequired,
    })).isRequired,
  }).isRequired,
  /**
   * Name of section (tab) to display.
   */
  section: PropTypes.string,
  baseUrl: PropTypes.string.isRequired,
};
/**
 * Renders an outreach page.
 */
export default function OutreachTemplate({title, subtitle, alert, data, section, baseUrl}) {
  const activeSection = section
    ? find(data.sections, (s) => s.name === section)
    : data.sections[0];

  const headerData = [{
    id: 0,
    name: title,
    type: 'static',
    icon: null,
    loaded: true,
  }];

  const sideNav = data.sections.map((sect) => ({
    url: `${baseUrl}/${sect.name}`,
    label: sect.title,
    iconType: sect.image,
  }));
  sideNav[0].otherUrls = [baseUrl];

  // must specify key for OutreachSection so that a new component is used when section changes
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main>
        <ResourceHeader data={headerData} subtitle={subtitle} />
        <div className="container">

          {alert && (
            <div className="mt-4">
              {alert}
            </div>
          )}

          <SideNav items={sideNav}>
            <OutreachSection
              key={activeSection.title}
              title={activeSection.title}
              text={activeSection.text}
              details={activeSection.sections}
              icon={activeSection.image}
              canCollapse={data.canCollapse}
              initialIsCollapsed={data.initialIsCollapsed}
            />
          </SideNav>
        </div>
      </main>
    </>
  );
}
OutreachTemplate.propTypes = propTypes;
