import clsx from 'clsx';
import {filter, forEach} from 'lodash';
import PropTypes from 'prop-types';
import {useMemo} from 'react';

const propTypes = {
  column: PropTypes.shape({
    filterValue: PropTypes.string,
    setFilter: PropTypes.func,
    preFilteredRows: PropTypes.arrayOf(PropTypes.object),
    filteredRows: PropTypes.arrayOf(PropTypes.object),
    id: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
};
/**
 * Renders level filter for react-table.
 *
 * @param {object} props - props container
 * @param {object} [props.column]
 * @param {string} [props.column.filterValue]
 * @param {Array} [props.column.preFilteredRows]
 * @param {Array} [props.column.filteredRows]
 * @param {Function} [props.column.setFilter]
 * @param {string} [props.column.id]
 * @param {string} [props.className]
 * @return {ReactElement}
 */
export default function LevelFilter({column:
{filterValue = '', preFilteredRows, filteredRows, setFilter, id}, className}) {

  // Get all the available options for the column
  const options = useMemo(() => {
    const uniqueOptions = new Set();
    forEach(preFilteredRows, (row) => {

      const source = row.values[id];
      for (let x = 0; x < source.length; x += 1) {
        if (source[x].status) {
          uniqueOptions.add(source[x].status);
        }
      }
    });
    return Array.from(uniqueOptions).sort();
  }, [id, preFilteredRows]);

  // Get options to show, so we can disable options that wouldn't show any rows
  const enabledOptions = useMemo(() => {
    const uniqueOptions = new Set();
    forEach(filteredRows, (row) => {

      const source = row.values[id];
      for (let x = 0; x < source.length; x += 1) {
        if (source[x].status) {
          uniqueOptions.add(source[x].status);
        }
      }
    });
    return Array.from(uniqueOptions);
  }, [filteredRows]);

  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      className={clsx('custom-select w-100', filterValue && 'filterSet', className)}
    >
      <option value="">All</option>
      {options.map((option) => (
        <option key={option} value={option} disabled={!filterValue && !enabledOptions.includes(option)}>
          {option}
        </option>
      ))}
    </select>
  );
}
LevelFilter.protoTypes = propTypes;

/**
 * React-table `filter` for PGx Level.
 *
 * @param {Array} rows
 * @param {Array} columnIds
 * @param {Array|string} filterValue
 * @return {Array}
 */
export function pgxLevelFilter(rows, columnIds, filterValue) {
  const id = columnIds[0];
  return filter(rows, (r) => {
    const source = r.values[id];

    for (let x = 0; x < source.length; x += 1) {
      if (source[x].status === filterValue) {
        return true;
      }
    }
    return false;
  });
}
