import PropTypes from 'prop-types';
import {useWatch} from 'react-hook-form';

import FormText from 'components/form/Text';

const propTypes = {
  name: PropTypes.string.isRequired,
  matchFieldName: PropTypes.string.isRequired,
};
/**
 * A field to confirm the value entered in the password field
 *
 * @param {object} props - props container
 * @param {string} props.name - the name of this confirmation field
 * @param {string} props.matchFieldName - the name of the password field to be matched (usually "password")
 * @return {React.ReactNode}
 */
export default function PasswordConfirmationField({name, matchFieldName}) {
  const password = useWatch({name: matchFieldName});

  return (
    <FormText
      name={name}
      label="Confirm New Password"
      type="password"
      required={true}
      validation={{
        matches: (v) => v === password || 'Does not match new password',
      }}
    />
  );
}
PasswordConfirmationField.propTypes = propTypes;
