import Tag from 'components/Tag';
import AlternateDrugTag from 'components/Tag/AlternateDrug';
import DosingInfoTag from 'components/Tag/DosingInfo';
import OtherGuidanceTag from 'components/Tag/OtherGuidance';
import Types from 'conf/types';

export default function RecommendationAnnotationTags({
  alternateDrugAvailable, dosingInformation, otherPrescribingGuidance, parentCls,
}) {
  let context = 'guidelineAnnotation';
  if (parentCls !== Types.guidelineAnnotation.displayType) {
    context = 'labelAnnotation';
  }
  const tags = [];
  if (alternateDrugAvailable) {
    tags.push(<AlternateDrugTag key="altDrug" context={context} />);
  }
  if (dosingInformation) {
    tags.push(<DosingInfoTag key="dosingInfo" context={context} />);
  }
  if (otherPrescribingGuidance) {
    tags.push(<OtherGuidanceTag key="other" context={context} />);
  }
  if (tags.length === 0) {
    tags.push(<Tag key="noAction">No Action</Tag>);
  }
  
  return <div className="tagContainer">{tags}</div>;
}
