import clsx from 'clsx';
import {forEach, isArray, join, map} from 'lodash';
import PropTypes from 'prop-types';

import AlternateDrugTag from 'components/Tag/AlternateDrug';
import {biomarkerTagFor} from 'components/Tag/Biomarker';
import DosingInfoTag from 'components/Tag/DosingInfo';
import {CancerTag, IndicationTag, PrescribingTag} from 'components/Tag/LabelAnnotation';
import OtherGuidanceTag from 'components/Tag/OtherGuidance';
import PediatricTag from 'components/Tag/Pediatric';
import {getLevelId, sortLaLevels} from 'utils/pgxLevelUtils';


const propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
};
/**
 * Renders a table cell containing source.
 *
 * @param {object} props - props container
 * @param {object | Array} [props.value]
 * @return {ReactElement}
 */
export default function SourceCell({value}) {
  const renderLabelItem = (l) => (
    <li key={l.url}><LabelItem {...l} /></li>
  );

  const listContent = isArray(value)
    ? map(value.sort(sortLaLevels), renderLabelItem)
    : renderLabelItem(value);

  return (
    <ul className="list list-unstyled mb-0">
      {listContent}
    </ul>
  );
}
SourceCell.propTypes = propTypes;

/**
 * Renders label item for source.
 *
 * @param {object} props - props container
 * @param {string} props.status
 * @param {string} props.url
 * @param {boolean} props.dosingInfo
 * @param {boolean} props.altDrugAvailable
 * @param {boolean} props.otherPrescribingGuidance
 * @param {boolean} props.cancerGenome
 * @param {boolean} props.prescribing
 * @param {boolean} props.pediatric
 * @param {string} props.biomarkerText
 * @param {boolean} props.indication
 * @param {Array<object>} props.genes
 * @return {JSX.Element}
 */
function LabelItem({
  status, url, biomarkerText, dosingInfo, altDrugAvailable, otherPrescribingGuidance,
  cancerGenome, prescribing, pediatric, indication, genes,
}) {
  return (
    <div>
      <LabelStatus status={status} genes={genes} url={url} />
      <div className="tagContainer">
        {biomarkerTagFor({status: biomarkerText, className: 'tag--sm'})}
        {dosingInfo ? <DosingInfoTag context="labelAnnotation" className="tag--sm" /> : null}
        {altDrugAvailable ? <AlternateDrugTag context="labelAnnotation" className="tag--sm" /> : null}
        {otherPrescribingGuidance ? <OtherGuidanceTag context="labelAnnotation" className="tag--sm" /> : null}
        {prescribing ? <PrescribingTag className="tag--sm" /> : null}
        {indication ? <IndicationTag className="tag--sm" /> : null}
        {cancerGenome ? <CancerTag className="tag--sm" /> : null}
        {pediatric ? <PediatricTag className="tag--sm" /> : null}
      </div>
    </div>
  );
}

/**
 * Renders label status for source.
 *
 * @param {object} props - props container
 * @param {string} props.status
 * @param {string} props.url
 * @param {Array<object>} props.genes
 * @return {JSX.Element}
 */
function LabelStatus({status, genes, url}) {
  const geneSymbols = map(genes, (gene) => gene.symbol);
  const geneSymbolsStr = geneSymbols?.length > 0 && `${geneSymbols.join(', ')}: `;

  return (
    <div className={clsx('label-status', `laLevelTag laLevelTag--${getLevelId(status)}`)}>
      {status === 'No label found'
        ? (
          <span>{status}</span>
        ) : (
          <a href={url}>
            <div>{geneSymbolsStr}</div>
            <div>{status}</div>
          </a>
        )}
    </div>
  );
}

/**
 * React-table `getCellExportValue` for source.
 *
 * @param {object} row
 * @param {object} column
 * @return {string}
 */
export function sourceExportValue(row, column) {

  const source = row.values[column.id];

  let output = '';
  const url = 'https://www.pharmgkb.org';

  forEach(source, (val, index) => {

    const tagArray = [];
    if (val.status) {
      output += val.status;
    }
    if (val.altDrugAvailable) {
      tagArray.push('Alternate Drug');
    }
    if (val.otherPrescribingGuidance) {
      tagArray.push('Other Prescribing Guidance');
    }
    if (val.cancerGenome) {
      tagArray.push('Cancer Genome');
    }
    if (val.dosingInfo) {
      tagArray.push('Dosing Info');
    }
    if (val.prescribing) {
      tagArray.push('Prescribing Info');
    }
    if (val.indication) {
      tagArray.push('Indication');
    }

    const rez = join(tagArray, ' / ');
    output += rez ? ` (${rez})` : '';

    if (val.url) {
      output += ` - ${url + val.url}`;
    }

    if (index < source.length - 1) {
      output += ', ';
    }
  });

  return output;
}
