import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {filter, map} from 'lodash';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Citation from 'components/Literature/Citation';
import Tag from 'components/Tag';
import Types from 'conf/types';


const propTypes = {
  literature: PropTypes.arrayOf(PropTypes.object),
  literatureInteractionCounts: PropTypes.object,
};
export default function LabelAnnotationSources({literature, literatureInteractionCounts}) {
  return (
    <ol className={clsx({'pl-3': literature.length !== 1}, {'list-unstyled': literature.length === 1})}>
      {map(literature, (lit) => {
        const counts = literatureInteractionCounts[lit.id] ?? {drugCount: 0, geneCount: 0};
        const hasTags = filter(lit?.terms, (tag) => /Has( No)? PGx/.test(tag?.term))
          .map((tag) => <Tag className="tag--sm" key={tag.id}>{tag.term}</Tag>);
        return (
          <li key={lit.id} className="mb-1">
            <Citation hideLinks={true} {...lit} />
            {hasTags}
            {counts.drugCount > 0 && (
              <Button className="btn-light btn-sm" href={Types.literature.url(lit.id) + '#drugDrug'}>
                Drug-Drug Interactions <span className="badge badge-dark">{counts.drugCount}</span>
              </Button>
            )}
            {counts.geneCount > 0 && (
              <Button className="btn-light btn-sm" href={Types.literature.url(lit.id) + '#drugGene'}>
                Drug-Gene Interactions <span className="badge badge-dark">{counts.geneCount}</span>
              </Button>
            )}
            {lit.fullTextUrl && (
              <Button
                href={lit.fullTextUrl}
                title="View Full Text"
                className="btn btn-sm btn-primary"
              >
                <FontAwesomeIcon icon="fa-regular fa-file-lines" /> View Full Text
              </Button>
            )}
            {lit.notes && <div>{lit.notes}</div>}
          </li>
        );
      })}
    </ol>
  );
}
LabelAnnotationSources.propTypes = propTypes;
