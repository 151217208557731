import numeral from 'numeraljs';
import PropTypes from 'prop-types';

import './File.scss';
import DownloadLink from 'components/links/Download/index';
import useAppContext from 'conf/AppContext';
import {extractProps} from 'utils/propUtils';


const propTypes = {
  /**
   * File object.
   * _fileName_ property is required if _children_ is not specified.
   */
  file: PropTypes.shape({
    fileName: PropTypes.string,
    path: PropTypes.string,
    size: PropTypes.number,
  }),
  iconOnly: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Used to set the `title` attribute as well as `aria-label` attribute if `aria-label` is not defined.
   */
  title: PropTypes.string,
  /**
   * The link's text (defaults to _file.fileName_ if this is not specified).
   */
  children: PropTypes.node,
};
/**
 * Renders a download link that triggers download survey before download.
 */
export default function FileDownloadLink({file, iconOnly = false, className, title, children, ...otherProps}) {
  const appContext = useAppContext();

  if (!file?.path) {
    return <a href="#" className="text-danger">missing file.path</a>;
  }
  let text = children;
  if (!children && !iconOnly) {
    if (!file?.fileName) {
      return <a href="#" className="text-danger">missing file.fileName</a>;
    }
    text = file.fileName;
  }

  const url = appContext.apiUrl(`/download/file/${file.path}`);
  const renderFileSize = () => {
    if (!iconOnly && file?.size) {
      return (<small className="filesize">{numeral(file.size).format('0.1 b')}</small>);
    } else {
      return '';
    }
  };

  return (
    <>
      <DownloadLink href={url} iconOnly={iconOnly} className={className} title={title} {...extractProps(otherProps)}>{text}</DownloadLink>
      &nbsp;{renderFileSize()}
    </>
  );
}
FileDownloadLink.propTypes = propTypes;
