import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet-async';

import './Page.scss';
import ResourceHeader, {headerDataProps} from 'components/templates/Resource/Header';


const propTypes = {
  title: PropTypes.string,
  headerData: PropTypes.arrayOf(headerDataProps).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  readableWidth: PropTypes.bool,
  children: PropTypes.node,
};
/**
 * Renders a page with a resource header.
 *
 * @param {object} props - component properties
 * @param {string} [props.title] - page title
 * @param {Array} props.headerData
 * @param {string} [props.subtitle] - subtitle to display in header
 * @param {boolean} [props.readableWidth]
 * @param {React.ReactNode} props.children
 */
export default function ResourcePage({title, headerData, subtitle, readableWidth = false, children}) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main>
        <ResourceHeader data={headerData} subtitle={subtitle} />

        <div className="resourcePage__content">
          <div className="container">
            {readableWidth ? <div className="readable-width">{children}</div> : children}
          </div>
        </div>
      </main>
    </>
  );
}
ResourcePage.propTypes = propTypes;
