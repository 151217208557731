import clsx from 'clsx';
import {lowerCase, map, sortBy} from 'lodash';

import Loading from 'components/Loading';
import InlinePage from 'components/Page/Inline';
import {
  PrescribingDrugGuidelineTag,
  PrescribingDrugLabelTag,
  PrescribingDrugPgxAssocTag,
} from 'components/Tag/PrescribingDrug';
import KyError from 'components/errors/KyError';
import Link from 'components/links/Link';
import ReadableWidthTemplate from 'components/templates/ReadableWidth';
import useAppContext from 'conf/AppContext';
import {useGet} from 'helpers/KyHooks';

import './index.scss';

export default function Index() {
  const appContext = useAppContext();

  const {response, error} = useGet('site/prescribingTree', {
    cacheResponse: true,
  });
  if (error) {
    appContext.toastError(<KyError kyError={error} />);
  }

  const tree = {
    name: 'ATC Root',
    id: '_root',
    children: response?.data,
  };


  const makePrefix = (id, isChemical) => <span className={clsx('text-monospace', {'text-info': isChemical})}>{id}</span>;

  const makeTreeNode = (n) => {
    const {id, name, chemicalId, chemical, guideline, label, pgxAssoc} = n;
    // eslint-disable-next-line no-use-before-define
    const subList = makeTreeList(n);

    const nodeType = chemical ? 'prescribingTree--drugName' : 'prescribingTree--className';
    const chemicalHref = `/chemical/${chemicalId}/prescribingInfo`;
    const nodeName = chemical ? (<a href={chemicalHref}>{name}</a>) : name;

    const displayName = (
      <span className={nodeType}>{nodeName}</span>
    );
    const guidelineLabel = chemical && guideline && <PrescribingDrugGuidelineTag />;
    const pgxAssocLabel  = chemical && pgxAssoc && <PrescribingDrugPgxAssocTag />;
    const labelLabel     = chemical && label && <PrescribingDrugLabelTag />;
    const cn = n.id.length === 1 && !chemical ? 'prescribingTree--topLevel' : null;

    return (
      <li key={n.id} className={cn}>
        {makePrefix(id, chemical)} {displayName} {guidelineLabel} {labelLabel} {pgxAssocLabel}
        {subList}
      </li>
    );
  };

  const makeTreeList = (m) => {
    const {id, children} = m;
    if (!children || children.length === 0) {
      return null;
    }
    const sortedChildren = sortBy(children, (c) => lowerCase(c.id));
    const childLists = map(sortedChildren, (c) => makeTreeNode(c));
    const subListKey = id + '_kids';

    return (
      <ul className="prescribingTree" key={subListKey}>
        {childLists}
      </ul>
    );
  };

  if (!response && !error) {
    return (
      <ReadableWidthTemplate title="Prescribing Info">
        <Loading />
      </ReadableWidthTemplate>
    );
  }

  const headerText = response ? <InlinePage id="prescribingHeader" /> : null;

  return (
    <ReadableWidthTemplate title="Prescribing Info">
      <section className="fact-section">
        {headerText}
      </section>

      <div className="prescribingTopMatter">
        <h4>Tag Legend</h4>
        <ul className="list-unstyled">
          <li><PrescribingDrugGuidelineTag /> = has been annotated as part of a <Link href="/guidelineAnnotations">Clinical Guideline Annotation</Link>.
          </li>
          <li><PrescribingDrugLabelTag /> = has prescribing information annotated as part of an <Link href="/fdaLabelAnnotations">FDA Drug Label Annotation</Link>.
          </li>
          <li><PrescribingDrugPgxAssocTag /> = is listed on the
            {' '}
            <Link href="https://www.fda.gov/medical-devices/precision-medicine/table-pharmacogenetic-associations">FDA Table of Pharmacogenetic Associations</Link>.
          </li>
        </ul>
      </div>

      {makeTreeList(tree, true)}
    </ReadableWidthTemplate>
  );
}
