import clsx from 'clsx';
import {every, forEach} from 'lodash';
import PropTypes from 'prop-types';
import {useEffect, useMemo, useRef, useState} from 'react';

import BarChart from 'components/Chart/LazyBar';
import Loading from 'components/Loading';
import {getPhenotypeCategoryChartData} from 'utils/annotationUtils';
import {calculateAccIdKey} from 'utils/dependencyUtils';

const propTypes = {
  variantAnnotations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    objCls: PropTypes.string,
  })),
  objCls: PropTypes.string.isRequired,
  filterChangeFn: PropTypes.func,
  captionTitle: PropTypes.string,
  className: PropTypes.string,
};
/**
 * Renders variant annotations bar chart.
 *
 * @param {object} props - props container
 * @param {Array} [props.variantAnnotations]
 * @param {string} props.objCls
 * @param {Function} [props.filterChangeFn] - function to call whenever y-axis is changed
 * @param {string} props.captionTitle
 * @param {string} [props.className]
 * @return {JSX.Element}
 */
export default function VariantAnnotationsBarChart({variantAnnotations, objCls, filterChangeFn,
  captionTitle, className}) {
  const [yAxis, setYAxis] = useState((objCls === 'chemical' || objCls === 'disease') ? 'genes' : 'chemicals');
  const ref = useRef(yAxis);

  useEffect(() => {
    ref.current = yAxis;
  }, [yAxis]);

  const dependencyKey = calculateAccIdKey(variantAnnotations);

  const chartData = useMemo(() => {
    if (variantAnnotations) {
      if (yAxis === 'phenotypeCategories') {
        return getPhenotypeCategoryChartData(variantAnnotations);
      } else {
        return createGeneOrDrugChartData(variantAnnotations, objCls);
      }
    } else {
      return null;
    }
  }, [yAxis, dependencyKey]);

  if (!chartData) {
    return <Loading />;
  }

  const topFive = chartData?.total > 5 ? '(top 5)' : '';
  const hasData = chartData.total !== 0;

  let stepSize;
  if (hasData) {
    const isStepSize = every(chartData.data, (element) => element.value <= 5);
    if (isStepSize) {
      stepSize = 1;
    }
  }


  const handleClick = (label) => {
    if (filterChangeFn) {
      filterChangeFn([
        {
          id: ref.current,
          value: label,
        },
      ]);
    }
  };

  const handleChange = (e) => {
    setYAxis(e.target.value);
    if (filterChangeFn) {
      filterChangeFn(null);
    }
  };

  return (
    <figure key={dependencyKey} className={clsx('vaFigure', className)}>
      <BarChart
        data={chartData.data}
        horizontal={true}
        clickHandler={handleClick}
        xAxisLabel={{stepSize, display: hasData}}
      />

      {hasData ? (
        <figcaption className="text-center text-muted">
          {captionTitle}
          <select onChange={handleChange}>
            <option value={(objCls === 'chemical' || objCls === 'disease') ? 'genes' : 'chemicals'}>
              {(objCls === 'chemical' || objCls === 'disease') ? `gene ${topFive}` : `drug ${topFive}`}
            </option>
            <option value="phenotypeCategories">phenotype category</option>
          </select>
        </figcaption>
      ) : (
        <figcaption className="text-center text-muted">
          No data to display
        </figcaption>
      )}
    </figure>
  );
}
VariantAnnotationsBarChart.propTypes = propTypes;


function createGeneOrDrugChartData(variantAnnotations, objCls) {

  const entityCounts = {};

  forEach(variantAnnotations, (varAnn) => {

    const entities = (objCls === 'chemical' || objCls === 'disease') ? varAnn.location.genes : varAnn.chemicals;
    for (let x = 0; x < entities.length; x += 1) {
      const name = entities[x].symbol || entities[x].name;
      entityCounts[name] = entityCounts[name] ? entityCounts[name] + 1 : 1;
    }
  });

  const orderedLabels = Object.keys(entityCounts);
  if (orderedLabels.length > 0) {
    // sort entities by # of annotations
    orderedLabels.sort((o1, o2) => {
      const total1 = entityCounts[o1];
      const total2 = entityCounts[o2];
      if (total1 === total2) {
        // compare by entity name
        return o1 < o2 ? -1 : 1;
      }
      return total1 < total2 ? 1 : -1;
    });
  }

  // construct dataset
  const maxEntities = Math.min(5, orderedLabels.length);

  const data = [];
  // show 'no data to display' in bar chart, values are assigned 0
  if (maxEntities === 0) {
    for (let y = 0; y < 5; y += 1) {
      data.push({label: '', value: 0});
    }
  } else {
    for (let y = 0; y < maxEntities; y += 1) {
      const key = orderedLabels[y];
      data.push({label: key, value: entityCounts[key]});
    }
  }
  return {data, total: orderedLabels.length};
}
