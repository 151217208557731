import ReadableWidthTemplate from 'components/templates/ReadableWidth';

export default function VersionsPage() {

  return (
    <ReadableWidthTemplate title="Versions">
      <p>Here is a list of resources that PharmGKB references and the version currently used on PharmGKB.</p>

      <table className="table--nonFluid">
        <thead>
          <tr>
            <th>Name</th>
            <th>Version</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>dbSNP</td>
            <td>146</td>
          </tr>

          <tr>
            <td>Human genome assembly</td>
            <td>GRCh37.p13</td>
          </tr>

          <tr>
            <td>NCBI Gene</td>
            <td>105</td>
          </tr>

          <tr>
            <td>1000 Genomes</td>
            <td>phase III</td>
          </tr>

        </tbody>
      </table>
    </ReadableWidthTemplate>
  );
}
