import clsx from 'clsx';
import PropTypes from 'prop-types';

import Tag, {renderTagIfPresent} from 'components/Tag/index';
import PediatricIcon from 'components/icons/Pediatric';


const propTypes = {
  showIcon: PropTypes.bool,
  showHelp: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default function PediatricTag({showIcon = true, showHelp = true, className, style}) {
  const props = {className: clsx('tour-pediatric', className)};
  if (showHelp) {
    props.tooltipText = 'Learn more about pediatric tags';
    props.tooltipUrl = '/pediatricTags';
  }
  return (
    <Tag {...props} style={style}>{showIcon && <PediatricIcon />}Pediatric</Tag>
  );
}
PediatricTag.propTypes = propTypes;


/**
 * Render pediatric tag if applicable.
 *
 * @param {object} params
 * @param {object} params.obj
 * @param {boolean} [params.showHelp]
 * @param {string} [params.className]
 */
export function renderPediatricTag({obj, showHelp = true, className}) {
  return renderTagIfPresent({
    tag: PediatricTag,
    obj,
    dbProp: 'pediatric',
    indexProp: 'pediatric',
    showHelp,
    className,
  });
}
