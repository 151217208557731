import PropTypes from 'prop-types';
import {lazy, Suspense} from 'react';

import InfoIcon from 'components/icons/Info';
import Link from 'components/links/Link';
import useUniqueId from 'hooks/uniqId';

// noinspection JSCheckFunctionSignatures
const ReactTooltip = lazy(() =>
  import(/* webpackChunkName: "tooltip" */'react-tooltip'));


const propTypes = {
  href: PropTypes.string.isRequired,
  /**
   * The relationship of the linked URL as space-separated link types (used to set the `rel` attribute).
   * Defaults to "help".
   */
  rel: PropTypes.string,
  /**
   * Optional tooltip text.
   */
  tooltip: PropTypes.string,
  /**
   * Forces link to be opened in a new tab if true, or in the same tab if false.
   * If undefined, it will default to opening external links in a new tab.
   */
  newTab: PropTypes.bool,
  /**
   * className for the link.
   */
  className: PropTypes.string,
  /**
   * className for icon.
   */
  iconClassName: PropTypes.string,
  iconSize: PropTypes.string,
  iconTransform: PropTypes.string,
  children: PropTypes.node,
};
/**
 * Renders an information icon link with a mouseover tooltip (if `tooltip` is specified).
 */
export default function InfoLink({href, rel = 'help', tooltip, className, iconClassName, iconSize, iconTransform,
  children, ...otherProps}) {
  const tooltipId = useUniqueId({prefix: 'tt'});

  const linkProps = {
    href,
    rel,
    className,
    ...otherProps,
  };
  if (!children) {
    linkProps.iconOnly = true;
  }

  if (tooltip) {
    if (linkProps.title) {
      // tooltip trumps title
      // eslint-disable-next-line
      if (!linkProps['aria-label']) {
        linkProps['aria-label'] = linkProps.title;
      }
      delete linkProps.title;
    }
    // ReactTooltip needs to be a sibling or descendent of element with data-tip,
    // otherwise we get a rendering error in Chrome.
    return (
      <Link {...linkProps} data-for={tooltipId} data-tip={tooltip}>
        <InfoIcon className={iconClassName} size={iconSize} transform={iconTransform} />{children}
        <span className="sr-only">{tooltip}</span>
        <Suspense>
          <ReactTooltip id={tooltipId} effect="solid" wrapper="span" />
        </Suspense>
      </Link>
    );
  } else {
    return <Link {...linkProps}><InfoIcon className={iconClassName} size={iconSize} transform={iconTransform} />{children}</Link>;
  }
}
InfoLink.propTypes = propTypes;
