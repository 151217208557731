import {captureException, withScope} from '@sentry/react';
import {forEach} from 'lodash';
import PropTypes from 'prop-types';
import React, {Suspense} from 'react';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {Slide, ToastContainer} from 'react-toastify';

import './index.scss';
import Loading from 'components/Loading';
import {SearchBarProvider} from 'components/Search/Bar/Provider';
import {EditContextProvider} from 'components/edit/EditContext';
import Footer from 'components/root/footer';
import RootHeader from 'components/root/header';
import {ModalServiceProvider} from 'components/shared/ModalService';
import ServerError from 'components/shared/error/server_error';
import {AppContextProvider} from 'conf/AppContext';
import {PediatricContextProvider} from 'context/Pediatric';


const helmetContext = {};

/**
 * This is the outermost wrapper component for all pages.
 *
 * It must be a class as it is also an error boundary.
 */
export default class Root extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    compName: PropTypes.string,
  };

  static defaultProps = {
    children: null,
    compName: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({error: this.renderErrorMessage(error, errorInfo?.componentStack)});
    withScope((scope) => {
      forEach(Object.keys(errorInfo), (key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      captureException(error);
    });
  }

  renderErrorMessage = (error, stacktrace) => {
    const trace = stacktrace && stacktrace.match(/\n?(.*)/s)[1];
    return (
      <>
        <p>{error.toString()}</p>
        {trace && <pre>{trace}</pre>}
      </>
    );
  };

  render() {
    const {error} = this.state;
    return (
      <AppContextProvider>
        <ModalServiceProvider>
          <HelmetProvider context={helmetContext}>
            <Helmet>
              <title>PharmGKB</title>
            </Helmet>
            <div className="page">
              <SearchBarProvider>
                <EditContextProvider>
                  <PediatricContextProvider>
                    <RootHeader {...this.props} />

                    <div className="page-content">
                      {error
                        ? <ServerError msg={error} />
                        : <Suspense fallback={<div className="mt-5 text-center"><Loading /></div>}>{this.props.children}</Suspense>}
                    </div>

                    <Footer />
                  </PediatricContextProvider>
                </EditContextProvider>
              </SearchBarProvider>
              <ToastContainer transition={Slide} draggable={false} icon={false} />
            </div>
          </HelmetProvider>
        </ModalServiceProvider>
      </AppContextProvider>
    );
  }
}
