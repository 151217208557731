import clsx from 'clsx';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import LoadingIcon from 'components/icons/Loading';
import {extractProps} from 'utils/propUtils';


const propTypes = {
  /**
   * Function to call on click or keyboard event.
   */
  actionHandler: PropTypes.func,
  /**
   * Type of button: `button` (the default), `submit`, or `reset`.  Mainly used for a button in a form.
   */
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  disabled: PropTypes.bool,
  /**
   * Determines if button is icon-only.
   * This will render a frame-less button.
   */
  iconOnly: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Used to set the `title` attribute as well as `aria-label` attribute if `aria-label` is not defined.
   */
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  /**
   * Determines if button is in waiting mode and should display loading icon/label.
   */
  waiting: PropTypes.bool,
  /**
   * Sets the label to display when waiting.  This will replace the existing label.
   */
  waitingLabel: PropTypes.node,
};
/**
 * Wrapper around a button that displays a loading icon/label when in "waiting" mode (e.g. waiting for submission to
 * complete).
 *
 * @return {ReactElement}
 */
export default function WaitingButton({actionHandler, type, disabled = false,
  iconOnly = false, className, title, children, waiting = false, waitingLabel, ...otherProps}) {

  // TODO(markwoon): improve accessibility of loading state (https://github.com/Shopify/polaris-react/issues/1212)
  const renderWaiting = () => (
    <>
      <LoadingIcon className="faIcon--textOnRight" />
      <span role="status" className={clsx({'sr-only': iconOnly})}>{waitingLabel || 'Working'}</span>
    </>
  );

  return (
    <Button
      actionHandler={actionHandler}
      type={type}
      disabled={disabled || waiting}
      className={className}
      iconOnly={iconOnly}
      title={title}
      {...extractProps(otherProps)}
    >
      {waiting ? renderWaiting() : children}
    </Button>
  );
}
WaitingButton.propTypes = propTypes;
