import PropTypes from 'prop-types';

import List from 'components/List';
import {renderItemWithoutTags} from 'components/List/Item';
import FactSection from 'components/shared/fact_section';
import {accessionIdProps} from 'conf/types';


const propTypes = {
  pathway: PropTypes.shape({
    relatedChemicals: PropTypes.arrayOf(accessionIdProps),
    relatedDiseases: PropTypes.arrayOf(accessionIdProps),
    relatedGenes: PropTypes.arrayOf(accessionIdProps),
  }).isRequired,
};

export default function PathwayAssociationsTab({pathway}) {

  return (
    <div className="connectionsTab">
      <FactSection>
        <p>
          The following entities have been associated with the this pathway.
        </p>
      </FactSection>

      <div id="chemicals" className="link-anchor" />
      <FactSection title="Chemicals">
        <List collection={pathway.relatedChemicals} renderItemFn={renderItemWithoutTags} singularNoun="chemical" />
      </FactSection>

      <div id="genes" className="link-anchor" />
      <FactSection title="Genes">
        <List collection={pathway.relatedGenes} renderItemFn={renderItemWithoutTags} singularNoun="gene" />
      </FactSection>

      <div id="diseases" className="link-anchor" />
      <FactSection title="Diseases">
        <List collection={pathway.relatedDiseases} renderItemFn={renderItemWithoutTags} singularNoun="disease" />
      </FactSection>
    </div>
  );
}
PathwayAssociationsTab.propTypes = propTypes;
