import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Stickyfill from 'stickyfilljs';

import App from 'conf/app';


export default class Sticky extends React.Component {
  static propTypes = {
    top: PropTypes.number,
    mobile: PropTypes.bool,
    stuckClass: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
  };

  static defaultProps = {
    top: 0,
    mobile: false,
  };


  constructor(props) {
    super(props);

    this.stickyElement = React.createRef();
    this.animationFrame = null;

    this.state = {
      isStuck: false,
    };
  }


  componentDidMount = () => {
    Stickyfill.addOne(this.stickyElement.current);

    if (this.props.stuckClass && App.inBrowser) {
      // eslint-disable-next-line no-undef
      window.addEventListener('scroll', this.handleScroll);
    }
  };

  componentWillUnmount = () => {
    Stickyfill.removeOne(this.stickyElement.current);

    if (this.props.stuckClass && App.inBrowser) {
      // eslint-disable-next-line no-undef
      window.removeEventListener('scroll', this.handleScroll);
    }
  };


  /**
   * Position sticky doesn't tell us when the element actually gets "stuck".  In this case we need
   * to watch the scroll events ourselves.  This is just for adding the stuck class, we still
   * let position: sticky do the layout.
   */
  handleScroll = () => {
    if (this.animationFrame !== null) {
      return;
    }

    // eslint-disable-next-line no-undef
    this.animationFrame = window.requestAnimationFrame(() => {
      const element = this.stickyElement.current;
      const elementTop = element.getBoundingClientRect().top;
      const stickyBoundary = elementTop - this.props.top;

      // eslint-disable-next-line no-undef
      const isStuck = window.scrollY > stickyBoundary;
      this.setState({isStuck});

      this.animationFrame = null;
    });
  };


  render = () => {
    const style = {
      top: this.props.top + 'px',
    };

    return (
      <div
        ref={this.stickyElement}
        className={clsx(
          'sticky__wrapper',
          this.props.className,
          this.props.mobile && 'sticky__wrapper__mobile',
          this.state.isStuck && this.props.stuckClass,
        )}
        style={style}
      >
        {this.props.children}
      </div>
    );
  }
}
