import React from 'react';
import PropTypes from 'prop-types';
import Fact from 'components/shared/fact';

const propTypes = {
  cases: PropTypes.number,
  controls: PropTypes.number,
};
/**
 * Component to display a Fact of "Study size" that combines optional case and optional control counts.
 *
 * @param {object} props - props container
 * @param {number} props.cases - number of cases
 * @param {number} props.controls - number of controls
 * @return {JSX.Element|null} a Fact displaying overall study size and details when appropriate
 */
export default function StudySizeFact({cases, controls}) {
  const label = 'Study size';
  if ((!cases || cases === -1) && (!controls || controls === -1)) {
    return null;
  } else if (!cases || cases === -1) {
    return <Fact pageTourClass="tour-study-size" compact={true} label={label} literal={controls} />;
  } else if (!controls || controls === -1) {
    return <Fact pageTourClass="tour-study-size" compact={true} label={label} literal={cases} />;
  } else {
    return <Fact pageTourClass="tour-study-size" compact={true} label={label} literal={`${cases + controls} (cases=${cases}; controls=${controls})`} />;
  }
}
StudySizeFact.propTypes = propTypes;
