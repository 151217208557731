import {forEach, isObject, map} from 'lodash';
import PropTypes from 'prop-types';
import {Fragment} from 'react';
import {useFormContext} from 'react-hook-form';

import ErrorMessage from 'components/errors/ErrorMessage';


const propTypes = {
  className: PropTypes.string,
};
export default function FormError({className = 'mt-3'}) {
  const formMethods = useFormContext();
  const {formState: {errors}, getValues} = formMethods;

  if (Object.keys(errors).length === 0) {
    return;
  }

  const inputKeys = Object.keys(getValues());
  const formErrors = [];
  forEach(Object.keys(errors), (key) => {
    if (key === 'root') {
      // server/global errors are stored under the 'root' key
      forEach(Object.keys(errors.root), (v) => {
        formErrors.push({name: v, ...errors.root[v]});
      });
    } else if (!inputKeys.includes(key)) {
      // an error that is not associated with an input field
      formErrors.push({name: key, ...errors[key]});
    }
  });
  
  return formErrors?.length > 0 && (
    <div className={className}>
      {map(formErrors, (err) => {
        const {name, type, message} = err;
        if (isObject(message)) {
          return <Fragment key={`${type}-${name}`}>{message}</Fragment>;
        } else {
          return <ErrorMessage key={`${type}-${name}`} message={message} />;
        }
      })}
    </div>
  );
}
FormError.propTypes = propTypes;
