import PropTypes from 'prop-types';


/**
 * Download-specific prop-types.
 */
export const downloadTableProps = {
  /**
   * From react-table-plugins/useExportData: Disables exporting and hides download button if set to true.
   */
  disableExport: PropTypes.bool,
  /**
   * From react-table-plugins/useExportData: Override the default export functionality. Function is used to return
   * data as FileBlob which will be downloaded.
   */
  getExportFileBlob: PropTypes.func,
  /**
   * From react-table-plugins/useExportData: Function is used to overwrite exported file name.
   */
  getExportFileName: PropTypes.func,
  /**
   * Additional configuration to be passed to papa's unparse instance on table download.
   * Only used if not providing custom `getExportFileBlob`.
   */
  parserConfig: PropTypes.object,
};


/**
 * Common propTypes for TableFrame.
 */
export const tablePropTypes = {
  /**
   * Unique human-readable id for the table. If specified, is used to:
   * * prefix download filename
   * * differentiate table events in analytics
   * * cache table state with sessionStorage
   */
  id: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  rowClassNameFn: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),

  canShowFullscreen: PropTypes.bool,
  canEditColumns: PropTypes.bool,
  canResizeColumns: PropTypes.bool,
  canHideFilters: PropTypes.bool,

  /**
   * Provide custom filters to be rendered above the table.
   */
  customFilters: PropTypes.node,
  /**
   * Provide updated filters (will trigger re-render).
   */
  updatedFilters: PropTypes.arrayOf(PropTypes.object),
  /**
   * Provide an additional function that is run when filters are reset.
   * Can be used to reset any custom filters provided with customFilters prop.
   */
  onResetFilters: PropTypes.func,
  /**
   * Provide custom buttons to be added to the toolbar.
   */
  customButtons: PropTypes.node,

  /**
   * Disables row expansion, even if table data has subRows.
   */
  disableExpansion: PropTypes.bool,

  /**
   * Function to call to update table data.
   */
  updateDataFn: PropTypes.func,
  /**
   * Renders # of data in header.
   */
  renderTotalFn: PropTypes.func,

  // -- props passed through to react-table -- //
  /**
   * Props passed down directly to the table element via react-table's useTable.
   *
   * Use this to customize react-table for props that are not directly supported by VirtualizedTable.
   */
  tableProps: PropTypes.object,
  /**
   * From react-table: Core columns configuration object for the entire table.
   */
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * From react-table: Data array that you want to display on the table.
   */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * From react-table: The initial state object for the table.
   */
  initialState: PropTypes.object,
  /**
   * From react-table: Allows overriding or adding additional sort types for columns to use. If a column's sort type
   * isn't found on this object, it will default to using the built-in sort types.
   */
  sortTypes: PropTypes.object,
  /**
   * From react-table: When true, the filters state will automatically reset when data is changed.
   * Defaults to false.
   */
  autoResetFilters: PropTypes.bool,

  // -- download-specific options -- //
  ...downloadTableProps,
};


/**
 * Full propTypes for TableFrame.
 */
export const propTypes = {
  ...tablePropTypes,

  renderTableFn: PropTypes.func.isRequired,
};


/**
 * These are the props provided by renderTableFn.
 */
export const tableLayoutProps = {
  // from TableFrame
  rowClassNameFn: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  showFilters: PropTypes.bool.isRequired,
  isFullscreen: PropTypes.bool.isRequired,
  hasStickyColumns: PropTypes.bool.isRequired,
  hasFilters: PropTypes.bool.isRequired,
  memoizedColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
  memoizedMaxDepth: PropTypes.number.isRequired,
  renderNoResultsFoundFn: PropTypes.func.isRequired,
  // from react-table
  getTableProps: PropTypes.func.isRequired, // table props from react-table
  getTableBodyProps: PropTypes.func.isRequired, // table body props from react-table
  headerGroups: PropTypes.arrayOf(PropTypes.object).isRequired, // headerGroups if your table have groupings
  rows: PropTypes.arrayOf(PropTypes.object).isRequired, // rows for the table based on the data passed
  prepareRow: PropTypes.func.isRequired, // Prepare the row (this function need to called for each row before getting the row props)
  state: PropTypes.object.isRequired, // Controlled state of the table
};
