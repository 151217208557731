import PropTypes from 'prop-types';

import './index.scss';
import './Virtualized.scss';

import TableFrame from 'components/Table/LazyFrame';
import VirtualizedTableLayout from 'components/Table/VirtualizedLayout';
import {tablePropTypes} from 'components/Table/frameProps';
import logger from 'conf/Logger';


const HEIGHT_REGEX = /^(\d+)(px|em|rem|vh)?$/;


const propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  minHeight: PropTypes.string,
  // react-virtuoso prop - specifies how many items to render initially (for SSR)
  initialItemCount: PropTypes.number,
  ...tablePropTypes,
};

/**
 * Renders a virtualized table based on react-table.
 * Uses react-virtuoso to handle row virtualization.
 *
 * This supports: sorting, filtering, resizing columns.
 *
 * Includes download button, with an option to hide via prop (`disableExport`).
 * Will revert to non-virtualized rows for IE11 or lower.
 *
 * @return {JSX.Element}
 */
export default function VirtualizedTable({
  height = 'calc(100vh - 350px)',
  minHeight = '420px',
  width = '100%',
  // react-virtuoso prop
  initialItemCount,
  // standard table props
  id,
  title,
  className,
  rowClassNameFn,
  canShowFullscreen = false,
  canEditColumns = false,
  canResizeColumns = true,
  canHideFilters = false,
  customFilters,
  updatedFilters,
  onResetFilters,
  customButtons,
  disableExpansion = false,
  updateDataFn,
  renderTotalFn,
  // reactTableProps
  tableProps = {},
  columns = [],
  data,
  initialState,
  sortTypes = {},
  autoResetFilters = false,
  // downloadTableProps
  disableExport = true,
  getExportFileName,
  getExportFileBlob,
  parserConfig,
}) {
  const rez = _checkMinHeight(height, minHeight);
  if (rez.error) {
    return rez.error;
  }
  const renderTableFn = (props) => (
    <VirtualizedTableLayout
      id={id}
      height={height}
      minHeight={rez.minHeight}
      width={width}
      initialItemCount={initialItemCount}
      {...props}
    />
  );

  const frameProps = {
    renderTableFn,
    // standard table props
    id,
    title,
    className,
    rowClassNameFn,
    canShowFullscreen,
    canEditColumns,
    canResizeColumns,
    canHideFilters,
    customFilters,
    updatedFilters,
    onResetFilters,
    customButtons,
    disableExpansion,
    updateDataFn,
    renderTotalFn,
    // reactTableProps
    tableProps,
    columns,
    data,
    initialState,
    sortTypes,
    autoResetFilters,
    // downloadTableProps
    disableExport,
    getExportFileName,
    getExportFileBlob,
    parserConfig,
  };

  return (
    <TableFrame {...frameProps} />
  );
}
VirtualizedTable.propTypes = propTypes;


function _checkMinHeight(height, minHeight) {

  if (height.startsWith('calc(')) {
    return {minHeight};
  }

  let strippedMinHeight;
  let minHeightUnit = 'px';
  let found = minHeight.match(HEIGHT_REGEX);
  if (!found) {
    logger.error(`Unexpected format for VirtualizedTable.minHeight (${minHeight})`);
    return {error: <div className="alert alert-danger">check console</div>};
  } else {
    strippedMinHeight = found[1];
    if (found[2]) {
      minHeightUnit = found[2];
    } else {
      logger.warn('Virtualized.minHeight missing unit, assuming px');
    }
  }

  let strippedHeight;
  let heightUnit = 'px';
  found = height.match(HEIGHT_REGEX);
  if (!found) {
    logger.error(`Unexpected format for VirtualizedTable.height (${minHeight})`);
    return {error: <div className="alert alert-danger">check console</div>};
  } else {
    strippedHeight = found[1];
    if (found[2]) {
      heightUnit = found[2];
    } else {
      logger.warn('Virtualized.height missing unit, assuming px');
    }
  }
  if (minHeightUnit !== heightUnit) {
    logger.error(`VirtualizedTable.minHeight (${minHeightUnit}) and Virtualized.height (${heightUnit}) should have matching units`);
    return {error: <div className="alert alert-danger">check console</div>};
  }
  if (strippedHeight < strippedMinHeight) {
    return {minHeight: `${strippedHeight}${heightUnit}`};
  }
  return {minHeight};
}
