import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';

import NotFound from 'components/shared/error/not_found';
import useAppContext from 'conf/AppContext';
import Types from 'conf/types';

const propTypes = {
  id: PropTypes.string.isRequired,
};

/**
 * Redirects a literature annotation link to the literature it's on
 *
 * @param {object} props - props container
 * @param {string} props.id - lit ann id
 * @return {JSX.Element}
 */
export default function RedirectLiteratureAnnotation({id}) {
  const appContext = useAppContext();
  const [error, setError] = useState(null);

  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      try {
        const rez = await appContext.api.get(`data/literatureAnnotation/${id}`, {parseJson: true});
        if (mounted) {
          if (rez.status === 'success') {
            appContext.redirect(Types.literature.url(rez.data.literature.id), true);
          } else {
            setError(<NotFound />);
          }
        }
      } catch (err) {
        if (mounted) {
          setError(<NotFound />);
        }
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    loadData();
    return () => {
      mounted = false;
    };
  }, [id]);

  return error;
}
RedirectLiteratureAnnotation.propTypes = propTypes;
