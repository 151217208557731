import PropTypes from 'prop-types';

import {renderItemWithoutTags} from 'components/List/Item';
import SearchableList, {COUNT_MODE} from 'components/List/Searchable';


const propTypes = {
  pathways: PropTypes.arrayOf(PropTypes.object),
};
/**
 * Renders related pathways tab.
 */
export default function RelatedPathwaysTab({pathways}) {

  return (
    <>
      <h3>Related Pathways</h3>
      <SearchableList
        collection={pathways}
        renderItemFn={renderItemWithoutTags}
        singularNoun="pathway"
        searchKeys={['name', 'description']}
        countMode={COUNT_MODE.LIST}
      />
    </>
  );
}
RelatedPathwaysTab.propTypes = propTypes;
