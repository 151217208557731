import clsx from 'clsx';
import PropTypes from 'prop-types';

import './ClinicalAnnotationLevel.scss';
import Tag from 'components/Tag/index';


const getClassName = (inLevel) => {
  switch (inLevel) {
    case '1A':
    case '1B': return 'caLevelTag--1';
    case '2A':
    case '2B': return 'caLevelTag--2';
    case '3': return 'caLevelTag--3';
    case '4': return 'caLevelTag--4';
    default: return '';
  }
};


const propTypes = {
  level: PropTypes.string.isRequired,
  showHelp: PropTypes.bool,
  className: PropTypes.string,
};

export default function ClinicalAnnotationLevelTag({level, showHelp = true, className}) {
  const infoProps = {};
  if (showHelp) {
    infoProps.tooltipText = 'Learn more about Clinical Annotation Levels of Evidence';
    infoProps.tooltipUrl = '/page/clinAnnLevels';
  }
  return (
    <Tag
      className={clsx('caLevelTag', getClassName(level), className)}
      {...infoProps}
    >
      {`Level ${level}`}
    </Tag>
  );
}
ClinicalAnnotationLevelTag.propTypes = propTypes;
