import MurmurHash from 'imurmurhash';
import PropTypes from 'prop-types';

import Banner from 'components/Banner/index';
import logger from 'conf/Logger';
import {useGet} from 'helpers/KyHooks';


const propTypes = {
  /**
   * A unique ID that will be used to determine if component should be refreshed.
   */
  refreshKey: PropTypes.string,
};
/**
 * This renders an announcement banner.
 */
export default function AnnouncementBanner({refreshKey}) {
  const {response, error} = useGet('site/announcement', {}, {
    cacheOnLive: true,
    loadId: refreshKey,
  });

  if (error) {
    logger.warn(error);
  }
  if (!response) {
    return null;
  }

  const text = response.data;
  const announcement = {
    id: buildBannerId(text),
    text,
  };
  return (
    <Banner id={announcement.id} className="announcement" aria-label="Announcement banner">
      <span dangerouslySetInnerHTML={{__html: announcement.text}} />
    </Banner>
  );
}
AnnouncementBanner.propTypes = propTypes;


export function buildBannerId(text) {
  return 'announcement-' + new MurmurHash(text, 3).result().toString();
}
