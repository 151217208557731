import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {capitalize, toLower} from 'lodash';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import ApproveButton from 'components/Button/Approve';
import EditButton from 'components/Button/Edit';
import HaplotypeTabList from 'components/Haplotype/TabList';
import Loading from 'components/Loading';
import Tag from 'components/Tag';
import useEditContext from 'components/edit/EditContext';
import EditControls from 'components/edit/EditControls';
import KyError from 'components/errors/KyError';
import DownloadLink from 'components/links/Download';
import Link from 'components/links/Link';
import CuratorOnly from 'components/shared/curator_only';
import HtmlContainer from 'components/shared/html_container';
import useAppContext from 'conf/AppContext';
import {GeneAlleleType} from 'conf/enums';
import {useGet} from 'helpers/KyHooks';


const propTypes = {
  id: PropTypes.string.isRequired,
};
/**
 * Renders the named alleles tab for the gene page.
 */
export default function GeneHaplotypesTab({id}) {
  const appContext = useAppContext();
  const editContext = useEditContext();

  const {response, error} = useGet(`site/gene/${id}/haplotypes`);

  let editControls;
  let content = <Loading />;
  let alleleTypeCap = renderTabHeader();
  let haplotypeTable = '';
  if (error) {
    content = <KyError kyError={error} />;
  } else if (response) {
    const {
      geneId, geneSymbol, isCpicGene, isPharmVar, overviewId, overviewHtml, alleleFile, cpicS3File,
      subId, sourceId, subStatus, subNeedsApproval, haplotypes, hideHaplotypes, alleleType,
    } = response.data;
    alleleTypeCap = renderTabHeader(alleleType);
    const alleleTypeLower = toLower(alleleTypeCap);
    const alleleTypeSentence = capitalize(alleleTypeLower.charAt(0)) + alleleTypeLower.slice(1);
    let dlHref;
    if (alleleFile) {
      dlHref = appContext.downloadAttachmentUrl(alleleFile);
    } else if (cpicS3File) {
      dlHref = cpicS3File;
    } else if (sourceId) {
      dlHref = appContext.apiUrl(`/download/submission/${sourceId}`);
    }

    if (editContext.isEditMode) {
      editControls = (
        <>
          <EditControls className="editControls--sm">
            <EditButton href={`/edit/haplotypes/${geneId}`} label="Edit" />
            {
              overviewId
                ? (
                  <EditButton href={`/edit/dataAnnotation/${overviewId}`} label="Edit Overview" />
                ) : (
                  <Button key="overview" href={`/edit/dataAnnotation?type=Haplotype%20Overview&target=${id}`}>
                    <FontAwesomeIcon icon="plus" /> Add Overview
                  </Button>
                )
            }
            <Button href="/edit/haplotype-submission" className="btn-primary">
              <FontAwesomeIcon icon="upload" /> Upload Haplotypes
            </Button>
            {renderSubmissionControls(subId, subStatus, subNeedsApproval,
              subId ? appContext.apiUrl(`/download/submission/${sourceId}`) : '', cpicS3File)}
          </EditControls>
          {(isCpicGene || isPharmVar) && (
            <div className="mb-4">
              {isCpicGene && <Tag className="tag--sm">CPIC Gene</Tag>}
              {isPharmVar && <Tag className="tag--sm">PharmVar Gene</Tag>}
            </div>
          )}
        </>
      );
    }

    if (isPharmVar) {
      if (hideHaplotypes) {
        content = (
          <p>
            Information about this gene&apos;s {alleleTypeLower} can be found
            at <Link href="https://www.pharmvar.org">PharmVar</Link>.
          </p>
        );
      } else {
        content = (
          <>
            <p>
              {alleleTypeSentence} are <Link href={`https://www.pharmvar.org/gene/${geneSymbol}`}>sourced from PharmVar</Link>.
              Please <Link href="https://www.pharmvar.org">contact PharmVar</Link> with questions about allele names,
              definitions or nucleotide positions as they are the authoritative source for this gene.
            </p>
            {overviewHtml && <HtmlContainer html={overviewHtml} className="mb-3" />}
            {dlHref && renderDownload(dlHref)}
          </>
        );
      }
    } else if (isCpicGene) {
      if (hideHaplotypes) {
        content = (
          <p>
            Information about this gene&apos;s {alleleTypeLower} can be found <Link href="/page/pgxGeneRef">here</Link>.
          </p>
        );
      } else {
        content = (
          <>
            <p>
              {alleleTypeSentence} are sourced from <Link href="/page/pgxGeneRef">CPIC/PharmGKB</Link>.
            </p>
            {overviewHtml && <HtmlContainer html={overviewHtml} className="mb-3" />}
            {dlHref && renderDownload(dlHref)}
          </>
        );
      }
    } else if (overviewHtml) {
      content = <HtmlContainer html={overviewHtml} className="mb-3" />;
    } else {
      content = <p>No {alleleTypeLower} for {geneSymbol}.</p>;
    }

    if (haplotypes?.length > 0) {
      const tableComp = (
        <HaplotypeTabList
          objId={id}
          haplotypes={haplotypes}
          alleleType={alleleTypeCap}
        />
      );
      if ((!isPharmVar && !isCpicGene) || hideHaplotypes) {
        haplotypeTable = <CuratorOnly>{tableComp}</CuratorOnly>;
      } else {
        haplotypeTable = tableComp;
      }
    }
  }

  return (
    <section>
      <h3>{alleleTypeCap}</h3>
      {editControls}
      {content}
      {haplotypeTable}
    </section>
  );
}
GeneHaplotypesTab.propTypes = propTypes;


function renderSubmissionControls(subId, subStatus, subNeedsApproval, sourceHref, cpicS3File) {
  if (!subId) {
    return '';
  }
  const notApproved = subStatus !== 'Approved';
  return (
    <>
      <Button key="submission" href={`/submission/${subId}`} className="btn-secondary">
        <FontAwesomeIcon icon={['far', 'file']} /> Submission <span className={clsx({'text-danger': notApproved})}>({subStatus})</span>
      </Button>
      {subNeedsApproval && <ApproveButton objCls="Submission" objId={subId} label="Approve Submission" />}
      <DownloadLink className="btn" href={sourceHref}>PGKB Format</DownloadLink>
      {cpicS3File && <DownloadLink className="btn" href={cpicS3File}>CPIC Format</DownloadLink>}
    </>
  );
}


function renderDownload(href) {
  return (
    <p>
      <DownloadLink className="btn btn-outline-secondary btn-sm" href={href}>Download Named Allele Definitions</DownloadLink>
    </p>
  );
}

function renderTabHeader(alleleType) {
  if (alleleType === GeneAlleleType.shortName.namedVariants) {
    return GeneAlleleType.shortName.namedVariants;
  } else {
    return GeneAlleleType.shortName.namedAlleles;
  }
}
