// These variables are set by Webpack's DefinePlugin:
/* global BASE_URL, BASE_API_URL, ENV, MODE, UMAMI_ID */
import App from 'ampersand-app';
import {startsWith} from 'lodash';

import {initializeApp} from 'conf/AppContext';
import logger from 'conf/Logger';
import 'conf/FontAwesome';

App.mode = MODE;
App.env = ENV;
App.baseUrl = BASE_URL;
App.baseApiUrl = BASE_API_URL;
App.previewBaseUrl = 'https://preview.pharmgkb.org';
App.umamiId = UMAMI_ID;

App.inBrowser = (typeof window !== 'undefined');
App.isPreview = MODE !== 'production';
App.isDev = ENV !== 'production' && ENV !== 'preview';
App.isPediatricMode = false;

App.currentUser = null;
App.router = null;
App.log = logger;

App.initializeApp = () => {
  const rez = initializeApp();
  App.router = rez.router;
  App.currentUser = rez.currentUser;
};

/**
 * Gets the current location (includes schema:hostname).
 *
 * @return {string}
 */
App.currentLocation = () => App.baseUrl + '/' + App.router.history.fragment;
/**
 * Gets the current location without scheme:hostname.
 *
 * @return {string}
 */
App.currentPath = () => '/' + App.router.history.fragment;
App.apiUrl = (endpoint) => {
  if (App.isDev && startsWith(endpoint, '/autocomplete/')) {
    return 'https://elasticsearch.pharmgkb.org' + endpoint;
  }
  return App.baseApiUrl + endpoint;
};

export default App;
