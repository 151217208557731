import {camelCase, startCase} from 'lodash';
import PropTypes from 'prop-types';

import ResourcePage from 'components/templates/Resource/Page';


const propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  type: PropTypes.string.isRequired,
  // title of page; defaults to name, and if name is unavailable, to `${type} ${id}`
  title: PropTypes.string,
  // below this can be undefined if not fully loaded
  name: PropTypes.string,
  symbol: PropTypes.string,
  // optional subtitle in header
  subtitle: PropTypes.string,
  children: PropTypes.node,
};

/**
 * Very, very simple wrapper around (readable width) Resource/Page.
 */
export default function ReadableWidthResourceTemplate({id, type, title, name, symbol, subtitle,
  children}) {
  const ccType = camelCase(type);
  const pageTitle = title || name || `${startCase(type)} ${id}`;
  const headerData = {
    id,
    type: ccType,
    subtitle,
    name,
    symbol,
  };

  return (
    <ResourcePage title={pageTitle} headerData={[headerData]} subtitle={subtitle} readableWidth={true}>
      {children}
    </ResourcePage>
  );
}
ReadableWidthResourceTemplate.propTypes = propTypes;
