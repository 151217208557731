import PropTypes from 'prop-types';

import ClinicalAnnotationDetail from 'components/ClinicalAnnotation/Detail';
import Loading from 'components/Loading';
import KyError from 'components/errors/KyError';
import NotFound from 'components/shared/error/not_found';
import FullWidthResourceTemplate from 'components/templates/FullWidthResource';
import useAppContext from 'conf/AppContext';
import {useGet} from 'helpers/KyHooks';

const propTypes = {
  id: PropTypes.string,
  embedded: PropTypes.bool,
};

/**
 * The page for Clinical Annotations
 *
 * @param {object} props
 * @param {string} [props.id] - A clinical annotation id
 * @param {boolean} [props.embedded] - true to embed component in other pages
 * @return {React.ReactNode}
 */
export default function ClinicalAnnotationPage({id, embedded = true}) {
  const appContext = useAppContext();
  const {response, error} = useGet(`site/clinicalAnnotation/${id}`, {
    cacheResponse: !appContext.isPreview,
  });

  const clinicalAnnotation = response?.data;
  let content = <Loading />;
  if (error) {
    if (error?.response?.status === 404 && !embedded) {
      return <NotFound />;
    }
    content = <KyError kyError={error} />;
  } else {
    content = clinicalAnnotation?.id && <ClinicalAnnotationDetail clinicalAnnotation={clinicalAnnotation} />;
  }

  if (embedded) {
    return (
      <div>{content}</div>
    );
  }
  return (
    <FullWidthResourceTemplate
      id={id}
      name={clinicalAnnotation?.name}
      type="clinicalAnnotation"
    >
      {content}
    </FullWidthResourceTemplate>
  );
}

ClinicalAnnotationPage.propTypes = propTypes;
