import clsx from 'clsx';
import PropTypes from 'prop-types';

import './Edit.scss';
import Button from 'components/Button/index';
import EditIcon from 'components/icons/Edit';


const propTypes = {
  href: PropTypes.string.isRequired,
  /**
   * Forces link to be opened in new tab if true, in same tab if false.
   * If undefined, it will default to opening external links in new tab.
   */
  newTab: PropTypes.bool,
  /**
   * Determines if button should be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * Determines if button is icon-only.
   * This will render a frame-less button.
   */
  iconOnly: PropTypes.bool,
  /**
   * The button's label.
   */
  label: PropTypes.string,
  className: PropTypes.string,
};
/**
 * Renders an edit button.  Just like a regular button, but with edit icon.
 */
export default function EditButton({href, newTab = false, disabled = false, iconOnly = false,
  label = 'Edit', className}) {
  return (
    <Button href={href} newTab={newTab} disabled={disabled} className={clsx('button--edit', className)} iconOnly={iconOnly} title={label}>
      <EditIcon />
      {iconOnly ? '' : label}
    </Button>
  );
}
EditButton.propTypes = propTypes;
