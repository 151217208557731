import {find, forEach, map} from 'lodash';
import PropTypes from 'prop-types';
import {FormProvider, useForm} from 'react-hook-form';
import {toast} from 'react-toastify';

import ButtonPanel from 'components/Button/Panel';
import {createText, renderAutomaticIcon, renderStatusIcon} from 'components/LinksTab/index';
import KyError from 'components/errors/KyError';
import FormCancel from 'components/form/Cancel';
import FormCheckboxGroup from 'components/form/CheckboxGroup';
import FormSubmit from 'components/form/Submit';
import Link from 'components/links/Link';
import useAppContext from 'conf/AppContext';
import {LinkOutResource} from 'conf/enums';

const propTypes = {
  links: PropTypes.object,
  objCls: PropTypes.string.isRequired,
  objId: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
/**
 * Modal form updating links
 *
 * @param {object} props - props container
 * @param {object} props.links
 * @param {string} props.objCls
 * @param {string} props.objId
 * @param {Function} props.onSave - function to call on save
 * @param {Function} props.onClose - function to call on cancel
 * @return {JSX.Element}
 */
export default function LinksTabForm({links, objCls, objId, onSave, onClose}) {
  const appContext = useAppContext();
  const {linkOptions, defaultValues} = createFormData(links);
  const formMethods = useForm({defaultValues});
  const {handleSubmit} = formMethods;

  const onSubmit = async (values) => {
    const data = [];
    forEach(Object.keys(values), (resource) => {
      if (values[resource].length > 0) {
        for (let x = 0; x < values[resource].length; x += 1) {
          if (LinkOutResource.ontology.includes(LinkOutResource.displayName[resource])) {
            const {name} = find(links[resource],
              (link) => link.resourceId === values[resource][x]);
            data.push({
              resource,
              resourceId: values[resource][x],
              name,
            });
          } else {
            data.push({
              resource,
              resourceId: values[resource][x],
            });
          }
        }
        return data;
      }
    });

    try {
      const rez = await appContext.api.put(`curation/${objCls}/${objId}/linkOuts`, {
        json: data,
        parseJson: true,
      });
      onSave(rez.data);
      toast.success('Links updated');
    } catch (err) {
      appContext.toastError(<KyError kyError={err} />);
    }
  };

  return (
    <div className="linksTab">
      <div>
        <h3>Update New Links</h3>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="linksTab__xrefs">
              {map(Object.keys(linkOptions), (src) => (
                <FormCheckboxGroup
                  key={src}
                  name={src}
                  label={<h4>{src}</h4>}
                  options={linkOptions[src]}
                  className="linksTab__xrefs__resource"
                />
              ))}
            </div>
            <ButtonPanel
              buttons={[
                <FormSubmit key="save">Save</FormSubmit>,
                <FormCancel key="cancel" actionHandler={onClose}>Cancel</FormCancel>,
              ]}
            />
          </form>
        </FormProvider>
      </div>
    </div>
  );
}
LinksTabForm.propTypes = propTypes;

function createFormData(links) {
  const linkOptions = {};
  const defaultValues = {};

  forEach(Object.keys(links), (link) => {
    if (link === 'Genetic Testing Registry') {
      return;
    }
    if (links[link].length > 0) {
      linkOptions[link] = [];
      defaultValues[link] = [];

      for (let x = 0; x < links[link].length; x += 1) {
        const {id, resource, name, resourceId, _url: url, automatic, status} = links[link][x];
        const text = createText(resource, name, resourceId);
        const automaticIcon = renderAutomaticIcon(automatic);
        const statusIcon = renderStatusIcon(status);
        const labelText = url ? <Link href={url}>{text}</Link> : text;
        const label = <>{labelText} {automaticIcon} {statusIcon}</>;
        linkOptions[link].push({label, value: resourceId});
        if (id !== -1) {
          defaultValues[link] = [...defaultValues[link], resourceId];
        }
      }
    }
  });
  return {linkOptions, defaultValues};
}
