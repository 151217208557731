import {map, sortBy} from 'lodash';

import EditButton from 'components/Button/Edit';
import Loading from 'components/Loading';
import EditControls from 'components/edit/EditControls';
import KyError from 'components/errors/KyError';
import ReadableWidthTemplate from 'components/templates/ReadableWidth';
import useAppContext from 'conf/AppContext';
import {useGet} from 'helpers/KyHooks';


export default function PgxPaperTypesPage() {
  const appContext = useAppContext();
  const {response, error} = useGet('data/ontologyTerm', {
    searchParams: {
      resource: 'pgxPaperTypes',
      view: 'max',
    },
    cacheResponse: !appContext.isPreview,
  });

  let body = <Loading />;
  if (error) {
    body = <KyError kyError={error} />;
  } else if (response) {
    const types = sortBy(response.data, (t) => t.term);
    body = (
      <dl className="mt-3">
        {map(types, (t) => (
          <span key={t.id}>
            <dt>{t.term}</dt>
            <dd>{t.description}</dd>
          </span>
        ))}
      </dl>
    );
  }

  return (
    <ReadableWidthTemplate title="PGx Paper Types">
      <EditControls>
        <EditButton href="/edit/ontology/pgxPaperTypes" />
      </EditControls>
      {body}
    </ReadableWidthTemplate>
  );
}
