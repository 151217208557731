import PropTypes from 'prop-types';

import LiteratureTab from 'components/Literature/Tab';
import Loading from 'components/Loading';
import SideNav from 'components/SideNav';
import KyError from 'components/errors/KyError';
import {totalOnlyResourceCount} from 'components/resource/resourceCounts';
import NotFound from 'components/shared/error/not_found';
import FullWidthResourceTemplate from 'components/templates/FullWidthResource';
import useAppContext from 'conf/AppContext';
import Types from 'conf/types';
import {useGet} from 'helpers/KyHooks';
import PathwayAssociationsTab from 'pages/Pathway/AssociationsTab';
import PathwayComponentsTab from 'pages/Pathway/ComponentsTab';
import PathwayDownloadsTab from 'pages/Pathway/DownloadsTab';
import PathwayOverviewTab from 'pages/Pathway/OverviewTab';
import RelatedPathwaysTab from 'pages/Pathway/RelatedPathwaysTab';


const propTypes = {
  id: PropTypes.string.isRequired,
  section: PropTypes.string,
  loadId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
/**
 * Renders pathway page.
 */
export default function PathwayPage({id, section = 'overview', loadId = 0}) {
  const appContext = useAppContext();

  const {response, error} = useGet(`site/pathway/${id}`, {
    cacheResponse: !appContext.isPreview,
  }, {
    loadId,
  });

  const baseUrl = Types.pathway.url(id);
  const menuItems = [
    {url: `${baseUrl}/overview`, label: 'Overview', otherUrls: [baseUrl]},
    {url: `${baseUrl}/components`, label: 'Components'},
    {url: `${baseUrl}/pathways`, label: 'Related Pathways'},
    {url: `${baseUrl}/associations`, label: 'Associations'},
    {url: `${baseUrl}/literature`, label: 'Related Literature'},
    {url: `${baseUrl}/downloads`, label: 'Downloads'},
  ];

  let name = `Pathway ${id}`;
  let body = <Loading />;
  let counts;
  if (error) {
    if (error.response?.status === 404) {
      return <NotFound />;
    }
    body = <KyError kyError={error} />;
  } else if (response) {
    const {pathway, components, interactions, literature, relatedPathways, needsApproval, userId} = response.data;
    counts = response.data.counts;
    menuItems[1].count = totalOnlyResourceCount(
      (interactions?.length ?? 0) +
      (components?.length ?? 0) +
      (pathway?.genes?.length ?? 0) +
      (pathway?.chemicals?.length ?? 0) +
      (pathway?.diseases?.length ?? 0));
    menuItems[2].count = totalOnlyResourceCount(relatedPathways?.length || 0);
    menuItems[3].count = totalOnlyResourceCount(
      (pathway?.relatedGenes?.length ?? 0) +
      (pathway?.relatedChemicals?.length ?? 0) +
      (pathway?.relatedDiseases?.length ?? 0));
    menuItems[4].count = counts?.literature || totalOnlyResourceCount(0);
    name = pathway.name;

    if (section === 'components') {
      body = (
        <PathwayComponentsTab
          genes={pathway.genes}
          chemicals={pathway.chemicals}
          diseases={pathway.diseases}
          components={components}
          interactions={interactions}
        />
      );
    } else if (section === 'pathways') {
      body = <RelatedPathwaysTab pathways={relatedPathways} />;
    } else if (section === 'associations') {
      body = <PathwayAssociationsTab pathway={pathway} />;
    } else if (section === 'literature') {
      body = <LiteratureTab objCls={pathway.objCls} id={id} title="Literature" literature={literature} />;
    } else if (section === 'downloads') {
      body = <PathwayDownloadsTab pathway={pathway} />;
    } else {
      body = <PathwayOverviewTab pathway={pathway} counts={counts} needsApproval={needsApproval} userId={userId} />;
    }
  }

  return (
    <FullWidthResourceTemplate id={id} type="pathway" name={name}>
      <SideNav items={menuItems}>
        {body}
      </SideNav>
    </FullWidthResourceTemplate>
  );
}
PathwayPage.propTypes = propTypes;
