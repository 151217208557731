import PropTypes from 'prop-types';

import SearchableList, {COUNT_MODE} from 'components/List/Searchable';
import Loading from 'components/Loading';
import CategoryFilter from 'components/templates/CategoryList/Filter';
import FullWidthTemplate from 'components/templates/FullWidth';

const propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    count: PropTypes.number,
  })).isRequired,
  collection: PropTypes.arrayOf(PropTypes.object),
  renderItem: PropTypes.func.isRequired,
  searchKeys: PropTypes.arrayOf(PropTypes.string),
  singularNoun: PropTypes.string.isRequired,
  countMode: PropTypes.number,
  className: PropTypes.string,
  loaded: PropTypes.bool,
  error: PropTypes.node,
  selectedFilters: PropTypes.arrayOf(PropTypes.string),
};
/**
 * This is a page template for a list of items that can be filtered by categories.
 *
 * @param {object} props - props container
 * @param {string} props.title - page title
 * @param {React.ReactNode} props.description - a markdown object description of the page
 * @param {Function} props.onFilterUpdate - Function to call when filters have been updated
 * @param {Array<object>} props.filters - Map of features to filter by and how many there are of each
 * @param {object} props.collection - list of search results
 * @param {Function} props.renderItem - function that renders individual item
 * @param {Array<string>} props.searchKeys - property keys to use for search.  Defaults to everything.
 * @param {string} props.singularNoun - Singular version of noun representing object
 * @param {number} props.countMode - determines if number of results should be displayed, and if the
 * `collection` is a list or search results.  Defaults to SearchableList.COUNT_MODE_NONE
 * @param {boolean} props.loaded - boolean loading indicator
 * @param {React.ReactNode} props.error - error to render
 * @param {string} props.className
 * @param {Array<string>} props.selectedFilters
 * @return {JSXElement}
 */
export default function CategoryListTemplate({
  title,
  description,
  onFilterUpdate,
  filters = [],
  collection = [],
  renderItem,
  searchKeys,
  singularNoun,
  countMode = COUNT_MODE.LIST,
  loaded = false,
  error,
  className = '',
  selectedFilters,
}) {

  let body;
  if (error) {
    body = error;
  } else if (loaded) {
    body = (
      <SearchableList
        collection={collection}
        renderItemFn={renderItem}
        searchKeys={searchKeys}
        singularNoun={singularNoun}
        countMode={countMode}
      />
    );
  } else {
    body = <Loading />;
  }

  return (
    <FullWidthTemplate title={title} className={className}>
      {description}
      <div className="mt-5 row">
        <div className="col-md-3">
          <CategoryFilter filters={filters} onFilterUpdate={onFilterUpdate} selectedFilters={selectedFilters} />
        </div>

        <div className="col-md-9">
          {body}
        </div>
      </div>
    </FullWidthTemplate>
  );
}
CategoryListTemplate.propTypes = propTypes;
