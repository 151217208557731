import {some} from 'lodash';
import PropTypes from 'prop-types';

import Tag from 'components/Tag/index';


const propTypes = {
  showHelp: PropTypes.bool,
  className: PropTypes.string,
};

// noinspection JSUnusedGlobalSymbols
export default function BpcaDrugTag({showHelp = true, className}) {
  const props = {className};
  if (showHelp) {
    props.tooltipText = 'Learn more about BPCA';
    props.tooltipUrl = '/page/BPCA';
  }

  return <Tag {...props}>BPCA Drug</Tag>;
}
BpcaDrugTag.propTypes = propTypes;


/**
 * Render BPCA drug tag if applicable.
 *
 * @param {object} params
 * @param {object} params.obj
 * @param {boolean} [params.showHelp]
 * @param {string} [params.className]
 */
export function renderBpcaDrugTag({obj, showHelp = true, className}) {
  let showTag = false;
  if (obj.tags) {
    if (obj.tags.includes('BPCA Drug')) {
      showTag = true;
    }
  } else if (obj.terms && obj.terms.length > 0) {
    showTag = !!some(obj.terms, (t) => t.term === 'BPCA Drug');
  }
  if (showTag) {
    return <BpcaDrugTag key="bpcaDrug" showHelp={showHelp} className={className} />;
  }
  return null;
}
