import './AlleleNote.scss';

export default function AlleleNote() {
  return (
    <p className="allele-note">
      <strong>Note:</strong> Alleles in PharmGKB are mapped to the positive chromosomal strand. Therefore, variants in
      genes on the &quot;minus&quot; strand (eg. <i>VKORC1</i>) are complemented in PharmGKB annotations.
    </p>
  );
}
