import LimitedEvidenceTag from 'components/Tag/LimitedEvidence';
import InfoLink from 'components/links/Info';

/**
 * Allele phenotype in row form to show in a two column table. Note: props have not been destructured due to the
 * "function" prop being a reserved word
 *
 * @param {object} ap - an Allele Phenotype object
 */
export default function AllelePhenotypeRow(ap) {
  return (
    <tr>
      <td className="allele">
        {fixLongAlleles(ap.allele)}
        {ap.function && <div className="mt-2"><small>{ap.function} <InfoLink href="/page/cpicFuncPhen" tooltip="More allele information" /></small></div>}
        {ap.activityValue && <div className="mt-2"><small>AV {ap.activityValue}</small></div>}
      </td>
      <td className="phenotype">
        {ap.limitedEvidence && <LimitedEvidenceTag className="tag--sm" />}
        {fixLongAlleles(ap.phenotype)}
      </td>
    </tr>
  );
}

function fixLongAlleles(text) {
  const parts = text.split('/');
  const rez = [];
  for (let x = 0; x < parts.length; x += 1) {
    if (rez.length > 0) {
      rez.push(<span key={`w${x}`}>/<wbr /></span>);
    }
    rez.push(<span key={x}>{parts[x]}</span>);
  }
  return rez;
}
