import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import FeedbackLink from 'components/links/Feedback';
import ReadableWidthTemplate from 'components/templates/ReadableWidth';
import logger from 'conf/Logger';
import {useGet} from 'helpers/KyHooks';


const propTypes = {
  message: PropTypes.string,
  prevUrl: PropTypes.string,
};

export default function FeedbackPage({message, prevUrl = '/'}) {
  const {response, error} = useGet('site/announcement');

  if (error) {
    logger.warn(error);
  }
  return (
    <ReadableWidthTemplate title="Submit Feedback">
      { response && <div className="alert alert-warning" dangerouslySetInnerHTML={{__html: response.data}} />}
      <p>
        We welcome any questions or feedback you might have for PharmGKB.
      </p>
      <p>
        <FeedbackLink body={message} srcUrl={prevUrl} className="btn btn-primary">
          <FontAwesomeIcon icon="envelope" className="faIcon--textOnRight" />
          Send Feedback
        </FeedbackLink>
      </p>
    </ReadableWidthTemplate>
  );
}

FeedbackPage.propTypes = propTypes;
