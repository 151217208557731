import {camelCase, startCase} from 'lodash';
import PropTypes from 'prop-types';

import ResourcePage from 'components/templates/Resource/Page';


const propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  type: PropTypes.string.isRequired,
  // title of page; defaults to name, and if name is unavailable, to `${type} ${id}`
  title: PropTypes.string,
  // below this can be undefined if not fully loaded
  name: PropTypes.string,
  symbol: PropTypes.string,
  // optional subtitle in header
  subtitle: PropTypes.node,
  children: PropTypes.node,
};

/**
 * Very, very simple wrapper around (full width) Resource/Page for a SINGLE resource.
 *
 * @param {object} props - component properties
 * @param {string|number} props.id
 * @param {string} props.type
 * @param {string} [props.title] - page title (if not provided, defaults to props.name, then props.type + props.id)
 * @param {string} [props.name] - title to display in header
 * @param {React.ReactNode} [props.subtitle] - subtitle to display in header
 * @param {string} [props.symbol]
 * @param {React.ReactNode} props.children
 */
export default function FullWidthResourceTemplate({id, type, title, name, symbol, subtitle,
  children}) {
  const ccType = camelCase(type);
  const pageTitle = title || name || `${startCase(type)} ${id}`;
  const headerData = {
    id,
    type: ccType,
    subtitle,
    name,
    symbol,
  };

  return (
    <ResourcePage title={pageTitle} headerData={[headerData]} subtitle={subtitle}>
      {children}
    </ResourcePage>
  );
}
FullWidthResourceTemplate.propTypes = propTypes;
