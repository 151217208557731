import Template from 'components/templates/ReadableWidth';

/**
 * Page to let the user know they are already signed in.
 *
 * @return {React.ReactNode}
 */
export default function AlreadySignedIn({}) {
  return (
    <Template title="Already Signed In">
      <div className="row justify-content-md-center">
        <div className="col-md-8">
          <p>
            It looks like you clicked on a password reset link, but you are already logged in.
          </p>
          <ul>
            <li>If you know your current password, you can <a href="/user/updatePassword">update it</a>.</li>
            <li>If you do not remember your current password, you can <a href="/user/logout">sign out</a> and <a href="/user/resetPassword">request a password reset</a>.</li>
          </ul>
        </div>
      </div>
    </Template>
  );
}
