import {every, size, sortBy, values as loValues} from 'lodash';
import {useState} from 'react';

import Loading from 'components/Loading';
import InlinePage from 'components/Page/Inline';
import ErrorMessage from 'components/errors/ErrorMessage';
import KyError from 'components/errors/KyError';
import FullFluidWidthTemplate from 'components/templates/FullFluidWidth';
import useAppContext from 'conf/AppContext';
import {useGet} from 'helpers/KyHooks';
import {analyticsEvent} from 'helpers/analytics';
import GenotypeGenePicker from 'pages/genotype/GenePicker';

import './index.scss';


/**
 * The GSI page for selecting genotypes
 *
 * @param {object} props - props
 * @param {object} props.genoQueryParam - genotypes that are coming from a query parameter
 */
export default function GenotypePage({genoQueryParam}) {
  const appContext = useAppContext();
  const [submitError, setSubmitError] = useState(undefined);
  const {response, error} = useGet('site/gsi/genes', {
    cacheResponse: true,
  });

  const genes = sortBy(response?.data, (g) => g.genesymbol);

  const handleOtherSubmit = (data) => {
    analyticsEvent('gsi-genotypePick', {choice: JSON.stringify(data)});
    const formEmpty =
      size(data) === 0 ||
      every(loValues(data), (alleleArray) => every(alleleArray, (allele) => !allele));
    if (formEmpty) {
      setSubmitError(<div className="mt-3"><ErrorMessage title="Empty Genotype" message="Pick at least one genotype before making a report" /></div>);
    } else {
      const url = `/genotypeResults?q=${encodeURIComponent(JSON.stringify(data))}`;
      appContext.redirect(url);
    }
  };

  let body = <Loading />;
  if (error) {
    body = <KyError kyError={error} />;
  } else if (response?.data) {
    body = (
      <>
        <div className="container">
          <InlinePage id="gsiHeader" className="mb-3" />
          <InlinePage id="gsiPickerHeader" className="mb-3" />
        </div>
        <div className="container">
          <GenotypeGenePicker genes={genes} defaultValues={genoQueryParam} onSubmit={handleOtherSubmit} />
          {submitError}
        </div>
      </>
    );
  }

  return (
    <FullFluidWidthTemplate title="GSI: Genotype Selection Interface">
      {body}
    </FullFluidWidthTemplate>
  );
}
