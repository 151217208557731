import {sortBy} from 'lodash';
import {matchSorter} from 'match-sorter';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';

import './Tab.scss';
import ChartConstants from 'components/Chart/Constants';
import {renderItemWithoutTags} from 'components/List/Item';
import SearchableList, {COUNT_MODE} from 'components/List/Searchable';
import Loading from 'components/Loading';
import PathwaysBarChart from 'components/Pathway/BarChart';
import KyError from 'components/errors/KyError';
import useAppContext from 'conf/AppContext';
import Types from 'conf/types';
import {useGet} from 'helpers/KyHooks';


const propTypes = {
  objCls: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};
/**
 * Renders the pathways tab.
 */
export default function PathwaysTab({objCls, id}) {
  const appContext = useAppContext();
  const [filteredData, setFilteredData] = useState();
  const [barChartFilter, setBarChartFilter] = useState(null);

  const target = objCls === 'combination' ? id : `${objCls}/${id}`;
  const {response, error} = useGet(`site/tab/pathways/${target}`, {
    cacheResponse: !appContext.isPreview,
  }, {
    transform: (r) => sortBy(r.data, (f) => f.name),
  });

  useEffect(() => {
    let matches;
    if (barChartFilter) {
      const query = barChartFilter[0];
      matches = matchSorter(response, query, {
        keys: Types.pathway.searchKeys,
        threshold: matchSorter.rankings.CONTAINS,
      });
    }
    setFilteredData(matches);
  }, [barChartFilter]);

  let body = <Loading />;

  if (error) {
    body = <KyError kyError={error} />;
  } else if (response) {
    body = (
      <>
        <div className="pathwaysTab">
          <PathwaysBarChart
            pathways={response}
            filterChangeFn={setBarChartFilter}
            yAxisLabel={{truncate: ChartConstants.truncateOptions.END, maxLength: 40}}
          />
        </div>
        <SearchableList
          collection={filteredData || response}
          renderItemFn={renderItemWithoutTags}
          searchKeys={Types.pathway.searchKeys}
          singularNoun="pathway"
          countMode={filteredData ? COUNT_MODE.FILTERED : COUNT_MODE.LIST}
        />
      </>
    );
  }

  return (
    <>
      <h3>Pathways</h3>
      {body}
    </>
  );
}
PathwaysTab.propTypes = propTypes;
