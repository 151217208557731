import PropTypes from 'prop-types';
import {useState} from 'react';

import './Tab.scss';
import Loading from 'components/Loading';
import VariantAnnotationsBarChart from 'components/VariantAnnotation/BarChart';
import VariantAnnotationsTabIntro from 'components/VariantAnnotation/TabIntro';
import VariantAnnotationTable from 'components/VariantAnnotation/Table';
import KyError from 'components/errors/KyError';
import AlleleNote from 'components/shared/AlleleNote';
import useAppContext from 'conf/AppContext';
import {useGet} from 'helpers/KyHooks';


const propTypes = {
  objCls: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default function VariantAnnotationsTab({objCls, id}) {
  const appContext = useAppContext();
  const target = objCls === 'combination' ? id : `${objCls}/${id}`;
  const {response, error} = useGet(`site/tab/variantAnnotations/${target}`, {
    cacheResponse: !appContext.isPreview,
  });

  const [filter, setFilter] = useState(null);

  let body = <Loading />;
  let barChart = <div className="vaFigure vaFigure--loading figure figure--floatRight"><Loading /></div>;

  if (error) {
    body = <KyError kyError={error} />;
  } else if (response) {
    if (response?.data?.length > 0) {
      body = (
        <VariantAnnotationTable
          data={response?.data}
          objCls={objCls}
          canHideFilters={false}
          updatedFilters={filter}
        />
      );
      barChart = (
        <VariantAnnotationsBarChart
          variantAnnotations={response?.data}
          objCls={objCls}
          filterChangeFn={setFilter}
          captionTitle="Annotations by "
          className="figure--floatRight"
        />
      );
    } else {
      body = <p className="noData">No variant annotations.</p>;
      barChart = undefined;
    }
  }

  return (
    <div className="variantAnnotationsTab">
      <VariantAnnotationsTabIntro objCls={objCls} id={id} chart={barChart} />
      {body}
      {!!barChart && <AlleleNote />}
    </div>
  );
}
VariantAnnotationsTab.propTypes = propTypes;
