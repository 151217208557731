import PropTypes from 'prop-types';

import HaplotypeTabList from 'components/Haplotype/TabList';
import Loading from 'components/Loading';
import KyError from 'components/errors/KyError';
import useAppContext from 'conf/AppContext';
import {useGet} from 'helpers/KyHooks';


const propTypes = {
  id: PropTypes.string.isRequired,
};
/**
 * Renders the named alleles tab for the variant page.
 */
export default function VariantHaplotypesTab({id}) {
  const appContext = useAppContext();

  const {response, error} = useGet(`site/variant/${id}/haplotypes`, {
    cacheResponse: !appContext.isPreview,
  });

  let content = <Loading />;
  if (error) {
    content = <KyError kyError={error} />;
  } else if (response) {
    if (response.data?.length > 0) {
      content = (
        <>
          <p>This variant is a part of the following named alleles.</p>
          <HaplotypeTabList haplotypes={response.data} />
        </>
      );
    } else {
      content = <p>This variant is not a part of any named alleles.</p>;
    }
  }

  return (
    <section>
      <h3>Named Alleles</h3>
      {content}
    </section>
  );
}
VariantHaplotypesTab.propTypes = propTypes;
