import {map, size} from 'lodash';

import Link from 'components/links/Link';
import Fact from 'components/shared/fact';
import FactSection from 'components/shared/fact_section';

export default function GuidelineAnnotationRelatedChemicalsTab({id, relatedChemicals, otherChemicals}) {
  return (
    <FactSection title="Related Chemicals">
      {size(relatedChemicals) > 0 && (
        <Fact label="Chemicals in this annotation">
          <ul className="list-inline">
            {map(relatedChemicals, (obj) => (
              <li key={obj.id} className="list-inline-item">
                <Link href={`/chemical/${obj.id}/guidelineAnnotation/${id}`}>{obj.name}</Link>
              </li>
            ))}
          </ul>
        </Fact>
      )}

      {size(otherChemicals) > 0 && (
        <Fact label="Chemicals in other annotations on the same guideline">
          <ul className="list-unstyled">
            {map(otherChemicals, (obj) => {
              // eslint-disable-next-line no-prototype-builtins
              const noRec = obj.hasOwnProperty('recommendation') && !obj.recommendation ? <span className="text-danger">(no recommendation)</span> : null;
              return (
                <li key={obj.chemical.id + obj.guidelineId}>
                  <Link href={`/chemical/${obj.chemical.id}/guidelineAnnotation/${obj.guidelineId}`}>
                    {obj.chemical.name} + {obj.genes} {noRec}
                  </Link>
                </li>
              );
            })}
          </ul>
        </Fact>
      )}
    </FactSection>
  );
}
