import {find, map, sortBy} from 'lodash';

import {renderMetadata, renderTitle} from 'components/Literature/Citation';
import Loading from 'components/Loading';
import KyError from 'components/errors/KyError';
import Link from 'components/links/Link';
import ReadableWidthTemplate from 'components/templates/ReadableWidth';
import {useGet} from 'helpers/KyHooks';


export default function DataSourcesPage() {
  const {response, error} = useGet('site/dataSources');
  
  const renderCitation = (citation) => {
    let ariaLabel = '';
    const pmid = find(citation.synonyms, (x) => x.startsWith('PMID:'));
    if (pmid) {
      ariaLabel = pmid;
    }
    const url = `https://www.ncbi.nlm.nih.gov/pubmed/${pmid.substring(5)}`;

    return (
      <li key={url}>
        <Link href={url} ariaLabel={ariaLabel}>{renderTitle(undefined, citation.name)}</Link><br />
        {renderMetadata(citation.properties.journal, citation.properties.year, citation.properties.authors, true)}
      </li>
    );
  };

  let body = <Loading />;
  if (error) {
    body = <KyError kyError={error} />;
  } else if (response) {
    const data = sortBy(response.data, (i) => i.name.toLowerCase());
    body = (
      <ul>
        {map(data, (item) => {
          let link;
          if (item.url) {
            link = <a href={item.url}>{item?.acronym ?? item.name}</a>;
          } else {
            link = item?.acronym ?? item.name;
          }
          return (
            <li key={item.name}>
              {link} {item?.acronym ? `(${item.name})` : ''}
              {item.citations?.length > 0 && (<ol>{map(item.citations, renderCitation)}</ol>)}
            </li>
          );
        })}
      </ul>
    );
  }

  return (
    <ReadableWidthTemplate title="Acknowledgements">
      <p>This is a list of resources that PharmGKB currently uses, along with any relevant citations:</p>
      {body}
    </ReadableWidthTemplate>
  );
}
