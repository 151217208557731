import clsx from 'clsx';
import {camelCase, compact, filter, map} from 'lodash';
import PropTypes from 'prop-types';

import './index.scss';
import {AuthorStyle, renderLitMetadata} from 'components/Literature/Citation';
import LiteratureXrefs from 'components/Literature/Xrefs';
import {renderTags} from 'components/Tag';
import ClinicalAnnotationLevelTag from 'components/Tag/ClinicalAnnotationLevel';
import ResourceIcon from 'components/icons/Resource';
import HtmlContainer from 'components/shared/html_container';
import {OntologyResource} from 'conf/enums';
import Types, {getBaseUrl, hasIcon} from 'conf/types';
import {englishJoin} from 'helpers/util';


const propTypes = {
  objCls: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
  description: PropTypes.node,
  thumbnailLink: PropTypes.string,
  extras: PropTypes.node,
  className: PropTypes.string,
};
/**
 * Renders a basic list item.
 */
export default function ListItem({objCls, name, url, description, thumbnailLink, extras, className}) {
  const ccType = camelCase(objCls);
  const icon = hasIcon(ccType);
  const displayType = Types[ccType]?.displayType;
  const title = url ? <a href={url}>{name}</a> : name;

  return (
    <div className={clsx('listItem', className)}>
      <div className="listItem__icon" role="presentation">
        {icon && <ResourceIcon type={ccType} />}
      </div>
      <div className="listItem__name">
        <div className="listItem__name__type">{displayType}</div>
        <div className="listItem__name__title">{title}</div>
        {description && <div className="listItem__name__description">{description}</div>}
        {extras && <div className="listItem__name__extras">{extras}</div>}
      </div>
      {thumbnailLink && (
        <div className="listItem__thumbnail">
          <img src={thumbnailLink} alt={`${name} diagram`} />
        </div>
      )}
    </div>
  );
}
ListItem.propTypes = propTypes;


export function renderItemWithTags(item) {
  return renderItem({item, showTags: true});
}

export function renderItemWithoutTags(item) {
  return renderItem({item, showTags: false});
}

export function renderItem({item, showTags}) {
  const description = parseDescription({item, showTags});
  let name = item.symbol || item.name || item.title || item.displayName;
  if (!name && item.objCls.toLowerCase().match(/variant.*annotation/)) {
    const relSet = [];
    if (item.relatedChemicals) {
      relSet.push(...item.relatedChemicals);
    }
    if (item.phenotypeLinks) {
      relSet.push(...compact(map(item.phenotypeLinks, (p) => p.disease)));
    }
    if (item.populationPhenotypeLinks) {
      relSet.push(...compact(map(item.populationPhenotypeLinks, (p) => p.disease)));
    }
    if (relSet.length > 0) {
      name = `Variant Annotation for ${englishJoin(map(relSet, (i) => i.symbol || i.name), 'and')}`;
    } else {
      name = `Variant Annotation ${item.id}`;
    }
  }
  return (
    <ListItem
      key={item.id}
      objCls={item.objCls}
      name={name}
      url={item.url ?? getBaseUrl(item.id, item.objCls)}
      description={description}
      thumbnailLink={item?.thumbnailLink}
      className="listItemContainer"
    />
  );
}

const litResources = ['PubMed', 'PubMed Central', 'DOI'];

export function parseDescription({item, showTags = false, showXrefLinks = false}) {
  const ccType = camelCase(item.objCls);
  if (ccType === 'literature') {
    let xrefs = item.crossReferences;
    if (item.synonyms) {
      xrefs = compact(map(item.synonyms, (s) => {
        const parts = s.split(':');
        if (litResources.includes(parts[0])) {
          return ({
            resource: parts[0],
            resourceId: parts.slice(1).join(''),
          });
        }
        return null;
      }));
    }
    let paperTypes = [];
    if (showTags) {
      if (item.properties) {
        paperTypes = item.properties.pgxPaperTypes ?? [];
      } else {
        paperTypes = map(filter(item.terms, (t) => t.resource === OntologyResource.shortName.pgxPaperTypes),
          (t) => t.term);
      }
    }
    return (
      <>
        {renderLitMetadata(item, AuthorStyle.ALL)}
        {showXrefLinks && xrefs && xrefs.length > 0 && <LiteratureXrefs xrefs={xrefs} />}
        {paperTypes.length > 0 && (<div>PGx Paper Type: {paperTypes.join(', ')}</div>)}
        {showTags && renderTags({obj: item, showHelp: false, className: 'tag--xs'})}
      </>
    );
  } else if (ccType === 'pathway') {
    let categories;
    if (item.url) {
      // from index
      categories = item.categories ?? [];
    } else {
      // from db
      categories = map(filter(item.terms, (t) => t.resource === OntologyResource.shortName.pathwayCategories),
        (t) => t.term);
    }
    return (
      <>
        {categories.length > 0 ? <em>{categories.join(', ')}</em> : null}
        {getDescriptionOrSummary(item)}
      </>
    );
  } else if (ccType === 'clinicalAnnotation') {
    const loe = item.properties ? item.properties.level : item.levelOfEvidence?.term;
    if (loe) {
      return (
        <div>
          <ClinicalAnnotationLevelTag level={loe} showHelp={false} className="tag--xs" />
        </div>
      );
    }
    return null;
  } else if (ccType.match(/variant\w*Annotation/)) {
    const desc = item.url ? item.description : item.sentence;
    const description = desc ? <div>{desc}</div> : null;
    if (showTags) {
      return (
        <>
          {description}
          {renderTags({obj: item, showHelp: false, className: 'tag--xs'})}
        </>
      );
    }
    return description;
  }
  const description = getDescriptionOrSummary(item);
  if (showTags) {
    return (
      <>
        {getDescriptionOrSummary(item)}
        {renderTags({obj: item, showHelp: false, className: 'tag--xs'})}
      </>
    );
  }
  return description;
}

function getDescriptionOrSummary(item) {
  if (item.url) {
    // from index
    return item.description ? <div>{item.description}</div> : null;
  } else {
    // from db
    return item.summary ? <HtmlContainer html={item.summary.html} /> : null;
  }
}
