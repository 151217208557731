import {isObject} from 'lodash';
import PropTypes from 'prop-types';

import FeedbackLink from 'components/links/Feedback';


const defaultMessage = (
  <p>
    Something unexpected happened.  We are looking into it and will try to fix this as soon as
    possible.  If you would like to <FeedbackLink /> to let us know the details around this
    problem, we would be very grateful.
  </p>
);

const propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  message: PropTypes.node,
};

export default function ErrorMessage({title, subtitle, message}) {
  const renderTitle = () => {
    if (title) {
      if (isObject(title)) {
        return title;
      } else {
        return <h4>{title}</h4>;
      }
    }
    return '';
  };
  const renderSubtitle = () => {
    if (subtitle) {
      if (isObject(subtitle)) {
        return title;
      } else {
        return <div className="subtitle">{subtitle}</div>;
      }
    }
    return '';
  };

  const renderMessage = () => {
    if (message) {
      if (isObject(message)) {
        return message;
      }
      return <p>{message}</p>;
    }
    return defaultMessage;
  };

  return (
    <div className="alert alert-danger errorDisplay">
      {renderTitle()}
      {renderSubtitle()}
      {renderMessage()}
    </div>
  );
}

ErrorMessage.propTypes = propTypes;
