import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import useSearchBar from 'components/Search/Bar/Provider';


const propTypes = {
  /**
   * Starting index of items to display out of all possible items.
   */
  startIndex: PropTypes.number,
  /**
   * Number of items on the current page.
   */
  numOnPage: PropTypes.number.isRequired,
  /**
   * Maximum number of items to display per page.
   */
  resultsPerPage: PropTypes.number,
  hasMore: PropTypes.number,
  className: PropTypes.string,
};
/**
 * Renders pager for search results.
 */
export default function SearchPagination({startIndex = 0, resultsPerPage = 20, numOnPage, hasMore = false, className}) {
  const searchBar = useSearchBar();

  if (startIndex === 0 && !hasMore) {
    return '';
  }

  const renderButton = (label, direction, offset, display) => {
    if (display) {
      return (
        <a href={searchBar.generateSearchUrl(searchBar.state, offset)}>
          <FontAwesomeIcon icon={['fas', `chevron-${direction}`]} />
          <span className="sr-only">{label} {resultsPerPage} results</span>
        </a>
      );
    }
    return (
      <span className="empty" aria-hidden={true}>
        <FontAwesomeIcon icon={['fas', `chevron-${direction}`]} />
      </span>
    );
  };

  const page = (startIndex / 20) + 1;
  return (
    <nav className={clsx('searchPage__pager', className)} aria-label="pagination">
      {renderButton('Previous', 'left', startIndex - resultsPerPage, startIndex > 0)}
      <span className="searchPage__pager__status">Page {page}</span>
      {renderButton('Next', 'right', startIndex + resultsPerPage, hasMore)}
    </nav>
  );
}
SearchPagination.propTypes = propTypes;
