import PropTypes from 'prop-types';

import Loading from 'components/Loading';
import PageContent from 'components/Page/Content';
import KyError from 'components/errors/KyError';
import NotFound from 'components/shared/error/not_found';
import ReadableWidthTemplate from 'components/templates/ReadableWidth';
import useAppContext from 'conf/AppContext';
import {useGet} from 'helpers/KyHooks';


const propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default function Page({id}) {
  const appContext = useAppContext();

  const {response, error} = useGet(`data/page/${id}`, {
    searchParams: {
      view: 'min',
    },
    cacheResponse: !appContext.isPreview,
  });

  let content = <Loading />;
  let title = 'PharmGKB';
  if (response) {
    title = response.data?.title;
    content = (
      <PageContent
        id={id}
        html={response.data?.markdown?.html}
        fullPage={true}
      />
    );
  } else if (error) {
    if (error.response?.status === 404) {
      return <NotFound />;
    }
    content = <KyError kyError={error} />;
  }

  return (
    <ReadableWidthTemplate title={title}>
      {content}
    </ReadableWidthTemplate>
  );
}
Page.propTypes = propTypes;
