import clsx from 'clsx';
import PropTypes from 'prop-types';

import Tag, {renderTagIfPresent} from 'components/Tag/index';


const propTypes = {
  showHelp: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default function RetiredTag({showHelp = false, style, className}) {
  const props = {className: clsx('tag--retired', className)};
  if (showHelp) {
    props.tooltipText = 'Learn more about retired annotations';
    props.tooltipUrl = '/page/retirement';
  }
  return (
    <Tag {...props} style={style}>Retired</Tag>
  );
}
RetiredTag.propTypes = propTypes;


export function renderRetiredTag({obj, showHelp, className}) {
  return renderTagIfPresent({
    tag: RetiredTag,
    obj,
    dbProp: 'retirementReason',
    indexProp: 'retired',
    showHelp,
    className,
  });
}
