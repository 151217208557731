import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {toast} from 'react-toastify';

import Button from 'components/Button';
import WaitingButton from 'components/Button/Waiting';
import KyError from 'components/errors/KyError';
import Link from 'components/links/Link';
import useAppContext from 'conf/AppContext';


const propTypes = {
  id: PropTypes.string.isRequired,
  rsid: PropTypes.string.isRequired,
  lastUpdated: PropTypes.string,
};
/**
 * Renders button to update variant with data from dbSNP.
 *
 * @param {object} props - component properties
 * @param {string} props.id
 * @param {string} props.rsid
 * @param {string} [props.lastUpdated]
 * @return {React.ReactNode}
 */
export default function DbsnpUpdateButton({id, rsid, lastUpdated = ''}) {
  const appContext = useAppContext();
  const [clicked, setClicked] = useState(false);
  let toastId = '';

  const dismissToast = () => {
    if (toastId) {
      toast.dismiss(toastId);
    }
  };

  const clickHandler = async (updateToLatest) => {
    if (clicked) {
      return;
    }
    setClicked(true);
    let reload = false;
    try {
      const searchParams = {};
      if (updateToLatest) {
        searchParams.updateToLatest = updateToLatest;
      }
      await appContext.api.get(`curation/variant/${id}/_updateFromDbsnp`, {searchParams});
      if (updateToLatest) {
        reload = true;
      }
      toast.success('Update complete');
    } catch (error) {
      if (error.name === 'HTTPError' && error.response?.status === 409) {
        const jsend = await appContext.api.readResponse(error.response);
        if (jsend?.data?.errors?.[0]?.code === 'alreadyExists') {
          const newRsid = jsend.data.replacementRsid[0].message;
          const newVarId = jsend.data.replacementVariantId[0].message;
          toastId = appContext.toastError(
            <p>
              This has been replaced by
              RSID <Link href={`https://www.ncbi.nlm.nih.gov/snp/${newRsid}`} newTab={true}>{newRsid}</Link>.
              It already exists in PharmGKB
              as <a href={`/variant/${newVarId}`} onClick={dismissToast}>Variant {newVarId}</a>.
            </p>,
          );
          reload = true;
        } else if (jsend?.data?.errors?.[0]?.code === 'replaceable') {
          const newRsid = jsend.data.replacementRsid[0].message;
          const updateToLatestHandler = async () => {
            toast.dismiss(toastId);
            await clickHandler(true);
          };
          toastId = appContext.toastWarning(
            <>
              <p>
                This RSID has been replaced by
                RSID <Link href={`https://www.ncbi.nlm.nih.gov/snp/${newRsid}`} newTab={true}>{newRsid}</Link>.
              </p>
              <p>
                Do you want to update this variant to the new RSID?
              </p>
              <p>
                <Button actionHandler={updateToLatestHandler} className="btn-warning">Yes, Update</Button>
                <Button actionHandler={dismissToast} className="btn-light">No</Button>
              </p>
            </>,
          );
          reload = true;
        } else if (jsend?.data?.errors?.[0]?.code === 'noReplacement') {
          appContext.toastWarning((
            <>
              RSID <Link href={`https://www.ncbi.nlm.nih.gov/snp/${rsid}`} newTab={true}>{rsid}</Link> has
              been retired and no replacement can be found.
            </>
          ));
          reload = true;
        } else {
          appContext.toastError(<KyError kyError={error} />);
        }
      } else {
        appContext.toastError(<KyError kyError={error} />);
      }
    } finally {
      setClicked(false);
      if (reload) {
        // reload at the end to make sure we don't set state after unmounted
        appContext.reload();
      }
    }
  };


  return (
    <WaitingButton
      actionHandler={clickHandler}
      waiting={clicked}
      waitingLabel="Updating from dbSNP"
      title={lastUpdated ? `Last updated on ${lastUpdated}` : ''}
    >
      <FontAwesomeIcon icon="sync" /> Update dbSNP Location
    </WaitingButton>
  );
}
DbsnpUpdateButton.propTypes = propTypes;
