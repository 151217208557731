import PropTypes from 'prop-types';

export const youtubeIdPattern = /^[\da-zA-Z\-_]+$/;

const propTypes = {
  videoId: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default function YoutubeEmbed({videoId, title = 'YouTube Video'}) {
  // reference: https://dustri.org/b/youtube-video-embedding-harm-reduction.html
  // but allow picture-in-picture
  return (
    <div className="youtube">
      <iframe
        title={title}
        width={560}
        height={315}
        src={`https://www.youtube-nocookie.com/embed/${videoId}?rel=0`}
        allowFullScreen={true}
        credentialless="true"
        referrerPolicy="no-referrer"
        sandbox="allow-scripts allow-same-origin allow-presentation"
        allow="accelerometer 'none'; ambient-light-sensor 'none'; autoplay 'none'; battery 'none'; bluetooth 'none'; browsing-topics 'none'; camera 'none'; ch-ua 'none'; display-capture 'none'; domain-agent 'none'; document-domain 'none'; encrypted-media 'none'; execution-while-not-rendered 'none'; execution-while-out-of-viewport 'none'; gamepad 'none'; geolocation 'none'; gyroscope 'none'; hid 'none'; identity-credentials-get 'none'; idle-detection 'none'; keyboard-map 'none'; local-fonts 'none'; magnetometer 'none'; microphone 'none'; midi 'none'; navigation-override 'none'; otp-credentials 'none'; payment 'none'; publickey-credentials-create 'none'; publickey-credentials-get 'none'; screen-wake-lock 'none'; serial 'none'; speaker-selection 'none'; sync-xhr 'none'; usb 'none'; web-share 'none'; window-management 'none'; xr-spatial-tracking 'none'"
        csp="sandbox allow-scripts allow-same-origin;"
        loading="lazy"
        style={{border: 0}}
      />
    </div>
  );
}
YoutubeEmbed.propTypes = propTypes;
