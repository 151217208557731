import PropTypes from 'prop-types';
import {useState} from 'react';

import DownloadIcon from 'components/icons/Download';
import DownloadLinkForm, {SURVEY_COOKIE_NAME} from 'components/links/Download/Form';
import Link from 'components/links/Link';
import {readCookie} from 'helpers/cookies';


const propTypes = {
  href: PropTypes.string.isRequired,
  /**
   * Icon to use instead of default download icon.
   */
  icon: PropTypes.node,
  iconOnly: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Used to set the `title` attribute as well as `aria-label` attribute if `aria-label` is not defined.
   */
  title: PropTypes.string,
  /**
   * The link's text.
   */
  children: PropTypes.node,
};
/**
 * Renders a download link that triggers download survey before download.
 */
export default function DownloadLink({href, icon, iconOnly = false, className, title, children, ...otherProps}) {
  const hasCompletedSurvey = readCookie(SURVEY_COOKIE_NAME);
  const [openSurvey, setOpenSurvey] = useState(false);

  if (!href) {
    return <a href="#" className="text-danger">missing url</a>;
  }

  const onSurveyClose = () => {
    setOpenSurvey(false);
  };

  let actionHandler;
  if (!hasCompletedSurvey) {
    actionHandler = async (event) => {
      if (!readCookie(SURVEY_COOKIE_NAME)) {
        event.stopPropagation();
        event.preventDefault();
        setOpenSurvey(true);
      } else {
        setOpenSurvey(false);
      }
    };
  }

  return (
    <span className="btnWrapper">
      <Link
        href={href}
        download={true}
        actionHandler={actionHandler}
        className={className}
        iconOnly={iconOnly}
        title={title}
        {...otherProps}
      >
        {icon || <DownloadIcon />}{iconOnly ? '' : children}
      </Link>
      {openSurvey ? <DownloadLinkForm url={href} onSurveyClose={onSurveyClose} /> : ''}
    </span>
  );
}
DownloadLink.propTypes = propTypes;
