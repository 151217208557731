import logger from 'conf/Logger';


/**
 * Gets the width of the window.
 *
 * @return {number}
 */
export function getWindowWidth() {
  return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
}

/**
 * Gets the breakpoint name as defined in SCSS.
 * This should match what's in `_variables.scss` and `overrides_global.scss`.
 *
 * @param {number} [windowWidth]
 * @return {string}
 */
export function getBreakpointName(windowWidth) {
  const width = windowWidth || getWindowWidth();
  if (width < 576) {
    return 'xs';
  } else if (width < 768) {
    return 'sm';
  } else if (width < 992) {
    return 'md';
  } else if (width < 1200) {
    return 'lg';
  } else if (width < 1600) {
    return 'xl';
  }
  return 'xx';
}



// this should match what's in `_variables.scss` and `overrides_global.scss`.
export const DEFAULT_FONT_FAMILY = '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, ' +
  '"Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';

/**
 * Gets the default font size for the current window width.
 * This should match what's in `_variables.scss` and `overrides_global.scss`.
 *
 * @param {number} [windowWidth]
 * @return {number}
 */
export function getFontSize(windowWidth) {
  const width = windowWidth || getWindowWidth();
  if (width < 576) {
    return 11;
  } else if (width < 768) {
    return 12;
  } else if (width < 992) {
    return 13;
  } else if (width < 1200) {
    return 14;
  } else if (width < 1600) {
    return 15;
  }
  return 17;
}


/**
 * Gets the font size for charts.  This is smaller at larger resolutions.
 *
 * @param {number} [windowWidth]
 * @return {number}
 */
export function getChartFontSize(windowWidth) {
  const width = windowWidth || getWindowWidth();
  if (width < 576) {
    return 11;
  } else if (width < 768) {
    return 12;
  } else if (width < 992) {
    return 13;
  } else if (width < 1200) {
    return 13;
  } else if (width < 1600) {
    // orig: 15
    return 14;
  }
  // orig: 17
  return 15;
}


/**
 * Gets the length of the text in em (based on fontSize provided).
 *
 * @param {string} text
 * @param {number} [fontSize] - font size in px
 * @return {number}
 */
export function getTextWidthInEm(text, fontSize) {
  const elem = document.createElement('span');
  elem.style.position = 'absolute';
  elem.style.left = '-1000px';
  elem.style.top = '-100px';
  elem.style.fontSize = fontSize ? `${fontSize}px` : `${getFontSize()}px`;
  elem.innerHTML = text;
  document.body.appendChild(elem);

  const width = Math.ceil(elem.clientWidth);
  try {
    document.body.removeChild(elem);
  } catch (err) {
    // ignore
    logger.debug('Error removing test element', err);
  }
  return width / fontSize;
}
