import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {formatDistanceToNow, parseISO} from 'date-fns';
import {map} from 'lodash';

import Button from 'components/Button';
import Loading from 'components/Loading';
import Link from 'components/links/Link';
import logger from 'conf/Logger';
import {useGet} from 'helpers/KyHooks';

import './LatestNews.scss';


/**
 * Renders most recent news from blog on home page.
 */
export default function LatestNews() {
  const {response, error} = useGet('site/blog', {
    cacheResponse: true,
  });

  const renderNewsItem = (blogPost) => {
    const {title, url} = blogPost;
    const author = blogPost.primary_author.name;
    const timestamp = formatDistanceToNow(parseISO(blogPost.published_at));
    return (
      <div className="news-item" key={url}>
        <div className="article-body">
          <div className="article-title">
            <Link href={url}>{title}</Link>
          </div>
          <div className="article-meta">
            <span className="timestamp">{timestamp} ago</span> by {author}
          </div>
        </div>
      </div>
    );
  };

  const noNews = <div className="col-md-6 text-center show-not-available-when-empty" />;
  let newsItems;
  if (error) {
    logger.error(error);
    newsItems = noNews;
  } else if (response) {
    const blogPosts = response?.data;
    if (blogPosts?.length > 0) {
      newsItems = <div className="col-md-7">{map(blogPosts, renderNewsItem)}</div>;
    } else {
      newsItems = noNews;
    }
  } else {
    newsItems = <div className="col-md-6 text-center"><Loading /></div>;
  }

  return (
    <div className="container home-section">
      <h3 className="section-heading news">Latest News</h3>
      <div className="row">
        <div className="col-md-3" />
        {newsItems}
      </div>
      <div className="row">
        <div className="col text-center mt-3">
          <Button href="https://blog.clinpgx.org" className="btn btn-outline-primary btn-lg">
            <FontAwesomeIcon icon="envelope" /> Subscribe at the ClinPGx Blog
          </Button>
        </div>
      </div>
    </div>
  );
}
