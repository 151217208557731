import {map, sortBy} from 'lodash';

import DrugTargetItem, {findObject} from 'components/DrugTarget/Item';

/**
 * Makes a simple list of drug target data from LinkAnnotation data. Since each LinkAnnotation is a link from one object
 * to another object we require the "subject" ID so we know which of the two objects to display in the list.
 *
 * @param {object} props - props container
 * @param {Array} props.targets - an array of LinkAnnotation objects of Drug Target type
 * @param {string} props.subjectId - the ID of the "subject" page (i.e. the page this is being shown on)
 */
export default function DrugTargetList({targets = [], subjectId}) {
  if (targets?.length === 0) {
    return null;
  }

  const sortedTargets = sortBy(targets, (t) => findObject(t.object1, t.object2, subjectId).name);

  return (
    <ul className="mb-0">
      {map(sortedTargets, (t) => <DrugTargetItem key={t.id} subjectId={subjectId} {...t} />)}
    </ul>
  );
}
