import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {debounce, filter, uniqueId} from 'lodash';
import PropTypes from 'prop-types';
import {useCallback, useEffect, useState} from 'react';

import './ApprovalStatus.scss';

const INITIAL_VALUE = {unapproved: false, unpublished: false};

const propTypes = {
  /**
   * Column props passed by react-table.
   */
  column: PropTypes.shape({
    filterValue: PropTypes.shape({
      unapproved: PropTypes.bool.isRequired,
      unpublished: PropTypes.bool.isRequired,
    }),
    preFilteredRows: PropTypes.arrayOf(PropTypes.object),
    setFilter: PropTypes.func,
  }).isRequired,
};
/**
 * Renders filters for approval status.
 */
export default function ApprovalStatusFilter({
  column: {filterValue = '', preFilteredRows, setFilter},
}) {
  // store the input value in state so we can debounce the filter updates
  // if we use debounce directly the input[text] will not sync up
  const [value, setValue] = useState(filterValue || INITIAL_VALUE);
  const [id] = useState(uniqueId());

  useEffect(() => {
    // this will end up getting called every time the filter is updated,
    // but we only care if it suddenly gets zeroed out (due to filters getting reset)
    if (!filterValue) {
      setValue(INITIAL_VALUE);
    }
  }, [filterValue]);

  // noinspection JSCheckFunctionSignatures
  const debouncedFilterValueUpdate = useCallback(debounce(async (inputValue) => {
    setFilter(inputValue);
  }, 200), [setFilter]);

  const onUnapprovedChange = () => {
    const newVal = {unapproved: !value.unapproved, unpublished: value.unpublished};
    setValue(newVal);
    debouncedFilterValueUpdate(newVal);
  };

  const onUnpublishedChange = () => {
    const newVal = {unapproved: value.unapproved, unpublished: !value.unpublished};
    setValue(newVal);
    debouncedFilterValueUpdate(newVal);
  };

  return (
    <div className="approvalStatusFilter">
      <div>
        <input
          className={clsx('form-check-inline', {filterSet: value.unapproved})}
          id={`a-${id}`}
          type="checkbox"
          checked={value.unapproved}
          onChange={onUnapprovedChange}
        />
        <label className="form-check-label" htmlFor={`a-${id}`}>Unapproved</label>
      </div>
      <div>
        <input
          className={clsx('form-check-inline', {filterSet: value.unpublished})}
          id={`p-${id}`}
          type="checkbox"
          checked={value.unpublished}
          onChange={onUnpublishedChange}
        />
        <label className="form-check-label" htmlFor={`p-${id}`}>Unpublished <small><FontAwesomeIcon icon="eye-slash" /></small></label>
      </div>
    </div>
  );
}
ApprovalStatusFilter.propTypes = propTypes;



/**
 * React-table `filter` for approval status.
 * Expects data to have `approved` and `published` properties.
 *
 * @param {Array} rows
 * @param {Array<string>} columnIds
 * @param {object} filterValue
 */
export function approvalStatusFilter(rows, columnIds, filterValue) {
  if (!filterValue || (filterValue.unapproved === false && filterValue.unpublished === false)) {
    return rows;
  }
  const id = columnIds[0];
  return filter(rows, (r) => {
    const {approved, published} = r.values[id];
    if (filterValue.unapproved) {
      if (filterValue.unpublished) {
        return !approved && !published;
      }
      return !approved;
    } else if (filterValue.unpublished) {
      return !published;
    }
    return true;
  });
}
