import {isArray, join, map} from 'lodash';
import {matchSorter} from 'match-sorter';
import PropTypes from 'prop-types';

import TextFilter from 'components/Table/Filter/Text';
import {getBaseUrl} from 'conf/types';
import {accIdNameComparator} from 'utils/comparators';


/**
 * Typical defaults for an AccessionIdentifier column.
 */
export const baseAccIdColumn = {
  Cell: AccIdCell,
  sortable: true,
  sortType: sortAccIds,
  Filter: TextFilter,
  filter: accIdFilter,
  getCellExportValue: accIdExportValue,
};


const propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
};
/**
 * Renders a table cell containing AccessionIdentifiers.
 *
 * To filter, use `{Filter: TextFilter, filter: accIdFilter}`.
 *
 * To sort, use `{sortType: sortAccIds}`.
 *
 * To export, use `{getCellExportValue: accIdExportValue}`.
 *
 * Sample column:
 * ```
 * {
 *   id: 'gene',
 *   Header: 'Gene',
 *   accessor: (originalRow) => {
 *     if (originalRow?.properties?.genes) {
 *       return originalRow.properties.genes;
 *     }
 *   },
 *   Cell: AccIdCell,
 *   sortable: true,
 *   sortType: sortAccIds,
 *   Filter: TextFilter,
 *   filter: accIdFilter,
 *   getCellExportValue: accIdExportValue,
 * }
 * ```
 */
export default function AccIdCell({value}) {
  if (!value) {
    return '';
  }

  if (isArray(value)) {
    return map(value.sort(accIdNameComparator), renderAccId);
  }
  return renderAccId(value);
}
AccIdCell.propTypes = propTypes;


// supports accId of either AccessionIdentifier or SimpleEntity
function renderAccId(accId, index, array) {
  const name = accId.symbol || accId.name;
  let url;
  if (accId.objCls) {
    // assume this is an AccessionIdentifier
    url = getBaseUrl(accId.id, accId.objCls);
  } else {
    // assume this is a SimpleEntity
    url = accId.url;
  }
  let link = name;
  if (url) {
    link = <a href={`${url}`}>{name}</a>;
  }
  if (!array) {
    return link;
  }
  const comma = index < array.length - 1 ? ', ' : '';
  return <span key={url}>{link}{comma}</span>;
}


/**
 * React-table `sortType` for AccessionIdentifiers.
 *
 * @param {object} rowA
 * @param {object} rowB
 * @param {string} columnId
 * @return {number}
 */
export function sortAccIds(rowA, rowB, columnId) {
  const a = rowA.values[columnId];
  const b = rowB.values[columnId];

  if (!a) {
    if (!b) {
      return 0;
    }
    return 1;
  } else if (!b) {
    return -1;
  }

  let aName;
  let bName;
  if (isArray(a)) {
    a.sort(accIdNameComparator);
    b.sort(accIdNameComparator);
    aName = a?.[0]?.symbol || a?.[0]?.name;
    bName = b?.[0]?.symbol || b?.[0]?.name;
  } else {
    aName = a.symbol || a.name;
    bName = b.symbol || b.name;
  }

  if (!aName) {
    if (!bName) {
      return 0;
    }
    return 1;
  } else if (!bName) {
    return -1;
  }
  return aName.localeCompare(bName);
}


/**
 * React-table `filter` for AccessionIdentifiers.
 *
 * @param {Array} rows
 * @param {Array} columnIds
 * @param {Array|string} filterValue
 * @return {Array}
 */
export function accIdFilter(rows, columnIds, filterValue) {
  if (!filterValue) {
    return rows;
  }
  const id = columnIds[0];
  return matchSorter(rows, filterValue, {
    keys: [
      (row) => getSymbolOrName(row.values[id]),
    ],
    threshold: matchSorter.rankings.CONTAINS,
  });
}


/**
 * React-table `getCellExportValue` for AccessionIdentifiers.
 *
 * @param {object} row
 * @param {object} column
 * @return {string}
 */
export function accIdExportValue(row, column) {
  const value = getSymbolOrName(row.values[column.id]);
  if (isArray(value)) {
    return join(value, '; ');
  }
  return value;
}


function getSymbolOrName(data) {
  if (data === undefined) {
    return '';
  }
  if (isArray(data)) {
    return map(data, (d) => d.symbol || d.name);
  } else {
    return data.symbol || data.name;
  }
}
