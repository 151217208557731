import clsx from 'clsx';
import {map} from 'lodash';
import {toast} from 'react-toastify';

import DeleteButton from 'components/Button/Delete';
import EditButton from 'components/Button/Edit';
import HistoryTable from 'components/History/Table';
import LiteratureCitation from 'components/Literature/Citation';
import Loading from 'components/Loading';
import EditControls from 'components/edit/EditControls';
import KyError from 'components/errors/KyError';
import ResourceLink from 'components/links/Resource';
import Fact from 'components/shared/fact';
import ReadableWidthTemplate from 'components/templates/ReadableWidth';
import useAppContext from 'conf/AppContext';
import {useGet} from 'helpers/KyHooks';


const displayName = (accId) => accId?.symbol || accId?.name || 'unspecified';

export default function LinkAnnotationPage({id}) {
  const appContext = useAppContext();
  const {response, error} = useGet(`data/linkAnnotation/${id}`, {searchParams: {view: 'most'}});

  const deleteSuccessHandler = (daId) => {
    toast.warn('Deleted', {autoClose: false});
  };
  const deleteFailureHandler = (daId, err) => {
    appContext.toastError(<KyError kyError={err} />);
  };

  let body;
  let title = 'Link Annotation';
  
  if (error) {
    body = <KyError kyError={error} />;
  } else if (!response) {
    body = <Loading />;
  } else {
    const {object1, object2, type, text, sources = [], history, literature, terms = [], valid} = response.data;
    title = `${displayName(object1)} ${type} ${displayName(object2)}`;
    body = (
      <div>
        <h2>Link Annotation {id}</h2>
        <EditControls>
          <EditButton href={`/edit/linkAnnotation/${id}`} />
          <DeleteButton
            id={id}
            url={`curation/linkAnnotation/${id}`}
            onSuccess={deleteSuccessHandler}
            onFailure={deleteFailureHandler}
          />
        </EditControls>
        <Fact label="Between">
          <ResourceLink resource={object1} />
          <br />
          and
          <br />
          {object2 && <ResourceLink resource={object2} />}
        </Fact>
        <Fact label="Type" literal={type} />
        <Fact label="Source" literal={sources.join(', ')} />
        <Fact label="Associated Publications">
          {map(literature, (l) => <LiteratureCitation key={l.id} {...l} />)}
        </Fact>
        <Fact label="Terms">
          {terms.length > 0 && <ul>{map(terms, (t) => <li key={t.id}>{t.resource}: {t.term}</li>)}</ul>}
        </Fact>
        <Fact label="Description">
          {text}
        </Fact>
        <Fact label="Status">
          <span className={clsx({'text-danger': !valid})}>{valid ? 'Valid' : 'Invalid'}</span>
        </Fact>
        <Fact label="History">
          <HistoryTable parentCls="LinkAnnotation" history={history} parentId={id} />
        </Fact>
      </div>
    );
  }

  return (
    <ReadableWidthTemplate title={title}>
      {body}
    </ReadableWidthTemplate>
  );
}
