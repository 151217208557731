import clsx from 'clsx';
import PropTypes from 'prop-types';

import './index.scss';
import Link from 'components/links/Link';
import logger from 'conf/Logger';
import {extractProps} from 'utils/propUtils';


/**
 * Checks if an event is due to an ignorable button keydown event (i.e. should only activate on
 * `Enter` or `Space`).
 */
export function isIgnorableKeydown(event) {
  return event.type === 'keydown' && event.key !== 'Enter' && event.key !== ' ';
}

/**
 * Creates an action handler (for onClick/onKeyDown) for buttons.
 *
 * @param {Function} action - function to call if action should be performed
 */
export function createActionHandler(action) {
  return (event) => {
    if (isIgnorableKeydown(event)) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();
    if (action) {
      action(event);
    }
  };
}


const propTypes = {
  /**
   * Function to call on click or keyboard event.
   * Not applicable if this is an `href` button.
   */
  actionHandler: PropTypes.func,
  href: PropTypes.string,
  /**
   * Type of button: `button` (the default), `submit`, or `reset`.  Mainly used for a button in a form.
   * Not applicable if this is an `href` button.
   */
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  /**
   * Forces link to be opened in new tab if true, in same tab if false.
   * If undefined, it will default to opening external links in new tab.
   * Only applicable if this is an `href` button.
   */
  newTab: PropTypes.bool,
  /**
   * Determines if button should be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * Determines if button is icon-only.
   * This will render a frame-less button.
   */
  iconOnly: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  /**
   * Used to set the `title` attribute as well as `aria-label` attribute if `aria-label` is not defined.
   */
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

/**
 * Creates a button out of either an <a> or <button> element as appropriate.
 *
 * @return {ReactElement}
 */
export default function Button({actionHandler, href, type, newTab = false, disabled = false,
  iconOnly = false, className, style, title, children, ...otherProps}) {
  if (!href && !actionHandler && !type) {
    logger.error('Button requires either href, actionHandler, or type property');
    return <button className="btn btn-danger">error - check console</button>;
  }
  if (href && (actionHandler || type)) {
    logger.error('Button with href cannot be used with actionHandler or type');
    return <button className="btn btn-danger">error - check console</button>;
  }
  if (actionHandler && newTab) {
    logger.error('Button with actionHandler cannot use newTab - must handle in onClick');
    return <button className="btn btn-danger">error - check console</button>;
  }

  const htmlProps = extractProps(otherProps);
  let classes;
  if (iconOnly) {
    if (!htmlProps['aria-label'] && !title) {
      logger.error('iconOnly Button must specify aria-label or title');
      return <button className="btn btn-danger">error - check console</button>;
    }
    classes = clsx('btn', 'button--iconOnly', className);
  } else if (!className && type === 'reset') {
    classes = 'btn btn-secondary';
  } else {
    classes = clsx('btn', className || 'btn-primary');
  }

  let button;
  // <a> cannot be disabled; use <button> instead
  if (href && !disabled) {
    // delegate to <Link>
    button = (
      <Link
        href={href}
        newTab={newTab}
        className={classes}
        style={style}
        role="button"
        title={title}
        {...htmlProps}
      >
        {children}
      </Link>
    );
  } else {
    const submitHandler = actionHandler ? createActionHandler(actionHandler) : null;
    if (title && !htmlProps['aria-label']) {
      htmlProps['aria-label'] = title;
    }

    button = (
      <button
        type={type || 'button'}
        onClick={submitHandler}
        onKeyDown={submitHandler}
        disabled={disabled}
        aria-disabled={disabled}
        className={classes}
        style={style}
        title={title}
        {...htmlProps}
      >
        {children}
      </button>
    );
  }

  return button;
}

Button.propTypes = propTypes;
