import clsx from 'clsx';
import {debounce, isNil, map} from 'lodash';
import PropTypes from 'prop-types';
import {
  createContext, forwardRef, Fragment, lazy, Suspense, useCallback, useContext, useEffect, useRef, useState,
} from 'react';

import Loading from 'components/Loading';
import ColumnHeader from 'components/Table/ColumnHeader';
import {tableLayoutProps} from 'components/Table/frameProps';
import {renderTableRow} from 'components/Table/utils';

// noinspection JSCheckFunctionSignatures
const TableVirtuoso = lazy(() =>
  import(/* webpackChunkName: "table" */'react-virtuoso')
    .then((module) => ({default: module.TableVirtuoso})));



function _getInitialScrollPosition(id) {
  if (id && sessionStorage) {
    const cachedPosition = sessionStorage.getItem(`table-${id}-scrollPosition`);
    if (cachedPosition) {
      return JSON.parse(cachedPosition);
    }
  }
  return 0;
}

const _updateScrollPosition = debounce((id, position) => {
  sessionStorage.setItem(`table-${id}-scrollPosition`, JSON.stringify(position));
}, 500, {leading: true, trailing: true});


const VirtuosoTableProps = createContext(undefined);

function VirtuosoTableWrapper(props) {
  const tableProps = useContext(VirtuosoTableProps);
  return <table {...tableProps} {...props} />;
}



const propTypes = {
  height: PropTypes.string,
  minHeight: PropTypes.string,
  width: PropTypes.string,
  // react-virtuoso prop - specifies how many items to render initially (for SSR)
  initialItemCount: PropTypes.number,
  ...tableLayoutProps,
};
export default function VirtualizedTableLayout({
  height,
  minHeight,
  width,
  // react-virtuoso prop
  initialItemCount,
  // from TableFrame
  id,
  rowClassNameFn,
  showFilters,
  isFullscreen,
  hasStickyColumns,
  hasFilters,
  memoizedColumns,
  memoizedMaxDepth,
  renderNoResultsFoundFn,
  // from react-table
  getTableProps, // table props from react-table
  getTableBodyProps, // table body props from react-table
  headerGroups, // headerGroups if your table has groupings
  rows, // rows for the table based on the data passed
  prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
  state, // Controlled state of the table
}) {
  const [initialScrollPosition] = useState(_getInitialScrollPosition(id));

  let onRangeChanged;
  if (id && sessionStorage) {
    onRangeChanged = ({startIndex}) => {
      // noinspection JSValidateTypes
      _updateScrollPosition(id, startIndex);
    };
  }

  const virtuosoRef = useRef(null);
  useEffect(() => {
    // when filters or sorting changed, jump to top of scroll area
    virtuosoRef.current?.scrollToIndex({index: 0});
  }, [state.filters, state.sortBy]);


  /**
   * Renders the headers.
   */
  const fixedHeaderContent = useCallback(() => map(headerGroups, (headerGroup, hdrIdx) => {
    const lastRow = hdrIdx === headerGroups.length - 1;
    const isGroup = headerGroups.length > 1 && !lastRow;
    return (
      <Fragment key={hdrIdx}>
        <tr
          {...headerGroup.getHeaderGroupProps({className: clsx({reactTable__group: isGroup})})}
          key={`${hdrIdx}-names`}
        >
          {map(headerGroup.headers, (column, idx) => {
            if (!column.isVisible) {
              return null;
            }
            return (
              <ColumnHeader
                key={column.id}
                headerGroup={headerGroup}
                isGroup={isGroup}
                memoizedMaxDepth={memoizedMaxDepth}
                memoizedColumns={memoizedColumns}
                column={column}
                colNum={idx}
              />
            );
          })}
        </tr>
        {hasFilters && renderFilters(headerGroup, hdrIdx)}
      </Fragment>
    );
  }), [hasFilters, headerGroups, memoizedColumns, memoizedMaxDepth]);

  /**
   * Renders tbody element.
   */
  const TableBody = useCallback(({style, ...props}, ref) => (
    <tbody {...getTableBodyProps()} {...props} ref={ref} />
  ), [getTableBodyProps]);

  /**
   * Renders the row container (tr).
   */
  const TableRow = useCallback((props) => {
    const index = props['data-index'];
    const row = rows[index];
    if (isNil(row)) {
      // this sometimes happens when using initialItemCount
      return null;
    }
    prepareRow(row);
    const customRowClassName = rowClassNameFn ? rowClassNameFn(row) : '';
    const className = clsx(customRowClassName, {'tr-even': index % 2 === 1});

    return (
      <tr
        aria-rowindex={index}
        {...row.getRowProps({...props, className})}
        key={index}
      />
    );
  }, [id, prepareRow, rowClassNameFn, rows]);

  /**
   * Renders the contents of a row (tr).
   */
  const itemContent = useCallback((index) => {
    const row = rows[index];
    if (isNil(row)) {
      // this sometimes happens when using initialItemCount
      return null;
    }
    prepareRow(row);
    return renderTableRow(row);
  }, [prepareRow, renderTableRow, rows]);

  const EmptyPlaceholder = useCallback(() => (
    <tbody>
      {renderNoResultsFoundFn()}
    </tbody>
  ), [renderNoResultsFoundFn]);

  return (
    <Suspense fallback={<Loading />}>
      <VirtuosoTableProps.Provider
        value={getTableProps({
          id,
          className: clsx('reactTable', 'virtualizedTable', {
            'reactTable--stickyColumns': hasStickyColumns,
          }),
        })}
      >
        <TableVirtuoso
          totalCount={rows?.length ?? 0}
          ref={virtuosoRef}
          style={{width, height, minHeight}}
          initialItemCount={initialItemCount}
          initialTopMostItemIndex={initialScrollPosition}
          rangeChanged={onRangeChanged}
          components={{
            Table: VirtuosoTableWrapper,
            TableBody: forwardRef(TableBody),
            TableRow,
            EmptyPlaceholder,
          }}
          fixedHeaderContent={fixedHeaderContent}
          itemContent={itemContent}
        />
      </VirtuosoTableProps.Provider>
    </Suspense>
  );
}
VirtualizedTableLayout.propTypes = propTypes;



function renderFilters(headerGroup, i) {
  return (
    <tr {...headerGroup.getHeaderGroupProps({className: 'tr tr--filter'})} key={`${i}-filters`}>
      {map(headerGroup.headers, (column) => {
        if (!column.isVisible) {
          return null;
        }
        const props = {
          className: clsx(column.filterClassName || column.headerClassName || column.className),
        };
        if (column.style) {
          props.style = column.style;
        }
        return (
          <th {...column.getHeaderProps(props)} key={column.id}>
            {column.canFilter && column.render('Filter')}
          </th>
        );
      })}
    </tr>
  );
}
