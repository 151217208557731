import {join, map} from 'lodash';
import PropTypes from 'prop-types';
import {useEffect} from 'react';

import Loading from 'components/Loading';
import useSearchBar from 'components/Search/Bar/Provider';
import SideNav from 'components/SideNav';
import {getMenuItems, getTabComponent} from 'components/SideNav/utils';
import ErrorMessage from 'components/errors/ErrorMessage';
import KyError from 'components/errors/KyError';
import ResourcePage from 'components/templates/Resource/Page';
import useAppContext from 'conf/AppContext';
import Types from 'conf/types';
import usePediatricContext from 'context/Pediatric';
import {useGet} from 'helpers/KyHooks';
import CombinationOverviewTab from 'pages/Combination/OverviewTab';


const propTypes = {
  id: PropTypes.string.isRequired,
  section: PropTypes.string,
};
/**
 * Renders combination page.
 */
export default function CombinationPage({id, section = 'overview'}) {
  const appContext = useAppContext();
  const pediatricContext = usePediatricContext();
  const searchBar = useSearchBar();
  const showOverview = !section || section === 'overview';

  const {response, error} = useGet(`site/combination/${id}`, {
    cacheResponse: !appContext.isPreview,
  });
  useEffect(() => {
    if (response) {
      searchBar.initializeSearchBar(response.data.objects);
    }
  }, [response]);

  let name = `Combination ${id}`;
  let body = <Loading />;
  let counts = {};
  let headerData = [{
    id,
    type: 'combination',
    name,
  }];
  if (error) {
    body = <KyError kyError={error} />;
  } else if (response) {
    headerData = response.data.objects;
    counts = response.data.counts;
    name = join(map(headerData, (o) => o.symbol || o.name), ' + ');
    if (showOverview) {
      body = (
        <CombinationOverviewTab ids={id} objects={headerData} counts={counts} />
      );
    }
  }
  const Comp = getTabComponent(section, pediatricContext.isPediatricMode);
  if (Comp) {
    body = <Comp id={id} objCls="combination" name={name} />;
  } else if (!showOverview) {
    body = <ErrorMessage message={`Unsupported tab "${section}"`} />;
  }


  const baseUrl = Types.combination.url(id);
  const menuItems = getMenuItems('combination', baseUrl, counts);

  return (
    <ResourcePage title={name} headerData={headerData}>
      <SideNav items={menuItems}>
        {body}
      </SideNav>
    </ResourcePage>
  );
}
CombinationPage.propTypes = propTypes;
