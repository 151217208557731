import {compact, forEach, every, isEmpty, size, sortBy} from 'lodash';

const GRCH37 = 'GRCh37';
const GRCH38 = 'GRCh38';

export const allelePattern = /^\([ACGT]{2,}\)\d+|[ACGT]+$/;
/**
 * Checks if a string is a known allele pattern in one of the following example forms
 * - "A", "GC"
 * - "(AGCT)2"
 *
 * @param {string} value - a string to test for
 * @return {boolean} true if the string is an allele, false otherwise
 */
export function isAllele(value) {
  return allelePattern.test(value);
}

const genotypePattern = /^\([ACGT]{2,}\)\d+\/\([ACGT]{2,}\)\d+|\([ACGT]{2,}\)\d+|[ACGT/]{2,}$/;
/**
 * Checks if a string is a known genotype pattern in one of the following example forms
 * - "AA", "GC", "G/T"
 * - "(AGCT)2"
 * - "(AGCT)2/(AGCT)3"
 *
 * @param {string} value - a string to test for
 * @return {boolean} true if the string is a genotype, false otherwise
 */
export function isGenotype(value) {
  return genotypePattern.test(value);
}

/**
 * Given an array of possible alleles for a Variant, this function will generate all possible
 * genotype combinations of the alleles usable in an annotation.
 *
 * @param {string[]} rawAlleles - array of string alleles
 * @return {string[]} an array of possible allele values usable in an annotation tool
 */
export function makeGenotypeValues(rawAlleles) {
  const alleles = compact(rawAlleles);
  const singleBaseMode = every(alleles, (a) => size(a) === 1);
  const genotypeSep = singleBaseMode ? '' : '/';

  const genotypes = new Set();
  forEach(alleles, (v) => genotypes.add(v + genotypeSep + v));
  for (let i = 0; i < alleles.length; i += 1) {
    for (let j = 0; j < alleles.length; j += 1) {
      if (i !== j) {
        const bases = sortBy([alleles[i], alleles[j]]);
        genotypes.add(bases.join(genotypeSep));
      }
    }
  }
  const genotypesArray = sortBy(Array.from(genotypes));

  const combos = new Set();
  for (let m = 0; m < genotypesArray.length; m += 1) {
    for (let n = 0; n < genotypesArray.length; n += 1) {
      if (m !== n) {
        const genos = sortBy([genotypesArray[m], genotypesArray[n]]);
        combos.add(genos.join(' + '));
      }
    }
  }
  const combosArray = sortBy(Array.from(combos));

  return [...genotypesArray, ...combosArray];
}

export function isGRCh37(text) {
  return !isEmpty(text) && text.includes(GRCH37);
}

export function isGRCh38(text) {
  return !isEmpty(text) && text.includes(GRCH38);
}

export function findChromosome(text) {
  if (isEmpty(text)) return undefined;

  const results = text.match(/chr[MXY]|chr\d+/);
  if (results && results.length === 1 && results[0].length <= 5) {
    return results[0];
  } else {
    return undefined;
  }
}
