import {forEach, map, reduce} from 'lodash';

import KyError, {hasJsendFieldErrors} from 'components/errors/KyError';


/**
 * Utility method to handle submit errors.
 *
 * @param {object} appContext
 * @param {HTTPError} err - error thrown by KyHelper
 * @param {Function} rhfSetErrorFn - RHF's setError function
 * @return {JSXElement} KyError if there are no field errors
 */
// eslint-disable-next-line import/prefer-default-export
export async function handleSubmitErrors(appContext, err, rhfSetErrorFn) {
  if (err) {
    if (appContext.api.isBadRequest(err)) {
      const errorResponse = await appContext.api.readResponse(err.response);
      if (hasJsendFieldErrors(errorResponse)) {
        forEach(Object.entries(errorResponse?.data), ([key, value = []]) => {
          const message = reduce(map(value, (e) => e.message), (r, m) => r + '; ' + m);
          rhfSetErrorFn(key, {type: 'api', message});
        });
        return;
      }
    }

    rhfSetErrorFn('root.serverError', {
      type: 'kyError',
      message: <KyError kyError={err} />,
    });
  }
}
