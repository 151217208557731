import {find, forEach, get, isArray, isNil, isPlainObject, map, some, startsWith} from 'lodash';

import InfoLink from 'components/links/Info';
import logger from 'conf/Logger';


export function normalizeInitialSelectValues(formLevel, fieldLevel, isMulti, allItems) {
  let defaultValue = formLevel;
  if (defaultValue === '' && fieldLevel !== '') {
    defaultValue = fieldLevel;
  }
  return normalizeSelectValues(defaultValue, isMulti, allItems);
}

export function normalizeSelectValues(options, isMulti, allItems) {
  if (isNil(options)) {
    return isMulti ? [] : '';
  }
  if (isMulti) {
    if (isArray(options)) {
      if (!isPlainObject(options[0])) {
        return map(options, (o) => {
          const match = find(allItems, (i) => i.value === o);
          if (match) {
            return match;
          }
          return ({label: o, value: o});
        });
      }
    } else {
      if (isPlainObject(options)) {
        return [options];
      } else {
        const match = find(allItems, (i) => i.value === options);
        if (match) {
          return [match];
        }
        return [{label: options, value: options}];
      }
    }
    return options;
  } else {
    let opt = options;
    if (isArray(options)) {
      if (options.length > 1) {
        logger.error('Multiple values for initial value of single select:', options);
      }
      opt = options[0];
    }
    if (!isPlainObject(opt)) {
      const match = find(allItems, (i) => i.value === opt);
      if (match) {
        return match;
      }
      return {label: opt, value: opt};
    }
    return opt;
  }
}


export function requiredValidator(value) {
  return value?.length > 0 ? true : 'This field is required.';
}

/**
 * Builds a custom validation object for RHF.
 *
 * @param {object} params
 * @param {object} [params.validation]
 * @param {boolean} [params.required]
 */
export function buildCustomValidation({validation = {}, required = false}) {

  if (required) {
    return {
      validate: {
        required: requiredValidator,
        ...validation,
      },
    };
  }
  if (!validation) {
    return undefined;
  }
  return {
    validate: validation,
  };
}


export function getErrors({disabled, errors, name}) {
  if (disabled) {
    return [];
  }
  const errs = [];
  const names = isArray(name) ? name : [name];
  forEach(names, (n) => {
    if (get(errors, n)) {
      errs.push(get(errors, n));
    }
  });
  return errs;
}

export function isInvalid({disabled, errors, name}) {
  return getErrors({disabled, errors, name}).length > 0;
}

export function checkErrors({disabled, errors, name}) {
  const errs = getErrors({disabled, errors, name});
  if (errs.length === 0) {
    return {invalid: false, errorMsg: null};
  }
  return {
    invalid: errs.length > 0,
    errorMsg: _renderErrors(errs),
  };
}

function _renderErrors(errs) {
  if (errs.length === 0) {
    return null;
  }
  return (
    <div className="invalid-feedback">
      <ul className="list-unstyled">
        {map(errs, (err, index) => <li key={index}>{err.message}</li>)}
      </ul>
    </div>
  );
}

/**
 * Use this function when you only have a single value that can be used as both the label and
 * the value of the option.
 *
 * @param {*} v - a value to be used as an option
 * @return {undefined|{label: *, value: *}}
 */
export const mapValue = (v) => {
  if (!v) {
    return undefined;
  }
  return {label: v, value: v};
};

/**
 * Make options for use in a form component from an enum object
 *
 * @param {object} e - enum object from enums.js
 * @return {{label: *, value: *}[]|undefined}
 */
export const makeEnumOptions = (e) => {
  if (!e) {
    return undefined;
  }
  return map(Object.keys(e.displayName), mapValue);
};

/**
 * Use this function when you only have a single value that can be used as both the label and
 * the value of the option.
 *
 * @param {*} ot - a value to be used as an option
 * @return {undefined|{label: *, value: *}}
 */
export const mapOntologyTerm = (ot) => {
  if (!ot) {
    return undefined;
  }
  return ({
    label: ot.term,
    value: ot.id,
  });
};

/**
 * Make options for use in a form component from terms
 *
 * @param {Array<object>} terms
 * @return {{label: *, value: *}[]|undefined}
 */
export const makeOntologyTermOptions = (terms) => {
  if (!terms) {
    return undefined;
  }
  return map(terms, mapOntologyTerm);
};

/**
 * Use this function when you only have a single value that can be used as both the label and
 * the value of the option.
 *
 * @param {*} accId - a value to be used as an option
 * @return {undefined|{label: *, value: *}}
 */
export const mapAccId = (accId) => ({
  label: accId.symbol || accId.name,
  value: accId.id,
});

/**
 * Maps an AccessionIdentifier to a label/value pair but ONLY uses the "name" property and ignores the "symbol".

 * @param {{name: string, id: *}} accId - a value to be used as an option
 * @return {undefined|{label: *, value: *}}
 */
export const mapAccName = (accId) => ({
  label: accId.name,
  value: accId.id,
});

/**
 * Make options for use in a form component from accIds
 *
 * @param {Array<object>} accIds
 * @return {{label: *, value: *}[]|undefined}
 */
export const makeAccIdOptions = (accIds) => {
  if (!accIds) {
    return undefined;
  }
  return map(accIds, mapAccId);
};

/**
 * Checks email valid or not.
 *
 * Based on https://github.com/manishsaraan/email-validator
 *
 * @param {string} email
 * @return {boolean}
 */
export function validateEmail(email) {
  const tester = /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

  if (!email) {
    return false;
  }

  const emailParts = email.split('@');

  if (emailParts.length !== 2) {
    return false;
  }

  const account = emailParts[0];
  const address = emailParts[1];

  if (account.length > 64) {
    return false;
  } else if (address.length > 255) {
    return false;
  }

  const domainParts = address.split('.');
  if (some(domainParts, (part) => part.length > 63)) {
    return false;
  }
  return tester.test(email);
}


/**
 * Checks url valid or not.
 *
 * @param {string} value
 * @param {string} [message]
 * @return {boolean | string}
 */
export function validateUrl(value, message) {
  const msg = message ?? 'URL must begin with "http://" or "https://".';
  return (startsWith(value, 'http://') || startsWith(value, 'https://')) || msg;
}

/**
 * Render label with or without info link.
 *
 * @param {object} label
 * @param {string} [helpLink]
 * @return {string | node}
 */
export function renderLabel(label, helpLink) {
  if (helpLink) {
    return (
      <>
        {label}{' '}
        <InfoLink
          href={helpLink}
          iconClassName="icon--superscript"
          iconSize="xs"
          newTab={true}
        />
      </>
    );
  }
  return label;
}
