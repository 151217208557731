import clsx from 'clsx';
import {filter, forEach, includes, intersection, isPlainObject, isString, map, uniqueId} from 'lodash';
import PropTypes from 'prop-types';
import {useMemo, useState} from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';

import './MultiSelect.scss';

export const MULTISELECT_AND_LABEL = 'Must include:';

const propTypes = {
  /**
   * Column props passed by react-table.
   */
  column: PropTypes.shape({
    filterValue: PropTypes.object,
    setFilter: PropTypes.func,
    preFilteredRows: PropTypes.arrayOf(PropTypes.object),
    id: PropTypes.string,
    /**
     * CSS classes to be added to the filter cell.
     */
    filterClassName: PropTypes.string,
  }).isRequired,
  /**
   * Text shown above all the options.
   */
  label: PropTypes.string,
  /**
   * Array of options to filter based on.
   * Can be an array of strings or objects ({id, label}).
   */
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        /** Option's unique id to filter by. */
        id: PropTypes.string,
        /** React node to render as label next to checkbox. */
        label: PropTypes.node,
      }),
    ]),
  ),
};
/**
 * Renders a dropdown menu with the ability to select multiple options via checkbox inputs.
 *
 * Expects value of the row to be an array of strings.
 */
export default function MultiSelectFilter({
  column: {filterValue = {}, setFilter, preFilteredRows, id, filterClassName},
  label = '',
  options,
}) {
  const [filterId] = useState(uniqueId('list-'));
  const [open, setOpen] = useState(false);

  const ref = useOnclickOutside(() => {
    setOpen(false);
  });

  const finalOptions = useMemo(() => {
    if (options) {
      return _standardizeOptions(options);
    }
    // calculate the options for filtering using the preFilteredRows
    const optionSet = new Set();
    forEach(preFilteredRows, (row) => {
      const values = row.values[id];
      if (values) {
        if (isString(values)) {
          optionSet.add(values);
        } else {
          optionSet.add(...values);
        }
      }
    });
    return _standardizeOptions(Array.from(optionSet).sort());
  }, [options, id, preFilteredRows]);


  const changeHandler = (event) => {
    const targetId = event.target.value;
    if (targetId) {
      const newFilterValue = {...filterValue};
      newFilterValue[targetId] = !newFilterValue[targetId];
      setFilter(newFilterValue);
    }
  };

  let filterSet;
  if (isPlainObject(filterValue)) {
    filterSet = includes(filterValue, true);
  } else {
    filterSet = filterValue?.length > 0;
  }

  return (
    <div ref={ref} className={clsx('multiSelectFilter', filterClassName)}>
      <button
        className={clsx('custom-select multiSelectFilter__button', {filterSet})}
        onClick={() => setOpen((prev) => !prev)}
      >
        {filterSet ? 'Custom' : 'All'}
      </button>
      {open && (
        <div className="multiSelectFilter__dropdown">
          {label ? <div className="mb-2">{label}</div> : null }
          {map(finalOptions, (option) => (
            <label key={option.id} className="multiSelectFilter__label mb-3" htmlFor={`${filterId}-${option.id}`}>
              <input
                id={`${filterId}-${option.id}`}
                type="checkbox"
                className="form-check-inline"
                checked={filterValue[option.id] ?? false}
                value={option.id}
                onChange={changeHandler}
              />
              {option.label}
            </label>
          ))}
        </div>
      )}
    </div>
  );
}
MultiSelectFilter.propTypes = propTypes;


function _standardizeOptions(options) {
  if (isPlainObject(options[0])) {
    return options;
  }
  return map(options, (opt) => ({
    id: opt,
    label: opt,
  }));
}

function _resolveSelected(filterValue) {
  const options = [];
  if (filterValue) {
    forEach(Object.keys(filterValue), (k) => {
      if (filterValue[k]) {
        options.push(k);
      }
    });
  }
  return options;
}


/**
 * React-table `filter` for string(s) that matches ANY chosen criteria.
 *
 * @param {Array} rows
 * @param {Array<string>} columnIds
 * @param {Array<string>} filterValue
 */
export function multiselectOrFilter(rows, columnIds, filterValue) {
  const selected = _resolveSelected(filterValue);
  if (selected.length === 0) {
    return rows;
  }
  const id = columnIds[0];
  return filter(rows, (r) => {
    const values = r.values[id];
    if (!values || values.length === 0) {
      return false;
    }
    if (isString(values)) {
      return includes(selected, values);
    } else {
      return intersection(values, selected).length > 0;
    }
  });
}

/**
 * React-table `filter` for string(s) that matches ALL chosen criteria.
 *
 * @param {Array} rows
 * @param {Array<string>} columnIds
 * @param {Array<string>} filterValue
 */
export function multiselectAndFilter(rows, columnIds, filterValue) {
  const selected = _resolveSelected(filterValue);
  if (selected.length === 0) {
    return rows;
  }
  const id = columnIds[0];
  return filter(rows, (r) => {
    const values = r.values[id];
    if (!values || values.length === 0) {
      return false;
    }
    if (isString(values)) {
      return selected.length === 1 && selected[0] === values;
    } else {
      return intersection(values, selected).length === selected.length;
    }
  });
}
