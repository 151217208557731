import {map} from 'lodash';
import PropTypes from 'prop-types';

import './Panel.scss';
import useAppContext from 'conf/AppContext';

const propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.node).isRequired,
};
/**
 * Container for buttons that arranges them based on platform default. Primary button should come first.
 *
 * @param {object} props - props container
 * @param {Array<React.ReactNode>} props.buttons - array of buttons to display, primary button listed first
 * @return {JSX.Element}
 */
export default function ButtonPanel({buttons}) {
  const appContext = useAppContext();

  let sortedButtons = buttons;
  if (!appContext.actionOnLeft) {
    // copy array before sorting to avoid modifying original array
    sortedButtons = buttons.slice().reverse();
  }
  return (
    <div className="buttonPanel">
      {map(sortedButtons, (btn, idx) => (<span key={idx}>{btn}</span>))}
    </div>
  );
}
ButtonPanel.propTypes = propTypes;
