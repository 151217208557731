import {truncate} from 'lodash';

import {DEFAULT_FONT_FAMILY, getBreakpointName, getChartFontSize} from 'utils/browserUtils';
import {truncateMiddle} from 'utils/textUtils';

const ChartConstants = {
  colors: [
    '#4dc9f6',
    '#f67019',
    '#f53794',
    '#537bc4',
    '#acc236',
    '#166a8f',
    '#00a950',
    '#58595b',
    '#8549ba',
  ],
  truncateOptions: {
    END: 'end',
    MIDDLE: 'middle',
  },
  scaleTypes: {
    LINEAR: 'linear',
    LOGARITHMIC: 'logarithmic',
    TIME_DAY: 'time-day',
    TIME_WEEK: 'time-week',
    TIME_MONTH: 'time-month',
    TIME_YEAR: 'time-year',
  },
  defaultTrendLineConfig: {
    colorMin: 'red',
    colorMax: 'red',
    lineStyle: 'dotted',
    width: 2,
    projection: false,
  },
  defaultLabelConfig: {
    datalabels: {
      color: 'black',
      anchor: 'end',
      align: 'end',
      offset: '0',
      display: 'auto',
      borderWidth: 0.0,
      borderColor: 'black',
      borderRadius: () => {
        switch (getBreakpointName()) {
          case 'xs':
            return 1;
          case 'sm':
            return 2;
          case 'md':
            return 3;
          default:
            return 4;
        }
      },
      backgroundColor: null,
      padding: () => {
        switch (getBreakpointName()) {
          case 'xs':
            return {top: 3, bottom: 3, left: 2, right: 2};
          case 'sm':
            return {top: 3, bottom: 3, left: 2, right: 2};
          case 'md':
            return {top: 5, bottom: 5, left: 2, right: 2};
          default:
            return {top: 5, bottom: 5, left: 5, right: 5};
        }
      },
      textAlign: 'center',
      opacity: 0.9,
      font: () => ({
        family: DEFAULT_FONT_FAMILY,
        size: getChartFontSize(),
        weight: 'normal',
      }),
    },
  },
  defaultLayoutConfig: {
    layout: (ctx) => {
      const {indexAxis} = ctx.chart.options;
      switch (getBreakpointName()) {
        case 'xs':
        case 'sm':
        case 'md':
        case 'lg':
          return {
            padding: {
              right: indexAxis === 'y' ? 30 : 0,
              left: 0,
              top: indexAxis === 'x' ? 30 : 0,
              bottom: 0,
            },
          };
        default:
          return {
            padding: {
              right: indexAxis === 'y' ? 40 : 0,
              left: 0,
              top: indexAxis === 'x' ? 40 : 0,
              bottom: 0,
            },
          };
      }
    },
  },
};
export default ChartConstants;


/**
 * Creates x-axis configuration object for line and bar charts.
 *
 * @param {object} opts
 * @param {boolean} [opts.display] - whether x-axis labels should be displayed
 * @param {string} [opts.displayTimeFormat] - define the time format, only applicable if type is TIME_*
 * @param {number} [opts.maxLength] - wrap label at x-axis tick marks at specified length (no wrap if 0)
 * @param {number} [opts.stepSize] - step size is count between ticks. If not set, tick step size is automatically determined by chart.js
 * @param {string} [opts.title] - axis title
 * @param {string} [opts.type] - define the scale type (use ChartConstants.scaleTypes)
 */
export function createXAxisConfig(opts) {
  return createAxisConfig(opts, 'x');
}

/**
 * Creates y-axis configuration object for line and bar charts.
 *
 * @param {object} opts
 * @param {string} [opts.align] - default alignment is right but 'left', or 'center' are other options
 * @param {boolean} [opts.display] - whether y-axis labels should be displayed
 * @param {string} [opts.displayTimeFormat] - define the time format, only applicable if type is TIME_*
 * @param {number} [opts.maxLength] - maximum length to truncate to (skip truncate if 0)
 * @param {number} [opts.stepSize] - step size is count between ticks. If not set, tick step size is automatically determined by chart.js
 * @param {string} [opts.title] - axis title
 * @param {string} [opts.truncate] - either 'middle' or 'end'
 * @param {string} [opts.type] - define the scale type (use ChartConstants.scaleTypes)
 */
export function createYAxisConfig(opts) {
  return createAxisConfig(opts, 'y');
}


/**
 * Creates axis configuration object for line and bar charts.
 *
 * @param {object} opts
 * @param {string} axisType - 'x' or 'y' axis
 */
function createAxisConfig(opts, axisType) {
  const display = opts?.display ?? true;
  if (!display) {
    return {
      display,
    };
  }

  const tickAlignment = {left: 'far', right: 'near', center: 'center'};

  const type = {};
  if (opts?.type && opts?.type !== ChartConstants.scaleTypes.LINEAR) {
    if (opts.type === ChartConstants.scaleTypes.TIME_DAY) {
      type.type = 'time';
      type.time = {
        unit: 'day',
      };
      if (opts?.displayTimeFormat) {
        type.time.displayFormats = {
          day: opts.displayTimeFormat,
        };
      }
    } else if (opts.type === ChartConstants.scaleTypes.TIME_WEEK) {
      type.type = 'time';
      type.time = {
        unit: 'week',
      };
      if (opts?.displayTimeFormat) {
        type.time.displayFormats = {
          week: opts.displayTimeFormat,
        };
      }
    } else if (opts.type === ChartConstants.scaleTypes.TIME_MONTH) {
      type.type = 'time';
      type.time = {
        unit: 'month',
      };
      if (opts?.displayTimeFormat) {
        type.time.displayFormats = {
          month: opts.displayTimeFormat,
        };
      }
    } else if (opts.type === ChartConstants.scaleTypes.TIME_YEAR) {
      type.type = 'time';
      type.time = {
        unit: 'year',
      };
      if (opts?.displayTimeFormat) {
        type.time.displayFormats = {
          year: opts.displayTimeFormat,
        };
      }
    } else {
      type.type = opts.type;
    }
  }
  const stepSize = {};
  if (opts?.stepSize) {
    stepSize.stepSize = opts.stepSize;
  }
  const title = {};
  if (opts?.title) {
    title.title = {
      display: true,
      text: opts.title,
    };
  }

  return {
    ticks: {
      ...(createCustomTickFormats(opts, axisType)),
      font: () => ({
        family: DEFAULT_FONT_FAMILY,
        size: getChartFontSize(),
      }),
      crossAlign: tickAlignment[opts?.align] ?? 'near',
      ...stepSize,
      display,
    },
    ...type,
    ...title,
  };
}

function createCustomTickFormats(opts, axisType) {

  // Chart.js 4.x has this breaking change
  // Ticks callback on timescale now receives timestamp instead of a formatted label.
  // https://www.chartjs.org/docs/latest/migration/v4-migration.html
  // We format time using `displayFormats` timescale property
  // if we do not return empty object, callback function will return timestamp instead of a formatted label.
  if (opts?.type && opts.type !== ChartConstants.scaleTypes.LINEAR &&
    opts.type !== ChartConstants.scaleTypes.LOGARITHMIC) {
    return {};
  }

  return {
    callback(value) {
      if (opts?.type === ChartConstants.scaleTypes.LOGARITHMIC) {
        return getBaseLog10(value);
      } else {
        const label = this.getLabelForValue(value);
        if (axisType === 'y') {
          const maxLength = opts.maxLength ?? 20;
          if (opts.truncate === ChartConstants.truncateOptions.MIDDLE) {
            return truncateMiddle(label, maxLength);
          } else if (opts.truncate === ChartConstants.truncateOptions.END) {
            return truncate(label, {length: maxLength, separator: '…'});
          } else {
            return label;
          }
        } else if (axisType === 'x') {
          const maxLength = opts.maxLength ?? 10;
          if (label.length > maxLength) {
            const rez = [];
            const split = label.split(' ');
            for (let x = 0; x < split.length; x += 1) {
              let cur = split[x];
              while (x + 1 < split.length && (cur.length + split[x + 1].length) <= maxLength) {
                cur += ' ' + split[x + 1];
                x += 1;
              }
              rez.push(cur);
            }
            return rez;
          } else {
            return label;
          }
        } else {
          return label;
        }
      }
    },
  };
}

function getBaseLog10(value) {
  // https://stackoverflow.com/questions/31355029/math-log-inaccuracy-how-to-deal-with-it
  const result = ((-3 * (Math.log(value * 100) / Math.log(0.001))) - 2);
  if (result >= 0 && Number.isInteger(result)) {
    return value;
  }
}
