import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import ResourceIcon from 'components/icons/Resource';

const propTypes = {
  title: PropTypes.string.isRequired,
  svgIcon: PropTypes.string,
  faIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  children: PropTypes.node,
};
export default function DownloadCard({title, svgIcon, faIcon, children}) {
  let icon = '';
  if (svgIcon) {
    icon = <ResourceIcon type={svgIcon} />;
  } else if (faIcon) {
    icon = <FontAwesomeIcon icon={faIcon} size="2x" />;
  }

  return (
    <div className="col-sm-6 downloads__card">
      <div className="downloads__card__icon">{icon}</div>
      <div className="downloads__card_text">
        <h4>{title}</h4>
        {children}
      </div>
    </div>
  );
}
DownloadCard.propTypes = propTypes;
