import CommaSeparatedList from 'components/shared/CommaSeparatedList';
import {getBaseUrl, resourceProps} from 'conf/types';


const propTypes = {
  resource: resourceProps,
};
/**
 * Renders a link for a resource.
 */
export default function ResourceLink({resource}) {
  const {objCls, id, name, symbol} = resource;
  const href = getBaseUrl(id, objCls);
  const text = symbol || name || id;

  if (href) {
    return <a href={href}>{text}</a>;
  }
  return <span>{text}</span>;
}
ResourceLink.propTypes = propTypes;


/**
 * Renders an array of resources using <ResourceLink />, separated by commas.
 *
 * @param {Array} resources
 * @param {string} [joiningWord]
 * @return {JSX.Element}
 */
export function renderResourceLinks(resources, joiningWord) {
  return (
    <CommaSeparatedList
      items={resources}
      renderFn={(r) => <ResourceLink resource={r} />}
      joiningWord={joiningWord}
    />
  );
}
