/* global ENV */
// ENV  - production, preview, beta, development or local, or test (when run by Jest)
import ky from 'ky';
import {isNil, isString, map, merge} from 'lodash';

import {stringifySearchParams} from 'components/links/utils';
import logger from 'conf/Logger';


/**
 * Adds `X-pgkb-website` header to ky config object.
 * Adds `signal` to ky config object if `abortController` is provided.
 *
 * @param {object} [config]
 * @param {AbortController} [abortController]
 * @return {object} (potentially) updated ky config object
 */
export function _prepRequestConfig(config = {}, abortController) {
  const changes = {
    headers: {
      // using this header to distinguish website traffic on API
      'X-pgkb-website': ENV,
    },
  };
  if (abortController) {
    changes.signal = abortController.signal;
  }
  return merge(config, changes);
}

/**
 * Validates target.  If target is a string, make sure it does not start with "/".
 */
function _checkTarget(target) {
  if (isNil(target)) {
    throw new Error('Path is undefined');
  }
  if (isString(target) && target.startsWith('/')) {
    throw new Error('Path cannot start with "/"');
  }
}


/**
 * Checks if response is JSON based on content-type.
 *
 * @param {Response} response
 * @return {boolean}
 */
function _isResponseJson(response) {
  const contentType = response.headers.get('content-type');
  return !!(contentType && contentType.indexOf('application/json') !== -1);
}


/**
 * Reads response as either JSON or text based on content-type.
 *
 * Response.body can only be read once, so we're just going to read it and store it in
 * Response.cachedResult.  If we ever call this method again, that previously read result will be
 * returned.
 *
 * @param {Response} response
 * @param {string} response.cachedResult - cached result of response from a previous call to this method
 * @return {Promise}
 */
export async function readResponse(response) {
  if (response.cachedResult) {
    return response.cachedResult;
  }
  const isJson = _isResponseJson(response);
  response.cachedResult = await (isJson ? response.json() : response.text());
  return response.cachedResult;
}


function buildCacheKey(input, options) {
  if (options && options.searchParams) {
    return input + '?' + stringifySearchParams(options.searchParams);
  }
  return input;
}


export default class KyHelper {
  /**
   * Constructor.
   *
   * @param {object} config - configuration object
   * @param {string} config.prefixUrl - a prefix to prepend to the input URL when making the request
   * (trailing slash `/` is optional and will be added automatically when necessary)
   * @param {number|object} [config.retry] - retry policy
   * @param {number|false} [config.timeout] - timeout in milliseconds for getting a response,
   * false for no timeout
   * @param {localForage} config.cache - LocalForage instance to use for storing API responses
   * @param {number} [config.cacheTtl] - time (in seconds) the resource will be available for once
   * it's been cached
   */
  constructor({prefixUrl, retry = 0, timeout = 30000, cache, cacheTtl = 30}) {
    // noinspection JSCheckFunctionSignatures
    this.ky = ky.create({
      prefixUrl,
      retry,
      timeout,
      credentials: 'include',
    });
    /**
     * @type {localForage}
     */
    this.cache = cache;
    this.cacheTtl = cacheTtl;
    this.prefixUrl = prefixUrl;
  }


  getFromCache = async (key, cacheTtl) => {
    const cacheHit = await this.cache.getItem(key);
    // json 204 returns empty string, so can't just do a truthy check
    if (cacheHit !== null) {
      const ttlInSeconds = cacheTtl || this.cacheTtl;
      const threshold = cacheHit.timestamp + (ttlInSeconds * 60000);
      if (threshold > Date.now()) {
        logger.debug('from cache:', key);
        return cacheHit.data;
      }
    }
    return null;
  };


  /**
   * Removes an entry from the cache.
   */
  removeFromCache = (target, options = {}) => {
    this.cache.removeItem(buildCacheKey(target, options))
      .catch((reason) => logger.error('Error removing from cache', reason));
  };


  /**
   * Executes a GET request, with the option of using the cache.
   *
   * @param {string|Request} target - resource to fetch
   *
   * @param {object} [options] - options object containing custom settings to apply to the request
   *
   * -- passed through to ky --
   * @param {object} [options.searchParams] - query parameters
   * @param {number|boolean} [options.timeout] - time to wait in milliseconds for getting a response, false to disable
   * @param {boolean} [options.throwHttpErrors] - throw non-2xx responses as errors, default true
   *
   * -- custom options --
   * @param {boolean} [options.cacheResponse] - true if response should be cached
   * @param {number} [options.cacheTtl] - time (in seconds) the resource will be available for once it's been cached
   * @param {boolean} [options.parseJson] - parse JSON out of response
   * @param {boolean} [options.parseText] - parse text out of response
   * @param {boolean} [options.forceLive] - should only be used on preview to force a query to be run on live (this
   * request cannot be cached)
   *
   * @param {AbortController} [abortController]
   *
   * @return {Promise<Response|string>} - a Promise containing the response
   */
  get = async (target, options = {}, abortController) => {
    _checkTarget(target);

    const {cacheResponse, cacheTtl, parseJson, parseText, forceLive, ...kyOptions} = options;
    let key;
    if (cacheResponse && !forceLive) {
      if (!parseJson && !parseText) {
        throw new Error('Must parse response to either JSON or text to cache it');
      }
      key = buildCacheKey(target, kyOptions);
      const cacheHit = await this.getFromCache(key, cacheTtl);
      if (cacheHit !== null) {
        return cacheHit;
      }
      logger.debug('from api:', key);
    }

    if (forceLive) {
      kyOptions.prefixUrl = this.prefixUrl.replace('/preview', '');
      if (kyOptions.prefixUrl === this.prefixUrl) {
        logger.error('Cannot forceLive on live!');
      }
    }

    let promise = this.ky.get(target, _prepRequestConfig(kyOptions, abortController));
    if (parseJson) {
      promise = promise.json();
    } else if (parseText) {
      promise = promise.text();
    }

    const response = await promise;
    if (cacheResponse) {
      this.cache.setItem(key, {data: response, timestamp: Date.now()})
        .catch((error) => {
          logger.info('Not caching (fetch failed).', error);
        });
    }

    return response;
  };


  /**
   * Executes multiple GET requests.
   *
   * @param {Array<{target: string, searchParams: object}>} targets - resources to fetch
   *
   * @param {object} [options] - options object containing custom settings to apply to the request
   *
   * -- passed through to ky --
   * @param {number|boolean} [options.timeout] - time to wait in milliseconds for getting a response, false to disable
   * @param {boolean} [options.throwHttpErrors] - throw non-2xx responses as errors, default true
   *
   * -- custom options --
   * @param {boolean} [options.parseJson] - parse JSON out of response
   * @param {boolean} [options.parseText] - parse text out of response
   * @param {boolean} [options.forceLive] - should only be used on preview to force a query to be run on live (this
   * request cannot be cached)
   *
   * @param {AbortController} [abortController]
   *
   * @return {Array<Promise<Response|string>>} - a Promise containing the response
   */
  getAll = async (targets, options, abortController) => {
    const {parseJson, parseText, forceLive, ...kyOptions} = options;
    if (forceLive) {
      kyOptions.prefixUrl = this.prefixUrl.replace('/preview', '');
      if (kyOptions.prefixUrl === this.prefixUrl) {
        logger.error('Cannot forceLive on live!');
      }
    }
    // eslint-disable-next-line no-return-await
    return await Promise.all(map(targets, (t) => {
      const {target, searchParams} = t;
      _checkTarget(t);
      let promise = this.ky.get(target, _prepRequestConfig({...kyOptions, searchParams}, abortController));
      if (parseJson) {
        promise = promise.json();
      } else if (parseText) {
        promise = promise.text();
      }
      return promise;
    }));
  };


  /**
   * Executes POST request.
   *
   * @param {string|Request} target - resource to post to
   * @param {object} [options] - options object containing custom settings to apply to the request
   *
   * -- passed through to ky --
   * @param {number|boolean} [options.timeout] - time to wait in milliseconds for getting a response, false to disable
   * @param {boolean} [options.throwHttpErrors] - throw non-2xx responses as errors, default true
   *
   * -- custom options --
   * @param {boolean} [options.parseJson] - parse JSON out of response
   * @param {boolean} [options.parseText] - parse text out of response
   * @param {boolean} [options.forceLive] - should only be used on preview to force a query to be run on live (this
   * request cannot be cached)
   *
   * @param {AbortController} [abortController]
   * @return {Promise} - the JSON response
   */
  post = async (target, options = {}, abortController) => {
    _checkTarget(target);

    const {parseJson, parseText, ...kyOptions} = options;
    let promise = this.ky.post(target, _prepRequestConfig(kyOptions, abortController));
    if (parseJson) {
      promise = promise.json();
    } else if (parseText) {
      promise = promise.text();
    }

    return promise;
  };


  /**
   * Executes multiple POST requests.
   *
   * @param {Array<{target: string, searchParams: object, body: object, json: object}>} targets - resources to post to
   *
   * @param {object} [options] - options object containing custom settings to apply to the request
   *
   * -- passed through to ky --
   * @param {number|boolean} [options.timeout] - time to wait in milliseconds for getting a response, false to disable
   * @param {boolean} [options.throwHttpErrors] - throw non-2xx responses as errors, default true
   *
   * -- custom options --
   * @param {boolean} [options.parseJson] - parse JSON out of response
   * @param {boolean} [options.parseText] - parse text out of response
   * @param {boolean} [options.forceLive] - should only be used on preview to force a query to be run on live (this
   * request cannot be cached)
   *
   * @param {AbortController} [abortController]
   *
   * @return {Array<Promise<Response|string>>} - a Promise containing the response
   */
  postAll = async (targets, options, abortController) => {
    const {parseJson, parseText, forceLive, ...kyOptions} = options;
    if (forceLive) {
      kyOptions.prefixUrl = this.prefixUrl.replace('/preview', '');
      if (kyOptions.prefixUrl === this.prefixUrl) {
        logger.error('Cannot forceLive on live!');
      }
    }
    // eslint-disable-next-line no-return-await
    return await Promise.all(map(targets, (t) => {
      const {target, body, json, searchParams} = t;
      _checkTarget(t);
      let promise = this.ky.post(target, _prepRequestConfig({...kyOptions, searchParams, body, json}, abortController));
      if (parseJson) {
        promise = promise.json();
      } else if (parseText) {
        promise = promise.text();
      }
      return promise;
    }));
  };


  /**
   * Executes PUT request.
   *
   * @param {string|Request} target - resource to post to
   * @param {object} [options] - options object containing custom settings to apply to the request
   *
   * @param {boolean} [options.parseJson] - parse JSON out of response
   * @param {boolean} [options.parseText] - parse text out of response
   *
   * @param {AbortController} [abortController]
   * @return {Promise} - the JSON response
   */
  put = async (target, options = {}, abortController) => {
    _checkTarget(target);

    const {parseJson, parseText, ...kyOptions} = options;
    let promise = this.ky.put(target, _prepRequestConfig(kyOptions, abortController));
    if (parseJson) {
      promise = promise.json();
    } else if (parseText) {
      promise = promise.text();
    }

    return promise;
  };


  /**
   * Executes DELETE request.
   *
   * @param {string|Request} target - resource to post to
   * @param {object} [options] - options object containing custom settings to apply to the request
   *
   * @param {boolean} [options.parseJson] - parse JSON out of response
   * @param {boolean} [options.parseText] - parse text out of response
   *
   * @param {AbortController} [abortController]
   * @return {Promise} - the JSON response
   */
  delete = async (target, options = {}, abortController) => {
    _checkTarget(target);

    const {parseJson, parseText, ...kyOptions} = options;
    let promise = this.ky.delete(target, _prepRequestConfig(kyOptions, abortController));
    if (parseJson) {
      promise = promise.json();
    } else if (parseText) {
      promise = promise.text();
    }

    return promise;
  };


  /**
   * Checks if response is JSON based on content-type.
   *
   * @param {Response} response
   * @return {boolean}
   */
  isResponseJson = _isResponseJson;


  /**
   * Reads response as either JSON or text based on content-type.
   *
   * Response.body can only be read once, so we're just going to read it and store it in
   * Response.cachedResult.  If we ever call this method again, that previously read result will be
   * returned.
   *
   * @param {Response} response
   * @param {string} response.cachedResult - cached result of response from a previous call to this method
   * @return {Promise}
   */
  readResponse = readResponse;


  /**
   * Checks if an Error return by ky is a result of a failed fetch call, as opposed to an error
   * returned by the server.
   *
   * @param {Error} kyError
   * @return {boolean}
   */
  isFetchError = (kyError) => kyError.name !== 'HTTPError';

  /**
   * Checks if an Error return by ky is 400 Bad Request error.
   *
   * @param {HTTPError} kyError
   * @return {boolean}
   */
  isBadRequest = (kyError) => kyError.name === 'HTTPError' && kyError?.response?.status === 400;

  /**
   * Reads the JSend error from an Error returned by ky.
   *
   * @param {HTTPError} kyError
   * @return {Promise}
   */
  readJsendError = (kyError) => this.readResponse(kyError.response);
}
