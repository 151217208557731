import {format as dateFormat, isAfter, isBefore, isValid, parse, parseISO} from 'date-fns';
import {find} from 'lodash';
import PropTypes from 'prop-types';
import {FormProvider, useForm} from 'react-hook-form';

import ButtonPanel from 'components/Button/Panel';
import FormCancel from 'components/form/Cancel';
import FormCheckbox from 'components/form/Checkbox';
import FormCombobox from 'components/form/Combobox';
import FormError from 'components/form/Error';
import FormSubmit from 'components/form/Submit';
import FormText from 'components/form/Text';
import FormTextarea from 'components/form/Textarea';
import {handleSubmitErrors} from 'components/form/utils';
import useAppContext from 'conf/AppContext';

const typeOptions = [
  {label: 'Update', value: 'Update'},
  {label: 'Note', value: 'Note'},
  {label: 'Correction', value: 'Correction'},
  {label: 'Create', value: 'Create'},
];

const visibilityOptions = [
  {label: 'Curator only', value: false},
  {label: 'Public', value: true},
];

const propTypes = {
  parentCls: PropTypes.string.isRequired,
  parentId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  event: PropTypes.shape({
    id: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    visibleToAll: PropTypes.bool.isRequired,
    majorChange: PropTypes.bool,
    description: PropTypes.string,
  }),
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

/**
 * Modal form for adding or editing history events.
 *
 * @param {object} props
 * @param {string} props.parentCls
 * @param {string} props.parentId
 * @param {object} [props.event] - event to edit, undefined if creating new event
 * @param {Function} props.onSave - function to call on save
 * @param {Function} props.onCancel - function to call on cancel
 * @return {JSX.Element}
 */
export default function HistoryForm({parentCls, parentId, event, onSave, onCancel}) {
  const appContext = useAppContext();
  
  const formMethods = useForm({
    defaultValues: {
      date: dateFormat(event ? parseISO(event.date) : Date.now(), 'MM/dd/yyyy'),
      type: find(typeOptions, (o) => o.label === (event?.type || 'Update')),
      visibleToAll: find(visibilityOptions, (o) => o.value === (event?.visibleToAll || false)),
      majorChange: event?.majorChange ?? false,
      description: event?.description ?? '',
    },
  });
  const {handleSubmit, setError} = formMethods;
  
  const onSubmit = async (values) => {
    try {
      let rez;
      if (event) {
        rez = await appContext.api.put(`curation/${parentCls}/${parentId}/history/${event.id}`, {
          json: {
            date: values.date,
            type: values.type.label,
            description: values.description,
            visibleToAll: values.visibleToAll.value,
            id: event.id,
          },
          parseJson: true,
        });
      } else {
        rez = await appContext.api.post(`curation/${parentCls}/${parentId}/history`, {
          json: {
            date: values.date,
            type: values.type.label,
            description: values.description,
            majorChange: values.majorChange,
            visibleToAll: values.visibleToAll.value,
          },
          parseJson: true,
        });
      }
      onSave(rez);
    } catch (err) {
      await handleSubmitErrors(appContext, err, setError);
    }
  };

  const formLabel = `${event ? 'Edit' : 'Add New'} History Event`;
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3>{formLabel}</h3>
        <FormText
          name="date"
          label="Date"
          help="Format: MM/DD/YYYY"
          required={true}
          validation={{
            validDate: (v) => {
              try {
                const formattedDate = parse(v, 'MM/dd/yyyy', new Date());
                const minDate = new Date(2000, 0, 1);

                if (!isValid(formattedDate)) {
                  return 'Please enter valid date';
                } else if (isBefore(formattedDate, minDate)) {
                  return `Date must be later than ${dateFormat(minDate, 'MM/dd/yyyy')}`;
                } else if (isAfter(formattedDate, new Date())) {
                  return 'Date must not be in the future';
                } else {
                  return true;
                }
              } catch (err) {
                return err.message;
              }
            },
          }}
        />
        <FormCombobox
          name="type"
          label="Type"
          options={typeOptions}
          required={true}
        />

        <FormCombobox
          name="visibleToAll"
          label="Visibility"
          options={visibilityOptions}
          required={true}
        />
        
        {!event && (
          <FormCheckbox
            name="majorChange"
            label="Take Ownership?"
          />
        )}
        
        <FormTextarea
          name="description"
          label="Comment"
          rows={3}
          cols={10}
        />
        <FormError />
        <ButtonPanel
          buttons={[
            <FormSubmit key="save">Save</FormSubmit>,
            <FormCancel key="cancel" actionHandler={onCancel}>Cancel</FormCancel>,
          ]}
        />
      </form>
    </FormProvider>
  );
}
HistoryForm.propTypes = propTypes;
