/**
 * This comparator compares 2 accession objects using the `symbol` property if available, or the `name` property if it's
 * not.
 *
 * @param {object} a
 * @param {string} a.symbol
 * @param {string} a.name
 * @param {object} b
 * @param {string} b.symbol
 * @param {string} b.name
 */
export function accIdNameComparator(a, b) {
  const aName = a?.symbol || a?.name;
  const bName = b?.symbol || b?.name;
  if (!a) {
    if (!b) {
      return 0;
    }
    return 1;
  } else if (!b) {
    return -1;
  }
  return variantNameComparator(aName, bName);
}


const rsidRegex = /rs(\d+)/;
const hapRegex = /.*\*(\d+)/;
/**
 * This comparator handles numbers in RSIDs and haplotypes properly.
 *
 * @param {string} a
 * @param {string} b
 */
export function variantNameComparator(a, b) {
  if (!a) {
    if (!b) {
      return 0;
    }
    return 1;
  } else if (!b) {
    return -1;
  }

  const rs1 = a.match(rsidRegex);
  const rs2 = b.match(rsidRegex);
  if (rs1) {
    const aVal = rs1[1];
    if (rs2) {
      return parseInt(aVal) - parseInt(rs2[1]);
    } else {
      return 1;
    }
  } else if (rs2) {
    return -1;
  }

  const h1 = a.match(hapRegex);
  const h2 = b.match(hapRegex);
  if (h1) {
    const aVal = h1[1];
    if (h2) {
      return parseInt(aVal) - parseInt(h2[1]);
    }
  }
  return a.localeCompare(b);
}

/**
 * This comparator will sort alphabetically ignoring case
 *
 * @param {string} a
 * @param {string} b
 */
export function caseInsensitiveComparator(a, b) {
  return a.localeCompare(b, undefined, {sensitivity: 'base'});
}
