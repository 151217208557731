import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {CSSTransition, TransitionGroup} from 'react-transition-group';

import './index.scss';

import {createActionHandler} from 'components/Button';
import useAppContext from 'conf/AppContext';


const propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
/**
 * This renders a banner that spans the width of it's enclosing container.
 */
export default function Banner({id, className, children}) {
  const appContext = useAppContext();
  const [closed, setClosed] = useState(true);

  useEffect(() => {
    async function checkState() {
      const value = await appContext.cache.getItem(`banner-${id}`);
      setClosed(value === 'dismissed');
    }
    if (id) {
      // noinspection JSIgnoredPromiseFromCall
      checkState();
    }
  }, [id]);

  /**
   * Handle close event, either by mouse click or 'Enter' via keyboard.
   *
   * @param {React.SyntheticEvent} event
   */
  const handleClose = createActionHandler(() => {
    setClosed(true);
    appContext.cache.setItem(`banner-${id}`, 'dismissed');
  });

  let content = null;
  if (!closed) {
    content = (
      <CSSTransition key={`banner-${id}`} timeout={750} classNames="banner--action">
        <div className={clsx('banner', className)}>
          <div className="container banner__frame">
            <div className="banner__content">
              <div className="banner__content__text">
                {children}
              </div>
            </div>
            <div
              title="hide banner"
              className="banner__close"
              role="button"
              onClick={handleClose}
              onKeyDown={handleClose}
              tabIndex={0}
            >
              <FontAwesomeIcon icon="times" size="lg" />
              <span className="screen-reader-text">hide banner</span>
            </div>
          </div>
        </div>
      </CSSTransition>
    );
  }
  return (
    <TransitionGroup>
      {content}
    </TransitionGroup>
  );
}

Banner.propTypes = propTypes;
