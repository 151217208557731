import PropTypes from 'prop-types';
import {useWatch} from 'react-hook-form';


const strength = {
  '-1': 'NA',
  0: 'Insecure',
  1: 'Bad',
  2: 'Weak',
  3: 'Good',
  4: 'Strong',
};

const propTypes = {
  /**
   * zxcvbn module, because we're lazy loading it.
   */
  zxcvbn: PropTypes.object,
  /**
   * The name of the Formik input to grab password from.
   */
  field: PropTypes.string.isRequired,
  className: PropTypes.string,
};
/**
 * Renders a password strength meter based on zxcvbn.
 */
export default function PasswordStrength({zxcvbn, field, className}) {
  const password = useWatch({name: field});

  if (!password || !zxcvbn) {
    return '';
  }

  const {score} = zxcvbn.default(password);
  return (
    <div className={className}>
      Password strength: {strength[score]}
      <meter className="password-strength__meter" min="-1" max="4" value={score}>
        {strength[score]}
      </meter>
    </div>
  );
}
PasswordStrength.propTypes = propTypes;
