import clsx from 'clsx';
import PropTypes from 'prop-types';

import './VipTier.scss';
import Tag from 'components/Tag/index';


const propTypes = {
  tier: PropTypes.string,
  showHelp: PropTypes.bool,
  className: PropTypes.string,
};

export default function VipTierTag({tier, showHelp = true, className}) {
  const props = {
    className: clsx('vipTierTag', className),
  };
  if (showHelp) {
    props.tooltipText = 'Learn more about VIP tiers';
    props.tooltipUrl = '/vips';
  }
  return <Tag {...props}>VIP {tier}</Tag>;
}
VipTierTag.propTypes = propTypes;
