import {includes, map, orderBy} from 'lodash';
import {useState} from 'react';

import Button from 'components/Button';
import ListItem, {parseDescription} from 'components/List/Item';
import {COUNT_MODE} from 'components/List/Searchable';
import KyError from 'components/errors/KyError';
import DownloadIcon from 'components/icons/Download';
import CategoryListTemplate from 'components/templates/CategoryList';
import useAppContext from 'conf/AppContext';
import Types, {getBaseUrl} from 'conf/types';
import {useGet} from 'helpers/KyHooks';

const defaultFilters = {
  pathways: 'Pathways',
  dosingGuidelines: 'Clinical Guideline Annotations',
  vips: 'VIPs',
  other: 'Other',
};

export default function PgkbPublications() {
  const appContext = useAppContext();
  const [selectedFilters, setSelectedFilters] = useState([]);

  const {response, error} = useGet('site/publications/pharmgkb', {cacheResponse: true});
  const data = response?.data ? {
    pathways: response?.data.pathway,
    dosingGuidelines: response?.data.guideline,
    vips: response?.data.vip,
    vipGene: response?.data.vipGene,
    other: response?.data.other,
  } : null;

  const filters = data ? Object.keys(defaultFilters).reduce((prev, curr) => {
    const name = defaultFilters[curr];
    const rez = data[curr];
    if (rez) {
      prev.push({name, count: rez.length});
    } else {
      prev.push({name, count: 0});
    }
    return prev;
  }, []) : [];

  const literature = data ? Object.keys(defaultFilters).reduce((prev, curr) => {
    const name = defaultFilters[curr];
    const rez = data[curr];
    if (rez) {
      if (selectedFilters.length === 0 || includes(selectedFilters, name)) {
        prev.push(...rez);
      }
    }
    return prev;
  }, []) : [];

  const sortedLiterature = orderBy(literature, ['year', 'title'], ['desc', 'asc']);

  const description = (
    <div>
      <p>
        Peer-reviewed scientific articles written or contributed by PharmGKB staff and other
        researchers. PharmGKB pathways and important pharmacogene summaries (VIPs) are published
        monthly in the journal <i>Pharmacogenetics and Genomics</i>.
      </p>
      <p>
        <Button
          className="btn-sm btn-outline-primary"
          href={appContext.apiUrl('/site/publications/pharmgkb/_download')}
        >
          <DownloadIcon /> Download Publication List (TSV)
        </Button>
      </p>
    </div>
  );

  let countMode;
  if (selectedFilters.length === 0) {
    countMode = COUNT_MODE.LIST;
  } else {
    countMode = COUNT_MODE.FILTERED;
  }

  return (
    <CategoryListTemplate
      title="PharmGKB Publications"
      description={description}
      filters={filters}
      onFilterUpdate={(newFilters) => setSelectedFilters(newFilters)}
      collection={sortedLiterature}
      renderItem={renderItem}
      searchKeys={Types.literature.searchKeys}
      singularNoun="publication"
      countMode={countMode}
      loaded={Boolean(response || error)}
      error={error && <KyError kyError={error} />}
    />
  );
}


function renderItem(item) {
  const description = parseDescription({item, showTags: true, showXrefLinks: true});
  const extras = item?.links?.length > 0
    ? <ul>{map(item.links, (l) => <li key={l.url}><a href={l.url}>{l.name}</a></li>)}</ul>
    : null;

  return (
    <ListItem
      key={item.id}
      objCls={item.objCls}
      name={item.title}
      url={item.url ?? getBaseUrl(item.id, item.objCls)}
      description={description}
      thumbnailLink={item?.thumbnailLink}
      className="listItemContainer"
      extras={extras}
    />
  );
}
