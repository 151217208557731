import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import {extractIconProps, extractProps} from 'utils/propUtils';


export const FONTAWESOME_PEDIATRIC_ICON = 'child-reaching';

const propTypes = {
  // iconProps
  className: PropTypes.string,
  size: PropTypes.string,
  transform: PropTypes.string,
};

export default function PediatricIcon(props) {
  return <FontAwesomeIcon icon={FONTAWESOME_PEDIATRIC_ICON} {...extractIconProps(props)} {...extractProps(props)} />;
}
PediatricIcon.propTypes = propTypes;
