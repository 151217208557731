import PropTypes from 'prop-types';

import ResourceCounts, {convertTypedCounts, resourceCountsProps} from 'components/resource/resourceCounts';
import FactSection from 'components/shared/fact_section';
import {accessionIdProps, getBaseUrl} from 'conf/types';


const propTypes = {
  ids: PropTypes.string.isRequired,
  objects: PropTypes.arrayOf(accessionIdProps).isRequired,
  counts: resourceCountsProps,
};
/**
 * Renders combination overview tab.
 */
export default function CombinationOverviewTab({ids, objects, counts = {}}) {
  const resourceCounts = convertTypedCounts('combination', ids, counts);
  return (
    <>
      <FactSection>
        <ResourceCounts counts={resourceCounts} />
      </FactSection>

      <FactSection>
        <p>
          This page details annotations, pathways and literature that include
          {objects.length > 2 ? ' ' : ' both '}
          {renderObjectList(objects)}
        </p>
      </FactSection>
    </>
  );
}
CombinationOverviewTab.propTypes = propTypes;


function renderObjectList(objects) {
  const list = [];
  for (let x = 0; x < objects.length; x += 1) {
    if (x > 0) {
      if (x + 1 === objects.length) {
        list.push(' and ');
      } else {
        list.push(', ');
      }
    }
    const obj = objects[x];
    list.push(<a key={obj.id} href={getBaseUrl(obj.id, obj.objCls)}>{obj.symbol || obj.name}</a>);
  }
  return list;
}
