import {every, filter, forEach, get, includes, isEmpty, map, size, some, toLower, uniq, words} from 'lodash';

import {isGenotype} from 'helpers/SequenceHelper';

const directionalPhrases = [
  'increased', 'decreased', 'more likely', 'less likely', 'poorer response', 'better response',
];

const comparePattern = /compared (with|to)/;
const metabPK = 'Metabolism/PK';

/**
 * Checks that if the text includes allele mentions then they must be unique
 *
 * @param {string} text - a chunk of text, probably a sentence
 * @return {boolean} true if the text has unique allele mentions, false if an allele is repeated
 */
export function alleleMentionsAreUnique(text) {
  const textWords = words(text, /[^,. ]+/g);
  const alleleWords = filter(textWords, isGenotype);
  const uniqueAlleleWords = uniq(filter(textWords, isGenotype));
  return size(alleleWords) < 2 || size(alleleWords) === size(uniqueAlleleWords);
}

export function validateAllelePhenotypes(allelePhenotypes, hasPk = false) {
  const apErrors = [];
  if (size(allelePhenotypes) > 0) {
    forEach(allelePhenotypes, (ap, i) => {
      const allele = [];
      const phenotype = [];
      if (!ap.allele) {
        allele.push('allele must not be blank');
      }
      if (!ap.phenotype) {
        phenotype.push('phenotype must not be blank');
      }
      if (ap.allele && ap.phenotype && !ap.phenotype.includes(ap.allele)) {
        phenotype.push('allele must be mentioned in phenotype');
      }
      if (ap.phenotype && !alleleMentionsAreUnique(ap.phenotype)) {
        phenotype.push('Uses the same allele more than once');
      }
      if (ap.phenotype &&
        hasPk &&
        !/This annotation only covers the pharmacokinetic relationship between (.+) and (.+) and does not include evidence about clinical outcomes\./.test(ap.phenotype)) {
        phenotype.push('PK annotation must include disclaimer, see "Annotation Types" field');
      }
      if (size(allelePhenotypes) > 1) {
        if (ap.phenotype && size(filter(allelePhenotypes, {phenotype: ap.phenotype})) > 1) {
          phenotype.push('this is a duplicate phenotype');
        }
        if (ap.phenotype && size(filter(allelePhenotypes, {allele: ap.allele})) > 1) {
          allele.push('this is a duplicate allele');
        }
      }
      if (size(allele) || size(phenotype)) {
        apErrors[i] = {allele, phenotype};
      }
    });
  }
  return apErrors;
}

/**
 * Validation function for validating a clinical annotation object. This can be used in Formik
 *
 * @param {boolean} topTierVip - if this annotation uses a top tier VIP, default false
 * @return {Function} an object with properties containing arrays of error messages for those properties
 */
export const validate = (topTierVip = false) => (values) => {
  const errors = {};

  if (isEmpty(values?.allelePhenotypes)) {
    errors.allelePhenotypeCollection = 'Need at least 1 allele-phenotype';
  } else {
    const uniqueAlleles = new Set(map(values.allelePhenotypes, (ap) => ap.allele));
    if (size(uniqueAlleles) && values.allelePhenotypes.length !== size(uniqueAlleles)) {
      errors.allelePhenotypeCollection = 'Must have all unique alleles';
    }
    const uniquePhenotypes = new Set(map(values.allelePhenotypes, (ap) => ap.phenotype));
    if (size(uniquePhenotypes) && values.allelePhenotypes.length !== size(uniquePhenotypes)) {
      errors.allelePhenotypeCollection = 'Must have all unique phenotypes';
    }
  }
  const apErrors = validateAllelePhenotypes(
    values?.allelePhenotypes,
    includes(get(values, 'types', []), metabPK),
  );
  if (!isEmpty(apErrors)) {
    errors.allelePhenotypes = apErrors;
  }

  if (isEmpty(values.locus)) {
    errors.locus = 'Locus is required';
  }
  if (!values.variantAnnotations || size(values.variantAnnotations) === 0) {
    errors.variantAnnotations = 'Must choose at least one variant annotation';
  }
  if (!values.chemicals || size(values.chemicals) === 0) {
    errors.chemicals = 'Must choose at least one chemical';
  }
  if (!values.level) {
    errors.level = 'Must choose a level of evidence';
  }
  if (get(values, 'level.label') === '2B' && topTierVip) {
    errors.level = 'Tier 1 VIP gene cannot be a 2B level';
  }
  if (size(values.chemicals) >= 2 && !values.logic) {
    errors.logic = 'Must choose and/or for chemicals';
  }
  if (values.overrideLevel && !values.overrideLevelDescription) {
    errors.overrideLevelDescription = 'Must supply a reason if overriding the score';
  }
  return errors;
};

/**
 * Function to make suggestions about fixing problems in allele phenotype values that appear in the
 * clinical annotation form
 *
 * @param {object[]} allelePhenotypes - an array of allele phenotype objects
 * @return {string[]} an array of string messages
 */
export function suggest(allelePhenotypes) {
  const suggestions = [];

  // bail out if there are no allele phenotypes present
  if (!allelePhenotypes || allelePhenotypes.length === 0) return suggestions;

  // check to see if "compared" is in all phenotypes
  if (!every(allelePhenotypes, (ap) => comparePattern.test(toLower(ap.phenotype)))) {
    suggestions.push('Not all phenotypes have "compared" term');
  }

  // check that not all phenotypes use the same directional phrase
  if (some(directionalPhrases, (dp) => every(allelePhenotypes, (p) => p.phenotype.includes(dp)))) {
    const phrases = filter(
      directionalPhrases,
      (dp) => every(allelePhenotypes, (p) => p.phenotype.includes(dp)),
    ).join(', ');
    suggestions.push(`All phenotypes use the same directional phrase: (${phrases})`);
  }

  return suggestions;
}
