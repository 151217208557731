import {forEach} from 'lodash';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import {useFormContext} from 'react-hook-form';

import WaitingButton from 'components/Button/Waiting';

const propTypes = {
  /**
   * Display the number of errors if true
   */
  showError: PropTypes.bool,
  /**
   * If showError is true, error keys in ignoreErrorKeys will not be counted.
   */
  ignoreErrorKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  waitingLabel: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};
/**
 * Submit button for use with react-hook-form.
 */
export default function FormSubmit({showError = true, ignoreErrorKeys = [], waitingLabel, className, children}) {
  const {formState: {isSubmitting, errors}} = useFormContext();

  let msg;
  if (showError) {
    const errorKeys = Object.keys(errors);
    let numErrors = errorKeys.length;
    if (errors.root) {
      numErrors += Object.keys(errors.root).length - 1;
    }

    if (ignoreErrorKeys) {
      let ignoredKeys = ignoreErrorKeys;
      if (!Array.isArray(ignoreErrorKeys)) {
        ignoredKeys = [ignoreErrorKeys];
      }

      if (ignoredKeys.length > 0) {
        forEach(errorKeys, (key) => {
          if (key === 'root') {
            forEach(Object.keys(errors.root), (v) => {
              if (ignoredKeys.includes(v)) {
                numErrors -= 1;
              }
            });
          } else if (ignoreErrorKeys.includes(key)) {
            numErrors -= 1;
          }
        });
      }
    }
    if (numErrors > 0) {
      msg = (
        <div className="text-center">
          <small className="text-danger">{`${pluralize('problem', numErrors, true)} found`}</small>
        </div>
      );
    }
  }

  return (
    <div style={{display: 'inline-block'}}>
      <WaitingButton
        className={className}
        type="submit"
        waiting={isSubmitting}
        waitingLabel={waitingLabel}
      >
        {children}
      </WaitingButton>
      {msg}
    </div>
  );
}
FormSubmit.propTypes = propTypes;
