import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {find} from 'lodash';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import {CrossReferenceResource} from 'conf/enums';
import {crossReferenceProps} from 'conf/types';

const propTypes = {
  fullTextUrl: PropTypes.string,
  crossReferences: PropTypes.arrayOf(crossReferenceProps),
};
/**
 * Displays a Button that's a link to a full-text version of a Literature object. You can pass in a destructured
 * Literature object. It will detect a proper URL to use for the link. This will return null if no url is available.
 *
 * If there is a fullTextUrl specified, use that no matter what. If no explicit fullTextUrl, link to the PubMedCentral
 * xref url since they always make full text available.
 *
 * @param {object} props - props container
 * @param {string} props.fullTextUrl - a String URL to a full-text version of the literature
 * @param {Array} props.crossReferences - an array of CrossReference objects that may contain a PMC xref
 */
export default function FullTextButton({fullTextUrl, crossReferences}) {
  let url = fullTextUrl;
  if (!url) {
    const pmc = find(crossReferences, (x) => x.resource === CrossReferenceResource.shortName.pmc);
    url = pmc?.url;
  }
  if (!url) {
    return null;
  } else {
    return (
      <Button
        href={url}
        title="View Full Text"
        className="btn btn-primary mb-5"
      >
        <FontAwesomeIcon icon="fa-regular fa-file-lines" /> View Full Text
      </Button>
    );
  }
}
FullTextButton.props = propTypes;
