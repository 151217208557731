import {concat, map, sortBy, without} from 'lodash';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';

import './Filter.scss';

import Sticky from 'components/shared/sticky';
import getYOffset from 'helpers/sticky';

const propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    count: PropTypes.number,
  })).isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
  selectedFilters: PropTypes.arrayOf(PropTypes.string),
};
/**
 * Renders a checkbox-based control for filtering lists.
 *
 * @param {object} props - props container
 * @param {Function} props.onFilterUpdate - Function to call when filters have been updated
 * @param {Array<object>} props.filters - Map of features to filter by and how many there are of each
 * @param {Array<string>} props.selectedFilters
 * @return {JSX.Element}
 */
export default function CategoryFilter({filters, onFilterUpdate, selectedFilters}) {
  const [currentFilters, setCurrentFilters] = useState([]);

  useEffect(() => {
    if (selectedFilters) {
      setCurrentFilters(selectedFilters);
      onFilterUpdate(selectedFilters);
    }
  }, [selectedFilters]);

  if (filters.length === 0) {
    return null;
  }

  const onChange = (e) => {
    const {value} = e.target;
    const filterArray = e.target.checked
      ? concat(currentFilters, value)
      : without(currentFilters, value);
    setCurrentFilters(sortBy(filterArray));
    onFilterUpdate(filterArray);
  };

  const renderItem = (filter) => {
    const key = `lf-${filter.name}`;

    return (
      <div className="checkbox" key={key}>
        <label htmlFor={key}>
          <input
            id={key}
            type="checkbox"
            value={filter.name}
            onChange={onChange}
            checked={currentFilters.includes(filter.name)}
          />
          {filter.name} {filter.count ? `(${filter.count})` : ''}
        </label>
      </div>
    );
  };

  return (
    <Sticky enabled={true} top={getYOffset(15)}>
      <div className="categoryFilters">
        <div className="heading-title">Category</div>
        {map(filters, renderItem)}
      </div>
    </Sticky>
  );
}
CategoryFilter.propTypes = propTypes;
