import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {capitalize, find, forEach, map, remove, size, some} from 'lodash';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {toast} from 'react-toastify';

import Button from 'components/Button';
import DeleteButton from 'components/Button/Delete';
import LinksTabNewForm from 'components/LinksTab/New';
import LinksTabUpdateSection from 'components/LinksTab/Update';
import Loading from 'components/Loading';
import useEditContext from 'components/edit/EditContext';
import EditControls from 'components/edit/EditControls';
import KyError from 'components/errors/KyError';
import Link from 'components/links/Link';
import useModalService from 'components/shared/ModalService';
import useAppContext from 'conf/AppContext';
import {LinkOutResource, LinkOutStatus} from 'conf/enums';
import {normalizeType} from 'conf/types';
import {useGet} from 'helpers/KyHooks';

const propTypes = {
  id: PropTypes.string.isRequired,
  objCls: PropTypes.string.isRequired,
};
/**
 * Renders the Links tab.
 *
 * @return {JSX.Element}
 */
export default function LinksTab({objCls, id}) {
  const appContext = useAppContext();
  const modalService = useModalService();
  const editContext = useEditContext();
  const [data, setData] = useState(null);
  const showLinkOuts = normalizeType(objCls) === 'chemical';

  const {response, error} = useGet(`site/linksTab/${objCls}/${id}`, {
    cacheResponse: !appContext.isPreview,
  });
  if (response && !data) {
    setData(sortLinks(response.data) || {});
  }

  const addSuccess = (type, entry) => {
    let isExistingLink = !entry;
    if (showLinkOuts && size(data.links) > 0) {
      isExistingLink = some(data.links[entry.resource], (l) => l.id === entry.id);
    }
    if (isExistingLink) {
      toast.info('Already added.');
    } else {
      const link = showLinkOuts
        ? {
          ...entry,
          type: 'linkOuts',
          isNew: true,
        }
        : {
          ...entry,
          type,
          text: createText(entry.resource, entry.term, (entry.resourceId || entry.termId)),
          isNew: true,
          automatic: false,
        };
      setData((prevData) => {
        const newData = {...prevData};

        if (!newData.links) {
          newData.links = {};
        } else {
          // mark everything else as old
          forEach(Object.keys(newData.links), (key) => {
            const len = newData.links[key].length;
            for (let x = 0; x < len; x += 1) {
              newData.links[key][x].isNew = false;
            }
          });
        }
        if (newData.links[link.resource]) {
          newData.links[link.resource].push(link);
        } else {
          newData.links[link.resource] = [link];
        }
        return newData;
      });
      toast.success('Link added.');
    }
    modalService.close();
  };

  const deleteSuccess = (deletedId) => {
    const [linkType, linkId, linkResource] = deletedId.split('--');
    setData((prevData) => {
      const links = prevData.links[linkResource];
      if (links) {
        // eslint-disable-next-line lodash/prefer-immutable-method
        remove(links, (l) =>
          // l.id is sometimes string and sometimes a number, so always treat as string
          `${l.id}` === linkId);
      }
      return {...prevData};
    });
    toast.success(`${capitalize(linkType)} deleted.`);
  };

  const deleteFailure = (deletedId, kyError) => {
    appContext.toastError(<KyError kyError={kyError} />);
  };

  const renderLink = (entry) => {
    const link = showLinkOuts
      ? {
        ...entry,
        type: 'linkOuts',
        text: createText(entry.resource, entry.name, entry.resourceId),
      }
      : {
        ...entry,
        type: (entry.name || entry.term) ? 'ontologyTerms' : 'crossReferences',
        text: createText(entry.resource, (entry.name || entry.term), (entry.resourceId || entry.termId)),
      };

    const linkId = `${link.type}--${link.id}--${link.resource}`;
    let controls = '';
    if (editContext.isEditMode) {
      const url = `curation/${objCls}/${id}/${link.type}/${link.id}`;
      controls = (
        <DeleteButton
          url={url}
          id={linkId}
          onSuccess={deleteSuccess}
          onFailure={deleteFailure}
          iconOnly={true}
          className="mr-2"
        />
      );
    }
    return (
      <li key={linkId} className={clsx({isNew: link.isNew})}>
        {controls}
        {link._url ? <Link href={link._url}>{link.text}</Link> : link.text}
        {' '}{renderStatusIcon(link.status)}
        {' '}{renderAutomaticIcon(link.automatic)}
      </li>
    );
  };

  const renderResource = (resource, links) => {
    if (links && links.length > 0) {
      return (
        <div className="linksTab__xrefs__resource" key={resource}>
          <h4>{resource}</h4>
          <ul>
            {map(links, renderLink)}
          </ul>
        </div>
      );
    }
  };

  const renderLinks = (linkMap) => {
    if (!linkMap) {
      return <p className="empty mb-4">No links found.</p>;
    }
    return (
      <div className="linksTab__xrefs">
        {map(Object.keys(linkMap), (key) => renderResource(key, linkMap[key]))}
      </div>
    );
  };

  const saveHandler = (newLinks) => {
    const links = {};
    forEach(newLinks, (entry) => {
      if (!links[entry.resource]) {
        links[entry.resource] = [];
      }
      const link = {
        ...entry,
        isNew: !find(data.links[entry.resource], (y) => y.id === entry.id),
      };
      links[entry.resource].push(link);
    });

    setData(sortLinks({canHaveLinks: true, links}));
    modalService.close();
  };

  const closeHandler = () => modalService.close();
  const openUpdateEditor = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();

    modalService.open({
      closeHandler,
      style: {
        width: '50em',
      },
      content: (
        <LinksTabUpdateSection
          objCls={capitalize(objCls)}
          objId={id}
          onClose={closeHandler}
          onSave={saveHandler}
        />
      ),
    });
  };

  const openNewEditor = async (evt) => {
    evt.preventDefault();
    evt.stopPropagation();

    modalService.open({
      closeHandler,
      style: {
        width: '30em',
      },
      content: (
        <LinksTabNewForm
          objCls={capitalize(objCls)}
          objId={id}
          onSave={addSuccess}
          onClose={closeHandler}
        />
      ),
    });
  };

  let body;
  if (error) {
    body = <KyError kyError={error} />;
  } else if (data) {
    const controls = (
      <EditControls>
        <Button
          actionHandler={openNewEditor}
          disabled={!data?.canHaveLinks}
          title={data?.canHaveLinks ? '' : `Cannot add links to ${objCls}`}
        >
          <FontAwesomeIcon icon="plus" /> New Link
        </Button>
        {showLinkOuts && (
          <Button
            actionHandler={openUpdateEditor}
          >
            Import Links
          </Button>
        )}
      </EditControls>
    );

    body = (
      <>
        {controls}
        {renderLinks(data?.links)}
      </>
    );
  } else {
    body = <Loading />;
  }

  return (
    <div className="linksTab">
      <h3>Links</h3>
      {body}
    </div>
  );
}
LinksTab.propTypes = propTypes;

function sortLinks(linkData) {
  if (linkData?.links) {
    forEach(Object.keys(linkData.links), (key) => {
      linkData.links[key].sort((l1, l2) => {
        const r1 = l1.resource.toLowerCase();
        const r2 = l2.resource.toLowerCase();
        if (r1 < r2) {
          return -1;
        }
        if (r1 > r2) {
          return 1;
        }
        if (l1.status !== l2.status) {
          if (l1.status === LinkOutStatus.shortName.valid) {
            return -1;
          } else if (l1.status === LinkOutStatus.shortName.obsolete) {
            return -1;
          }
          return 1;
        }
        const t1 = createText(l1.resource, l1.name, l1.resourceId);
        const t2 = createText(l2.resource, l2.name, l2.resourceId);

        if (t1 < t2) {
          return -1;
        }
        if (t1 > t2) {
          return 1;
        }
        return 0;
      });
    });

    const sortedLinkSources = {};
    forEach(Object.keys(linkData.links).sort(), (source) => {
      sortedLinkSources[source] = linkData.links[source];
    });
    return {canHaveLinks: linkData.canHaveLinks, links: sortedLinkSources};
  }
  return linkData;
}

export function createText(resource, term, resourceId) {
  let text;
  if (LinkOutResource.ontology.includes(LinkOutResource.displayName[resource])) {
    text = `${term} (${resourceId})`;
  } else {
    if (term && resourceId.toLowerCase() !== term.toLowerCase()) {
      text = `${resourceId} (${term})`;
    } else {
      text = resourceId;
    }
  }
  return text;
}

export function renderStatusIcon(status) {
  if (status === LinkOutStatus.shortName.invalid) {
    return (
      <FontAwesomeIcon
        icon={['fal', 'ban']}
        size="xs"
        title="Invalid"
      />
    );
  } else if (status === LinkOutStatus.shortName.obsolete) {
    return (
      <FontAwesomeIcon
        icon={['fal', 'scroll-old']}
        size="xs"
        title="Obsolete"
      />
    );
  }
}

export function renderAutomaticIcon(isAutomatic) {
  return isAutomatic && (
    <FontAwesomeIcon
      icon={['fal', 'robot']}
      size="xs"
      title="Added programatically"
    />
  );
}
