import clsx from 'clsx';
import PropTypes from 'prop-types';

import Tag, {renderTagIfPresent} from 'components/Tag/index';
import logger from 'conf/Logger';

import './DosingInfo.scss';


const propTypes = {
  context: PropTypes.oneOf(['guideline', 'guidelineAnnotation', 'label', 'labelAnnotation']).isRequired,
  showHelp: PropTypes.bool,
  className: PropTypes.string,
};

export default function DosingInfoTag({context, showHelp = true, className}) {
  const props = {
    className: clsx('dosingInfoTag', className),
  };
  if (showHelp) {
    if (context === 'guideline' || context === 'guidelineAnnotation') {
      props.tooltipUrl = '/page/clinicalGuidelineLegend#clinical-guideline-annotation-tags';
    } else if (context === 'label' || context === 'labelAnnotation') {
      props.tooltipUrl = '/page/drugLabelLegend#drug-label-annotation-tags';
    } else {
      logger.error(`context must be either "guidelineAnnotation" or "labelAnnotation", got "${context}"`);
      return <div className="text-danger">error - check console</div>;
    }
    props.tooltipText = 'Learn more about the Dosing Info tag';
  }
  return (
    <Tag {...props}>Dosing Info</Tag>
  );
}
DosingInfoTag.propType = propTypes;


export function renderDosingInfoTag({obj, showHelp, className}) {
  return renderTagIfPresent({
    tag: DosingInfoTag,
    obj,
    dbProp: 'dosingInformation',
    indexProp: 'dosing-info',
    showHelp,
    className,
  });
}
