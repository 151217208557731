import clsx from 'clsx';
import {every, forEach, map} from 'lodash';
import PropTypes from 'prop-types';
import {useEffect, useMemo, useRef, useState} from 'react';

import BarChart from 'components/Chart/LazyBar';
import Loading from 'components/Loading';
import {resourceProps} from 'conf/types';
import {getPhenotypeCategoryChartData} from 'utils/annotationUtils';
import {calculateAccIdKey} from 'utils/dependencyUtils';


const propTypes = {
  clinicalAnnotations: PropTypes.arrayOf(resourceProps),
  pgxLevels: PropTypes.shape({
    clinicalAnnotations: PropTypes.arrayOf(resourceProps),
    pgxLevelStats: PropTypes.object,
  }),
  filterChangeFn: PropTypes.func,
  captionTitle: PropTypes.string,
  className: PropTypes.string,
};
/**
 * Renders clinical annotations bar chart.
 *
 * @param {object} props - props container
 * @param {Array} [props.clinicalAnnotations]
 * @param {object} [props.pgxLevels]
 * @param {Function} [props.filterChangeFn] - function to call whenever y-axis is changed
 * @param {string} props.captionTitle
 * @param {string} [props.className]
 * @return {JSX.Element}
 */
export default function ClinicalAnnotationsBarChart({clinicalAnnotations, pgxLevels, filterChangeFn,
  captionTitle, className}) {
  const [yAxis, setYAxis] = useState('level');
  const ref = useRef(yAxis);
  
  useEffect(() => {
    ref.current = yAxis;
  }, [yAxis]);

  const dependencyKey = pgxLevels
    ? 'pgx' + calculateAccIdKey(pgxLevels?.clinicalAnnotations)
    : calculateAccIdKey(clinicalAnnotations);
  const chartData = useMemo(() => {
    if (pgxLevels) {
      return reformatPgxLevels(pgxLevels);
    } else if (clinicalAnnotations) {
      if (yAxis === 'type') {
        return getPhenotypeCategoryChartData(clinicalAnnotations);
      } else {
        return createLevelChartData(clinicalAnnotations);
      }
    } else {
      return null;
    }
  }, [yAxis, dependencyKey]);

  if (!chartData) {
    return <Loading />;
  }

  if (pgxLevels && chartData.length === 0) {
    return null;
  }

  const hasData = chartData.total !== 0;

  let stepSize;
  if (hasData) {
    const isStepSize = every(chartData.data, (element) => element.value <= 5);
    if (isStepSize) {
      stepSize = 1;
    }
  }

  const handleClick = (label) => {
    let formattedLabel = label;
    if (ref.current === 'level') {
      formattedLabel = label.split(' ')[1];
    }

    const selected = {};
    selected[formattedLabel] = true;

    if (filterChangeFn) {
      filterChangeFn([
        {
          id: ref.current,
          value: selected,
        },
      ]);
    }
  };

  const handleChange = (e) => {
    setYAxis(e.target.value);
    if (filterChangeFn) {
      filterChangeFn(null);
    }
  };


  if (pgxLevels) {
    return (
      <figure key={dependencyKey} className={clsx('caFigure', className)}>
        <BarChart data={chartData.data} horizontal={true} />
        <figcaption className="text-center text-muted">
          Clinical annotations by PGx level
        </figcaption>
      </figure>
    );

  } else {
    const caption = hasData ? (
      <figcaption className="text-center text-muted">
        {captionTitle}
        <select onChange={handleChange}>
          <option value="level">level</option>
          <option value="type">phenotype category</option>
        </select>
      </figcaption>
    )
      : (
        <figcaption className="text-center text-muted">
          No data to display
        </figcaption>
      );

    return (
      <figure key={dependencyKey} className={clsx('caFigure', className)}>
        <BarChart
          data={chartData.data}
          horizontal={true}
          clickHandler={handleClick}
          xAxisLabel={{stepSize, display: hasData}}
        />
        {caption}
      </figure>
    );
  }
}
ClinicalAnnotationsBarChart.propTypes = propTypes;


function createLevelChartData(clinicalAnnotations) {

  const levels = {
    '1A': {label: 'Level 1A', value: 0, color: '#0ABC72'},
    '1B': {label: 'Level 1B', value: 0, color: '#0ABC72'},
    '2A': {label: 'Level 2A', value: 0, color: '#2A74DF'},
    '2B': {label: 'Level 2B', value: 0, color: '#2A74DF'},
    3: {label: 'Level 3', value: 0, color: '#FFC107'},
    4: {label: 'Level 4', value: 0, color: '#C53B3B'},
  };

  let total = 0;
  forEach(clinicalAnnotations, (cliAnn) => {
    const {level} = cliAnn.properties;

    if (levels[level]) {
      levels[level].value += 1;
      total += 1;
    }
  });

  let data = [];
  if (total === 0) {
    for (let y = 0; y < 5; y += 1) {
      data.push({label: '', value: 0});
    }
  } else {
    const keys = Object.keys(levels);
    const sortedLevels = keys.sort();
    data = map(sortedLevels, (level) => levels[level]);
  }

  return {data, total};
}


function reformatPgxLevels(pgxLevels) {

  const levels = {
    '1A': {label: 'Level 1A', value: 0, color: '#0ABC72'},
    '1B': {label: 'Level 1B', value: 0, color: '#0ABC72'},
    '2A': {label: 'Level 2A', value: 0, color: '#2A74DF'},
    '2B': {label: 'Level 2B', value: 0, color: '#2A74DF'},
    3: {label: 'Level 3', value: 0, color: '#FFC107'},
    4: {label: 'Level 4', value: 0, color: '#C53B3B'},
  };

  const keys = Object.keys(levels);
  const sortedLevels = keys.sort();
  const {pgxLevelStats} = pgxLevels;

  let total = 0;
  forEach(sortedLevels, (level) => {
    if (pgxLevelStats[level]) {
      levels[level].value = pgxLevelStats[level];
      total += 1;
    }
  });

  const data = map(sortedLevels, (level) => levels[level]);
  return {data, total};
}
