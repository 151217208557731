import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {get} from 'lodash';
import PropTypes from 'prop-types';
import {toast} from 'react-toastify';

import Button from 'components/Button';
import EditControls from 'components/edit/EditControls';
import LayeredIcon from 'components/icons/LayeredIcon';
import Link from 'components/links/Link';
import useModalService from 'components/shared/ModalService';
import {addHtmlContainerIfDefined} from 'components/shared/html_container';
import useAppContext from 'conf/AppContext';
import Types from 'conf/types';

const propTypes = {
  id: PropTypes.number,
  sourceDrug: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }),
  targetDrug: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }),
  targetGene: PropTypes.shape({
    symbol: PropTypes.string,
    id: PropTypes.string,
  }),
  interacts: PropTypes.bool,
  directional: PropTypes.bool,
  description: PropTypes.shape({
    html: PropTypes.string,
  }),
  clinicallyRelevant: PropTypes.shape({
    term: PropTypes.string,
  }),
  geneRole: PropTypes.shape({
    term: PropTypes.string,
  }),
  onDelete: PropTypes.func,
};
/**
 * This component will display either a drug-drug or a drug-gene interaction
 *
 * @param {object} props - the props container
 * @param {number} props.id - the interaction id
 * @param {object} props.sourceDrug - the source Chemical object
 * @param {object} props.targetDrug - the target Chemical object, will be undefined if targetGene exists
 * @param {object} props.targetGene - the target Gene object, will be undefined if targetDrug exists
 * @param {boolean} props.directional - true if source to target is a directional relationship
 * @param {boolean} props.interacts - false if this interaction is asserts a negative relationship
 * @param {object} props.description - a markdown object description of the interaction
 * @param {object} props.geneRole - an ontology term for the role of the gene in the interaction, undefined if targetGene is undefined
 * @param {object} props.clinicallyRelevant - an ontology term that describes whether the interaction is relevant
 * @param {Function} props.onDelete - function to call on successful deletion
 * @return {JSX.Element}
 */
export default function DrugInteraction({
  id,
  sourceDrug,
  targetDrug,
  targetGene,
  directional = false,
  interacts = true,
  description,
  geneRole,
  clinicallyRelevant,
  onDelete,
}) {
  const appContext = useAppContext();
  const modalService = useModalService();
  const iconName = directional ? 'arrow-right' : 'arrows-h';
  const icon = interacts ? <FontAwesomeIcon icon={iconName} /> : <LayeredIcon bottomIcon={iconName} topIcon={['fal', 'times']} />;

  const targetName = get(targetGene, 'symbol') || get(targetDrug, 'name');
  const targetId = get(targetGene, 'id') || get(targetDrug, 'id');
  const type = !targetGene ? Types.chemical : Types.gene;
  const interactionType = !targetGene ? 'drugDrugInteraction' : 'drugGeneInteraction';
  const interactionSuffix = targetGene ? ` (${get(geneRole, 'term')})` : '';
  const relevance = get(clinicallyRelevant, 'term');

  const handleDelete = () => {
    modalService.confirm({
      content: 'Delete this interaction?',
      yesLabel: 'Delete',
      noLabel: 'Cancel',
      yesHandler: async () => {
        await appContext.api.delete(`curation/${interactionType}/${id}`);
        toast.success('Deleted');
        onDelete();
      },
    });
  };

  return (
    <div className="mb-2">
      <div className="font-weight-bold" style={{display: 'flex'}}>
        <div>
          <Link href={Types.chemical.url(sourceDrug.id)}>{sourceDrug.name}</Link>
          {' '}
          {icon}
          {' '}
          <Link href={type.url(targetId)}>{targetName}{interactionSuffix}</Link>
        </div>
        <EditControls className="mb-0 ml-2">
          <Button href={`/edit/${interactionType}/${id}`}><FontAwesomeIcon icon="edit" /> Edit</Button>
          <Button actionHandler={handleDelete} className="btn-danger"><FontAwesomeIcon icon="times" /> Delete</Button>
        </EditControls>
      </div>
      <div><strong>Clinically Relevant:</strong> {relevance}</div>
      <div>{addHtmlContainerIfDefined(description?.html, {className: 'ml-2'})}</div>
    </div>
  );
}
DrugInteraction.propTypes = propTypes;
