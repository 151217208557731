import clsx from 'clsx';
import {forEach} from 'lodash';
import PropTypes from 'prop-types';
import {useMemo} from 'react';

import BarChart from 'components/Chart/LazyBar';
import Loading from 'components/Loading';
import {calculateAccIdKey} from 'utils/dependencyUtils';

/**
 * Sort order for levels in the bar chart
 */
const FdaLevelOrder = {
  'Testing Required': 1,
  'Testing Recommended': 2,
  'Actionable PGx': 3,
  'Informative PGx': 4,
  'No Clinical PGx': 5,
  'Criteria Not Met': 6,
};

/**
 * Colors in the bar chart
 */
const FdaLevelColor = {
  'Testing Required': '#c53b3b',
  'Testing Recommended': '#ffc107',
  'Actionable PGx': '#0ABC72',
  'Informative PGx': '#2A74DF',
  'No Clinical PGx': '#5BC0DE',
  'Criteria Not Met': '#657188',
};

const propTypes = {
  fdaLabelAnnotations: PropTypes.arrayOf(PropTypes.object),
  filterChangeFn: PropTypes.func,
  className: PropTypes.string,
};

/**
 * Renders FDA label annotations bar chart.
 *
 * @param {object} props - props container
 * @param {Array} [props.fdaLabelAnnotations]
 * @param {Function} [props.filterChangeFn]
 * @param {string} [props.className]
 * @return {JSX.Element}
 */
export default function FdaLabelAnnotationsBarChart({fdaLabelAnnotations, filterChangeFn, className}) {
  const dependencyKey = calculateAccIdKey(fdaLabelAnnotations);
  const chartData = useMemo(() => createPGxLevelData(fdaLabelAnnotations), [dependencyKey]);

  if (!chartData) {
    return <Loading />;
  }

  const handleClick = (label) => {
    if (filterChangeFn) {
      filterChangeFn([
        {
          id: 'pgxLevel',
          value: label,
        },
      ]);
    }
  };
  
  return (
    <figure key={dependencyKey} className={clsx('fdaFigure', className)}>
      <BarChart data={chartData} horizontal={true} stacked={true} clickHandler={handleClick} />
      <figcaption className="text-center text-muted">
        Count of annotations by PGx level with and without prescribing info.<br />
        Gray represents annotations without prescribing info.
      </figcaption>
    </figure>
  );

}
FdaLabelAnnotationsBarChart.propTypes = propTypes;

function createPGxLevelData(fdaLabelAnnotations) {
  const labels = [];
  const prescribingData = {
    label: 'Prescribing Info',
    data: [],
    backgroundColor: [],
  };
  const noPrescribingData = {
    label: 'No Prescribing Info',
    data: [],
    backgroundColor: [],
  };

  forEach(FdaLevelOrder, (value, key) => {
    let prescribing = 0;
    let noPrescribing = 0;
    for (let x = 0; x < fdaLabelAnnotations.length; x += 1) {
      const d = fdaLabelAnnotations[x];
      if (d.pgxLevel === key) {
        if (d.prescribingInformation) {
          prescribing += 1;
        } else {
          noPrescribing += 1;
        }
      }
    }

    labels.push(key);

    prescribingData.data.push(prescribing);
    prescribingData.backgroundColor.push(FdaLevelColor[key]);

    noPrescribingData.data.push(noPrescribing);
    // using $grey30 from _variables.scss
    noPrescribingData.backgroundColor.push('#C5CAD2');
  });

  return {labels, datasets: [prescribingData, noPrescribingData]};
}
