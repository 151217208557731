// These variables are set by Webpack's DefinePlugin:
/* global ENV */
import {isUndefined} from 'lodash';
import PropTypes from 'prop-types';
import {createContext, useContext, useEffect, useMemo, useState} from 'react';

import useAppContext from 'conf/AppContext';
import logger from 'conf/Logger';
import App from 'conf/app';
import {analyticsEvent} from 'helpers/analytics';


const PEDIATRIC_MODE_CACHE_KEY = 'pgkb.pediatricMode';
const initialPediatricMode = ENV === 'pediatric';

/**
 * Pediatric context (determines whether pediatric data should be highlighted).
 */
const PediatricContext = createContext(undefined);

const propTypes = {
  children: PropTypes.node,
  /** Forces pediatric mode (for testing).  */
  forcePediatricMode: PropTypes.bool,
};
/**
 * This is a React.Context.Provider for the pediatric context.
 */
export function PediatricContextProvider({children, forcePediatricMode}) {
  const appContext = useAppContext();
  const [isPediatricMode, setPediatricMode] = useState(isUndefined(forcePediatricMode)
    ? initialPediatricMode : forcePediatricMode);

  useEffect(() => {
    if (!isUndefined(forcePediatricMode)) {
      return;
    }
    appContext.cache.getItem(PEDIATRIC_MODE_CACHE_KEY)
      .then((value) => {
        if (value !== null) {
          const isEnabled = value === 'true';
          if (isEnabled !== isPediatricMode) {
            setPediatricMode(isEnabled);
            App.isPediatricMode = isEnabled;
          }
        }
      })
      .catch((err) => {
        logger.warn('Error loading pediatric mode from cache', err);
      });
  }, [forcePediatricMode, appContext.user, appContext.isPreview]);

  /**
   * Toggles pediatric mode.
   *
   * @param {string} location - location of toggle
   */
  const togglePediatricMode = (location = 'header') => {
    analyticsEvent('focus-pediatric', {
      switchTo: `${location}-${!isPediatricMode}`,
      location: appContext.getCurrentPath(),
    });
    setPediatricMode((prevState) => {
      appContext.cache.setItem(PEDIATRIC_MODE_CACHE_KEY, `${!prevState}`)
        .catch((err) => logger.warn('Error caching pediatric mode', err));
      return !prevState;
    });
  };

  /**
   * Updates pediatric mode to specified value.
   */
  const updatePediatricMode = (isEnabled) => {
    appContext.cache.setItem(PEDIATRIC_MODE_CACHE_KEY, isEnabled ? 'true' : 'false')
      .catch((err) => logger.warn('Error caching pediatric mode', err));
    setPediatricMode(isEnabled);
  };

  const providerProps = useMemo(() => ({
    isPediatricMode,
    setPediatricMode: updatePediatricMode,
    togglePediatricMode,
  }), [isPediatricMode]);

  return (
    <PediatricContext.Provider value={providerProps}>
      {children}
    </PediatricContext.Provider>
  );
}
PediatricContextProvider.propTypes = propTypes;


const usePediatricContext = () => {
  const context = useContext(PediatricContext);
  if (!context) {
    throw new Error('PediatricContext must be used within PediatricContextProvider');
  }
  return context;
};
export default usePediatricContext;
