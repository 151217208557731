import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {flatten, includes, map, size, upperFirst, values} from 'lodash';
import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {toast} from 'react-toastify';

import './OverviewTab.scss';

import Button from 'components/Button';
import ApproveButton from 'components/Button/Approve';
import DataAnnotationUpsertButton from 'components/DataAnnotation/UpsertButton';
import DrugTargetList from 'components/DrugTarget/List';
import LabelOccurrenceSection from 'components/LabelOccurrence';
import {renderItemWithoutTags} from 'components/List/Item';
import MultiColumnList, {accObjRenderFn, accObjSizeFn} from 'components/List/MultiColumn';
import SearchableList from 'components/List/Searchable';
import PharmgkbTagButton from 'components/PharmgkbTag/Button';
import {renderBpcaDrugTag} from 'components/Tag/BpcaDrug';
import PediatricTag from 'components/Tag/Pediatric';
import useEditContext from 'components/edit/EditContext';
import EditControls from 'components/edit/EditControls';
import Link from 'components/links/Link';
import ResourceCounts, {convertTypedCounts, resourceCountsProps} from 'components/resource/resourceCounts';
import CuratorOnly from 'components/shared/curator_only';
import Fact from 'components/shared/fact';
import FactSection from 'components/shared/fact_section';
import {useTour} from 'components/tour/hook';
import useAppContext from 'conf/AppContext';
import {accessionIdProps} from 'conf/types';
import usePediatricContext from 'context/Pediatric';


const propTypes = {
  chemical: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    altNames: PropTypes.shape({
      generic: PropTypes.arrayOf(PropTypes.string),
      trade: PropTypes.arrayOf(PropTypes.string),
    }),
    types: PropTypes.arrayOf(PropTypes.string),
    terms: PropTypes.arrayOf(PropTypes.shape({
      term: PropTypes.string,
    })),
  }),
  counts: resourceCountsProps,
  needsApproval: PropTypes.bool,
  isPediatric: PropTypes.bool,
  images: PropTypes.shape({
    imageUrl: PropTypes.string,
    imageLargeUrl: PropTypes.string,
    structureUrl: PropTypes.string,
  }),
  description: PropTypes.shape({
    id: PropTypes.number.isRequired,
    html: PropTypes.string,
  }),
  pedSummary: PropTypes.shape({
    id: PropTypes.number.isRequired,
    html: PropTypes.string,
  }),
  pgxSummary: PropTypes.shape({
    id: PropTypes.number.isRequired,
    html: PropTypes.string,
  }),
  indication: PropTypes.shape({
    id: PropTypes.number.isRequired,
    html: PropTypes.string,
  }),
  vips: PropTypes.arrayOf(PropTypes.shape({
    gene: PropTypes.string,
    url: PropTypes.string,
    tier: PropTypes.string,
    sections: PropTypes.arrayOf(PropTypes.string),
  })),
  classChildren: PropTypes.arrayOf(accessionIdProps),
  classParents: PropTypes.arrayOf(accessionIdProps),
  metaboliteOf: PropTypes.arrayOf(accessionIdProps),
  mixtures: PropTypes.arrayOf(accessionIdProps),
  formula: PropTypes.shape({
    id: PropTypes.number.isRequired,
    html: PropTypes.string,
    sources: PropTypes.arrayOf(PropTypes.string),
  }),
  molWeightAvg: PropTypes.shape({
    id: PropTypes.number.isRequired,
    html: PropTypes.string,
    sources: PropTypes.arrayOf(PropTypes.string),
  }),
  molWeightMono: PropTypes.shape({
    id: PropTypes.number.isRequired,
    html: PropTypes.string,
    sources: PropTypes.arrayOf(PropTypes.string),
  }),
  targets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    object1: accessionIdProps,
    object2: accessionIdProps,
  })),
  labelOccurrences: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    object1: accessionIdProps,
    object2: accessionIdProps,
  })),
};

export default function ChemicalOverviewTab({chemical, counts, needsApproval, isPediatric, images, description,
  pedSummary, vips, pgxSummary, indication, classChildren, classParents, metaboliteOf, mixtures, formula, molWeightAvg,
  molWeightMono, targets, labelOccurrences}) {
  const appContext = useAppContext();
  const pediatricContext = usePediatricContext();
  const editContext = useEditContext();

  const readyForTour = useTour(isPediatric ? 'chemical' : 'chemicalNoPediatric');
  useEffect(() => {
    readyForTour();
  }, [readyForTour]);


  const renderPediatricTags = () => {
    if (!isPediatric) {
      return null;
    }
    let bpcaDrugTag;
    if (pediatricContext.isPediatricMode) {
      bpcaDrugTag = renderBpcaDrugTag({obj: chemical});
    }
    return (
      <div className="mt-3">
        <PediatricTag />
        {bpcaDrugTag}
      </div>
    );
  };


  const resourceCounts = convertTypedCounts('chemical', chemical.id, counts);
  const synonyms = flatten(values(chemical?.altNames));
  const isDrugClass = includes(chemical.types, 'Drug Class');
  const onApproveSuccess = () => {
    toast.success('Approved.');
    appContext.reloadData();
  };

  return (
    <>
      <EditControls>
        <Button href={`/edit/chemical/${chemical.id}`}>
          <FontAwesomeIcon icon="edit" /> Edit
        </Button>
        <ApproveButton objCls="Chemical" objId={chemical.id} disabled={!needsApproval} onSuccess={onApproveSuccess} />
        <PharmgkbTagButton objId={chemical.id} objCls="chemical" />
      </EditControls>

      <FactSection>
        <ResourceCounts counts={resourceCounts} />
        {renderPediatricTags()}
      </FactSection>

      {pediatricContext.isPediatricMode && (pedSummary || editContext.isEditMode) && (
        <FactSection sectionClasses="alert alert-info">
          <Fact
            label="Pediatric Summary"
            html={pedSummary?.html}
            hideWhenEmpty={!editContext.isEditMode}
            labelBtns={<DataAnnotationUpsertButton targetId={chemical.id} type="pedSummary" annotationId={pedSummary?.id} />}
          />
        </FactSection>
      )}

      <FactSection>
        {images?.imageUrl && (
          <Fact label="Structure">
            <div className="chemicalStructure">
              <div>
                <img src={images.imageUrl} alt={chemical.name} />
              </div>
              <div className="ml-2">
                <ul className="list-unstyled">
                  {images.imageLargeUrl && (
                    <li>
                      <Link href={images.imageLargeUrl}>
                        <FontAwesomeIcon icon={['far', 'expand-alt']} />large version
                      </Link>
                    </li>
                  )}
                  {images.structureUrl && (
                    <li>
                      <Link href={images.structureUrl}>
                        <FontAwesomeIcon icon={['far', 'cube']} />3D version
                      </Link>
                    </li>
                  )}
                  <li><small>source: <Link href="https://pubchem.ncbi.nlm.nih.gov/">PubChem</Link></small></li>
                </ul>
              </div>
            </div>
          </Fact>
        )}

        <Fact label="Type" literal={chemical.types ? chemical.types.join(', ') : ''} inline={true} />

        <Fact label="PharmGKB ID" literal={chemical.id} inline={true} />

        <Fact
          label="Description"
          html={description?.html}
          hideWhenEmpty={!appContext.isPreview}
          labelBtns={<DataAnnotationUpsertButton targetId={chemical.id} type="Description" annotationId={description?.id} allowDelete={true} />}
        />

        {(vips?.length > 0 || targets?.length > 0) && (
          <CuratorOnly>
            {vips?.length > 0 && (
              <Fact label="Included in VIPs" compact={true}>
                <ul className="list-unstyled">
                  {map(vips, (vip) => <li key={vip.url}><Link href={vip.url}>{vip.gene} VIP Annotation ({vip.tier})</Link></li>)}
                </ul>
              </Fact>
            )}
            <Fact label="Drug Targets">
              <DrugTargetList targets={targets} subjectId={chemical.id} />
            </Fact>
            <Fact label="Pharmacology">
              <LabelOccurrenceSection occurrences={labelOccurrences} subjectId={chemical.id} />
            </Fact>
          </CuratorOnly>
        )}

        <Fact
          label="Pharmacogenetics"
          html={pgxSummary?.html}
          hideWhenEmpty={!appContext.isPreview}
          labelBtns={<DataAnnotationUpsertButton targetId={chemical.id} type="Pharmacogenetics" annotationId={pgxSummary?.id} allowDelete={true} />}
        />

        <Fact
          label="Indication"
          html={indication?.html}
          hideWhenEmpty={!appContext.isPreview}
          labelBtns={<DataAnnotationUpsertButton targetId={chemical.id} type="Indication" annotationId={indication?.id} allowDelete={true} />}
        />

      </FactSection>

      {renderClassParents(classParents)}
      {isDrugClass ? renderClassChildren(classChildren)
        : renderDrug(chemical, mixtures, metaboliteOf, formula, molWeightAvg, molWeightMono)}

      <Fact
        label="Synonyms"
        labelBtns={(
          <EditControls>
            <Button href={`/edit/alternateNames/chemical/${chemical.id}`}>
              <FontAwesomeIcon icon="edit" /> Edit
            </Button>
          </EditControls>
        )}
      >
        {
          synonyms.length === 0 ? <p className="text-muted">None</p>
            : <MultiColumnList data={synonyms} />
        }
      </Fact>
    </>
  );
}
ChemicalOverviewTab.propTypes = propTypes;


function renderDrug(chemical, mixtures, metaboliteOf, formula, molWeightAvg, molWeightMono) {
  return (
    <>
      {renderMixtures(chemical, mixtures)}
      {renderComponents(chemical)}
      {renderMetabolites(chemical, metaboliteOf)}
      {renderMolecularProperties(chemical, formula, molWeightAvg, molWeightMono)}
    </>
  );
}

function renderMixtures(chemical, mixtures) {
  if (mixtures?.length === 0) {
    return null;
  }
  return (
    <FactSection title="Mixtures">
      <p>The following mixtures contain {chemical.name}.</p>
      <MultiColumnList data={mixtures} renderFn={accObjRenderFn} sizeFn={accObjSizeFn} />
    </FactSection>
  );
}

function renderComponents(chemical) {
  const {name, components} = chemical;
  if (components?.length === 0) {
    return null;
  }
  return (
    <FactSection title="Components">
      <p>{upperFirst(name)} includes the following molecules.</p>
      <MultiColumnList data={components} renderFn={accObjRenderFn} sizeFn={accObjSizeFn} />
    </FactSection>
  );
}

function renderMetabolites(chemical, metaboliteOf) {
  const {metabolites} = chemical;
  if (metaboliteOf?.length === 0 && metabolites?.length === 0) {
    return null;
  }
  const {name} = chemical;

  return (
    <FactSection title="Metabolites">
      {metaboliteOf?.length > 0 && renderMetaboliteContent(`${name} is a metabolite of the following`, metaboliteOf)}
      {metabolites?.length > 0 && renderMetaboliteContent(`${name} metabolizes into the following`, metabolites)}
      <footer><small>(source: PharmGKB)</small></footer>
    </FactSection>
  );
}

function renderMetaboliteContent(text, collection) {
  return (
    <div>
      <p>{text}:</p>
      <MultiColumnList data={collection} renderFn={accObjRenderFn} sizeFn={accObjSizeFn} />
    </div>
  );
}

function renderMolecularProperties(chemical, formula, molWeightAvg, molWeightMono) {
  const {smiles, inchi} = chemical;

  return (
    <div>
      <FactSection title="Molecular Properties" notAvailableWhenEmpty={true}>
        <Fact
          label="Molecular Formula"
          html={formula?.html}
          contentClasses="fixed-width-font"
          source={renderSources(formula?.sources)}
        />
        <Fact label="SMILES" html={smiles} contentClasses="fixed-width-font" source={renderSource('PubChem')} />
        <Fact label="InChI" html={inchi} contentClasses="fixed-width-font" source={renderSource('PubChem')} />
        <Fact
          label="Average Molecular Weight"
          html={molWeightAvg?.html}
          source={renderSources(molWeightAvg?.sources)}
        />
        <Fact
          label="Monoisotopic Molecular Weight"
          html={molWeightMono?.html}
          source={renderSources(molWeightMono?.sources)}
        />
      </FactSection>
    </div>
  );
}

function renderSources(sources) {
  if (sources?.length > 0) {
    return renderSource(sources.join(', '));
  }
  return null;
}

function renderSource(source) {
  return (source ? `(source: ${source})` : null);
}


function renderClassChildren(classChildren) {
  if (classChildren?.length === 0) {
    return null;
  }
  return (
    <FactSection title="Category Members">
      <p>The following have been classified under this therapeutic category.</p>
      <MultiColumnList data={classChildren} renderFn={accObjRenderFn} sizeFn={accObjSizeFn} />
    </FactSection>
  );
}

function renderClassParents(classParents) {
  if (size(classParents) === 0) {
    return null;
  } else {
    return (
      <FactSection title="Classifications">
        <SearchableList collection={classParents} singularNoun="molecule" renderItemFn={renderItemWithoutTags} searchKeys={['name']} />
      </FactSection>
    );
  }
}
