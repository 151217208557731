import clsx from 'clsx';
import {map} from 'lodash';
import PropTypes from 'prop-types';

import 'components/List/MultiColumn.scss';
import Link from 'components/links/Link';
import {getBaseUrl} from 'conf/types';


const propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
  renderFn: PropTypes.func,
  sizeFn: PropTypes.func,
  ordered: PropTypes.bool,
  className: PropTypes.string,
};
/**
 * Renders a list of things in as many columns as will fit in container.
 *
 * @param {object} props - props container
 * @param {Array} props.data - array of data
 * @param {Function} props.renderFn - make a link for accession object
 * @param {Function} props.sizeFn - calculate name or symbol size for accession object
 * @param {boolean} props.ordered - make the list ordered or unordered style
 * @param {string} props.className
 * @return {JSX.Element}
 */
export default function MultiColumnList({data, renderFn, sizeFn, ordered = false, className}) {

  let avgLen = 0;
  let maxLen = 0;
  for (let x = 0; x < data.length; x += 1) {
    const len = sizeFn ? sizeFn(data[x]) : data[x].length;
    avgLen += len;
    maxLen = Math.max(maxLen, len);
  }
  avgLen /= data.length;

  let finalLength = maxLen;
  if ((avgLen * 2 < maxLen)) {
    finalLength = avgLen * 2;
  }
  if (finalLength > 12) {
    finalLength /= 1.5;
  } else {
    // buffer for list symbol
    finalLength += 1;
  }

  const props = {
    className: clsx('multiColumn', className),
    style: {
      columnWidth: `${finalLength}rem`,
    },
  };

  const renderItems = () => map(data, (d, i) => (
    <li key={`${i}+${d}`}>{renderFn ? renderFn(d) : d}</li>
  ));

  if (ordered) {
    return <ol {...props}>{renderItems()}</ol>;
  } else {
    return <ul {...props}>{renderItems()}</ul>;
  }

}
MultiColumnList.propTypes = propTypes;


/**
 * Default size function for accession objects.
 */
export function accObjSizeFn({symbol, name}) {
  return symbol ? symbol.length : name.length;
}

/**
 * Default render function for accession objects.
 */
export function accObjRenderFn({objCls, id, symbol, name}) {
  return (
    objCls ? <Link href={getBaseUrl(id, objCls)}>{symbol || name}</Link> : name
  );
}
