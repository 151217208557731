import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import LayeredIcon from 'components/icons/LayeredIcon';
import DownloadLink from 'components/links/Download';
import useAppContext from 'conf/AppContext';

const propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};
export default function InteractionSubhead({id, type}) {
  const appContext = useAppContext();
  return (
    <div style={{display: 'flex', marginBottom: '1rem'}}>
      <div style={{flex: '1 0 auto'}}>
        <FontAwesomeIcon icon="arrow-right" /> = directional interaction,
        {' '}
        <FontAwesomeIcon icon="arrows-h" /> = non-directional interaction,
        {' '}
        <LayeredIcon bottomIcon="arrows-h" topIcon={['fal', 'times']} /> = does not interact
      </div>
      <div style={{flex: '0 1 auto'}}>
        <DownloadLink href={appContext.apiUrl(`/site/literature/${id}/${type}`)} className="btn btn-outline-secondary">Download</DownloadLink>
      </div>
    </div>
  );
}
InteractionSubhead.propTypes = propTypes;
