// These are equivalent to FileType in the cpic-data repo
/* eslint-disable import/prefer-default-export */
export const FileTypeEnum = {
  ALLELE_DEFINITION: 'Allele Definition Table',
  ALLELE_FUNCTION_REFERENCE: 'Allele Functionality Table',
  ALLELE_SUMMARY: 'Allele Summary',
  DIPLOTYPE_PHENOTYPE: 'Diplotype-Phenotype Table',
  DRUG_RESOURCE: 'Drug Reference',
  DRUG_REVIEW: 'Drug Review',
  FLOWCHART: 'Flow Chart',
  FREQUENCY: 'Frequency Table',
  GENE_CDS: 'Gene Consult',
  GENE_PHENOTYPE: 'Gene Phenotype Table',
  GENE_RESOURCE: 'Gene Resource',
  GUIDELINE: 'Guideline',
  PAIR: 'Pair',
  PHARMVAR: 'PharmVar',
  RECOMMENDATION: 'Recommendation Table',
  TERM: 'Term',
  TEST_ALERT: 'Pre- and Post-Test Alerts',
  PAIR_DIFF: 'CPIC Pair Diff',
};
