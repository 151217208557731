import {forEach} from 'lodash';

/**
 * Phenotype categories, sorted by display order.
 */
const _phenotypeCategories = [
  'Efficacy',
  'Toxicity',
  'Dosage',
  'Metabolism/PK',
  'PD',
  'Other',
];

export function getPhenotypeCategoryChartData(annotations) {

  const entityCounts = {};
  let total = 0;
  forEach(annotations, (ann) => {
    const categories = ann.phenotypeCategories || ann.categories;
    if (!categories) {
      return;
    }

    for (let x = 0; x < categories.length; x += 1) {
      if (entityCounts[categories[x]]) {
        entityCounts[categories[x]] += 1;
      } else {
        entityCounts[categories[x]] = 1;
      }
      total += 1;
    }
  });

  const rez = [];
  for (let x = 0; x < _phenotypeCategories.length; x += 1) {
    const cat = _phenotypeCategories[x];
    rez.push({
      label: cat,
      value: entityCounts[cat] ?? 0,
    });
  }
  return {data: rez, total};
}
