import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {forEach, keys, map, size, toNumber} from 'lodash';
import PropTypes from 'prop-types';

import CuratorOnly from 'components/shared/curator_only';
import App from 'conf/app';

const propTypes = {
  suggestions: PropTypes.arrayOf(PropTypes.string),
  errors: PropTypes.shape({
    allelePhenotypes: PropTypes.arrayOf(PropTypes.shape({
      allele: PropTypes.arrayOf(PropTypes.string),
      phenotype: PropTypes.arrayOf(PropTypes.string),
    })),
    allelePhenotypeCollection: PropTypes.string,
  }),
};

/**
 * A component to display allele phenotype errors and suggestions only when in preview mode
 *
 * @param {object} args - functional args
 * @param {string[]} args.suggestions - an array of suggestion messages
 * @param {object} args.errors - an object of error messages
 * @return {*} a preview-only widget to display with the allele phenotypes, null if not preview
 */
export default function AllelePhenotypeEvaluation({suggestions = [], errors = {}}) {
  if (!App.isPreview) return <div />;

  const {allelePhenotypes: individualErrors = [], allelePhenotypeCollection: collectionErrors} = errors;
  const hasIndividualErrors = individualErrors.length !== 0;
  const hasCollectionErrors = size(collectionErrors) !== 0;

  if (!hasIndividualErrors && !hasCollectionErrors && size(suggestions) === 0) {
    return (
      <div className="alert alert-success mt-1">
        <small className="text-success">curator only</small>
        <div><FontAwesomeIcon icon="check-circle" /> No problems found</div>
      </div>
    );
  } else {
    return (
      <CuratorOnly>
        <section>
          <header className="font-weight-bold">Errors</header>
          {!hasIndividualErrors && !hasCollectionErrors && <small>No errors.</small>}
          {hasCollectionErrors && <p>{collectionErrors}</p>}
          {renderIndividualErrors(individualErrors)}
        </section>
        <section>
          <header>Warnings</header>
          {size(suggestions) === 0 && <small>No warnings</small>}
          <ol>{suggestions.map((s) => <li key={s}>{s}</li>)}</ol>
        </section>
      </CuratorOnly>
    );
  }
}

function renderIndividualErrors(errors) {
  if (size(errors) === 0) {
    return null;
  }

  const messages = [];
  forEach(keys(errors), (k) => {
    const num = toNumber(k) + 1;
    if (errors[k]) {
      if (errors[k].allele) {
        forEach(errors[k].allele, (a) => messages.push(`Allele ${num}: ${a}`));
      }
      if (errors[k].phenotype) {
        forEach(errors[k].phenotype, (p) => messages.push(`Phenotype ${num}: ${p}`));
      }
    }
  });

  return (
    <ul>{map(messages, (m) => <li key={m}>{m}</li>)}</ul>
  );
}

AllelePhenotypeEvaluation.propTypes = propTypes;
