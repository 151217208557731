import {isArray, isString, join, map} from 'lodash';

/**
 * Turns JSON objects into strings for use in the label application diff tool
 *
 * @param {*} val - some JSON element
 * @return {string} a string representation of the element
 */
// eslint-disable-next-line import/prefer-default-export
export function textify(val) {
  if (!val) {
    return '';
  } else if (isString(val)) {
    return val;
  } else if (isArray(val)) {
    return join(map(val, textify), '\n\n');
  } else {
    return JSON.stringify(val, null, 2);
  }
}
