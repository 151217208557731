import {forEach, startsWith} from 'lodash';


/**
 * Extracts data-* and aria-* props.
 *
 * @param {object} otherProps
 * @return {object}
 */
export function extractProps(otherProps) {
  const props = {};
  if (otherProps) {
    forEach(Object.keys(otherProps), (p) => {
      if (startsWith(p, 'data-') || startsWith(p, 'aria-')) {
        props[p] = otherProps[p];
      }
    });
  }
  return props;
}


/**
 * Extracts event handler (on*) props.
 *
 * @param {object} otherProps
 * @return {object}
 */
export function extractEventProps(otherProps) {
  const props = {};
  if (otherProps) {
    forEach(Object.keys(otherProps), (p) => {
      if (p.match(/^on[A-Z][A-Za-z]+$/) || p === 'tabIndex') {
        props[p] = otherProps[p];
      }
    });
  }
  return props;
}


export function extractIconProps({className, size, transform}) {
  const params = {};
  if (className) {
    params.className = className;
  }
  if (size) {
    params.size = size;
  }
  if (transform) {
    params.transform = transform;
  }
  return params;
}
