import {map, words} from 'lodash';
import numeral from 'numeraljs';
import PropTypes from 'prop-types';

import Loading from 'components/Loading';
import AutocompleteResult from 'components/Search/Bar/AutocompleteResult';
import useSearchBar from 'components/Search/Bar/Provider';
import {combinationUrl, hitPropType} from 'components/Search/utils';
import useAppContext from 'conf/AppContext';


const propTypes = {
  /**
   * Top autocomplete results.
   */
  hits: PropTypes.arrayOf(hitPropType),
  /**
   * Total number of potential autocomplete matches.
   */
  total: PropTypes.number,
  /**
   * Index of hit that should be highlighted (because it was selected via keyboard).
   */
  selectedResult: PropTypes.number,
};
/**
 * Renders autocomplete results.
 */
export default function SearchBarAutocomplete({hits, total, selectedResult = -1}) {
  const appContext = useAppContext();
  const searchBar = useSearchBar();

  /**
   * Catch mouse down event to resolve problem on Safari.
   */
  const onShowAllMouseDown = (event) => {
    if (appContext.isSafari) {
      // prevent default so that blur() isn't called (https://stackoverflow.com/a/57630197/1063501)
      event.preventDefault();
    }
  };

  const renderFilteringByType = () => (searchBar.state.typeFilters?.length > 0
    ? <span className="searchBar__autocomplete--filteringByType">filtering by type</span>
    : '');

  const renderShowAll = () => {
    const size = (total === 10000 ? 'over ' : '') + numeral(total).format('0,0');
    const text = `Show all results (${size})`;

    return (
      <div className="searchBar__autocomplete__showAll">
        <a
          href={searchBar.generateSearchUrl(searchBar.state)}
          className="btn btn-primary"
          onMouseDown={onShowAllMouseDown}
        >
          {text}
        </a>
        {renderFilteringByType()}
      </div>
    );
  };

  let body = <div className="searchBar__autocomplete__empty"><Loading /></div>;
  if (hits) {
    if (total === 0) {
      body = (
        <div className="searchBar__autocomplete__empty">
          No results matched your query.
          {renderFilteringByType()}
        </div>
      );
    } else {
      let baseUrl = '';
      if (searchBar.hasPinned()) {
        baseUrl = `${combinationUrl(searchBar.state.pinnedHits)}`;
      }
      const queryWords = words(searchBar.state.query, /\S+/g);
      body = (
        <>
          {map(hits, (h, idx) => (
            <AutocompleteResult
              key={h.id}
              baseUrl={baseUrl}
              hit={h}
              searchWords={queryWords}
              highlighted={idx === selectedResult}
            />
          ))}
          {renderShowAll()}
        </>
      );
    }
  }

  return (
    <div className="searchBar__autocomplete dropdown-menu">
      {body}
    </div>
  );
}
SearchBarAutocomplete.propTypes = propTypes;
