import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import {extractProps} from 'utils/propUtils';


const propTypes = {
  className: PropTypes.string,
};

export default function TrashIcon({className, ...otherProps}) {
  return <FontAwesomeIcon icon={['far', 'trash-can']} className={className} {...extractProps(otherProps)} />;
}
TrashIcon.propTypes = propTypes;
