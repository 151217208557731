import clsx from 'clsx';
import {map} from 'lodash';
import PropTypes from 'prop-types';

import LiteratureXrefs from 'components/Literature/Xrefs';
import {englishJoin} from 'helpers/util';

import './Citation.scss';
import citationData from './Citations.json';


/**
 * @property {string} NONE
 * @property {string} FIRST_ONLY
 * @property {string} ALL
 */
export const AuthorStyle = {
  NONE: 'none',
  FIRST_ONLY: 'firstOnly',
  ALL: 'all',
};


/**
 * Regular expression used to check if literature title ends with punctuation.
 *
 * @type {RegExp}
 */
const endsWithPunctRE = /[!.?]$/;

/**
 * Render title.
 *
 * @param {string} [id] - optional ID of literature, generates link if provided
 * @param {string} name
 * @param {boolean} italicize - true if title should be italicized
 * @param {string} type - literature type
 * @return {*} React element
 */
export const renderTitle = (id, name, italicize = false, type) => {
  let punct = type === 'Literature' && '.';
  let title = name;
  const match = endsWithPunctRE.exec(title);
  if (match) {
    title = title.substring(0, title.length - 1);
    punct = match[0];
  }
  if (id) {
    return <span className={clsx({'literatureCitation__title--italicize': italicize})}><a href={`/literature/${id}`}>{title}</a>{punct} </span>;
  } else {
    return <span className={clsx({'literatureCitation__title--italicize': italicize})}> {title}{punct}</span>;
  }
};

/**
 * Render journal.
 *
 * @param {string} [name]
 * @return {*} React element
 */
const renderJournal = (name) => {
  if (!name) {
    return null;
  }
  let journal = name;
  if (!endsWithPunctRE.test(journal)) {
    journal += '.';
  }
  return <span className="journal-name">{journal} </span>;
};

/**
 * Render year.
 *
 * @param {number|string} [year]
 * @return {*} React element
 */
const renderYear = (year) => year && year !== -1 && `${year}. `;

/**
 * Render journal.
 *
 * @param {Array} [authors]
 * @param {boolean} [firstOnly]
 * @return {*} React element
 */
export const renderAuthors = (authors, firstOnly = false) => {
  if (!authors || authors.length === 0) {
    return null;
  }
  let aus;
  if (authors.length === 1) {
    aus = `${authors[0]}.`;
  } else if (firstOnly) {
    aus = `${authors[0]} et al.`;
  } else {
    aus = `${englishJoin(authors, 'and')}.`;
  }
  return <span>{aus} </span>;
};

/**
 * Renders journal, year and authors.
 *
 * @param {string} [journal]
 * @param {number|string} [year]
 * @param {Array} [authors]
 * @param {boolean} [firstAuthorOnly]
 * @return {*} React element
 */
export const renderMetadata = (journal, year, authors, firstAuthorOnly = false) =>
  (
    <>
      {renderJournal(journal)}
      {renderYear(year)}
      {renderAuthors(authors, firstAuthorOnly)}
    </>
  );

/**
 * Source-agnostic literature metadata renderer.
 *
 * @param {object} lit - literature object from either db or index
 * @param {string} [authorStyle]
 */
export function renderLitMetadata(lit, authorStyle = AuthorStyle.NONE) {
  let journal;
  let year;
  let authors;
  let firstAuthorOnly = false;
  if (lit.properties) {
    journal = lit.properties.journal;
    year = lit.properties.year;
    if (authorStyle !== AuthorStyle.NONE) {
      authors = lit.properties.authors;
      firstAuthorOnly = authorStyle === AuthorStyle.FIRST_ONLY;
    }
  } else {
    journal = lit.journal;
    year = lit.year;
    if (authorStyle !== AuthorStyle.NONE) {
      authors = lit.authors;
      firstAuthorOnly = authorStyle === AuthorStyle.FIRST_ONLY;
    }
  }
  return renderMetadata(journal, year, authors, firstAuthorOnly);
}


export const getDefaultCitation = () => (
  <>
    {map(citationData.citations, (c) => <LiteratureCitation key={c.id} className="mb-2" {...c} />)}
  </>
);


const propTypes = {
  id: PropTypes.number,
  title: PropTypes.string.isRequired,
  journal: PropTypes.string,
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  authors: PropTypes.arrayOf(PropTypes.string),
  crossReferences: PropTypes.arrayOf(PropTypes.shape({
    resourceId: PropTypes.string.isRequired,
    resource: PropTypes.string.isRequired,
    _url: PropTypes.string.isRequired,
  })).isRequired,
  italicizeTitle: PropTypes.bool,
  type: PropTypes.string,
  /**
   * Determines if metadata (journal, year, authors) should be hidden.
   */
  hideMetadata: PropTypes.bool,
  firstAuthorOnly: PropTypes.bool,
  /**
   * Determines if external links should be hidden.
   */
  hideLinks: PropTypes.bool,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
};
/**
 * Renders an internal literature citation.
 */
export default function LiteratureCitation({id, title, journal = '', year = '', authors = [], crossReferences = [],
  italicizeTitle = false, type = 'Literature', hideMetadata = false, firstAuthorOnly = false, hideLinks = false,
  className = []}) {

  if (!id) {
    return null;
  }

  return (
    <div className={clsx('literatureCitation', className)}>
      {renderTitle(id, title, italicizeTitle, type)}
      {hideMetadata ? '' : renderMetadata(journal, year, authors, firstAuthorOnly)}
      {hideLinks ? '' : <small><LiteratureXrefs xrefs={crossReferences} /></small>}
    </div>
  );
}
LiteratureCitation.propTypes = propTypes;
