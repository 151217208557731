import clsx from 'clsx';
import {forEach, lowerCase, map, upperCase} from 'lodash';
import PropTypes from 'prop-types';
import {useMemo} from 'react';

import BarChart from 'components/Chart/LazyBar';
import Loading from 'components/Loading';
import {accessionIdProps} from 'conf/types';

/**
 * Sort order for sources in the bar chart
 */
const GuidelineSourceOrder = {
  cpic: 1,
  dpwg: 2,
  other: 3,
};

/**
 * Colors in the bar chart
 */
const GuidelineSourceColor = {
  cpic: '#B82C4C',
  dpwg: '#FF9E00',
  other: '#1565C0',
};

const propTypes = {
  guidelineSources: PropTypes.arrayOf(PropTypes.shape({
    drug: accessionIdProps,
  })),
  filterChangeFn: PropTypes.func,
  className: PropTypes.string,
};
/**
 * Renders guideline annotations bar chart.
 *
 * @param {object} props - props container
 * @param {Array} [props.guidelineSources]
 * @param {Function} [props.filterChangeFn]
 * @param {string} [props.className]
 * @return {JSX.Element | null}
 */
export default function GuidelineAnnotationsBarChart({guidelineSources, filterChangeFn, className}) {

  const dependencyKey = map(guidelineSources, ({drug}) => drug.id).join(',');

  const chartData = useMemo(() => {
    if (guidelineSources) {
      return createSourceChartData(guidelineSources);
    } else {
      return null;
    }
  }, [dependencyKey]);
  
  if (!chartData) {
    return (
      <div className="gaFigure gaFigure--loading figure figure--floatRight">
        <Loading />
      </div>
    );
  }

  const handleClick = (label) => {
    const formattedLabel = lowerCase(label);
    if (filterChangeFn) {
      filterChangeFn(formattedLabel);
    }
  };

  return (
    <figure key={dependencyKey} className={clsx('gaFigure', className)}>
      <BarChart
        data={chartData}
        stacked={true}
        horizontal={true}
        clickHandler={handleClick}
      />
      <figcaption className="text-center text-muted">
        Count of annotations by source.<br />
        Gray represents annotations without recommendation.
      </figcaption>
    </figure>
  );

}
GuidelineAnnotationsBarChart.propTypes = propTypes;

function createSourceChartData(drugs) {

  const labels = [];
  const recommendationData = {
    label: 'Recommendation',
    data: [],
    backgroundColor: [],
  };
  const noRecommendationData = {
    label: 'No recommendation',
    data: [],
    backgroundColor: [],
  };

  forEach(GuidelineSourceOrder, (value, key) => {
    let recommendation = 0;
    let noRecommendation = 0;

    for (let x = 0; x < drugs.length; x += 1) {
      const drug = drugs[x];
      const source = drug[key];

      for (let i = 0; i < source.length; i += 1) {
        if (source[i].recommendation) {
          recommendation += 1;
        } else {
          noRecommendation += 1;
        }
      }
    }

    labels.push(upperCase(key));

    recommendationData.data.push(recommendation);
    recommendationData.backgroundColor.push(GuidelineSourceColor[key]);

    noRecommendationData.data.push(noRecommendation);
    // using $grey30 from _variables.scss
    noRecommendationData.backgroundColor.push('#C5CAD2');
  });

  return {labels, datasets: [recommendationData, noRecommendationData]};
}
