import PropTypes from 'prop-types';

import './index.scss';
import Loading from 'components/Loading';
import KyError from 'components/errors/KyError';
import NotFound from 'components/shared/error/not_found';
import ReadableWidthResource from 'components/templates/ReadableWidthResource';
import useAppContext from 'conf/AppContext';
import {useGet} from 'helpers/KyHooks';
import LabelAnnotationDetail from 'pages/LabelAnnotation/Details';

const propTypes = {
  id: PropTypes.string.isRequired,
  loadId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
/**
 * Detail page for a drug label annotation
 *
 * @param {object} props - props container
 * @param {string} props.id - label annotation ID
 * @param {string|number} props.loadId - used to force re-fetch of the data
 */
export default function LabelAnnotationPage({id, loadId = 0}) {
  const appContext = useAppContext();
  const {response, error} = useGet(`site/labelAnnotation/${id}`, {
    cacheResponse: !appContext.isPreview,
    searchParams: {view: 'max'},
  }, {
    loadId,
  });

  let name = `Label Annotation ${id}`;
  let content = <Loading />;
  if (error) {
    if (error?.response?.status === 404) {
      return <NotFound />;
    }
    content = <KyError kyError={error} />;
  } else if (response) {
    const {labelAnnotation} = response.data;
    name = labelAnnotation?.name;
    content = <LabelAnnotationDetail id={id} data={response?.data} />;
  }
  return (
    <ReadableWidthResource
      id={id}
      type="labelAnnotation"
      name={name}
      title={name}
    >
      {content}
    </ReadableWidthResource>
  );
}
LabelAnnotationPage.propTypes = propTypes;
