import clsx from 'clsx';
import {filter, groupBy} from 'lodash';
import {useState} from 'react';

import Loading from 'components/Loading';
import InlinePage from 'components/Page/Inline';
import Section from 'components/Section';
import KyError from 'components/errors/KyError';
import Link from 'components/links/Link';
import FullFluidWidthTemplate from 'components/templates/FullFluidWidth';
import {useGet} from 'helpers/KyHooks';
import FdaPgxAssociationTable from 'pages/FdaPgxAssociations/table';

export default function FdaPgxAssociationTablePage({}) {
  const {response, error} = useGet('site/fdaPgxAssociation', {cacheResponse: true});
  const [onlyCurrent, setOnlyCurrent] = useState(true);

  const handleToggle = (val) => () => {
    setOnlyCurrent(val);
  };

  let body = <Loading />;
  if (error) {
    body = <KyError kyError={error} />;
  } else if (response) {
    const {associations, tableTypes} = response.data;
    const tables = groupBy(associations, (a) => a.subsection);

    body = (
      <div className="fdaSectionContainer">
        <div className="container">
          <InlinePage id="fdaPgxAssocHeader" />

          <p>
            <Link href="https://www.fda.gov/medical-devices/precision-medicine/table-pharmacogenetic-associations">Original source at FDA.</Link>
          </p>

          {filter(associations, (a) => a.removed)?.length > 0 && (
            <div className="mb-4">
              <div className="btn-group" role="group">
                <button className={clsx('btn', {'btn-primary': onlyCurrent}, {'btn-outline-primary': !onlyCurrent})} type="button" onClick={handleToggle(true)}>Only Current</button>
                <button className={clsx('btn', {'btn-primary': !onlyCurrent}, {'btn-outline-primary': onlyCurrent})} type="button" onClick={handleToggle(false)}>Current + Removed</button>
              </div>
            </div>
          )}

          <div className="mb-4">
            Sections
            <ul>
              {tableTypes.map((t, i) => (
                <li key={t}><a href={`#table${i}`}>{t}</a> (n={tables[t]?.length})</li>
              ))}
            </ul>
          </div>
        </div>

        {tableTypes.map((t, i) => (
          <Section key={t} title={t} className="mt-4" id={`table${i}`}>
            <FdaPgxAssociationTable associations={tables[t]} onlyCurrent={onlyCurrent} scrollTo={window.location.hash} />
          </Section>
        ))}
      </div>
    );
  }

  return (
    <FullFluidWidthTemplate title="FDA Table of Pharmacogenetic Associations">
      {body}
    </FullFluidWidthTemplate>
  );
}
