import Loading from 'components/Loading';
import InlinePage from 'components/Page/Inline';
import VariantAnnotationTable from 'components/VariantAnnotation/Table';
import KyError from 'components/errors/KyError';
import AlleleNote from 'components/shared/AlleleNote';
import FullFluidWidthTemplate from 'components/templates/FullFluidWidth';
import usePediatricContext from 'context/Pediatric';
import {useGet} from 'helpers/KyHooks';


export default function VariantAnnotationsPage() {
  const pediatricContext = usePediatricContext();

  const {response, error} = useGet('site/variantAnnotations/_example', {
    searchParams: {
      pediatric: pediatricContext.isPediatricMode,
    },
    cacheResponse: true,
  });

  let body = <Loading />;
  if (error) {
    body = <KyError kyError={error} />;
  } else if (response) {
    body = <VariantAnnotationTable data={response?.data} />;
  }

  return (
    <FullFluidWidthTemplate title="Variant Annotations">
      <div className="container mb-5">
        <div className="readable-width">
          <InlinePage id="variantAnnotationsHeader" />
          <AlleleNote />
        </div>
      </div>
      {body}
    </FullFluidWidthTemplate>
  );
}
