import clsx from 'clsx';
import PropTypes from 'prop-types';

import './index.scss';
import EditControls from 'components/edit/EditControls';
import InfoLink from 'components/links/Info';


const propTypes = {
  /**
   * Unique ID for HTML element.
   */
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  helpLink: PropTypes.string,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  /**
   * Controls will be added to EditControls.
   */
  editControls: PropTypes.node,
  controlsInline: PropTypes.bool,
  children: PropTypes.node,
};
/**
 * Renders a section of content.
 * The main goal for this component is to handle rendering of the title with associated help link and edit controls.
 */
export default function Section({id, title, helpLink, className, titleClassName, editControls, controlsInline = false,
  children}) {

  let controls = null;
  if (editControls) {
    controls = (
      <EditControls
        className={clsx({
          section__editControls: !controlsInline,
          'editControls--inline': controlsInline,
        })}
      >
        {editControls}
      </EditControls>
    );
  }

  return (
    <div id={id} className={clsx('section', className)}>
      <h3 className={titleClassName}>{title} {renderHelpLink(helpLink)} {controlsInline && controls}</h3>
      {!controlsInline && controls}
      {children}
    </div>
  );
}
Section.propTypes = propTypes;


function renderHelpLink(helpLink) {
  if (helpLink) {
    return (
      <InfoLink tooltip="More information" href={helpLink} iconClassName="icon--superscript" iconSize="xs" />
    );
  }
  return null;
}
