import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import App from 'conf/app';
import Loading from 'components/Loading';

/**
 * A safe way of adding arbitrary HTML into the DOM.
 *
 * @class
 */
export default class HtmlContainer extends React.Component {
  static displayName = 'HTML Container';

  static propTypes = {
    html: PropTypes.string.isRequired,
    /** Classes to apply to container. */
    className: PropTypes.string,
    /** Determines if container should be inlined (i.e. <span>) or not (i.e. <div). */
    inline: PropTypes.bool,
    reducedHeaderSize: PropTypes.bool,
    /**
     * {function} an optional click handler
     */
    onClick: PropTypes.func,
    style: PropTypes.object,
  };

  static defaultProps = {
    className: '',
    inline: false,
    reducedHeaderSize: true,
    onClick: null,
    style: {},
  };

  componentDidMount = () => {
    // eslint-disable-next-line no-undef
    if (window && window.location.hash) {
      try {
        // eslint-disable-next-line no-undef
        const targetElement = document.querySelector(window.location.hash);
        if (targetElement) {
          targetElement.scrollIntoView();
        }
      } catch (e) {
        // eslint-disable-next-line no-undef
        App.log.info(`Using a bad query selector ${window.location.hash}`);
      }
    }
  };

  render = () => {
    const compConfig = {
      className: clsx(
        'html-container',
        this.props.className,
        {'html-container--reduced-header-size': this.props.reducedHeaderSize},
      ),
      dangerouslySetInnerHTML: {__html: this.props.html},
      onClick: this.props.onClick,
      style: this.props.style,
    };

    return this.props.inline ? <span {...compConfig} /> : <div {...compConfig} />;
  };
}


/**
 * Adds HTML in an HtmlContainer, unless html is falsy, in which case displays a loading indicator.
 *
 * @param {string} html - HTML to add
 * @param {object} [props] - config properties
 * @param {string} [props.className] - classes to apply to container
 * @param {boolean} [props.inline] - true if HTML should be in an inline container
 * @param {boolean} [props.reducedHeaderSize]
 * @return {*}
 */
export function addHtmlContainerWhenLoaded(html, props = {}) {
  return html ? <HtmlContainer html={html} {...props} /> : <Loading />;
}

/**
 * Adds HTML in an HtmlContainer, unless html is falsy.
 *
 * @param {string} html - HTML to add
 * @param {object} [props] - config properties
 * @param {string} [props.className] - classes to apply to container
 * @param {boolean} [props.inline] - true if HTML should be in an inline container
 * @param {boolean} [props.reducedHeaderSize]
 * @return {*}
 */
export function addHtmlContainerIfDefined(html, props = {}) {
  return html ? <HtmlContainer html={html} {...props} /> : '';
}
