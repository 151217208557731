import {map} from 'lodash';
import {useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';

import DiffJson from 'components/Diff/Json';
import FormCombobox from 'components/form/Combobox';
import FormSubmit from 'components/form/Submit';
import useAppContext from 'conf/AppContext';

export default function FdaBiomarkerDiffForm({options}) {
  const appContext = useAppContext();
  const formMethods = useForm({
    defaultValues: {
      lVersion: {value: options[1][0], label: options[1][1]},
      rVersion: {value: options[0][0], label: options[0][1]},
    },
  });
  const [content, setContent] = useState(undefined);

  const optionArray = map(options, (o) => ({label: o[1], value: o[0]}));

  const {handleSubmit: rhfHandleSubmit} = formMethods;

  const handleSubmit = async (data) => {
    try {
      const response = await appContext.api.post('curation/fdaBiomarker/diff', {json: data, parseJson: true});

      const lBiomarkers = response.data[0].biomarkers;
      const rBiomarkers = response.data[1].biomarkers;

      setContent(<DiffJson oldObj={lBiomarkers} newObj={rBiomarkers} />);
    } catch (err) {
      appContext.toastError(err);
    }
  };

  return (
    <div>
      <FormProvider {...formMethods}>
        <form onSubmit={rhfHandleSubmit(handleSubmit)}>
          <div className="row">
            <div className="col">
              <FormCombobox name="lVersion" label="Compare Version" options={optionArray} />
            </div>
            <div className="col">
              <FormCombobox name="rVersion" label="To Version" options={optionArray} />
            </div>
          </div>
          <FormSubmit>Compare</FormSubmit>
        </form>
      </FormProvider>
      <div>
        {content}
      </div>
    </div>
  );
}
