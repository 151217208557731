import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';

import ApproveButton from 'components/Button/Approve';
import EditButton from 'components/Button/Edit';
import EditControls from 'components/edit/EditControls';
import EditFrame from 'components/edit/Frame';
import DownloadLinkForm, {SURVEY_COOKIE_NAME} from 'components/links/Download/Form';
import HtmlContainer from 'components/shared/html_container';
import useAppContext from 'conf/AppContext';
import logger from 'conf/Logger';
import {readCookie} from 'helpers/cookies';

const _surveyUrlPattern = /^https:\/\/.+pharmgkb\.org(?::[0-9+])?\/v1(?:\/preview)?\/download\/.+$/;


const propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  html: PropTypes.string,
  /**
   * True if page content is used in full Page, false if in InlinePage.
   * Determines if EditFrame is used.
   */
  fullPage: PropTypes.bool,
  showTitle: PropTypes.bool,
  reducedHeaderSize: PropTypes.bool,
  className: PropTypes.string,
  editHint: PropTypes.string,
  extraControls: PropTypes.node,
};

export default function PageContent({id, title, html, fullPage = false, showTitle = false, reducedHeaderSize = false,
  className = '', editHint, extraControls}) {
  const appContext = useAppContext();
  const [survey, setSurvey] = useState({url: null, open: false});
  const [needsApproval, setNeedsApproval] = useState(false);

  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      try {
        const response = await appContext.api.get(`curation/approve/page/${id}`, {parseJson: true});
        if (mounted) {
          setNeedsApproval(response?.data?.[0] === id);
        }
      } catch (error) {
        logger.error(error);
      }
    };
    if (appContext.isPreview) {
      // noinspection JSIgnoredPromiseFromCall
      loadData();
    }
    return () => {
      mounted = false;
    };
  }, []);

  const approveSuccessHandler = (key) => {
    if (key === id) {
      toast.success('Approve succeeded.');
      setNeedsApproval(false);
    }
  };

  const enableSurvey = !readCookie(SURVEY_COOKIE_NAME);

  // looks for clicks on download links to display download survey
  const handleClick = (event) => {
    const {localName, href} = event.target;
    if (localName === 'a') {
      const match = _surveyUrlPattern.exec(href);
      if (match !== null) {
        if (!readCookie(SURVEY_COOKIE_NAME)) {
          event.stopPropagation();
          event.preventDefault();
          setSurvey({url: href, open: true});
        } else {
          setSurvey({url: null, open: false});
        }
      }
    }
  };

  let body;
  if (!html) {
    body = <em className="text-muted">No content.</em>;
  } else {
    body = (
      <HtmlContainer
        html={html}
        reducedHeaderSize={reducedHeaderSize}
        onClick={enableSurvey ? handleClick : null}
      />
    );
  }

  const hideSurvey = () => setSurvey({url: null, open: false});
  const controls = (
    <>
      <EditButton href={`/edit/page/${id}`} label={editHint ? `Edit ${editHint}` : 'Edit'} />
      <ApproveButton
        objCls="Page"
        objId={id}
        disabled={!needsApproval}
        label={editHint ? `Approve ${editHint}` : 'Approve'}
        onSuccess={approveSuccessHandler}
      />
      {extraControls}
    </>
  );
  const content = (
    <>
      {showTitle && <h1>{title || id}</h1>}
      {body}
      {survey.open ? <DownloadLinkForm url={survey.url} onSurveyClose={hideSurvey} /> : ''}
    </>
  );

  if (fullPage) {
    return (
      <div className={className}>
        <EditControls>
          {controls}
        </EditControls>
        {content}
      </div>
    );
  } else {
    return (
      <div className={className}>
        <EditFrame controls={controls}>
          {content}
        </EditFrame>
      </div>
    );
  }
}
PageContent.propTypes = propTypes;
