import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';

import ApproveButton from 'components/Button/Approve';
import DiffButton from 'components/Button/Diff';


const propTypes = {
  /**
   * Class of object to approve.
   */
  objCls: PropTypes.string.isRequired,
  /**
   * ID of object to approve.
   */
  objId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /**
   * Determines if button is icon-only.
   * This will render a frame-less button.
   */
  iconOnly: PropTypes.bool,
  approved: PropTypes.bool,
  published: PropTypes.bool,
};


export default function ApproveAndDiffButtons({objCls, objId, iconOnly = true, approved, published = true}) {
  // manage diff button's disabled state, approve button can take care of itself
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setDisabled(approved || !published);
  }, [approved, published]);


  const approveSuccessHandler = () => {
    setDisabled(true);
    toast.success('Approve succeeded.');
  };

  return (
    <>
      <ApproveButton
        objCls={objCls}
        objId={objId}
        iconOnly={iconOnly}
        disabled={approved}
        onSuccess={approveSuccessHandler}
      />
      <DiffButton
        objCls={objCls}
        objId={objId}
        iconOnly={iconOnly}
        disabled={disabled}
      />
    </>
  );
}
ApproveAndDiffButtons.propTypes = propTypes;
