import {map} from 'lodash';
import PropTypes from 'prop-types';

import Link from 'components/links/Link';
import QuestionSection from 'components/templates/Outreach/QuestionSection';

const propTypes = {
  text: PropTypes.node,
  sections: PropTypes.arrayOf(PropTypes.object),
  interested: PropTypes.arrayOf(PropTypes.object),
};
/**
 * Renders an outreach detail.  One section can have many details.
 */
export default function Details({text, sections, interested}) {

  const renderFurtherReading = (link) => (
    <li key={link.url}>
      {link.type ? `${link.type}: ` : ''}
      <Link newTab={true} href={link.url}>
        {link.text}
      </Link>
      {link.by && <>{' '}by {link.by}</>}
    </li>
  );

  const hasInterested = () => (
    interested
      ? (
        <div className="further_interest">
          <h4>Interested in learning more?</h4>
          <ul>
            {map(interested, renderFurtherReading)}
          </ul>
        </div>
      )
      : ''
  );

  const nestedCollapse = sections
    ? (map(sections, (section, key) =>
      (
        <QuestionSection
          key={key}
          sections={section.sections}
          title={section.title}
          text={section.text}
        />
      ),
    ))
    : '';

  return (
    <div>
      {text ?? ''}

      {nestedCollapse}

      {hasInterested()}
    </div>
  );
}
Details.propTypes = propTypes;
