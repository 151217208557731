import clsx from 'clsx';

import Tag from 'components/Tag/index';

import './PrescribingDrug.scss';

function renderTag(label, className, tooltipText, tooltipUrl) {
  return (
    <Tag
      className={clsx('tag--xs', 'prescribingDrug', className)}
      tooltipText={tooltipText}
      tooltipUrl={tooltipUrl}
    >
      {label}
    </Tag>
  );
}
export function PrescribingDrugGuidelineTag({}) {
  return renderTag(
    'Guideline',
    'prescribingDrug__guideline',
    'Learn more about Clinical Guideline Annotation',
    '/guidelineAnnotations');
}
export function PrescribingDrugLabelTag({}) {
  return renderTag(
    'Label',
    'prescribingDrug__label',
    'Learn more about Drug Label Annotations',
    '/labelAnnotations');
}
export function PrescribingDrugPgxAssocTag({}) {
  return renderTag(
    'PGx Assoc',
    'prescribingDrug__pgxAssoc',
    'Learn more about FDA PGx Associations',
    '/fdaPgxAssociations');
}
