import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {map} from 'lodash';
import PropTypes from 'prop-types';
import {useEffect, useRef, useState} from 'react';
import AnimateHeight from 'react-animate-height';

import './Section.scss';
import Button, {isIgnorableKeydown} from 'components/Button';
import {renderIcon} from 'components/SideNav/Menu';
import Details from 'components/templates/Outreach/Details';


const propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.node.isRequired,
  details: PropTypes.arrayOf(PropTypes.object),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
  canCollapse: PropTypes.bool,
  initialIsCollapsed: PropTypes.bool,
};
/**
 * Renders main text, then renders details in optional collapsible section.
 */
export default function OutreachSection({title, text, details = [], icon, canCollapse = true,
  initialIsCollapsed = true}) {
  const [isOpen, setOpen] = useState(initialIsCollapsed);

  const componentIsMounted = useRef(true);
  // eslint-disable-next-line
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const actionHandler = (event) => {
    if (isIgnorableKeydown(event)) {
      return;
    }
    if (componentIsMounted.current) {
      setOpen(!isOpen);
    }
  };
  

  const renderDetails = () => {
    if (details.length === 0) {
      return '';
    }

    const moreButton = canCollapse
      ? (
        <p>
          <Button actionHandler={actionHandler} className="btn-link">
            {isOpen ? <FontAwesomeIcon icon="plus" /> : <FontAwesomeIcon icon="minus" />}
            <span className="sr-only">Show </span>{isOpen ? 'More' : 'Less'}
          </Button>
        </p>
      )
      : '';

    return (
      <>
        {moreButton}
        <AnimateHeight
          height={(canCollapse && isOpen) ? 0 : 'auto'}
          duration={500}
          className={clsx('outreachContent__subsections', {
            'outreachContent__subsections--no-collapse-toggle': !canCollapse,
          })}
        >
          {
            map(details, (detail, key) =>
              (
                <Details
                  key={key}
                  text={detail.text}
                  sections={detail.sections}
                  className={detail.class}
                  interested={detail.interested}
                />
              ),
            )
          }
        </AnimateHeight>
      </>
    );
  };

  return (
    <div className="outreachSection">
      <div className="outreachContent readable-width">
        <h3 className="outreachContent__title">
          {renderIcon(icon, 'd-lg-none d-md-none outreachContent__icon')}
          {title}
        </h3>
        <div className="outreachContent__text">
          { text }
        </div>

        {renderDetails()}
      </div>
    </div>
  );
}
OutreachSection.propTypes = propTypes;
