import clsx from 'clsx';
import {debounce, isFunction} from 'lodash';
import PropTypes from 'prop-types';
import {useCallback, useEffect, useState} from 'react';

import './Text.scss';


const propTypes = {
  /**
   * Column props passed by react-table.
   */
  column: PropTypes.shape({
    filterValue: PropTypes.string,
    preFilteredRows: PropTypes.arrayOf(PropTypes.object),
    setFilter: PropTypes.func,
    /**
     * Unique id of column
     */
    id: PropTypes.string,
    /**
     * Column header.
     */
    Header: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node]),
    /**
     * CSS classes to be added to the filter cell.
     */
    filterClassName: PropTypes.string,
  }).isRequired,
  /**
   * Controls whether placeholder is shown (will list number of rows).
   */
  showPlaceholder: PropTypes.bool,
  /**
   * Label - used for screen reader-only label and placeholder.
   * If unspecified, screen reader-only label defaults to `Header` value.
   */
  label: PropTypes.string,
};
/**
 * Renders a text input.
 */
export default function TextFilter({
  column: {id, Header, filterValue = '', preFilteredRows, setFilter, filterClassName},
  label = 'rows',
  showPlaceholder = false,
}) {
  // store the input value in state so we can debounce the filter updates
  // if we use debounce directly the input[text] will not sync up
  const [value, setValue] = useState(filterValue || '');

  useEffect(() => {
    // handle case where filter gets zeroed out due to filters getting reset
    if (!filterValue) {
      setValue('');
    } else {
      setValue(filterValue);
    }
  }, [filterValue]);

  // noinspection JSCheckFunctionSignatures
  const debouncedFilterValueUpdate = useCallback(debounce((inputValue) => {
    setFilter(inputValue);
  }, 200), [setFilter]);

  const changeHandler = (event) => {
    setValue(event.target.value);
    debouncedFilterValueUpdate(event.target.value);
  };
  const placeholder = showPlaceholder ? `Search ${preFilteredRows.length} ${label}` : undefined;
  let ariaLabel;
  if (label && label !== 'rows') {
    ariaLabel = `Filter ${label}`;
  } else if (isFunction(Header)) {
    ariaLabel = `Filter ${Header()}`;
  } else {
    ariaLabel = `Filter ${Header}`;
  }

  return (
    <label className={clsx('textFilter', filterClassName)}>
      <input
        type="text"
        name={id}
        value={value}
        onChange={changeHandler}
        className={clsx({filterSet: filterValue})}
        placeholder={placeholder}
      />
      <span className="sr-only">{ariaLabel}</span>
    </label>
  );
}
TextFilter.propTypes = propTypes;
