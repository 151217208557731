import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useEffect, useRef, useState} from 'react';
import AnimateHeight from 'react-animate-height';

import {isIgnorableKeydown} from 'components/Button';


const propTypes = {
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
export default function Question({title, visible = false, className, children}) {
  const [isOpen, setOpen] = useState(visible);

  const componentIsMounted = useRef(true);
  // eslint-disable-next-line
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const actionHandler = (event) => {
    if (isIgnorableKeydown(event)) {
      return;
    }
    if (componentIsMounted.current) {
      setOpen(!isOpen);
    }
  };

  const height = isOpen ? 'auto' : 0;
  const classes = clsx('question__title', className);

  return (
    <div className="question">
      <div role="button" className={classes} tabIndex={0} onKeyPress={actionHandler} onClick={actionHandler}>
        <div className="question__title__icon">
          <FontAwesomeIcon icon="question" mask="comment" size="2x" transform="up-.5 shrink-8" />
        </div>
        <div className="question__title__text">
          <h5>{title}</h5>
        </div>
        <div className="question__title__control">
          <FontAwesomeIcon icon={isOpen ? 'chevron-up' : 'chevron-down'} aria-hidden={true} />
          <span className="sr-only">See Answer</span>
        </div>
      </div>

      <AnimateHeight height={height} duration={500}>
        {children}
      </AnimateHeight>
    </div>
  );
}
Question.propTypes = propTypes;
