import PropTypes from 'prop-types';
import clsx from 'clsx';

import LoadingIcon from 'components/icons/Loading';

import './index.scss';


const propTypes = {
  /**
   * Determines if only icon should be shown.
   */
  iconOnly: PropTypes.bool,
  /**
   * Text to display.
   * Ignored if `iconOnly` is true.
   */
  text: PropTypes.string,
};
/**
 * This renders a loading indicator.
 */
export default function Loading({iconOnly = false, text = 'Loading...'}) {
  return (
    <div className="loading" role="status">
      <LoadingIcon />
      <span className={clsx({'sr-only': iconOnly})}>{text}</span>
    </div>
  );
}
Loading.propTypes = propTypes;
