import PropTypes from 'prop-types';
import {isArray, join, map} from 'lodash';
import {matchSorter} from 'match-sorter';

import {getBaseUrl} from 'conf/types';
import {renderLitXrefLink, resourceLabel} from 'components/links/LitXref';
import TextFilter from 'components/Table/Filter/Text';


/**
 * Typical defaults for a Literature column.
 */
export const baseLiteratureColumn = {
  Cell: LiteratureCell,
  Filter: TextFilter,
  filter: literatureFilter,
  getCellExportValue: literatureExportValue,
};


const propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
};
/**
 * Renders a table cell containing literature.
 *
 * To filter, use `{Filter: TextFilter, filter: literatureFilter}`.
 *
 * To export, use `{getCellExportValue: literatureExportValue}`.
 *
 * Sample column:
 * ```
 * {
 *   id: 'pmid',
 *   Header: 'Literature',
 *   accessor: (originalRow) => {
 *     if (originalRow?.properties?.literature) {
 *       return originalRow.properties.literature;
 *     }
 *     return '';
 *   },
 *   Cell: LiteratureCell,
 *   Filter: TextFilter,
 *   filter: literatureFilter,
 *   getCellExportValue: literatureExportValue,
 *   ...flexColumnStyle({width: 180}),
 * },
 * ```
 */
export default function LiteratureCell({value}) {
  if (!value) {
    return '';
  }

  if (isArray(value)) {
    return map(value, (l) => renderLit(l));
  }
  return renderLit(value);
}
LiteratureCell.propTypes = propTypes;


function renderLit(lit) {
  const xref = lit.crossReferences[0];
  return renderLitXrefLink(resourceLabel(xref), xref.resourceId, getBaseUrl(lit.id, lit.objCls), lit.title);
}

/**
 * React-table `filter` for literature.
 *
 * @param {Array} rows
 * @param {Array} columnIds
 * @param {Array|string} filterValue
 * @return {Array}
 */
export function literatureFilter(rows, columnIds, filterValue) {
  if (!filterValue) {
    return rows;
  }
  const id = columnIds[0];
  return matchSorter(rows, filterValue, {
    keys: [
      (row) => {
        const data = row.values[id];
        if (isArray(data)) {
          return map(data, (d) => d?.crossReferences?.[0]?.resourceId);
        } else {
          return data?.crossReferences?.[0]?.resourceId;
        }
      },
    ],
    threshold: matchSorter.rankings.STARTS_WITH,
  });
}


/**
 * React-table `getCellExportValue` for Literature.
 *
 * @param {object} row
 * @param {object} column
 * @return {string}
 */
export function literatureExportValue(row, column) {
  const data = row.values[column.id];
  if (isArray(data)) {
    return join(map(data, getXrefString), ', ');
  } else {
    return getXrefString(data);
  }
}

function getXrefString(data) {
  const xref = data?.crossReferences?.[0];
  if (xref) {
    return resourceLabel(xref) + xref.resourceId;
  }
  return '';
}
