import {useEffect, useState} from 'react';

import Loading from 'components/Loading';
import ReadableWidthTemplate from 'components/templates/ReadableWidth';
import useAppContext from 'conf/AppContext';
import logger from 'conf/Logger';


export default function ResetPage() {
  const appContext = useAppContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true;
    async function clearCache() {
      try {
        await appContext.cache.clear();
        if (mounted) {
          setLoading(false);
        }
      } catch (error) {
        logger.warn(error);
      }
    }
    // noinspection JSIgnoredPromiseFromCall
    clearCache();

    return () => {
      mounted = false;
    };
  }, []);


  const body = loading
    ? <Loading text="Clearing Cache..." />
    : <p>Cache has been cleared.</p>;

  return (
    <ReadableWidthTemplate title="Reset">
      {body}
    </ReadableWidthTemplate>
  );
}
