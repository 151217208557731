import PropTypes from 'prop-types';

import Tag from 'components/Tag/index';

/**
 * Limited evidence tag that is used in the context of clinical annotations
 */
export default function LimitedEvidenceTag({className}) {
  return (
    <Tag
      className={className}
    >
      Limited Evidence
    </Tag>
  );
}
LimitedEvidenceTag.propTypes = {
  className: PropTypes.string,
};
