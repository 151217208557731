import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import Downshift from 'downshift';
import {
  compact, filter, find, findIndex, get, isArray, isEqual, isNil, isObject, isPlainObject, isString, map, size, some,
  toString,
} from 'lodash';
import {matchSorter} from 'match-sorter';
import PropTypes from 'prop-types';
import {useEffect, useRef, useState} from 'react';
import {Controller, useFormContext} from 'react-hook-form';

import 'components/form/Combobox.scss';
import useAppContext from 'conf/AppContext';
import logger from 'conf/Logger';
import {formOptionsArray, normalizeType, reactSelectOptionProps} from 'conf/types';
import {renderLabel} from 'utils/formUtils';


export const DEFAULT_AUTOCOMPLETE_URL = 'site/autocomplete?view=most';
export const DEFAULT_AUTOCOMPLETE_OPTION = {
  url: DEFAULT_AUTOCOMPLETE_URL,
};


const STATUS = {
  UNINITIALIZED: 'uninitialized',
  INITIALIZED: 'initialized',
  DISABLED: 'disabled',
};

const requiredValidator = (selectedItems) => {
  // undefined means disabled input
  if (selectedItems === undefined) {
    return true;
  } else if (selectedItems === null) {
    return 'Invalid value';
  } else if (isArray(selectedItems) && selectedItems?.length === 0) {
    return 'Must have at least one selected!';
  } else if (isString(selectedItems) && selectedItems.length === 0) {
    return 'Must have at least one selected!';
  }
  return true;
};


const propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      objCls: PropTypes.arrayOf(PropTypes.string),
      // if this Combobox is for autocomplete and addTypeToValue is true, each option's value will include its type
      // (e.g. "gene/PA264" vs. "PA264")
      addTypeToValue: PropTypes.bool,
    }),
    formOptionsArray,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  isMulti: PropTypes.bool,
  value: PropTypes.oneOfType([
    reactSelectOptionProps,
    formOptionsArray,
  ]),
  validation: PropTypes.object,
  required: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  help: PropTypes.string,
  helpLink: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
};
/**
 * A drop-down select field for use in a react-hook-form. If you supply an initial value (either here or in the form
 * component) make sure it's a label/value object or array of label/value objects.
 *
 * @param {object} props - props container
 * @param {string} props.name - the name of the field
 * @param {object | Array<object> | Array<string>} [props.options] - The options for this select field. Use an array
 * for static options. Use an object with the URL of the endpoint to hit for autocomplete.
 * @param {boolean} [props.isMulti] - True to allow selection of multiple options, false otherwise.
 * @param {object | Array<object>} [props.value] - initial value for select field
 * @param {object} [props.validation] - validation for the field
 * @param {bool} [props.required] - marks input as required
 * @param {string|object} props.label - the label for the field
 * @param {string} [props.help] - help text for the field
 * @param {string} [props.helpLink] - the link for the help icon
 * @param {boolean} [props.readOnly] - Marks field as read-only. Will submit.
 * @param {boolean} [props.disabled] - Disables field. Will not submit.
 * @param {string} [props.className] - CSS classes to assign to this element
 * @param {string} [props.style] - CSS style object to apply to the Downshift select element
 */
export default function FormCombobox({name, options, isMulti = false, value, validation, required = false,
  label, help, helpLink, readOnly = false, disabled = false, className, style}) {

  let finalOptions = options;
  // should not allow empty string as value - does not make sense in combo box
  // and simplifies validation later on
  if (isArray(options)) {
    if (options.length > 0 && !isObject(options[0])) {
      let isValid = true;
      finalOptions = map(options, (o) => {
        if (o === '') {
          isValid = false;
        }
        return ({label: o, value: o});
      });
      if (!isValid) {
        logger.error('Options has value with empty string');
        return <div className="btn btn-danger">error - check console</div>;
      }
    }
  } else if (isPlainObject(options) && !options?.url) {
    logger.error('Invalid options');
    return <div className="btn btn-danger">error - check console</div>;
  }

  const isAutocomplete = !isArray(finalOptions);

  const finalValidation = {
    validate: {},
  };
  if (required) {
    finalValidation.validate.required = requiredValidator;
  }
  if (isAutocomplete) {
    finalValidation.validate.minLength = (selectedItems) => {
      if (isString(selectedItems)) {
        return selectedItems.length === 1 ? `Invalid value: ${selectedItems}` : true;
      } else if (selectedItems === null) {
        return 'Invalid value';
      }
    };
  } else {
    finalValidation.validate.validOption = (selectedItems) => {
      if (selectedItems === null) {
        return 'Invalid value';
      }
      if (size(selectedItems) === 0) {
        return true;
      }
      const invalidItems = [];
      const items = isArray(selectedItems) ? selectedItems : [selectedItems];
      for (let x = 0; x < items.length; x += 1) {
        const item = items[x];
        if (!some(finalOptions, (option) => option.value === item.value)) {
          invalidItems.push(item);
        }
      }
      return invalidItems.length > 0 ? `Invalid values: ${map(invalidItems, (i) => i.label ?? i).join(', ')}` : true;
    };
  }
  if (validation) {
    finalValidation.validate = {
      ...finalValidation.validate,
      ...validation,
    };
  }

  return (
    <Controller
      name={name}
      rules={finalValidation}
      defaultValue={value}
      render={(props) => (
        <DownshiftCombobox
          label={label}
          options={finalOptions}
          isMulti={isMulti}
          isAutocomplete={isAutocomplete}
          help={help}
          helpLink={helpLink}
          readOnly={readOnly}
          disabled={disabled}
          className={className}
          style={style}
          {...props}
        />
      )}
    />
  );
}
FormCombobox.propTypes = propTypes;


const downshiftPropTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  options: PropTypes.oneOfType([
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      objCls: PropTypes.arrayOf(PropTypes.string),
      // if this Combobox is for autocomplete and addTypeToValue is true, each option's value will include its type
      // (e.g. "gene/PA264" vs. "PA264")
      addTypeToValue: PropTypes.bool,
    }),
    formOptionsArray,
  ]).isRequired,
  isMulti: PropTypes.bool,
  isAutocomplete: PropTypes.bool,
  help: PropTypes.string,
  helpLink: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  field: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    reactSelectOptionProps,
    formOptionsArray,
  ]),
  formState: PropTypes.object,
  errors: PropTypes.object,
};
function DownshiftCombobox({label, options, isMulti, isAutocomplete, help, helpLink, readOnly, disabled, className, style,
  field: {onChange: rhfOnChange, onBlur, name, value: rhfValue}, formState: {errors}}) {
  const appContext = useAppContext();
  // setRhfValue is only used to set initial value, all other changes go through rhfOnChange
  // defaults to [] in multi-select, '' in single select, undefined if disabled,
  // and null if there are no valid matching option to initial value
  const {setValue: setRhfValue, clearErrors} = useFormContext();
  const [status, setStatus] = useState(STATUS.UNINITIALIZED);


  // normalize rhfValue so that values always follow {label,value} format
  // rhfValue can come from either field or form level
  let finalValue = rhfValue;
  if (!isNil(rhfValue)) {
    if (isArray(rhfValue)) {
      if (rhfValue.length > 0 && !isObject(rhfValue[0])) {
        finalValue = map(rhfValue, (o) => ({label: o, value: o}));
      }
    } else if (!isObject(rhfValue)) {
      if (rhfValue === '') {
        // empty string is not a valid selection
        finalValue = isMulti ? [] : '';
      } else {
        finalValue = {label: rhfValue, value: rhfValue};
      }
    }
  } else {
    finalValue = isMulti ? [] : '';
  }

  // current value of input field
  const [currentValue, setCurrentValue] = useState(isMulti ? '' : finalValue?.label ?? '');
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState(finalValue);
  // used in single select mode to revert current value when esc is hit
  const [prevValidValue, setPrevValidValue] = useState(null);
  // used in multi-select mode to track deleted items to recover when undo is hit
  const [deletedItems, setDeletedItems] = useState([]);
  const inputRef = useRef(null);

  // need to use lodash get because "name" string could include array index notation
  const errorMsgs = disabled ? [] : compact([get(errors, `${name}.message`)]);
  const invalid = errorMsgs.length > 0;

  const findItem = (itemLabel) => find(items, (item) => item.label === itemLabel);
  const findHighlightedItemIndex = (item) => findIndex(options, (option) => (option.value) === item.value);

  // If the component is disabled/read only/has initial values we need to send value to RHF using
  // setRhfValue so that toggling controls in storybook works
  // When we make any change in input element, downshift onChange trigger rhfOnChange

  useEffect(() => {
    if (disabled) {
      setRhfValue(name, undefined, {shouldValidate: true});
      setStatus(STATUS.DISABLED);
      return;
    }

    if (status !== STATUS.INITIALIZED) {
      // on mount, we need to re-initialize RHF because we want to fix field-level default value
      // (on initial submit, selectedItems == finalValues)
      if (status === STATUS.UNINITIALIZED) {
        setRhfValue(name, selectedItems);
      } else {
        // recover from disabled status
        if (isMulti) {
          if (currentValue) {
            setRhfValue(name, currentValue);
          } else {
            setRhfValue(name, selectedItems);
          }
        } else {
          if (selectedItems) {
            setRhfValue(name, selectedItems);
          } else {
            setRhfValue(name, currentValue);
          }
        }
      }
      setStatus(STATUS.INITIALIZED);
    }
  }, [disabled, status]);

  useEffect(() => {
    const loadData = async () => {
      let updatedData;
      if (isAutocomplete) {
        if (size(selectedItems) === 0) {
          if (currentValue.length === 0) {
            setItems([]);
            setRhfValue(name, isMulti ? [] : '');
            return;
          } else if (currentValue.length === 1) {
            setItems([]);
            // null means currentValue doesn't match any options
            setRhfValue(name, null);
            return;
          }
        } else {
          if (currentValue.length < 2) {
            setItems([]);
            return;
          }
        }
        try {
          if (currentValue.length > 1) {
            const {url, addTypeToValue, ...otherArgs} = options;
            const json = {
              size: 5,
              ...otherArgs,
              query: currentValue,
            };
            const rez = await appContext.api.post(url, {json, parseJson: true});
            updatedData = map(rez?.data?.hits, (hit) => ({
              value: addTypeToValue ? `${normalizeType(hit.objCls)}/${hit.id}` : hit.id,
              label: hit.name,
            }));
          }
        } catch (err) {
          // TODO(markwoon): should somehow make it clear to user something is broken
          logger.error(err);
        }
      } else {
        if (!isMulti && currentValue === selectedItems?.label) {
          updatedData = options;
        } else {
          updatedData = (currentValue
            ? matchSorter(options, currentValue, {
              keys: ['label', (item) => item],
              threshold: matchSorter.rankings.WORD_STARTS_WITH,
            })
            : options);
        }
      }

      let filteredData = updatedData;
      if (isMulti) {
        if (selectedItems?.length > 0) {
          filteredData = filter(updatedData, (item) => {
            for (let x = 0; x < selectedItems.length; x += 1) {
              if (isEqual(item, selectedItems[x])) {
                return false;
              }
            }
            return true;
          });
        }
      }
      setItems(filteredData);
    };
    // noinspection JSIgnoredPromiseFromCall
    loadData();
  }, [currentValue, selectedItems]);

  // Set selected items with triggering other input value
  useEffect(() => {
    if (finalValue.length > 0) {
      setSelectedItems(finalValue);
    }
  }, [finalValue]);


  if (readOnly && disabled) {
    logger.error('Select cannot be both readOnly and disabled');
    return <div className="text-danger">error - check console</div>;
  }

  const renderErrors = () => {
    if (errorMsgs.length === 0) {
      return null;
    }
    return (
      <div className="invalid-feedback">
        <ul className="list-unstyled">
          {map(errorMsgs, (m) => <li key={m}>{m}</li>)}
        </ul>
      </div>
    );
  };

  const removeSelectedItem = (key) => {
    setCurrentValue('');
    const filteredSelectedItems = [];

    for (let x = 0; x < selectedItems.length; x += 1) {
      const item = selectedItems[x];
      if (toString(item.value) !== toString(key)) {
        filteredSelectedItems.push(item);
      } else {
        setDeletedItems([item, ...deletedItems]);
      }
    }
    setSelectedItems(filteredSelectedItems);
  };

  const stateReducer = (state, changes) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.changeInput:
        setCurrentValue(changes.inputValue.trim());
        setDeletedItems([]);
        if (changes.inputValue.trim().length > 0) {
          return {...changes, highlightedIndex: 0};
        } else {
          return changes;
        }

      case Downshift.stateChangeTypes.keyDownArrowDown:
      case Downshift.stateChangeTypes.keyDownArrowUp:
        if (isAutocomplete) {
          if (isMulti) {
            return changes;
          } else {
            if (changes.inputValue) {
              setCurrentValue(changes.inputValue);
              return changes;
            } else if (state.inputValue) {
              setCurrentValue(state.inputValue);
              return changes;
            } else {
              setCurrentValue('');
              return changes;
            }
          }
        } else {
          if (state.inputValue) {
            if (items.length === 0) {
              setCurrentValue('');
              return changes;
            } else {
              return changes;
            }
          } else {
            setCurrentValue('');
            return changes;
          }
        }

      case Downshift.stateChangeTypes.mouseUp:
      case Downshift.stateChangeTypes.blurInput:
      case Downshift.stateChangeTypes.blurButton:
        return {...changes, inputValue: state.inputValue};

      case Downshift.stateChangeTypes.keyDownEscape:
        if (isMulti) {
          return {...changes, inputValue: state.inputValue};
        } else {
          if (prevValidValue) {
            return {...changes, inputValue: prevValidValue};
          } else {
            return {...changes, inputValue: state.inputValue};
          }
        }

      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.itemMouseEnter:
      case Downshift.stateChangeTypes.clickItem:
      case Downshift.stateChangeTypes.clickButton:
        return changes;

      case '__undo__':
        if (state.inputValue) {
          return {...changes, inputValue: ''};
        } else if (isMulti && deletedItems.length > 0) {
          return {...changes, inputValue: deletedItems[0].label};
        }
        return changes;

      case '__backspace__':
        if (!state.inputValue && selectedItems?.length > 0) {
          removeSelectedItem(selectedItems[selectedItems.length - 1].value);
        }
        return changes;

      case '__tab__':
        if (findItem(state.inputValue)) {
          return {...changes, highlightedIndex: 0};
        } else {
          return {...changes, highlightedIndex: -1};
        }

      case '__enter__':
        if (isMulti) {
          if (state.inputValue) {
            if (items.length > 0) {
              return {...changes, inputValue: items[0].label};
            } else {
              // null means there are no valid matching option
              rhfOnChange(null);
              return {...changes, inputValue: state.inputValue};
            }
          } else {
            if (selectedItems?.length > 0) {
              return {...changes, inputValue: state.inputValue};
            } else {
              // no input
              rhfOnChange([]);
              return {...changes, inputValue: state.inputValue};
            }
          }
        } else {
          if (state.inputValue) {
            if (selectedItems?.label === state.inputValue) {
              return {...changes, inputValue: state.inputValue};
            } else {
              if (items.length > 0) {
                return {...changes, inputValue: items[0].label};
              } else {
                // null means there are no valid matching option
                rhfOnChange(null);
                return {...changes, inputValue: state.inputValue};
              }
            }
          } else {
            // no input
            rhfOnChange('');
            return {...changes, inputValue: state.inputValue};
          }
        }
      default:
        return changes;
    }
  };

  const handleMultiSelection = (state, stateAndHelpers) => {
    const {inputValue, setState, isOpen} = stateAndHelpers;
    const {type} = state;
    let curItem = findItem(inputValue);

    switch (type) {
      case Downshift.stateChangeTypes.changeInput:
      case Downshift.stateChangeTypes.keyDownArrowUp:
      case Downshift.stateChangeTypes.keyDownArrowDown:
      case Downshift.stateChangeTypes.itemMouseEnter:
      case Downshift.stateChangeTypes.unknown:
      case '__backspace__':
      case '__toggle_menu__':
        clearErrors(name);
        return;
      case '__undo__':
        clearErrors(name);
        if (deletedItems.length > 0) {
          curItem = deletedItems[0];
          setDeletedItems(deletedItems.slice(1));
          break;
        } else {
          return;
        }
      case Downshift.stateChangeTypes.keyDownEscape:
        break;
      case Downshift.stateChangeTypes.clickButton:
        inputRef.current.focus();
        clearErrors(name);
        setCurrentValue('');
        setState({isOpen});
        return;
      default:
    }

    if (size(selectedItems) === 0) {
      if (inputValue) {
        if (curItem) {
          rhfOnChange([curItem]);
          setSelectedItems([curItem]);
          setState({isOpen: false, inputValue: ''});
          setCurrentValue('');
          if (isAutocomplete) {
            setItems([]);
          }
        } else {
          // null means there are no valid matching option
          rhfOnChange(null);
        }
      } else {
        // no input
        rhfOnChange([]);
      }
    } else {
      if (inputValue) {
        if (curItem) {
          rhfOnChange([...selectedItems, curItem]);
          setSelectedItems([...selectedItems, curItem]);
          setState({isOpen: false, inputValue: ''});
          setCurrentValue('');
          if (isAutocomplete) {
            setItems([]);
          }
        } else {
          // null means there are no valid matching option
          rhfOnChange(null);
        }
      } else {
        rhfOnChange(selectedItems);
      }
    }
  };

  const handleSingleSelection = (state, stateAndHelpers) => {
    const {inputValue, setState, highlightedIndex, isOpen} = stateAndHelpers;
    const {type} = state;
    switch (type) {
      case Downshift.stateChangeTypes.changeInput:
      case Downshift.stateChangeTypes.keyDownArrowUp:
      case Downshift.stateChangeTypes.keyDownArrowDown:
      case Downshift.stateChangeTypes.itemMouseEnter:
      case Downshift.stateChangeTypes.unknown:
      case '__backspace__':
      case '__toggle_menu__':
        clearErrors(name);
        return;
      case '__undo__':
        setDeletedItems([]);
        clearErrors(name);
        return;
      case Downshift.stateChangeTypes.clickButton:
        inputRef.current.focus();
        clearErrors(name);
        setCurrentValue('');
        setState({isOpen, highlightedIndex});
        return;
      case Downshift.stateChangeTypes.keyDownEscape:
        if (prevValidValue) {
          rhfOnChange(prevValidValue.selectedItem);
          setSelectedItems(prevValidValue.selectedItem);
          setState({inputValue: prevValidValue.inputValue, isOpen: false});
          setCurrentValue(prevValidValue.inputValue);
          return;
        } else {
          break;
        }
      default:
    }

    if (inputValue) {
      const curItem = findItem(inputValue);
      if (curItem) {
        rhfOnChange(curItem);
        setSelectedItems(curItem);
        if (isAutocomplete) {
          setState({isOpen: false, selectedItem: ''});
        } else {
          setState({isOpen: false, selectedItem: '', highlightedIndex: findHighlightedItemIndex(curItem)});
        }
        setCurrentValue(inputValue);
        setPrevValidValue({
          inputValue: curItem.label,
          selectedItem: curItem,
          highlightedIndex,
        });
      } else {
        setSelectedItems(null);
        // null means there are no valid matching option
        rhfOnChange(null);
      }
    } else {
      // no value
      rhfOnChange('');
    }
  };

  const handleToggleMenu = (event, toggleMenu, isOpen, inputValue) => {
    event.stopPropagation();
    event.preventDefault();

    if (!isOpen && !readOnly) {
      setCurrentValue('');
      if (inputValue) {
        toggleMenu({type: '__toggle_menu__', highlightedIndex: findHighlightedItemIndex(inputValue)});
      } else {
        toggleMenu({type: '__toggle_menu__'});
      }
    }
  };

  const onFocus = (inputValue, setState) => {
    if (readOnly) {
      inputRef.current.blur();
      return;
    }

    clearErrors(name);
    if (inputValue && items.length > 0) {
      setState({isOpen: true, highlightedIndex: findHighlightedItemIndex(inputValue)});
    } else if (inputValue) {
      setCurrentValue('');
      setState({isOpen: true, highlightedIndex: null});
    }
  };

  const onKeyDown = (event, setState) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      setState({type: '__enter__', isOpen: false});
    } else if (event.key === 'Backspace') {
      setState({type: '__backspace__', isOpen: true});
    } else if (event.key === 'Tab') {
      setState({type: '__tab__', isOpen: false});
    } else if (event.key === 'ArrowDown') {
      setState({type: Downshift.stateChangeTypes.keyDownArrowDown, isOpen: true});
    } else if (event.key === 'ArrowUp') {
      setState({type: Downshift.stateChangeTypes.keyDownArrowUp, isOpen: true});
    } else if ((event.ctrlKey === true || event.metaKey === true) && event.key === 'z') {
      setState({type: '__undo__', isOpen: false});
    }
  };

  const formGroupReadOnlyProps = {};
  if (readOnly) {
    formGroupReadOnlyProps.disabled = true;
  }

  return (
    <Downshift
      initialInputValue={currentValue}
      initialSelectedItem={selectedItems}
      onStateChange={isMulti ? handleMultiSelection : handleSingleSelection}
      itemToString={(item) => (item?.label ?? item)}
      stateReducer={stateReducer}
    >
      {({
        // prop getters
        getLabelProps,
        getInputProps,
        getItemProps,
        getMenuProps,
        getToggleButtonProps,

        // actions
        clearSelection,
        setState,
        toggleMenu,

        // states
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem,
      }) => {
        // click handler to delete selected value in multi-select
        let deleteHandler;
        // click handler for clearing input value in single select autocomplete
        let clearInput;
        // used to highlight selected option in single select
        let selectedValue;
        const editable = !(disabled || readOnly);
        if (isMulti) {
          if (editable) {
            deleteHandler = (event) => {
              event.stopPropagation();
              inputRef.current.focus();
              const closestAncestor = event.target.closest('button[data-id]');
              if (closestAncestor && closestAncestor.getAttribute('data-id')) {
                removeSelectedItem(closestAncestor.getAttribute('data-id'));
              }
              setState({isOpen: true});
            };
          }
        } else {
          selectedValue = selectedItems?.value;
          if (isAutocomplete && editable && (selectedItem || inputValue)) {
            clearInput = () => {
              clearSelection();
              inputRef.current.focus();
              setCurrentValue('');
            };
          }
        }
        // hide input if not editable and something is selected
        const showInput = editable || !isMulti || selectedItems?.length === 0 || currentValue?.length > 0;

        return (
          <div className="formCombobox" style={style}>
            <div
              className={clsx('form-group', className, {'is-invalid': invalid, 'text-muted': !editable})}
              {...formGroupReadOnlyProps}
            >
              <label
                {...getLabelProps({
                  className: clsx({'text-danger': invalid}),
                })}
              >
                {renderLabel(label, helpLink)}
              </label>
              <div
                className={clsx('formCombobox__select', {
                  'formCombobox__select--disabled': disabled,
                  'formCombobox__select--readOnly': readOnly,
                })}
              >
                {isMulti && renderSelectedItems(selectedItems, deleteHandler)}
                {showInput && (
                  <div className="formCombobox__input">
                    <input
                      {...getInputProps({
                        ref: inputRef,
                        name,
                        type: 'text',
                        spellCheck: 'false',
                        disabled,
                        readOnly,
                        onBlur,
                        className: clsx('form-control', {'is-invalid': invalid}),
                        onClick: (event) => handleToggleMenu(event, toggleMenu, isOpen, inputValue),
                        onFocus: () => onFocus(inputValue, setState),
                        onKeyDown: (event) => onKeyDown(event, setState, inputValue),
                      })}
                    />
                    {clearInput && (
                      <button className="btn formCombobox__input__clear" onClick={clearInput} tabIndex={-1}>
                        <FontAwesomeIcon icon="times" />
                      </button>
                    )}
                    {renderMoreButton({isAutocomplete, editable, getToggleButtonProps, isOpen})}
                  </div>
                )}
              </div>
              <Menu
                getMenuProps={getMenuProps}
                getItemProps={getItemProps}
                items={items}
                isOpen={isOpen}
                disabled={disabled}
                highlightedIndex={highlightedIndex}
                selectedValue={selectedValue}
              />
              {help && <small className="form-text text-muted">{help}</small>}
              {renderErrors()}
            </div>
          </div>
        );
      }}
    </Downshift>
  );
}
DownshiftCombobox.propTypes = downshiftPropTypes;


function renderMoreButton({isAutocomplete, editable, getToggleButtonProps, isOpen}) {
  if (isAutocomplete) {
    return null;
  }
  const baseParams = {
    className: 'btn formCombobox__input__more',
    tabIndex: -1,
  };
  const params = editable ? getToggleButtonProps(baseParams) : {...baseParams, disabled: true};
  return (
    <button {...params}>
      <FontAwesomeIcon icon={isOpen ? 'chevron-up' : 'chevron-down'} />
    </button>
  );
}

function renderSelectedItems(selectedItems, deleteHandler) {
  if (size(selectedItems) === 0) {
    return null;
  }
  return (
    <>
      {
        map(selectedItems, (item) => (
          <div key={item.value} className="formCombobox__hit">
            {item.label}
            {deleteHandler && (
              <button title={`remove ${item.label}`} data-id={item.value} onClick={deleteHandler} tabIndex={-1}>
                <FontAwesomeIcon icon="times" size="sm" />
              </button>
            )}
          </div>
        ))
      }
    </>
  );
}

function Menu({getMenuProps, getItemProps, items, isOpen, disabled, highlightedIndex, selectedValue}) {
  if (!isOpen || (items?.length ?? 0) === 0) {
    return null;
  }
  const menuProps = getMenuProps({className: 'list-group'});
  return (
    <ul {...menuProps} key={menuProps?.id}>
      {map(items, (item, index) => (
        <Item
          {...getItemProps({
            index,
            item,
            disabled,
            className: clsx({
              'list-group-item-secondary': index === highlightedIndex && item.value !== selectedValue,
              'list-group-item-info': item.value === selectedValue,
            }),
          })}
          key={item.value}
        >
          {item.label}
        </Item>
      ))}
    </ul>
  );
}

function Item({className, children, ...rest}) {
  return (
    <li {...rest} className={clsx('list-group-item', className)}>
      {children}
    </li>
  );
}

export const mapAccId = (a) => {
  if (!a) {
    return null;
  } else {
    return {
      id: a.id,
      label: a.symbol || a.name,
      value: a.id,
    };
  }
};

export const mapOntTerm = (a) => {
  if (!a) {
    return null;
  } else {
    return {
      id: a.id,
      label: a.term,
      value: a.id,
    };
  }
};
