import {filter, find, forEach, indexOf, isEmpty, isNull, map, sum} from 'lodash';
import numeral from 'numeraljs';
import PropTypes from 'prop-types';

import './FrequencyTable.scss';
import Loading from 'components/Loading';
import SimpleTable from 'components/Table/Simple';
import {fixedColumnStyle} from 'components/Table/columnHelpers';
import ErrorMessage from 'components/errors/ErrorMessage';
import KyError from 'components/errors/KyError';
import InfoLink from 'components/links/Info';
import Link from 'components/links/Link';
import {useGet} from 'helpers/KyHooks';


const aggregatedPopulations = {
  '1000_Genomes': {
    ALL: ['AFR', 'AMR', 'EAS', 'EUR', 'SAS'],
  },
  ALFA: {
    // total
    SAMN10492705: ['SAMN10492703', 'SAMN10492704', 'SAMN10492695', 'SAMN10492702', 'SAMN10492699', 'SAMN10492700',
      'SAMN11605645'],
  },
  gnomadGenome: {
    ALL: ['AFR', 'AMI', 'AMR', 'ASJ', 'EAS', 'FIN', 'NFE', 'SAS', 'OTH'],
  },
  gnomadExome: {
    ALL: ['AFR', 'AMI', 'AMR', 'ASJ', 'EAS', 'FIN', 'NFE', 'SAS', 'OTH'],
  },
};

const subPopulations = {
  '1000_Genomes': {
    AFR: ['ACB', 'ASW', 'ESN', 'GWD', 'LWK', 'MSL', 'YRI'],
    AMR: ['CLM', 'MXL', 'PEL', 'PUR'],
    EAS: ['CDX', 'CHB', 'CHS', 'JPT', 'KHV'],
    EUR: ['CEU', 'FIN', 'GBR', 'IBS', 'TSI'],
    SAS: ['BEB', 'GIH', 'ITU', 'PJL', 'STU'],
  },
  ALFA: {
    // ALFA african
    SAMN10492703: ['SAMN10492698', 'SAMN10492696'],
    // ALFA asian
    SAMN10492704: ['SAMN10492697', 'SAMN10492701'],
  },
};

const gnomadPopulationKeys = {
  ALL: 'All populations',
  AFR: 'African/African American',
  AMI: 'Amish',
  AMR: 'Latino',
  ASJ: 'Ashkenazi Jewish',
  EAS: 'East Asian',
  FIN: 'Finnish',
  NFE: 'Non-Finnish European',
  SAS: 'South Asian',
  OTH: 'Other',
};

const populationKeys = {
  '1000_Genomes': {
    // all populations
    ALL: 'All populations',

    // super populations
    AFR: 'African',
    AMR: 'Ad Mixed American',
    EAS: 'East Asian',
    EUR: 'European',
    SAS: 'South Asian',

    // individual populations
    ACB: 'African Caribbeans in Barbados',
    ASW: 'Americans of African Ancestry in SW USA',
    BEB: 'Bengali from Bangladesh',
    CDX: 'Chinese Dai in Xishuangbanna, China',
    CEU: 'Utah Residents (CEPH) with Northern and Western European Ancestry',
    CHB: 'Han Chinese in Beijing, China',
    CHS: 'Southern Han Chinese',
    CLM: 'Colombians from Medellin, Colombia',
    ESN: 'Esan in Nigeria',
    FIN: 'Finnish in Finland',
    GBR: 'British in England and Scotland',
    GIH: 'Gujarati Indian from Houston, Texas',
    GWD: 'Gambian in Western Divisions in the Gambia',
    IBS: 'Iberian Population in Spain',
    ITU: 'Indian Telugu from the UK',
    JPT: 'Japanese in Tokyo, Japan',
    KHV: 'Kinh in Ho Chi Minh City, Vietnam',
    LWK: 'Luhya in Webuye, Kenya',
    MSL: 'Mende in Sierra Leone',
    MXL: 'Mexican Ancestry from Los Angeles USA',
    PEL: 'Peruvians from Lima, Peru',
    PJL: 'Punjabi from Lahore, Pakistan',
    PUR: 'Puerto Ricans from Puerto Rico',
    STU: 'Sri Lankan Tamil from the UK',
    TSI: 'Toscani in Italia',
    YRI: 'Yoruba in Ibadan, Nigeria',
  },
  ALFA: {
    SAMN10492695: 'European',
    SAMN10492698: 'African American',
    SAMN10492696: 'African Others',
    SAMN10492703: 'African',
    SAMN10492697: 'East Asian',
    SAMN10492702: 'South Asian',
    SAMN10492701: 'Other Asian',
    SAMN10492704: 'Asian',
    SAMN10492699: 'Latin American 1',
    SAMN10492700: 'Latin American 2',
    SAMN11605645: 'Other',
    SAMN10492705: 'All populations',
  },
  gnomadExome: gnomadPopulationKeys,
  gnomadGenome: gnomadPopulationKeys,
};


const propTypes = {
  variantId: PropTypes.string.isRequired,
  source: PropTypes.oneOf(['1000_Genomes', 'ALFA', 'gnomadGenome', 'gnomadExome']),
};
/**
 * Renders the variant frequency table.
 *
 * @param {object} props - component props
 * @param {string} props.variantId - variant ID
 * @param {string} [props.source]
 */
export default function VariantFrequencyTable({variantId, source = '1000_Genomes'}) {
  const {response, error} = useGet('report/variantFrequency', {
    searchParams: {
      fp: variantId,
      source,
    },
    cacheResponse: true,
  });

  if (error) {
    return <KyError kyError={error} />;
  }
  if (isNull(response)) {
    return <Loading />;
  }

  const {populations, bases} = response?.data || {};
  if (!populations?.length) {
    return <p className="empty">No frequencies available.</p>;
  }

  const colorsArray = ['#c53b3b', '#497de3', '#00B774', '#ffd878'];
  const baseColorMap = {};
  forEach(bases, (base, index) => {
    baseColorMap[base] = colorsArray[index];
  });

  /** @type {object[]} */
  let tableData = [];
  const initialState = {
    expanded: {
      0: true,
    },
  };
  let usePopIdForName = true;
  // aggregate population key current frequency source
  let aggregateId;
  let popHref;
  let blurb;

  const getTableData = () => {
    const aggregationIds = Object.keys(aggregatedPopulations[source]);
    tableData = filter(populations, (pop) => (indexOf(aggregationIds, pop?.population) !== -1));
    for (let x = 0; x < tableData.length; x += 1) {
      const pop = tableData[x];
      const aggPops = aggregatedPopulations[source][pop.population];
      pop.subRows = filter(populations, (p) => (indexOf(aggPops, p?.population) !== -1));
      if (pop.subRows.length > 0) {
        for (let y = 0; y < pop.subRows.length; y += 1) {
          const aggPop = pop.subRows[y];
          const subPops = subPopulations?.[source]?.[aggPop.population];
          if (subPops) {
            aggPop.subRows = filter(populations, (p) => (indexOf(subPops, p?.population) !== -1));
            // uncomment this to auto-expand sub-populations:
            // initialState.expanded[`0.${y}`] = true;
          }
        }
      }
    }
  };

  if (source === '1000_Genomes') {
    popHref = 'http://www.internationalgenome.org/category/population/';
    blurb = (
      <p>
        Based on data from
        the <Link href="http://www.internationalgenome.org/">1000 Genomes project</Link>, phase III.
      </p>
    );
    getTableData();
    // noinspection JSUnusedAssignment
    tableData.sort((a, b) => a.population.localeCompare(b.population));
    aggregateId = 'ALL';
  } else if (source === 'ALFA') {
    popHref = 'https://www.ncbi.nlm.nih.gov/snp/docs/gsr/alfa/';
    blurb = (
      <p>
        Based on data
        from the <Link href={popHref}>Allele Frequency Aggregator</Link> (ALFA).
      </p>
    );
    getTableData();
    const popKeys = populationKeys[source];
    // noinspection JSUnusedAssignment
    tableData.sort((a, b) => popKeys[a.population].localeCompare(popKeys[b.population]));
    usePopIdForName = false;
  } else if (source === 'gnomadGenome' || source === 'gnomadExome') {
    popHref = 'https://gnomad.broadinstitute.org/faq#what-populations-are-represented-in-the-gnomad-data';
    const type = source === 'gnomadExome' ? 'v2 exome' : 'v3 genome';
    blurb = (
      <p>
        Based on {type} data from the <Link href="https://gnomad.broadinstitute.org/">Genomes Aggregation Database</Link>
        {' '}
        (gnomAD) retrieved from the <Link href="https://useast.ensembl.org/Homo_sapiens/Info/Index">Ensembl</Link> API.
      </p>
    );
    getTableData();
    aggregateId = 'ALL';
  } else {
    return <ErrorMessage title={`Unsupported source for variant frequencies: '${source}'`} />;
  }


  const popInfoLink = popHref ? <InfoLink href={popHref} tooltip="Population Definitions" newTab={true} /> : '';
  const populationColumn = {
    id: 'population',
    accessor: (pop) => pop,
    // eslint-disable-next-line react/prop-types
    Cell: ({value: pop}) => {
      const totalN = sum(map(pop.bases, (b) => b.size));
      const popId = pop.population;
      const title = populationKeys[source][popId];
      let name;
      if (usePopIdForName && pop.population !== aggregateId) {
        name = <abbr title={title} aria-label={title}>{pop.population}</abbr>;
      } else {
        name = title;
      }
      return (
        <>
          {name}
          <div className="ml-1 variantFrequency__population__size">n={numeral(totalN).format('0,0')}</div>
        </>
      );
    },
    Header: <>Aggregated Populations {popInfoLink}</>,
    ...fixedColumnStyle(240),
    className: 'variantFrequency__population',
  };

  const formatChartData = (pop) => {
    const chartData = {};
    const chartKeys = {};
    chartData.name = pop.population;
    forEach(pop.bases, (base) => {
      chartData[base.base] = base.freq;
      chartKeys[base.base] = true;
    });
    return {data: [chartData], keys: Object.keys(chartKeys).sort()};
  };
  const distributionColumn = {
    id: 'freqDistDisplay',
    accessor: (pop) => pop,
    // eslint-disable-next-line react/prop-types
    Cell: ({value: pop}) => {
      const chartData = formatChartData(pop);
      return (
        <div className="progress" role="presentation">
          {map(chartData.keys, (key) => {
            const barWidth = numeral(chartData.data[0][key]).format('0.00%');
            return (
              <div
                className="progress-bar"
                style={{width: barWidth, backgroundColor: baseColorMap[key]}}
                key={key}
                title={barWidth}
              >
                {key}
              </div>
            );
          })}
        </div>
      );
    },
    Header: 'Distribution',
    className: 'variantFrequency__distribution',
  };


  // eslint-disable-next-line react/prop-types
  const renderBase = (data) => {
    if (data) {
      const {base, freq, size} = data;
      const title = `n=${numeral(size).format('0,0')}`;
      return <span style={{color: baseColorMap[base]}} title={title}>{numeral(freq).format('0.00%')}</span>;
    }
    return '';
  };
  const buildBaseColumn = (base) => ({
    id: isEmpty(base) ? 'del' : base,
    accessor: (pop) => find(pop?.bases, (b) => b.base === base),
    Cell: ({value}) => renderBase(value),
    sortable: true,
    Header: isEmpty(base) ? 'del' : base,
    ...fixedColumnStyle(80),
    className: 'variantFrequency__allele',
  });


  const columns = [populationColumn, distributionColumn];
  forEach(bases, (base) => {
    columns.push(buildBaseColumn(base));
  });

  return (
    <>
      {blurb}
      <SimpleTable
        className="variantFrequency table-sm"
        canResizeColumns={false}
        // reactTableProps
        columns={columns}
        data={tableData}
        initialState={initialState}
      />
    </>
  );
}
VariantFrequencyTable.propTypes = propTypes;
