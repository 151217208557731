import {map} from 'lodash';

import Link from 'components/links/Link';
import Fact from 'components/shared/fact';
import FactSection from 'components/shared/fact_section';
import Types from 'conf/types';

export default function GuidelineRelatedTab({guidelineChemicals, guidelineGenes}) {
  return (
    <FactSection title="Related" halfWidth={true}>
      <p className="font-italic">These chemicals and genes are part of PharmGKB annotations on this guideline. This is
        not an exhaustive list of all chemicals and genes mentioned in the guideline.
      </p>

      <Fact label="Annotated Chemicals" halfWidth={true}>
        {map(guidelineChemicals, (g) => (
          <div key={g.id}><Link href={Types.chemical.url(g.id)}>{g.name}</Link></div>
        ))}
      </Fact>

      <Fact label="Annotated Genes" halfWidth={true}>
        {map(guidelineGenes, (g) => (
          <div key={g.id}><Link href={Types.gene.url(g.id)}>{g.symbol}</Link></div>
        ))}
      </Fact>
    </FactSection>
  );
}
