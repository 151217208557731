import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

/**
 * Creates an icon that is visually one icon stacked on top of another icon
 *
 * @param {object} props - props container
 * @param {string|Array<string>} props.bottomIcon - the icon for the bottom layer
 * @param {string|Array<string>} props.topIcon - the icon to show over the bottom layer
 * @param {string} props.topIconColor - the color for the top icon, defaults to red
 * @param {string} props.size - optional scaling size for the icon
 * @return {JSX.Element}
 */
export default function LayeredIcon({bottomIcon, topIcon, topIconColor = 'red', size = ''}) {
  const sizeCls = !size ? '' : `fa-${size}`;
  return (
    <span className={clsx('fa-layers', 'fa-fw', sizeCls)}>
      <FontAwesomeIcon icon={bottomIcon} />
      <FontAwesomeIcon icon={topIcon} color={topIconColor} />
    </span>
  );
}
LayeredIcon.propTypes = {
  bottomIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  topIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  topIconColor: PropTypes.string,
  size: PropTypes.string,
};
