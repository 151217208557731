import {debounce, filter, isEqual, map, uniqBy} from 'lodash';
import PropTypes from 'prop-types';
import React, {useContext, useMemo, useState} from 'react';

import {stringifySearchParams} from 'components/links/utils';
import useAppContext from 'conf/AppContext';
import logger from 'conf/Logger';


export const SearchBarContext = React.createContext(undefined);

export const pinnableTypes = [
  'chemical', 'disease', 'gene', 'haplotype', 'literature', 'variant', 'combination',
];

const MIN_AUTOCOMPLETE_QUERY_LENGTH = 2;


const propTypes = {
  children: PropTypes.node,
};
/**
 * This is a React.Context.Provider for the search bar service.
 *
 * @param {object} props
 * @param {React.node} props.children
 */
export function SearchBarProvider({children}) {
  const appContext = useAppContext();
  const [searchState, setSearchState] = useState({query: '', pinnedHits: [], typeFilters: [], origPinnedHits: []});
  const [autocomplete, setAutocomplete] = useState(null);

  const isSearchPage = () => /\/search$|\/search\?/.test(appContext.getCurrentUrl());

  const hasPinned = () => searchState.pinnedHits?.length > 0;

  const generateSearchUrl = ({pinnedHits, query, typeFilters}, offset = 0) => {
    const params = {
      query,
    };
    const pinned = map(pinnedHits, (h) => `[${h.objCls}:${h.id}:"${h.name}"]`).join();
    if (pinned) {
      params.pinned = pinned;
    }
    if (offset) {
      params.offset = offset;
    }
    if (isSearchPage()) {
      params.type = typeFilters;
    }
    return `/search?${stringifySearchParams(params)}`;
  };

  /**
   * Update autocomplete results.
   */
  const _updateAutocomplete = debounce(async (newState) => {
    const {query, pinnedHits, typeFilters} = newState;
    if (query?.length >= MIN_AUTOCOMPLETE_QUERY_LENGTH) {
      const connections = map(pinnedHits, (hit) => `${hit.objCls}/${hit.id}`);
      try {
        const rez = await appContext.api.post('site/autocomplete', {
          json: {
            query,
            connections,
            objCls: typeFilters,
            size: 5,
          },
          parseJson: true,
        });
        setAutocomplete(rez.data);
      } catch (err) {
        if (err?.response?.status !== 429) {
          logger.error('Error looking up autocomplete', err);
        }
      }
    } else {
      setAutocomplete(null);
    }
  }, 250);


  const initializeSearchBar = (pinnedHits = []) => {
    if (!isSearchPage()) {
      if (pinnedHits.length === 1) {
        if (!pinnableTypes.includes(pinnedHits[0].objCls) || pinnedHits[0].notLoaded) {
          return;
        }
      }
      const newState = {
        pinnedHits,
        query: '',
        typeFilters: [],
        origPinnedHits: pinnedHits,
      };
      if (!isEqual(searchState, newState)) {
        setSearchState(newState);
      }
      if (autocomplete) {
        setAutocomplete(null);
      }
    }
  };

  /**
   * Updates pinned hits and resets query.
   */
  const updatePins = (pinnedHits) => {
    if (!isSearchPage()) {
      const newPins = pinnedHits ? uniqBy(pinnedHits, (p) => p.id) : searchState.pinnedHits;
      const newState = {
        pinnedHits: newPins,
        query: '',
        typeFilters: searchState.typeFilters,
        origPinnedHits: newPins,
      };
      setSearchState(newState);
    }
  };

  const resetPins = () => {
    if (!isSearchPage()) {
      const newState = {
        pinnedHits: searchState.origPinnedHits,
        query: searchState.query,
        typeFilters: searchState.typeFilters,
        origPinnedHits: searchState.origPinnedHits,
      };
      if (!isEqual(searchState, newState)) {
        setSearchState(newState);
      }
      if (searchState.query) {
        _updateAutocomplete(newState);
      } else if (autocomplete) {
        setAutocomplete(null);
      }
    }
  };


  /**
   * Updates current query.
   *
   * @param {string} query
   */
  const updateQuery = (query) => {
    const newState = {
      query: query || '',
      pinnedHits: searchState.pinnedHits,
      typeFilters: searchState.typeFilters,
      origPinnedHits: searchState.origPinnedHits,
    };
    setSearchState(newState);
    // noinspection JSValidateTypes
    _updateAutocomplete(newState);
  };

  /**
   * Removes a pinned hit.
   *
   * @param {string} id - ID of pinned hit to remove
   */
  const removePinnedHit = (id) => {
    const pinnedHits = filter(searchState.pinnedHits, (h) => h.id !== id);
    if (pinnedHits.length !== searchState.pinnedHits.length) {
      const newState = {
        pinnedHits,
        query: searchState.query,
        typeFilters: searchState.typeFilters,
        origPinnedHits: searchState.origPinnedHits,
      };
      setSearchState(newState);
      // noinspection JSValidateTypes
      _updateAutocomplete(newState);
      if (isSearchPage()) {
        appContext.redirect(generateSearchUrl(newState));
      }
    }
  };


  /**
   * Redirects to search page.
   */
  const goToSearchPage = () => {
    appContext.redirect(generateSearchUrl(searchState));
  };

  const providerProps = useMemo(() => ({
    isSearchPage,
    initializeSearchBar,
    updatePins,
    resetPins,
    showAutocomplete: () => searchState.query?.length >= MIN_AUTOCOMPLETE_QUERY_LENGTH && !!autocomplete,
    autocomplete,
    hasPinned,
    state: searchState,
    setState: setSearchState,
    updateQuery,
    removePinnedHit,
    generateSearchUrl,
    goToSearchPage,
  }), [searchState, autocomplete]);

  return (
    <SearchBarContext.Provider value={providerProps}>
      {children}
    </SearchBarContext.Provider>
  );
}
SearchBarProvider.propTypes = propTypes;


const useSearchBar = () => {
  const context = useContext(SearchBarContext);
  if (!context) {
    throw new Error('SiteSearchContext must be used within SiteSearchProvider');
  }
  return context;
};
export default useSearchBar;
