import clsx from 'clsx';
import {map} from 'lodash';
import {matchSorter} from 'match-sorter';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';

import './index.scss';
import './Searchable.scss';
import Loading from 'components/Loading';
import useUniqueId from 'hooks/uniqId';

/**
 * Count mode determines how filtered results are displayed.
 */
export const COUNT_MODE = {
  /** Do not display count. */
  NONE: 0,
  /** Display number of items (uses `singularNoun` property).  */
  LIST: 1,
  /** Display number of results.  */
  RESULTS: 2,
  /** Display filters. */
  FILTERED: 3,
  /** Display sorted values */
  SORTED: 4,
};

const propTypes = {
  collection: PropTypes.arrayOf(PropTypes.object),
  renderItemFn: PropTypes.func.isRequired,
  /**
   * Name of item being rendered.  Used for counts and if there are no items to display.
   */
  singularNoun: PropTypes.string.isRequired,
  /**
   * Array of property names (on object in collection) to use for search.
   */
  searchKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * The minimum number of items in collection before search box is rendered.
   */
  minSearchSize: PropTypes.number,
  /**
   * Determines if the number of results should be displayed, and if the `collection` is a list or search results.
   * Defaults to `COUNT_MODE.NONE`.
   */
  countMode: PropTypes.number,
  className: PropTypes.string,
  extraControls: PropTypes.node,
};
/**
 * Renders a searchable list of Accession objects.
 */
export default function SearchableList({
  collection,
  renderItemFn,
  singularNoun,
  searchKeys,
  minSearchSize = 2,
  countMode = COUNT_MODE.NONE,
  className,
  extraControls,
}) {
  const queryId = useUniqueId({prefix: 'list-'});
  const [search, setSearch] = useState('');
  const [results, setResults] = useState([]);

  useEffect(() => {
    setResults(sortItems(collection, search, countMode, searchKeys));
  }, [collection]);

  const getResultVerb = () => {
    if (search || countMode === COUNT_MODE.FILTERED) {
      return ' match your query';
    } else if (countMode === COUNT_MODE.RESULTS) {
      return ' found';
    } else {
      return '';
    }
  };

  const renderEmpty = () =>
    <div className="list__empty empty">No {pluralize(singularNoun)}{getResultVerb()}.</div>;

  const updateFilter = (evt) => {
    const matches = sortItems(collection, evt.target.value, countMode, searchKeys);
    setSearch(evt.target.value);
    setResults(matches);
  };

  const renderFilter = () => {
    let count = '';
    if (countMode !== COUNT_MODE.NONE && results.length > 0) {
      count = (
        <div className="searchableList__count">
          {pluralize(singularNoun, results.length, true)}{getResultVerb()}
        </div>
      );
    }

    return (
      <>
        <div className="searchableList__filters">
          <div className="searchableList__filters__search">
            <label htmlFor={queryId}>Search</label>
            <input id={queryId} type="text" value={search} onChange={updateFilter} className="form-control" />
          </div>
          {extraControls}
        </div>
        {count}
      </>
    );
  };

  let filteredData = '';
  let body;
  if (!collection) {
    body = <Loading />;
  } else {
    if (countMode === COUNT_MODE.FILTERED || collection.length > minSearchSize) {
      filteredData = renderFilter();
    }

    if (results.length === 0) {
      body = renderEmpty();
    } else {
      body = map(results, renderItemFn);
    }
  }

  return (
    <div className={clsx('list searchableList', className)}>
      {filteredData}
      {body}
    </div>
  );
}
SearchableList.propTypes = propTypes;

/**
 * Sort and filter items
 *
 * @param {Array<object>} items
 * @param {string} value
 * @param {object} countMode
 * @param {Array<string>} searchKeys
 * @return {Array}
 */
function sortItems(items, value, countMode, searchKeys) {
  if (countMode === COUNT_MODE.FILTERED || countMode === COUNT_MODE.SORTED) {
    return matchSorter(items, value, {
      keys: searchKeys,
      baseSort: (a, b) => (a.index < b.index ? -1 : 1),
    });
  } else if (value) {
    return matchSorter(items, value, {
      keys: searchKeys,
      threshold: matchSorter.rankings.CONTAINS,
    });
  } else {
    return items;
  }
}
