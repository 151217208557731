import clsx from 'clsx';
import {camelCase, some} from 'lodash';
import PropTypes from 'prop-types';
import {lazy, Suspense} from 'react';

import './index.scss';
import {renderAlternateDrugTag} from 'components/Tag/AlternateDrug';
import {renderBiomarkerTag} from 'components/Tag/Biomarker';
import {renderBpcaDrugTag} from 'components/Tag/BpcaDrug';
import {renderDosingInfoTag} from 'components/Tag/DosingInfo';
import {renderCancerTag, renderIndicationTag, renderPrescribingTag} from 'components/Tag/LabelAnnotation';
import {renderOtherGuidanceTag} from 'components/Tag/OtherGuidance';
import {renderPediatricTag} from 'components/Tag/Pediatric';
import {renderRetiredTag} from 'components/Tag/Retired';
import InfoIcon from 'components/icons/Info';
import InfoLink from 'components/links/Info';
import useUniqueId from 'hooks/uniqId';
import {extractProps} from 'utils/propUtils';

// noinspection JSCheckFunctionSignatures
const ReactTooltip = lazy(() =>
  import(/* webpackChunkName: "tooltip" */'react-tooltip'));


const propTypes = {
  /** If provided, this info icon will be displayed with the specified text as the tooltip. */
  tooltipText: PropTypes.string,
  /** If provided, this info icon will link to specified URL. */
  tooltipUrl: PropTypes.string,
  /**
   * Determines if the tooltip link opens in a new tab.
   * Forces link to be opened in a new tab if true, or in the same tab if false.
   * If undefined, it will default to opening external links in a new tab.
   */
  tooltipNewTab: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
};

export default function Tag({tooltipUrl, tooltipText, tooltipNewTab = true, className, style, children,
  ...otherProps}) {
  const tooltipId = useUniqueId();

  const renderTooltip = () => {
    if (!tooltipText) {
      return '';
    }
    if (tooltipUrl) {
      return <InfoLink href={tooltipUrl} tooltip={tooltipText} newTab={tooltipNewTab} className="tag__tooltip" />;
    }
    // ReactTooltip needs to be a sibling or descendent of the element with data-tip,
    // otherwise we get a rendering error in Chrome.
    return (
      <span className="tag__tooltip">
        <InfoIcon data-for={tooltipId} data-tip={tooltipText} /><span className="sr-only">{tooltipText}</span>
        <Suspense>
          <ReactTooltip id={tooltipId} effect="solid" />
        </Suspense>
      </span>
    );
  };

  return (
    <span className={clsx('tag', className)} style={style} {...extractProps(otherProps)}>
      <span className="tag__label">
        {children}
      </span>
      {renderTooltip()}
    </span>
  );
}
Tag.propTypes = propTypes;


/**
 * Render tag if applicable.
 *
 * @param {object} params
 * @param {object} params.tag
 * @param {object} params.obj
 * @param {string} params.dbProp
 * @param {string} params.indexProp
 * @param {boolean} [params.showHelp]
 * @param {string} [params.className]
 */
export function renderTagIfPresent({tag, obj, dbProp, indexProp, showHelp = true, className}) {
  let showTag = false;
  if (obj.tags) {
    if (obj.tags.includes(indexProp)) {
      showTag = true;
    }
  } else {
    showTag = !!obj[dbProp];
  }
  if (showTag) {
    const T = tag;
    return <T key={indexProp} showHelp={showHelp} className={className} context={camelCase(obj.objCls)} />;
  }
  return null;
}


export function renderTags({obj, showHelp, className, containerClassName}) {
  const tags = {
    altDrug: renderAlternateDrugTag({obj, showHelp, className}),
    biomarker: renderBiomarkerTag({obj, showHelp, className}),
    bpcaDrug: renderBpcaDrugTag({obj, showHelp, className}),
    cancer: renderCancerTag({obj, showHelp, className}),
    dosingInfo: renderDosingInfoTag({obj, showHelp, className}),
    otherGuidance: renderOtherGuidanceTag({obj, showHelp, className}),
    pediatric: renderPediatricTag({obj, showHelp, className}),
    prescribing: renderPrescribingTag({obj, showHelp, className}),
    retired: renderRetiredTag({obj, showHelp, className}),
    indication: renderIndicationTag({obj, showHelp, className}),
  };
  if (!some(Object.keys(tags), (k) => !!tags[k])) {
    return null;
  }
  return (
    <div className={clsx('tagContainer', containerClassName)}>
      {tags.retired}
      {tags.dosingInfo}
      {tags.altDrug}
      {tags.otherGuidance}
      {tags.prescribing}
      {tags.biomarker}
      {tags.cancer}
      {tags.pediatric}
      {tags.bpcaDrug}
      {tags.indication}
    </div>
  );
}
