import PropTypes from 'prop-types';

import InfoLink from 'components/links/Info';
import useAppContext from 'conf/AppContext';


export function renderInfoLink(helpLink) {
  if (helpLink) {
    if (helpLink.indexOf('pharmgkb.atlassian.net/wiki') !== -1) {
      return <WikiLink href={helpLink} />;
    } else {
      return <small><InfoLink href={helpLink} iconClassName="icon--superscript" iconSize="2xs" /></small>;
    }
  }
  return null;
}



/**
 * This is a wrapper around InfoLink that is curator-only. This is meant to link off to documentation at the
 * curator wiki so curators can get more information about what to do in a particular tool/page.
 *
 * @param {object} props - props container
 * @param {string} props.href - a url to the curation wiki
 * @return {React.ReactNode}
 */
function WikiLink({href}) {
  const appContext = useAppContext();
  return (
    appContext.isPreview && (
      <small><InfoLink href={href} iconClassName="icon--superscript" iconSize="2xs" /></small>
    )
  );
}
WikiLink.propTypes = {
  href: PropTypes.string.isRequired,
};
