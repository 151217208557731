import PropTypes from 'prop-types';


const propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
};
/**
 * Renders an array of Strings as a comma-separated string.
 */
export default function StringsCell({value}) {
  if (!value) {
    return '';
  }
  return value.sort().join(', ');
}
StringsCell.propTypes = propTypes;
