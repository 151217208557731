import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


const propTypes = {
  value: PropTypes.bool,
};
/**
 * Renders a boolean value as a checked checkbox.
 */
export default function BooleanCell({value}) {
  if (!value) {
    return '';
  }
  return (
    <div className="text-center">
      <FontAwesomeIcon icon={['far', 'check-square']} />
    </div>
  );
}
BooleanCell.propTypes = propTypes;
